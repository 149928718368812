import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import PublicIcon from '@mui/icons-material/Public';
import { Box, Divider, Stack,Dialog,
  DialogActions,
  DialogContent,
  DialogProps, } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { USER_APP_BASE_URL } from '../../configurations/url.config';
import { Link,} from "react-router-dom";
import Close from '@mui/icons-material/Close';




interface CustomModalProps {
  isOpen: boolean;
  handleClose: () => void;
  coverImageSrc: string;
  dpSrc: string;
  title: string;
  city: string;
  members: [];
  communityId: string;
  handleCopyLink: () => void;
  copied: boolean;
  status: string;
  whatsappShareUrl: string;
  facebookShareUrl: string;
  linkedinShareUrl: string;
  twitterShareUrl: string;
  link: string;
}

const ShareCommunityPost: React.FC<CustomModalProps> = ({
  isOpen,
  handleClose,
  coverImageSrc,
  dpSrc,
  title,
  city,
  members,
  communityId,
  handleCopyLink,
  status,
  copied,
  whatsappShareUrl,
  facebookShareUrl,
  linkedinShareUrl,
  twitterShareUrl,
  link
}) => {

  const [maxImgWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const [fullWidth] = React.useState(true);


  const maxLength = 6; // Maximum length for the title

  const truncatedTitle = title?.length > maxLength ? title.substring(0, maxLength) : title;
  
  const label = `${USER_APP_BASE_URL}/${truncatedTitle}`;
  

 


  return (
    <>
              {/* Pop Up for Image Display */}
              <Stack sx={{padding:'0px'}}>
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxImgWidth}
                open={isOpen}
                onClose={handleClose}
                

              >
                <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ backgroundColor: '#F0F9FF' }}
                  >
                    <Stack></Stack>
                    <Stack>

                    </Stack>
                    <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                      <Box
                        onClick={handleClose}
                        sx={{
                          backgroundColor: "#3C5AA0",
                          padding: "4px",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontSize: "10px",
                          mt: "0.5px",
                          mr: "10px",
                          cursor: "pointer",
                          "&:hover": {
                            // backgroundColor: "#50A1CA", // Background color on hover
                            cursor: "pointer",
                            border: "none",
                          },
                        }}
                      >
                        <Close sx={{ fontSize: "15px" }} />
                      </Box>
                    </Stack>
                  </Stack>
                </DialogActions>


                <DialogContent sx={{padding:'0px'}}>
                {/* <div
        style={{
          marginTop: "18vh",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "white",
          borderRadius: "15px",
          maxWidth: "600px",
          height: "auto",
          justifyContent: 'center',
          alignContent: 'center',
        }}
      > */}
        {/* Top Section - Cover image */}
        <div style={{ position: "relative", padding:'0px' }}>
          <img src={coverImageSrc} alt="Cover" style={{
            width: "100%",
            height: "auto",  // Set height to auto to maintain aspect ratio
            maxHeight: 200,  // Set the maximum height
            objectFit: "cover",  // Use object-fit to handle the cropping
            position: "relative",
          }} />
          <div style={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            color: "white",
            padding: "5px",
            top: '20%',
            left: "95%",

          }}>
            {/* <Typography sx={{
              textAlign: 'right',
              color: "#4B4B4B",
              cursor: 'pointer',
              padding: '10px',
              fontSize: '15px'
            }}
              onClick={handleClose}><CancelIcon sx={{ color: '#505050', fontSize: '35px' }}> </CancelIcon></Typography> */}
          </div>
        </div>
        {/* Bottom Section - Description */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#ffffff",
            marginTop: "-18px",
            position: 'relative',
            borderRadius: "20px 20px 0px 0px",
            borderTop: '1px solid #A9A9A9',
            boxShadow: '0px -5px 5px -5px #A9A9A9',
            
             // Adjust values as needed
          }}
        >
          {/* Add display picture (DP) */}
          <Box sx={{ position: "relative" }}>
            <Avatar
              src={dpSrc}
              alt="DP"
              sx={{
                width: "100px",
                height: "100px",
                position: "relative",
                top: "-60px",
                zIndex: 1, // Ensure the Avatar is on top of the white circle
              }}
            />
            <Avatar
              sx={{
                backgroundColor: "#ffffff",
                width: "120px", // Adjust the size to fit your design
                height: "120px", // Adjust the size to fit your design
                borderRadius: "100%",
                position: "absolute",
                top: "-70px", // Adjust the top position to align with the Avatar
                left: "-10px", // Adjust the left position to align with the Avatar
                zIndex: 0, // Set a lower zIndex to place it beneath the Avatar
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Add a box shadow
              }}
            />
          </Box>
          {/* Add text below */}
          <Typography
            sx={{ fontWeight: "700", fontSize: "18px", mt: "-40px" }}
            variant="body1"
            gutterBottom
          >
            {title.length > 50 ? title.slice(0, 50) + '...' : title}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {city} | {} Members
          </Typography>

          <Stack direction={"row"} sx={{
            mt: 2
          }}>
            <Chip
              label={label}
              icon={<PublicIcon sx={{fontSize:'18px'}} />}
              component="a"
              href={`${USER_APP_BASE_URL}/communities/${communityId}/about`}
              target="_blank"
              clickable
              sx={{
                backgroundColor: '#f1f1f1',
                fontSize: '12px',
                textTransform: 'lowercase',
                "@media (max-width: 600px)": {
                  marginBottom: "1rem",
                },
              }}
            />
            <InputAdornment position="end">
              <Button
                sx={{
                  backgroundColor: "#f1f1f1",
                  textTransform: "capitalize",
                  color: "#636363",
                  fontWeight: 'normal',
                  mt: 4,
                  fontSize: "12px",
                  display:{xs:'none', md:'flex',},
                  "&:hover": { backgroundColor: "#f1f1f1" },
                  borderRadius: "20px 20px 20px 20px",
                }}
                variant="text"
                color="primary"
                startIcon={<FileCopyOutlinedIcon />}
                onClick={handleCopyLink}
              >
                {copied ? 'Copied!' : 'Copy'}
              </Button>
            </InputAdornment>
            {link && (
              <Typography
                component={Link}
                to={link}
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#50A1CA",
                  textAlign: "right",
                  alignItems: "end",
                  cursor: "pointer",
                  textDecoration: "underline",
                  mt: 1,
                  ml: 1
                }}
              >
                Go to Publish
              </Typography>
            )}
          </Stack>
        </div>

        <Box sx={{ pt: 2 }}>
          <Stack
            gap={1}
            direction="row"
            sx={{
              fontWeight: 400,
              // pt: 1,
              mx: 2,
              // pb: 3,
              color: "#636363",
              justifyContent: "center",
              fontFamily: "Roboto",
              alignContent: "center",
            }}
          >
            {/* <Button
              sx={{
                color: "white",

                textTransform: "capitalize",
                backgroundColor: "#3B9B7F",
                "&:hover": {
                  backgroundColor: "#3B9B7F",
                },
              }}
              variant="contained"
              startIcon={<ShareIcon />}

            >
              <Typography
                sx={{

                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: 'Roboto',

                }}
              >
                Share With
              </Typography>
            </Button> */}
          </Stack>

          <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  pb: 3,
                  mx: 2,
                }}
              >
                <TextField
                  size="small"
                  sx={{
                    borderRadius: '100px',
                    width:{xs:'80%', sm:'80%', md:"calc(50% - 5px)",},
                    '&:hover': { border: 'none' },
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            textTransform: 'capitalize',
                            color: '#ffffff',
                            fontSize: { xs: "14px", md: "16px", },
                            fontWeight: 600,
                            backgroundColor: '#3B9B7F',
                            cursor: 'default',
                            p:{xs:1, md:0.7},
                            fontFamily:"Inter",
                            '&:hover': { backgroundColor: '#3B9B7F' },
                            ml: '-15px',
                            borderRadius: '20px 0px 0px 20px',
                          }}
                        >
                          Share With
                        </Button>
                        <Divider orientation="vertical" sx={{ height: 38 }} />
                        <Stack
                          direction="row"
                          spacing={{ xs: 2, md: 1.6 }}
                          alignItems="center"
                          ml="10px"
                          sx={{ pt: 1, ml: { xs: "5px", md: "8px", } }}
                        >
                          {whatsappShareUrl && (
                            <a
                              href={whatsappShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <WhatsAppIcon
                                sx={{
                                  fontSize: {xs:"20px",md:"30px",},
                                  color: '#25d366',
                                }}
                              />
                            </a>
                          )}
                          {facebookShareUrl && (
                            <a
                              href={facebookShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FacebookIcon
                                sx={{
                                  fontSize: {xs:"20px",md:"30px",},
                                  color: '#3b5998',
                                }}
                              />
                            </a>
                          )}
                          {linkedinShareUrl && (
                            <a
                              href={linkedinShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkedInIcon
                                sx={{
                                  fontSize: {xs:"20px",md:"30px",},
                                  color: '#1976d2',
                                }}
                              />
                            </a>
                          )}
                          {twitterShareUrl && (
                            <a
                              href={twitterShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TwitterIcon
                                sx={{
                                  fontSize: {xs:"20px",md:"30px",},
                                  color: '#1da1f2',
                                }}
                              />
                            </a>
                          )}
                        </Stack>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '100px',
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                />
              </Box>
        </Box>
      {/* </div> */}

                </DialogContent>
              </Dialog>

              </Stack>

             

    
    </>
  );
};

export default ShareCommunityPost;
