import React from 'react';
import { Button, CircularProgress } from '@mui/material';
interface CustomButtonProps {
  loading: boolean,
  children: string,
  icon?: React.ReactNode;
  background: string;
  textColor: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disable: boolean
}
const CustomButton: React.FC<CustomButtonProps> = ({ children, icon, background, textColor, onClick, disable, loading }) => {

  return (
    <Button 
      sx={{
        width: 'fit-content',
        height: "30px",
        borderRadius: '10px',
        background: background,
        color: textColor,
        textTransform: 'capitalize',
        px: 2,
        fontWeight: '500',
        fontSize: '12px',
        border: "1px solid gray",
        '&:hover': {
          background: background,
          cursor: 'pointer',
          border: "1px solid gray",
          boxShadow: 'none',
        },
        '& .MuiButton-startIcon': {
          mr: '2px', // Adjust this value as needed
        },
      }}
      startIcon={!loading && icon}
      onClick={onClick}
      disabled={disable}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : children}
    </Button>
  );
};

export default CustomButton;
