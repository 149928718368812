// paymentService.ts

import axios from 'axios';

import { BASE_URL } from "../configurations/url.config";

export const getAllPaymentModes = async (token: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/payment/modes`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return { status: 500, data: [] };
    }
  };

  export const getAllSuperAdminsBankAccounts = async (token: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/bankaccounts/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return { status: 500, data: [] };
    }
  };
