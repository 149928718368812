
import {
  Box,
  Button,
  Card,
  Typography,
  Stack,
} from "@mui/material";
import CommunityBrief from "./CommunityBrief";
import { useNavigate } from "react-router";
import 'simplebar-react/dist/simplebar.min.css';
import { Suggestions } from "./SuggestionsCommunity/Suggestions";

const HomeRightBar = () => {
  
  const navigate = useNavigate();

  const handleCreateNew = () => {
    navigate("/communities/add");
  };


  return (
    <Box
      sx={{
        display: { xs: "none", md: "none", lg: "block" },
        position: "sticky",

        overflowY: "auto",
      }}
    >
      <Box maxWidth={{ xs: 350, xl: 460 }} sx={{  }}>
        <Stack spacing={1.5}>
          <CommunityBrief />
          <Card sx={{ padding: 1.5 }} elevation={0}>
            <Button
              fullWidth
              sx={{
                padding: "0.5vw",
                backgroundColor: "#3C5AA0",
                color: "white",
                fontFamily:'Inter',
                "&:hover": { backgroundColor: "#3C5AA0" },
              }}
              onClick={handleCreateNew}
            >
              Create Community
            </Button>
          </Card>
        </Stack>
        <Box
          sx={{
           
            alignItems: "center",
            // maxWidth: 400, 
            // height:'34vh',
            // backgroundColor:'#FFFFFF',
            pt: 1,
            
            borderRadius: "10px",
          }}
        >
          <Typography
            
            sx={{ color: "#787878", fontWeight: 500, fontSize: "14px",pb: 1, }}
          >
            Suggestions for you
          </Typography>
          <Box sx={{height:'39vh', backgroundColor:'white', borderRadius:'10px'}}>
          <Suggestions />
          </Box>
          {/* <SuggestionsCommunity /> */}
          {/* <SuggestionsList /> */}
        </Box>
      </Box>
    </Box>
  );
};
export default HomeRightBar;
