import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import React, { Component } from 'react'

export interface ITextContentCell {
    title: any;
    subTitle?: number | string;
    icon?: Component
}
export const TextContentCell = ({ title, subTitle, icon }: ITextContentCell) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"flex-start"}
            spacing={0}
        >
            {icon && <>{icon}</>}
            <Box>
                {title && <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                    {title}
                </Typography>}
                {subTitle && <Typography
                    sx={{ m: 0, fontSize: "11px !important", color: "#565656" }}
                >
                    {subTitle}
                </Typography>
                }
            </Box>
        </Stack>
    )
}
