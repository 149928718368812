import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";

import HomePost from "../../../Home/HomePost";

import store from "../../../../store";
import { useSelector } from "react-redux";
import { ICommunity } from "../../../../models/communities.modal";

import { useCommunity } from "../../../../hooks/useCommunity";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../../utils/constants";

const Profile: React.FC = () => {
  // const navigate = useNavigate();
  const { getCommunityById } = useCommunity();
 

  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);



  // eslint-disable-next-line
  const [communityProfile, setCommunityProfile] = useState<ICommunity>();
  const state = store.getState();
  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });
  const id = selectedCommunity.selectedCommunity?._id;

  useEffect(() => {
    if (!id) return;
    const fetchCommunity = async () => {
      setLoading(true);
      const communityData = await getCommunityById(id);
      setLoading(false);
      setCommunityProfile(communityData?.data);
    };

    fetchCommunity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // const handleToggleContents = () => {
  //   setShowFullContents((prevShowFullContents) => !prevShowFullContents);
  // };

  // const Notification = [
  //   {
  //     type: "Payment",
  //     date: new Date(),
  //     title: "Payment received from Mr. Gautam Rai",
  //     description: "",
  //   },
  //   {
  //     type: "Community",
  //     date: new Date(),
  //     title: "ParamSukha Org created by Naman Arora",
  //     description: "",
  //   },
  //   {
  //     type: "Community",
  //     date: new Date(),
  //     title: "ParamSukha Org created by Naman Arora",
  //     description: "",
  //   },
  //   {
  //     type: "Plan",
  //     date: new Date(),
  //     title: "New plan is activated by Arjun Sharma",
  //     description: "",
  //   },
  //   {
  //     type: "Plan",
  //     date: new Date(),
  //     title: "New plan is activated by Arjun Sharma",
  //     description: "",
  //   },
  //   {
  //     type: "User",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  //   {
  //     type: "User",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  //   {
  //     type: "Support",
  //     date: new Date(),
  //     title: "Inquire generate by Anil Kumar",
  //     description: "",
  //   },
  //   {
  //     type: "Support",
  //     date: new Date(),
  //     title: "Inquire generate by Anil Kumar",
  //     description: "",
  //   },
  //   {
  //     type: "User",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  //   {
  //     type: "Prajwal",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  //   {
  //     type: "R",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  //   {
  //     type: "k",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  //   {
  //     type: "i",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  //   {
  //     type: "ya",
  //     date: new Date(),
  //     title: "Re-activate by Sheela lalwani",
  //     description: "",
  //   },
  // ];

  // const handleToggleContent = () => {
  //   setShowFullContent(!showFullContent);
  // };

  // const inviteHandler = () => {
  //   setInvited(true);
  // };

  // const followHandler = () => {
  //   setFollowed(true);
  // };

  // const blockHandler = () => {
  //   setBlocked(!blocked);
  // };

  // const reportHandler = () => {
  //   alert("You reported this profile");
  // };
  //single file upload

  // const handleImageChange = async () => {
  //   const fileInput = document.createElement("input");
  //   fileInput.type = "file";
  //   fileInput.accept = "image/*";

  //   fileInput.addEventListener("change", async (event) => {
  //     const file = (event.target as HTMLInputElement).files?.[0];
  //     if (file) {
  //       try {
  //         const fileUploadResult = await uploadCommunitySingleFile(
  //           file,
  //           "logo",
  //           id ?? ""
  //         );
  //         if (fileUploadResult?.data) {
  //           console.log("File uploaded successfully");
  //         } else {
  //           console.error("Response is undefined");
  //         }
  //       } catch (error) {
  //         console.error("Error uploading file:", error);
  //       }
  //     }
  //   });

  //   fileInput.click();
  // };
  // const handleImageLoad = () => {
  //   setLoading(false);
  // };

  /* Cover Image Edit */

  // const handleEditCoverImage = () => {
  //   const fileInput = document.createElement("input");
  //   fileInput.type = "file";
  //   fileInput.accept = "image/*";

  //   fileInput.addEventListener("change", async (event) => {
  //     const file = (event.target as HTMLInputElement).files?.[0];
  //     if (file) {
  //       try {
  //         if (file) {
  //           try {
  //             const fileUploadResult = await uploadCommunitySingleFile(
  //               file,
  //               "banner",
  //               id ?? ""
  //             );
  //             if (fileUploadResult?.data) {
  //               console.log("File uploaded successfully");
  //             } else {
  //               console.error("Response is undefined");
  //             }
  //           } catch (error) {
  //             console.error("Error uploading file:", error);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error uploading file:", error);
  //       }
  //     }
  //   });

  //   fileInput.click();
  // };

  // const handleDrop = (e: any) => {
  //   console.log(e.dataTransfer.files[0]);
  // };

  // open Modal Style

  // const bannerStyle = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: { xs: 400, md: 800 },
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  //   boxShadow: 24,
  //   p: 4,
  // };

  // const BannerRef = useRef<HTMLInputElement>(null);

  // const handleClickOnUploadBanner = () => {
  //   BannerRef.current?.click();
  //   // console.log("handleClick",BannerRef.current)
  // };

  // const handleUploadBanner = (e: any) => {
  //   const selectedFiles = e.target.files;
  //   console.log("selectedFile", selectedFiles);
  //   const selectedFilesArray = Array.from(selectedFiles);
  //   if (selectedFilesArray.length > 3) {
  //     return alert("you must select 3 images only");
  //   }
  //   const imagesArray = selectedFilesArray.map((file: any) => {
  //     return URL.createObjectURL(file);
  //   });
  //   setSelectedBannerUrl((previousImage) => previousImage.concat(imagesArray));
  // };

  // const handleClickOnRemoveBanner = () => {
  //   setSelectedBannerUrl([]);
  // };

  // const MainBannerClick = (img: any) => {
  //   setBannerImageUrl(img);
  //   setBannerImageOpen(true);
  // };

  // const onBannerImageHover = () => {
  //   setBannerImageHover(true);
  // };
  // const onBannerImageLeave = () => {
  //   setBannerImageHover(false);
  // };

  // const deleteSingleBanner = (i: any) => {
  //   const newArr = selectedBannerUrl.filter((item, index) => index !== i);
  //   setSelectedBannerUrl(newArr);
  // };

  //emoji selector logic

  // const [inputStr, setInputStr] = useState("");
  // const [showPicker, setShowPicker] = useState(false);

  // const onEmojiClick = (event: any, emojiObject: { emoji: string }) => {
  //   setInputStr((prevInput) => prevInput + emojiObject.emoji);
  //   setShowPicker(false);
  // };

  // dialog box for uploading new posts

  // const [openDialog, setOpenDialog] = useState(false);

  // const handlePaperClick = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  // progress bar functionality for community creation

  // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 10,
  //   borderRadius: 5,
  //   [`&.${linearProgressClasses.colorPrimary}`]: {
  //     backgroundColor: "#EFEFEF",
  //     // theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
  //     // backgroundColor: theme.palette.mode === "light" ? "#EFEFEF" : "#EFEFEF",
  //   },
  //   [`& .${linearProgressClasses.bar}`]: {
  //     borderRadius: 5,
  //     backgroundColor: theme.palette.mode === "light" ? "#7FC41B" : "#7FC41B",
  //   },
  // }));
  // const completionPercentage = 54;

  //  like icon logics

  // const [favHeartTouch, setFavHeartTouch] = useState(false);

  // const handleHeartClick = (e: React.MouseEvent<HTMLElement>) => {
  //   e.stopPropagation(); // Prevent the click event from bubbling up to the parent div (handleClickData)

  //   setFavHeartTouch(!favHeartTouch);
  // };

  // const handleFavoriteIconClick = (title: string) => {
  //   console.log("Favorite icon clicked for:", title);
  //   // Implement your logic for handling favorite icon click here
  // };

  // menu to copyFile,edit and delete posts
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // leave community dialog popup

  // const [openDialogg, setOpennDialogg] = useState(false);

  // const handleLeaveCommunity = () => {
  //   setOpennDialogg(true);
  // };

  // const handleCloseDialogg = () => {
  //   setOpennDialogg(false);
  // };

  // const handleConfirmLeave = () => {
  //   // Perform leave community action here
  //   // ...

  //   setOpennDialogg(false); // Close the dialog after leave action is performed
  // };

  // const handleEditCommunity = () => {
  //   navigate(`/communities/${selectedCommunity?.selectedCommunity?.id}`);
  // };
  return (
    <>
      <Helmet>
      <Helmet>
      <title>{metaConfig.title.myProfile}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      </Helmet>
      {/* <Container maxWidth="xl" sx={{ mb: 1 }}> */}
      <Grid container spacing={1} sx={{ height: "auto" }}>
        {/* <Grid
          item
          // xs={6}
          md={4}
          lg={3.5}
          xl={3.5}
          display={{ xs: "none", md: "none", lg: "block", xl: "block" }}
          sx={{ ml: { lg: "2vw", xl: "2vw" } }}
        >
          <CommunityBrief />
        </Grid> */}
      </Grid>
      {/* </Container> */}
      {/* <Container maxWidth="xl"> */}
      <Grid container spacing={1}>
        {/* <Grid item lg={8} xs={12} sx={{ ml: "50px" }}> */}
        <Grid item sm={12} xs={12} md={12}>
          {/* <HomeCreatePost /> */}

          <HomePost />
        </Grid>

        {/* <Grid
          item
          xs={6}
          md={4}
          lg={3.5}
          xl={3.5}
          display={{ xs: "none", lg: "block" }}
          sx={{ ml: { lg: "2vw", xl: "2vw" }, mt: 1 }}
        >
          <div
            style={{
              position: "sticky",
              top: "20px",
              maxHeight: "calc(100vh - 20px)",
              overflowY: "auto",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                pl: "10px",
                pr: "10px",
                pb: "10px",
                height: "100%",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", pt: "10px" }}
              >
                Notifications
              </Typography>
              <Notifications data={Notification} />
            </Paper>
          </div>
        </Grid> */}
      </Grid>
      {/* </Container> */}
    </>
  );
};

export default Profile;
