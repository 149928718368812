import React from "react";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Paper,
  Stack,
} from "@mui/material";
import { AddPlan } from "../Plans/AddPlan";
import store from "../../store";
import { useSelector } from "react-redux";
import Close from "@mui/icons-material/Close";

// Use lowercase for the function name
export const NoPlans = () => {
  const [openPlans, setOpenPlans] = React.useState(false);
  // const [isAddNewInDialog, setIsAddNewInDialog] = React.useState(false);
  // const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const state = store.getState();

  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });

  const communitiesSuggestion = useSelector(() => {
    return state?.communities;
  });
  // const filteredSelectedCommunity = communitiesSuggestion.communities.filter(
  //   (community) => community._id === selectedCommunity.selectedCommunity?.id
  // );

  let filteredSelectedCommunity :any=[] ;//= communitiesSuggestion?.communities?.filter(
    //   (community: any) =>
    //     community._id === selectedCommunity.selectedCommunity?.id
    // );
    if (Array.isArray(communitiesSuggestion?.communities)) {
      // Filter the array if it is not null or undefined
       filteredSelectedCommunity = communitiesSuggestion.communities.filter(
          (community: any) =>
              community._id === selectedCommunity.selectedCommunity?._id
      );
      // Use the filtered array here
  } 

  // const handleOpenAddNew = () => {
  //   if (openPlans) {
  //     setIsAddNewInDialog(true);
  //   } else {
  //     window.location.href = "/plans/new";
  //   }
  // };

  // const handleClickOpenPlans = () => {
  //   setOpenPlans(true);
  // };

  const handleClose = () => {
    setOpenPlans(false);
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Stack
          spacing={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingBottom: "10px", p: 2 }}
        >
          <Card elevation={0}>
            <CardMedia
              component="img"
              alt="No posts"
              image={require("./../../assets/icons/svg-icons/noPlans.png")}
              sx={{
                objectFit: {xs:"contain", md:"cover"},
                width: "100%", // Set the width to 100% to fill the container
                height: "40vh", // Set the height to 100% to fill the container
              }}
            />
          
          </Card>
          <Typography sx={{color:'#777777', fontSize:{xs:'13px', md:'15px'}, fontWeight:'500', fontFamily:'Inter'}}>No Plans Available</Typography>
          {/* <Typography>No plans created yet</Typography> */}
          {/* <Typography>Be the first one to share something with the community</Typography> */}
          <Stack direction={"row-reverse"} flexGrow={1}>
            {/* <Button
              variant="contained"
              onClick={handleClickOpenPlans}
              sx={{
                textTransform: "capitalize",
                borderRadius: "30px",
                backgroundColor: "#3C5AA0",
                boxShadow:'none',
                "&:hover": {
                  backgroundColor: "#50A1CA", // Background color on hover
                  cursor: "pointer",
                  border: 'none',
                }
              }}
            >
              Create Plan
            </Button> */}
            <Dialog
              fullWidth
              maxWidth={maxWidth}
              open={openPlans}
              onClose={handleClose}
            >
              <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ backgroundColor: '#F0F9FF' }}
                >
                  <Stack></Stack>
                  <Stack>

                  </Stack>
                  <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                    <Box
                      onClick={handleClose}
                      sx={{
                        backgroundColor: "#3C5AA0",
                        padding: "4px",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: "10px",
                        mt: "0.5px",
                        mr: "10px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#50A1CA", // Background color on hover
                          cursor: "pointer",
                          border: "none",
                        },
                      }}
                    >
                      <Close sx={{ fontSize: "15px" }} />
                    </Box>
                  </Stack>
                </Stack>
              </DialogActions>
              <DialogContent>
                <AddPlan
                  selectedCommunity={filteredSelectedCommunity}
                  closeDialog={handleClose} loadPlans={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </DialogContent>
            </Dialog>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
