import React from "react";
import {  Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getStaticValue } from "../../../utils/StaticValues";

export interface IStatusCell {
  text: string;
  type: "error" | "warning" | "info" | "success";
  slug: string;
}

export const RoleTypeCell = ({ text, type, slug }: IStatusCell) => {
  const styles = {
    backgroundColor: "red",
    color: "#3C5AA0",
    border: " 1px solid #3C5AA0",
    width: "100px",
    textAlign: "center",
  };

  const getStyles = (status: string) => {
    switch (status) {
      case "success":
        styles.backgroundColor = "white";
        styles.color = "#3C5AA0";
        styles.border = "1px solid #3C5AA0";
        break;
      case "warning":
        styles.backgroundColor = "white";
        styles.color = "#3B9B7F";
        styles.border = "1px solid #3B9B7F";
        break;
      case "error":
        styles.backgroundColor = "white";
        styles.color = "#3C5AA0";
        styles.border = " 1px solid #3C5AA0";
        break;
    }
    return styles;
  };

  return (
    <>
      <Box
        sx={{
          padding: "3px",
          borderRadius: "5px",
          ...getStyles(type),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <AddIcon sx={{ marginRight: "3px", fontSize: "15px" }} />
        <Typography variant="body1">{getStaticValue(text)}</Typography>
      </Box>
      <Typography variant="body1" sx={{ textAlign: "center", width: "100px" }}>
        {slug}
      </Typography>
    </>
  );
};
