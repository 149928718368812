import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Stack } from "@mui/system";
import { CustomTableHeader } from "./components/CustomTableHeader";
import { IAction, IColumn } from "../../models/table.model";
import { ContactCell } from "./components/ContactCell";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import React from "react";
import { getStaticValue } from "../../utils/StaticValues";
import { RoleTypeCell } from "./components/RoleTypeCell";
import { CommunityMembers, IPlan } from "../../models/community-user.model";
import { useSelector } from "react-redux";
import { useCommunity } from "../../hooks/useCommunity";
import { useUsers } from "../../hooks/useUsers";
import { useSnackbar } from "notistack";
import { AdminPerson } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

interface ITableProps {
  data: CommunityMembers[];
  columns: IColumn[];
  onActionClick: (
    a: IAction,
    data: any,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => void;
  // checkboxes: { [key: string]: boolean };
  //  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  loadUserList: () => void;
}

export const UsersTable = ({
  columns,
  data,
  onActionClick,
  loadUserList,
}: ITableProps) => {
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<CommunityMembers[]>(data);

  const [sortDirections, setSortDirections] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});

  const loggedInUserData = useSelector((state: any) => state.loggedInUser);

  useEffect(() => {
    setTableData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: "asc" | "desc" | undefined } = {};
      let newSortedColumns: string[] = [];

      // Toggle the sorting direction for the clicked column
      if (prevSortDirections[columnTitle] === "asc") {
        newSortDirections[columnTitle] = "desc";
      } else {
        newSortDirections[columnTitle] = "asc";
      }

      // Include the clicked column in the list of sorted columns
      newSortedColumns.push(columnTitle);

      setSortedColumns(newSortedColumns);

      return newSortDirections;
    });
  };
  const sortedData = [...tableData];

  const sortedColumnTitles = sortedColumns?.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );

  if (sortedColumnTitles?.length > 0) {
    sortedData?.sort((a: CommunityMembers, b: CommunityMembers) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns?.find((col) => col.title === columnTitle);

        if (column) {
          if (column.dataKey === "user") {
            // Sorting for the "admin" column based on the "name" property
            const aValue = `${a.user?.firstName} ${a.user?.lastName}`;
            const bValue = `${b.user?.firstName} ${b.user?.lastName}`;

            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column.dataKey === "contact") {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.user?.emailId;
            const bValue = b?.user?.emailId;

            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column?.dataKey === "community") {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.community?.name;
            const bValue = b?.community?.name;

            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column.dataKey === "subscription") {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.subscription;
            const bValue = b?.subscription;

            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          }
          if (column.dataKey === "roleType") {
            // Sorting for the "admin" column based on the "name" property
            const aValue = a?.type;
            const bValue = b?.type;

            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "joinedOn") {
            // Sorting for the "category" column
            const aValueDate = new Date(a?.createdAt);
            const bValueDate = new Date(b?.createdAt);

            if (aValueDate.getTime() !== bValueDate.getTime()) {
              // If the dates are not the same, sort based on date
              if (sortDirection === "asc") {
                return aValueDate.getTime() - bValueDate.getTime();
              } else {
                return bValueDate.getTime() - aValueDate.getTime();
              }
            } else {
              // If dates are the same, include time for sorting
              const aValueTime = new Date(
                `${a?.createdAt}T${a?.createdAtTime}`
              );
              const bValueTime = new Date(
                `${b?.createdAt}T${b?.createdAtTime}`
              );

              if (sortDirection === "asc") {
                return aValueTime.getTime() - bValueTime.getTime();
              } else {
                return bValueTime.getTime() - aValueTime.getTime();
              }
            }
          } else if (column.dataKey === "status") {
            // Sorting for the "created/updated" column based on date and time
            const aValue = a?.status;
            const bValue = b?.status;

            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else {
            // If dates are the same, include time for sorting
            // const aValueTime = new Date(
            //   `${a.joinedOn}T${a.joinedOn}`
            // );
            // const bValueTime = new Date(
            //   `${b.joinedOn}T${b.joinedOn}`
            // );
            //   if (sortDirection === "asc") {
            //     return aValueTime.getTime() - bValueTime.getTime();
            //   } else {
            //     return bValueTime.getTime() - aValueTime.getTime();
            //   }
            // }
          }
          // Default sorting for other columns
          // const aValue = a[column.dataKey];
          // const bValue = b[column.dataKey];

          if (sortDirection === "asc") {
            //  return aValue.localeCompare(bValue);
          } else {
            // return bValue.localeCompare(aValue);
          }
        }
      }
      return 0;
    });
  }

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    // console.log(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    handleChangePage(null, 0);
  }, [data]);
  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);
  // const maxRowsPerPage = tableData.length;
  // const rowsPerPageOptions = [1, 2, 5, 10].filter(
  //   (option) => option <= maxRowsPerPage
  // );
  const { communityUserRole } = useCommunity();
  const { leaveCommunityUserById } = useUsers();

  const [openModals, setOpenModals] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<CommunityMembers | null>(null);
  const [roleSwitch, setRoleSwitch] = useState<boolean>(false);
  const [slug, setSlug] = useState(selectedRow?.slug);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (row: CommunityMembers) => {
    setSelectedRow(row);
    if (loggedInUserData.user.id !== selectedRow?.user._id) {
      setOpenModals(true);
      setRoleSwitch(row.type === "Admin" || row.type === "ADMIN");
      setSlug(row.slug);
    }
  };

  const handleClose = () => {
    setOpenModals(false);
  };

  const HandleAssignSubmit = async () => {
    if (selectedRow) {
      try {
        setIsLoading(true);
        const type = roleSwitch ? "ADMIN" : "USER";
        const userId = selectedRow._id;
        const community_id = selectedRow.community._id;

        const formData = {
          type: type,
          slug: slug,
        };
        await communityUserRole(
          community_id,
          userId,
          formData
        );

        enqueueSnackbar("Role Set Successfully", { variant: "success" });
        loadUserList();
        const updatedTableData = tableData.map((row: CommunityMembers) =>
          row._id === selectedRow._id
            ? { ...row, type: type, slug: slug || "" }
            : row
        );
        setTableData(updatedTableData);
      } catch (error) {
        console.log("error", error);
        enqueueSnackbar("An error occured while setting a role", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
        handleClose();
      }
    }
  };

  //delete user
  const [deleteAnchorEl, setDeleteAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const openDeletePopover = Boolean(deleteAnchorEl);
  const idDeletePopover = openDeletePopover ? "delete-popover" : undefined;

  // const handleDeleteUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
  //   setDeleteAnchorEl(e.currentTarget);
  // };

  const handleDeleteClosePopover = () => {
    setDeleteAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDeleteUserById = async () => {
    if (selectedRow) {
      await leaveCommunityUserById(selectedRow._id);
      handleDeleteClosePopover();
    }
  };

  //status

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statusPopoverAnchor, setStatusPopoverAnchor] =
    useState<null | HTMLElement>(null);
  const openPopOver = Boolean(statusPopoverAnchor);
  const idPopOver = openPopOver ? "simple-popover" : undefined;
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handlePopOverClick = () => {
    //  if( selectedRow?.addedBy !== selectedRow?.user?._id){
    setStatusPopoverAnchor(anchorEl);
    handleMenuClose();
    //  }
  };
  const handleCloseStatusPopOver = () => {
    setStatusPopoverAnchor(null);
  };
  const handleStatusUpdate = async () => {
    if (selectedRow) {
      try {
        setIsLoading(true);
        const userId = selectedRow._id;
        const communityId = selectedRow?.community._id;
        const newStatus = selectedRow.status === "ACTIVE" ? "BLOCK" : "ACTIVE";

        const formData = {
          status: newStatus,
        };
        await communityUserRole(communityId, userId, formData);
        enqueueSnackbar("Status updated Successfully", { variant: "success" });
        handleCloseStatusPopOver();
        setSelectedRow(null);

        const updatedData = data.map((item) =>
          item._id === selectedRow._id ? { ...item, status: newStatus } : item
        );
        setTableData(updatedData);
        loadUserList();
      } catch (error) {
        console.log("error", error);
        enqueueSnackbar("An error occured while updating a Status", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };


  const navigate = useNavigate();
  const handlenavigateUser = (id: string) => {
    navigate(`/posts/profile/${id}`);
  };
  return (
    <>
      {" "}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openModals}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: "3px", backgroundColor: "#F0F9FF", height: "50px" }}
          >
            <Typography
              sx={{
                color: "#3C5AA0",
                fontSize: "20px",
                fontWeight: "500",
                backgroundColor: "#F0F9FF",
                pt: 1,
                px: 2,
              }}
            >
              Assign Role / Identity
            </Typography>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                backgroundColor: "#3C5AA0",
                padding: "4px",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "10px",
                mt: "0.5px",
                mr: "10px",
                cursor: "pointer",
              }}
            >
              <IconButton onClick={() => handleClose()}>
                <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
              </IconButton>
            </Stack>
          </Grid>
          {selectedRow && (
            <Stack
              display={"flex"}
              // alignItems={"center"}
              // justifyContent={"center"}
              spacing={2}
            >
              <Typography
                sx={{ color: "black", fontSize: "13px", pt: 1, px: 2 }}
              >
                Assign a predefined role tag based on common roles or positions
                within the community
              </Typography>
              <Stack
                sx={{
                  paddingLeft: "5px",
                  // backgroundColor: "#B9B9B9",
                  width: "100%",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  px: 2,
                }}
              >
                <Stack
                  flexDirection={"row-reverse"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      cursor:
                        loggedInUserData.user.id !== selectedRow.user._id
                          ? "pointer"
                          : null,
                      color: roleSwitch ? "#000000" : "#FFFFFF",
                      border: "1px solid #F0F0F0",
                      backgroundColor: roleSwitch ? "#FFFFFF" : "#3C5AA0",
                      borderRadius: "100px",
                      px: 1.2,
                      minHeight: { xs: "30px", md: "35px" },
                      maxHeight: { xs: "30px", md: "35px" },
                      minWidth: { md: "100%", xs: "100%" },
                      maxWidth: { md: "100%", xs: "100%" },
                    }}
                    onClick={() => {
                      if (loggedInUserData.user.id !== selectedRow.user._id)
                        setRoleSwitch(false);
                    }}
                  >
                    Member
                  </Typography>
                  <Typography
                    component="button"
                    sx={{
                      cursor: "pointer",
                      color: roleSwitch ? "#FFFFFF" : "#000000",
                      border: "1px solid #F0F0F0",
                      backgroundColor: roleSwitch ? "#3C5AA0" : "#FFFFFF",
                      px: 1.2,
                      borderRadius: "100px",
                      minHeight: { xs: "30px", md: "35px" },
                      maxHeight: { xs: "30px", md: "35px" },
                      minWidth: { md: "100%", xs: "100%" },
                      maxWidth: { md: "100%", xs: "100%" },
                    }}
                    onClick={() => setRoleSwitch(true)}
                  >
                    Admin
                  </Typography>
                </Stack>

                <Typography sx={{ fontSize: "13px", pt: 1 }}>OR</Typography>
              </Stack>
              <Stack
                sx={{
                  px: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  label="Optional: Enter a custom Role Tag"
                  placeholder="eg. Gym Trainer"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: { fontSize: "13px" } }}
                  InputProps={{
                    style: { fontSize: "12px" },
                  }}
                  variant="outlined"
                  style={{ borderRadius: "30px", maxWidth: "300px" }}
                  value={slug}
                  onChange={(e: any) => setSlug(e.target.value)}
                />
              </Stack>

              <Box
                sx={{
                  mt: 1.5,
                  display: "flex",
                  px: 2,
                  pb: 2,
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 1, sm: 2, md: 2 },
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "4px",
                    background:
                      "linear-gradient(92deg, #3C5AA0 9.79%, #50A1CA 92.96%)",
                    "&:hover": {
                      backgroundColor: "#50A1CA",
                      cursor: "pointer",
                      border: "none",
                    },
                  }}
                  // onClick={() => {
                  //   handleAssign(order, alignment || "", slug || "");
                  //   handleClose();
                  // }}
                  onClick={HandleAssignSubmit}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "grey" }} />
                  ) : (
                    "Assign"
                  )}
                </Button>
              </Box>
            </Stack>
          )}
        </>
      </Dialog>
      <PerfectScrollbar>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ height: "calc(100vh - 25vh)" }}
        >
          <Table
            // sx={{
            //   "& th": {
            //     fontSize: "13px",
            //     fontWeight: 500,
            //     color: "#000000",
            //     fontFamily: "Inter",
            //     p: 0.5,
            //     textAlign: "center",
            //   },
            //   "& td": {
            //     borderColor: "#E0E0E0",
            //     fontSize: "11px",
            //     fontWeight: 400,
            //     height: "50px",
            //     color: "#565656",
            //     p: "5px 5px",

            //     "& .MuiTypography-root": { fontSize: "11px" },
            //   },
            //   backgroundColor: "white",
            // }}

            sx={{
              "& th": {
                fontSize: "12px",
                fontWeight: 700,
                color: "#565656",
                p: 1,
              },
              "& td": {
                fontSize: "15px",
                fontWeight: 500,

                color: "#1A1A1A",
                p: "5px 10px",
                "& .MuiTypography-root": { m: 0, fontSize: "11px" },
                // "& .MuiTypography-root:nth-child(2)": {
                //   m: 0,
                //   fontSize: "6px",
                //   color: "#56565680",
                //   fontWeight: 600,
                // },
              },
            }}
          >
            <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: '#ffffff' }}>
              <TableRow sx={{}}>
                {columns?.map((column: IColumn, i: number) => (
                  <TableCell
                    key={i}
                    onClick={() => handleSort(column.title)}
                    sx={{}}
                  >
                    <CustomTableHeader
                      title={column.title}
                      sortDirection={sortDirections[column.title]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <NoPosts /> */}
              {paginatedData?.map((item: CommunityMembers, i: number) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handlenavigateUser(
                          item?.user?._id,

                        )
                      }
                    >
                      {/* {columns[0]?.dataKey === "user" && (
                          // <UserInfoCell
                          //   name={`${item?.user?.firstName} ${item?.user?.lastName}`}
                          //   avatar={item?.user?.avatar}
                          //   avatarStyles={{
                          //     height: "30px",
                          //     width: "30px",
                          //     fontSize: "11px",
                          //   }}
                          //   nameProps={{
                          //     sx: {
                          //       fontWeight: 600,
                          //       fontSize: "13px !important",
                          //       color: "#1A1A1A",
                          //     },
                          //   }}
                          //   // id={item.id}
                          //   idProps={{
                          //     sx: {
                          //       fontWeight: 400,
                          //       fontSize: "10px !important",
                          //     },
                          //   }}
                          // />
                        )} */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={item?.user?.avatar}
                          sx={{
                            height: "30px",
                            width: "30px",
                            fontSize: "11px",
                          }}
                          alt={`${item?.user?.firstName}`}
                        />
                        <Typography
                          style={{
                            marginLeft: "8px",
                            fontWeight: 600,
                            fontSize: "11px !important",
                            color: "#1A1A1A",
                            flexDirection: "row",
                            minWidth: 100,
                          }}
                        >
                          <Tooltip
                            title={`${item?.user?.firstName} ${item?.user?.lastName}`}
                            placement="bottom"
                          >
                            <Stack>
                              {item?.user?.firstName?.length > 15
                                ? `${item?.user?.firstName?.substring(
                                  0, 15
                                )}...`
                                : `${item?.user?.firstName} ${item?.user?.lastName}`}
                            </Stack>
                          </Tooltip>

                          <Stack sx={{ p: 0 }}>
                            {item?.addedBy === item?.user?._id && (
                              <AdminPerson />
                            )}
                          </Stack>
                        </Typography>
                      </div>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {columns[1].dataKey === "contact" && (
                      <Tooltip
                        title={
                          <Stack>
                            <Typography sx={{ fontSize: "11px" }}>
                              {" "}
                              {item?.user?.phoneNumber}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {item?.user?.emailId}
                            </Typography>
                          </Stack>
                        }
                        placement="bottom"
                      >
                        <Stack>
                          <ContactCell
                            list={[
                              {
                                item: item?.user?.phoneNumber,
                                isVerified: item?.user?.isPhoneVerified,
                              },
                              {
                                item:
                                  item?.user?.emailId?.length > 20
                                    ? `${item?.user?.emailId?.substring(
                                      0,
                                      20
                                    )}...`
                                    : item.user?.emailId,
                                isVerified: item?.user?.isEmailVerified,
                              },
                            ]}
                          />
                        </Stack>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {columns[2].dataKey === "community" && (
                      // <TextContentCell
                      // title={item.community.name}
                      // subTitle={`${item.} ${item.joinedOn}`}
                      // />
                      <Tooltip
                        title={item.community.name}
                        placement="left-start"
                        arrow
                      >
                        <Avatar src={item?.community.logo}></Avatar>
                      </Tooltip>
                    )}
                  </TableCell>

                  {/* <TableCell>{getStaticValue(item.user.role)}</TableCell> */}
                  <TableCell>
                    {item.plans && Array.isArray(item.plans) && item.plans.length > 0 ? (
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {item.plans.slice(0, 2).map((plan: IPlan, index: number) => (
                          <React.Fragment key={index}>
                            {index > 0 && ', '}
                            <span>{plan.name}</span>
                          </React.Fragment>
                        ))}
                        {item.plans.length > 2 && (
                          <>
                            {','} (+{item.plans.length - 2})
                          </>
                        )}
                      </Typography>
                    ) : (
                      <Typography>NA</Typography>
                    )}

                  </TableCell>
                  <TableCell
                    key={i}
                    onClick={() => {
                      if (loggedInUserData.user.id !== item?.user._id && item?.addedBy !== item?.user?._id) {
                        handleOpen(item);
                      }
                    }}
                    sx={{ textTransform: "capitalize", cursor: "pointer" }}
                  >
                    <RoleTypeCell
                      text={item.type}
                      type={
                        getStaticValue(item.type) === "Admin" ||
                          item.type === "Admin"
                          ? "success"
                          : item.type === "User" || "USER"
                            ? "warning"
                            : "error"
                      }
                      slug={item.slug}
                    />
                  </TableCell>

                  <TableCell>
                    <Stack sx={{ minWidth: 65 }}>
                      <Typography>{item?.createdAt}</Typography>
                      <Typography
                        sx={{ m: 0, fontSize: "10px", color: "#56565680" }}
                      >
                        {item.createdAtTime}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    {/* <Box
                        sx={{
                          cursor:
                            loggedInUserData?.user?.id === item.user?._id
                              ? null
                              : "pointer",
                          fontSize: "13px",
                          backgroundColor:
                            item.status === "BLOCK" ? "#ffa87d1a" : "#10a00d1a",
                          color:
                            item.status === "BLOCK" ? "#FF8143" : "#10A00D",
                          border:
                            item.status === "BLOCK"
                              ? "1px solid #ffa87d4d"
                              : "1px solid rgba(16, 160, 13, 0.3)",
                          textAlign: "center",
                          p: 0.3,
                          pr: 1,
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={(e) => {
                          handleStatusClick(e);
                          setSelectedRow(item);
                        }}
                      >
                        <ArrowDropDownIcon sx={{ cursor: "pointer" }} />
                        {getStaticValue(item.status)}
                      </Box> */}

                    <Grid
                      container
                    // justifyContent="center"
                    // alignItems="center"
                    >
                      <Grid item xs={12} md={12} lg={8}>
                        <Box
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            // px: "8px",
                            boxShadow: "none",
                            textAlign: "center",
                            borderRadius: "4px",
                            textTransform: "capitalize",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            p: 0.2,
                            cursor:
                              loggedInUserData?.user?.id === item.user?._id
                                ? null
                                : "pointer",
                            backgroundColor:
                              item.status === "BLOCK"
                                ? "#ffa87d1a"
                                : "#10a00d1a",
                            color:
                              item.status === "BLOCK" ? "#FF8143" : "#10A00D",
                            border:
                              item.status === "BLOCK"
                                ? "1px solid #ffa87d4d"
                                : "1px solid rgba(16, 160, 13, 0.3)",
                          }}
                          onClick={(e) => {
                            handleStatusClick(e);
                            setSelectedRow(item);
                          }}
                        >
                          <ArrowDropDownIcon sx={{ cursor: "pointer" }} />
                          <Typography
                            sx={{
                              textAlign: "center",
                              pr: 1,
                              fontSize: "13px",
                            }}
                          >
                            {getStaticValue(item.status)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {loggedInUserData.user.id !== selectedRow?.user._id && selectedRow?.addedBy !== selectedRow?.user?._id && (
                      <Menu
                        id={`menu-${i}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                          disablePadding: true,
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            if (loggedInUserData.user.id !== selectedRow?.user._id && selectedRow?.addedBy !== selectedRow?.user?._id) {
                              handlePopOverClick();
                            }
                          }}
                          sx={{
                            fontSize: "14px",
                            backgroundColor:
                              selectedRow?.status === "ACTIVE"
                                ? "#ffa87d1a"
                                : "#10a00d1a",
                            color:
                              selectedRow?.status === "ACTIVE"
                                ? "#FF8143"
                                : "#10A00D",
                            border:
                              selectedRow?.status === "ACTIVE"
                                ? "1px solid #ffa87d4d"
                                : "1px solid rgba(16, 160, 13, 0.3)",
                            px: 2,
                            borderRadius: "4px",
                          }}
                        >
                          {selectedRow?.status === "ACTIVE"
                            ? "Block"
                            : "Active"}
                        </MenuItem>
                      </Menu>
                    )}
                  </TableCell>

                  {/* <TableCell
                    // onClick={(e: any) => {
                    //   handleDeleteUser(e);
                    //   setSelectedRow(item);
                    // }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Button
                      disabled={item?.addedBy === item?.user?._id}
                      sx={{ color: "gray" }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <Popover
        id={idDeletePopover}
        open={openDeletePopover}
        anchorEl={deleteAnchorEl}
        onClose={handleDeleteClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete the invitation for{" "}
          {selectedRow?.user.firstName} {selectedRow?.user.lastName}?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
            onClick={() => {
              handleDeleteClosePopover();
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
              onClick={handleDeleteUserById}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Popover
        id={idPopOver}
        open={openPopOver}
        anchorEl={statusPopoverAnchor}
        onClose={() => setStatusPopoverAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          {selectedRow?.status === "ACTIVE" ? "Block User?" : "Active User?"}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          {selectedRow?.status === "ACTIVE"
            ? "Do you really want to Block this User?"
            : "Do you really want to make this user active?"}
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseStatusPopOver}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleStatusUpdate}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "grey" }} />
              ) : (
                <>{selectedRow?.status === "ACTIVE" ? "Block" : "Active"}</>
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Stack
        direction={"row-reverse"}
        alignItems={"center"}
        sx={{ color: "#A5A5A5", fontSize: { xs: "10px", md: '1.5vh', }, pr: { xs: "0px", md: "25px" } }}
      >
        Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{" "}
        {data.length} entries
        <TablePagination
          // rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="View"
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: { border: "1px solid #A5A5A5", borderRadius: "5px" },
            },
            // You can also customize the style of the menu options if desired
            MenuProps: {
              PaperProps: { style: { border: "1px solid #A5A5A5" } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data.length / rowsPerPage);

            return (
              <Stack
                direction={"row"}
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <IconButton
                  onClick={(event) => onPageChange(event, 0)} // Go to the first page
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton>

                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>

                {[...Array(3)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: "1.9vh",
                          border:
                            page === pageNumber ? "1px solid #3C5AA0" : "none", // Highlight the active page with a border
                          color: page === pageNumber ? "#3C5AA0" : "#000000", // Highlight the active page with a color
                          width: page === pageNumber ? "30px" : "30px",
                          height: page === pageNumber ? "30px" : "30px",
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  } else {
                    return null; // Don't render the button if it's out of bounds
                  }
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>

                <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              </Stack>
            );
          }}
        />
      </Stack>
    </>
  );
};
