

import "./styles.css"
import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";

export interface ImageData {
  data: string;
  width: number;
  height: number;
  name: string;
  file: File | null
}

interface ImageUploadProps {
  onSingleImageSelected: (imageFile: File | null, imageData: ImageData | null) => void;
  onMultipleImagesSelected: (files: FileList | null) => void;
  onImageDeleted: () => void;
  imageWidth: number;
  imageHeight: number;
  imageName: string;
  multiple: boolean;
  uploadLabelHeight: number;
  uploadLabelWidth: number;
  initialImage: string
}

const CustomImageUploader: React.FC<ImageUploadProps> = ({ onSingleImageSelected, onMultipleImagesSelected, initialImage, onImageDeleted, uploadLabelWidth, uploadLabelHeight, imageWidth, imageHeight, imageName, multiple }) => {
  const [singleImage, setSingleImage] = useState<ImageData | null>(initialImage ? { data: initialImage, width: imageWidth, height: imageHeight, name: imageName, file: null } : null);
  const [multipleImages, setMultipleImages] = useState<FileList | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  useEffect(() => {
    if (initialImage) {
      setSingleImage({
        data: initialImage,
        width: imageWidth,
        height: imageHeight,
        name: imageName,
        file: null
      });
    }
  }, [initialImage, imageWidth, imageHeight, imageName]);

  const handleSingleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!multiple) {
      const file = e.target.files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const imageFile = new File([file], file.name, { type: file.type });
          setSingleImage({
            data: reader.result as string,
            width: imageWidth,
            height: imageHeight,
            name: imageFile?.name,
            file: null,
          });
          onSingleImageSelected(imageFile, {
            data: reader.result as string,
            width: imageWidth,
            height: imageHeight,
            name: imageFile?.name,
            file: imageFile,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }; 

  const handleMultipleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    // Combine existing images with newly selected images
    const combinedImages = [
      ...(multipleImages ? Array.from(multipleImages) : []),
      ...(files ? Array.from(files) : []),
    ];

    const maxImages = 5; // Set the maximum number of images allowed

    if (combinedImages.length > maxImages) {
      setErrorMessage(`Please select only up to ${maxImages} images.`);
    } else {
      setErrorMessage(null);

      // Create a DataTransfer object and set its files property
      const dataTransfer = new DataTransfer();
      combinedImages.forEach((file) => {
        dataTransfer.items.add(file);
      });

      // Use the files property of the DataTransfer object
      setMultipleImages(dataTransfer.files);
      onMultipleImagesSelected(dataTransfer.files);
    }
  };
  const removeAllMultipleImages = () => {
    setMultipleImages(null);
    onMultipleImagesSelected(null);
    onImageDeleted();
  };

  const removeSingleImage = (index: number) => {
    if (multipleImages) {
      const newImages: File[] = Array.from(multipleImages);
      newImages.splice(index, 1);

      const dataTransfer = new DataTransfer();
      newImages.forEach((file) => {
        dataTransfer.items.add(file);
      });

      setMultipleImages(dataTransfer.files);
      onMultipleImagesSelected(dataTransfer.files);
      onImageDeleted();
    }
  };

  const removeSingleImagePreview = () => {
    setSingleImage(null);
    onSingleImageSelected(null, null);
    onImageDeleted();
  };

  return (
    <div className="image-uploader">
      <div className="image-container">
        {multiple ? (
          <div className="multiple-image-container" style={{ display: "flex" }}>
            <label className="upload-label">
              {errorMessage && (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errorMessage}
                </div>
              )}
              <div className="dotted-box">
                <img
                  src={require("../../assets/images/upload.png")}
                  alt="upload"
                  height={uploadLabelHeight}
                  width={uploadLabelWidth}
                />
              </div>
              <input
                type="file"
                hidden
                accept="image/*"
                multiple={true}
                onChange={handleMultipleImageChange}
              />
            </label>
            <div style={{ marginLeft: "10px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {Array.from(multipleImages || []).map((image, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Item ${index}`}
                      style={{ height: imageHeight, width: imageWidth }}
                    />
                    {/* <button
                      onClick={() => removeSingleImage(index)}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "#3C5AA0",
                        border: "none",
                        cursor: "pointer",
                        padding: "2px",
                        borderRadius: "50%",
                        fontSize: "16px",
                      }}
                    >
                      &times;
                    </button> */}
                    <Box
                    onClick={() => removeSingleImage(index)}
                    className="delete-icon-container"
                    sx={{
                      position: "absolute",
                      top: "3px",
                      right: "0px",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "10px",
                      cursor: "pointer",
                      backgroundColor: " #3C5AA0",
                      "&:hover": {
                        backgroundColor: "red",
                        cursor: "pointer",
                        border: "none",
                      },
                    }}
                  >
                    <Close sx={{ fontSize: "13px" }} />
                  </Box>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="single-image-container">
            {!singleImage ? (
              <label className="upload-label">
                <div className="dotted-box">
                  <img
                    src={require("../../assets/images/upload.png")}
                    alt="upload-single-img"
                    style={{
                      width: `${uploadLabelWidth}px`,
                      height: `${uploadLabelHeight}px`,
                    }}
                  />
                </div>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  multiple={false}
                  onChange={handleSingleImageChange}
                />
              </label>
            ) : (
              <div className="image-wrapper">
                <Box display="flex">
                  <label className="upload-label">
                    <div className="dotted-box">
                      <img
                        src={require("../../assets/images/upload.png")}
                        alt="upload-single-img"
                        style={{
                          width: `${uploadLabelWidth}px`,
                          height: `${uploadLabelHeight}px`,
                        }}
                      />
                    </div>
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      multiple={false}
                      onChange={handleSingleImageChange}
                    />
                  </label>
                  <Box sx={{ borderRadius: imageName === 'logo' ? '50%' : '0%', }}>

                    <img
                      src={singleImage.data}
                      alt="single-item"
                      style={{
                        width: imageName === 'logo' ? `${imageWidth}px` : "100%",
                        height: `${imageHeight}px`,
                        marginLeft: "10px",
                        borderRadius: imageName === 'logo' ? '50%' : '0%',
                      }}
                    />
                  </Box>
                  <Box
                    onClick={removeSingleImagePreview}
                    className="delete-icon-container"
                    sx={{
                      position: "absolute",
                      top: "3px",
                      right: "-8px",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "10px",
                      cursor: "pointer",
                      backgroundColor: " #3C5AA0",
                      "&:hover": {
                        backgroundColor: "red",
                        cursor: "pointer",
                        border: "none",
                      },
                    }}
                  >
                    <Close sx={{ fontSize: "13px" }} />
                  </Box>
                </Box>
              </div>
            )}
          </div>
        )}
        {multiple && (multipleImages || []).length > 0 && (
          <div>
            <button
              onClick={removeAllMultipleImages}
              style={{
                backgroundColor: "#2f2f2f",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              Remove All
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomImageUploader;

