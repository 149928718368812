import { Box, Stack } from "@mui/material";




export const Loader = () => {
  return (
    <Stack sx={{
      color: 'grey.500', display: "flex",

      // backgroundColor:'#ffffff',
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
    }} spacing={2} direction="row" >
      {/* <CircularProgress color="inherit" /> */}
      <Box
        component={"img"}
        src={require("../../assets/images/loader.gif")}
        alt=""
        sx={{ width: "50%" }}
      />
    </Stack>
  );
};

export default Loader;
