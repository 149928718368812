import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  Link,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Bin, EditIcon } from "../../../assets/icons";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import DragAndAddFiles from "./DragAndAddFiles";
import LinkIcon from "@mui/icons-material/Link";
import AddSectionPopUp from "./AddSectionPopUp";
import AddLinkPopUp from "./AddLinkPopUp";
import {
  ILinks,
  ISections,
  interfaceCourse,
} from "../../../models/courses.model";
import { useCourses } from "../../../hooks/useCourses";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditDocumentPopUp from "./EditDocumentPopUp";
import { AddSectionDocument } from "../../../services/courses.service";
import { AuthContext, IAuthContext } from "../../../contexts/Auth.context";
import { FileCopyOutlined } from "@mui/icons-material";
interface sectionProps {
  data: interfaceCourse[];
  selectedCommunityId: string;
  refetchCourseData: () => void;
}
const TestSection: React.FC<sectionProps> = ({
  data,
  selectedCommunityId,
  refetchCourseData,
}) => {
  const { deleteSections, updateCourseSectionsById,deleteSectionDocument } = useCourses();
  const [isLoading, setIsLoading] = useState(false);
  const [sectionValues, setSectionValues] = useState<ISections>();

  const section = data?.length > 0 ? data[0]?.sections : [];
  const courseId = data[0]?._id;
  const [sections, setSections] = useState(section);

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [openAddLinkDialog, setOpenAddLinkDialog] = useState(false);
  const [openEditDocument, setOpenEditDocument] = useState(false);
  const [openAddSection, setOpenAddSection] = useState(false);
  const [linkInputs, setLinkInputs] = useState([""]);
  const [documents, setDocuments] = useState();
  const [expanded, setExpanded] = useState<string | null>(null);
  const [deleteSectionPopOverAnchor, setDeleteSectionPopOverAnchor] =
    useState<null | HTMLElement>(null);
  const [deleteLinkAnchor, setDeleteLinkAnchor] = useState<null | HTMLElement>(
    null
  );
  const [deleteDocumentPopOverAnchor, setDeleteDocumentPopOverAnchor] =
  useState<null | HTMLElement>(null);
  const [sectionItem, setSectionItem] = useState<ISections | null>(null);
  const [linkItem, setLinkItem] = useState<ILinks>();
  useEffect(() => {
    if (data[0]?.sections) {
      setSections(data[0]?.sections);
      // const allFiles = data[0]?.sections.flatMap(
      //   (section: ISections) => section.documents
      // );
      // setDocuments(allFiles);
    }
  }, [data]);

  // const handleFilesSelected = (files: FileList | null) => {
  //   setDocuments(files);
  // };
  const handleFilesSelected = async (files: FileList | null) => {
    // setDocuments(files); 
//call api
    if (files && sectionItem) {
      try {       
        await AddSectionDocument(getAccessToken(),selectedCommunityId,courseId,sectionItem?._id,files )   
      refetchCourseData();
      } catch (error) {
        console.log(error);
      }
    }
  };
// console.log(documents);

  const openSectionPopOver = Boolean(deleteSectionPopOverAnchor);
  const idDeleteSectionPopover = openSectionPopOver
    ? "delete-popover"
    : undefined;

  const openLinkPopOver = Boolean(deleteLinkAnchor);
  const idDeleteLinkPopover = openLinkPopOver ? "delete-popover" : undefined;

  const openDeleteDocumentPopOver = Boolean(deleteDocumentPopOverAnchor);
  const idDeleteDocumentPopover = openDeleteDocumentPopOver ? "delete-popover" : undefined; 

  const handleSectionDelete = (e: any, item: ISections) => {
    setDeleteSectionPopOverAnchor(e.currentTarget);
    setSectionItem(item);
  };

  const handleCloseSectionPopOver = () => {
    setDeleteSectionPopOverAnchor(null);
  };

  const handleLinkDelete = (e: any, item: ILinks) => {
    setDeleteLinkAnchor(e.currentTarget);
    setLinkItem(item);
  };

  const handleCloseLinkPopOver = () => {
    setDeleteLinkAnchor(null);
  };

  const handleDeleteDocument=(e:any,item:any)=>{
    setDeleteDocumentPopOverAnchor(e.currentTarget)
    setDocuments(item)
  }
  const handleCloseDeleteDocument=()=>{
    setDeleteDocumentPopOverAnchor(null)
  }
  const handleDeleteSectionDocument=async()=>{
    
    if (documents && sectionItem) {
      const {_id} = documents
      try{
        setIsLoading(true)
      await deleteSectionDocument(selectedCommunityId,sectionItem?._id,_id )

      }catch(err){
        console.log("error");
        
      }finally{
      refetchCourseData();
      setIsLoading(false);
      handleCloseDeleteDocument()

      }
    }
  }

  const handleSectionLinkDelete = async () => {
    if (sectionItem) {
      const updatedLinks = sectionItem?.links.filter(
        (link) => link._id !== linkItem?._id
      );
      try {
        setIsLoading(true)
        await updateCourseSectionsById(
          selectedCommunityId,
          courseId,
          sectionItem?._id,
          { links: updatedLinks }
        );
      } catch (error) {
        console.log("error");
      } finally {
        handleCloseLinkPopOver();
        refetchCourseData();
        setIsLoading(false)
      }
    }
  };

  //DRAG
  const [draggedItem, setDraggedItem] = useState<number | null>(null);
  const handleDragStart = (index: number) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index: number) => {
    const newSections = [...sections];
    const draggedSection = newSections[draggedItem!];
    newSections.splice(draggedItem!, 1);
    newSections.splice(index, 0, draggedSection);
    setSections(newSections);
    setDraggedItem(index);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };

  // const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const fileList = event.target.files;
  //   if (fileList) {
  //     const filesArray = Array.from(fileList); // Convert FileList to array
  //     setDocuments(filesArray);
  //   }
  // };

  const handleAddLink = () => {
    setLinkInputs([...linkInputs, ""]); // Add a new input to the array
  };

  const handleDeleteLink = (index: any) => {
    const updatedInputs = linkInputs.filter((_, i) => i !== index);
    setLinkInputs(updatedInputs);
  };

  const handleInputChange = (value: any, index: any) => {
    const updatedInputs = [...linkInputs];
    updatedInputs[index] = value;
    setLinkInputs(updatedInputs);
  };

  // const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleOpenAddLinkDialog = (item: ISections) => {
    setOpenAddLinkDialog(true);
    setSectionValues(item);
  };
  const handleCloseAddLinkDialog = () => {
    setOpenAddLinkDialog(false);
  };
  const handleOpenAddSectionDialog = (e: any, item: ISections) => {
    setOpenAddSection(true);
    setSectionValues(item);
  };
  const handleCloseAddSectionDialog = () => {
    setOpenAddSection(false);
  };

  const handleEditDocument=(e:any,item:any)=>{
    setOpenEditDocument(true)
    setDocuments(item)
  }
  const handleCloseEditDocument=()=>{
    setOpenEditDocument(false)
  }


  const handleDeleteSections = async () => {
    if (sectionItem) {
      try {
        setIsLoading(true);
        await deleteSections(selectedCommunityId, courseId, sectionItem?._id);
        await refetchCourseData(); // Fetch updated data
        // if(updat)
        // setSections(updatedData?.courses[0].section);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        handleCloseSectionPopOver();
      }
    }
  };
  return (
    <Stack>
      {sections
        ?.slice()
        .reverse()
        .map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              "& .MuiAccordionSummary-content": {
                margin: 0, // Remove the margin for MuiAccordionSummary-content
              },
              boxShadow: "none",
              mb: 1,
              "& .Mui-expanded": {
                minHeight: "0", // Remove the minimum height for expanded state
              },
              "&:before": {
                height: "0 !important", // Set height to 0 for Accordion's :before pseudo-element
              },
              "& .MuiAccordion-root.Mui-expanded": {
                minHeight: "0 !important",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                m: 0,
              },
              "& .MuiButtonBase-root-MuiAccordionSummary-root": {
                minHeight: 0,
              },
            }}
          >
            <AccordionSummary
              sx={{ p: 0, m: 0 }}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={() => handleDragOver(index)}
            >
              <Grid container alignItems="center" sx={{ p: 0, m: 0 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  justifyContent={"space-between"}
                  sx={{
                    p: 2,
                    backgroundColor: "#F0F9FF",
                    border: "1px solid #D9EBF6",
                    borderRadius: "5px 5px 0 0",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" alignItems="center">
                      <DragIndicatorOutlinedIcon
                        sx={{ color: "#565656", fontSize: "20px", pr: "3px" }}
                      />
                      {expanded === `panel${index}` ? (
                        <ArrowForwardIosIcon
                          sx={{ fontSize: "11px", transform: "rotate(90deg)" }}
                        />
                      ) : (
                        <ArrowForwardIosIcon sx={{ fontSize: "11px" }} />
                      )}
                      <Stack sx={{ px: "10px" }}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#565656",
                            fontFamily: "Roboto",
                            fontWeight: "600",
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: "#565656",
                            fontFamily: "Roboto",
                            fontWeight: "400",
                          }}
                        >
                          {item?.description}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <EditIcon
                        sx={{ color: "#3C5AA0", fontSize: "20px", pr: "3px" }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleOpenAddSectionDialog(e, item);
                        }}
                      />
                      <Stack
                        sx={{ ml: "10px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSectionDelete(e, item);
                        }}
                      >
                        <Bin />
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 0,
                mt: "0px",
                border: "1px solid #D9EBF6",
                borderRadius: "0 0 0 5px",
              }}
            >
              <Grid item xs={12} sx={{ px: 2, mt: 1 }}>
                <Stack onClick={() => setSectionItem(item)}>
                <DragAndAddFiles
                  data={data}
                  sectionPopUp={true}
                  section={item}
                  refetchCourseData={refetchCourseData}
                  onFilesSelected={handleFilesSelected}
                  inputId={"section-file"}
                />
                </Stack>
               
                  <Stack sx={{ px: 2 ,pb:1}}>
                  {item?.documents?.slice().reverse().map((doc:any, docIndex) => (
                      <Grid
                        item
                        key={docIndex}
                        xs={12}
                        sm={12}
                        alignItems="center"
                        sx={{
                          p: 2,
                          backgroundColor: "#F0F9FF",
                          borderRadius: "5px",
                          mb:1
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" alignItems="center">
                            <DragIndicatorOutlinedIcon
                              sx={{
                                color: "#565656",
                                fontSize: "20px",
                                pr: "3px",
                              }}
                            />
                            <FileCopyOutlined sx={{color:"#3C5AA0",fontSize:"20px"}}/>
                            <Stack sx={{ px: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#565656",
                                  fontFamily: "Roboto",
                                  fontWeight: "600",
                                }}
                              >
                                {doc?.label}
                              </Typography>
                              <Link
                                href={`${doc?.value}`} 
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "8px",
                                    color: "#565656",
                                    fontFamily: "Roboto",
                                    fontWeight: "600",
                                  }}
                                >
                                  Link
                                </Typography>
                              </Link>
                            </Stack>
                          </Stack>
                          <Stack direction="row" alignItems="center">
                            <EditIcon
                              sx={{
                                color: "#3C5AA0",
                                fontSize: "20px",
                                pr: "3px",
                              }}
                              onClick={(e: any) => { handleEditDocument(e, doc) }}
                            />
                            <Stack sx={{ ml: "10px" }}  onClick={(e) => {
                                handleDeleteDocument(e, doc);
                                setSectionItem(item);
                              }}>
                              <Bin />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                     ))}
                  </Stack>
                {/* )} */}
                <Stack sx={{ px: 2 }}>
                  {item?.links
                    ?.slice()
                    .reverse()
                    .map((links, linksIndex) => (
                      <Grid
                        item
                        key={linksIndex}
                        xs={12}
                        sm={12}
                        alignItems="center"
                        sx={{
                          p: 2,
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" alignItems="center">
                            <LinkIcon
                              sx={{
                                transform: "rotate(135deg)",
                                color: "#3C5AA0",
                              }}
                            />
                            <Stack sx={{ px: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#565656",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {links.name}
                              </Typography>
                              <Link
                                href={`${links.value}`} 
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "8px",
                                    color: "#565656",
                                    fontFamily: "Roboto",
                                    fontWeight: "600",
                                  }}
                                >
                                  Link
                                </Typography>
                              </Link>
                            </Stack>
                          </Stack>
                          <Stack direction="row" alignItems="center">
                            <EditIcon
                              sx={{
                                color: "#3C5AA0",
                                fontSize: "20px",
                                pr: "3px",
                              }}
                              onClick={() => {
                                handleOpenAddLinkDialog(item);
                                setLinkItem(links);
                              }}
                            />
                            <Stack
                              sx={{ ml: "10px" }}
                              onClick={(e) => {
                                handleLinkDelete(e, links);
                                setSectionItem(item);
                              }}
                            >
                              <Bin />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                </Stack>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAddSection}
        onClose={handleCloseAddSectionDialog}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <AddSectionPopUp
          handleCloseAddSectionDialog={handleCloseAddSectionDialog}
          section={sectionValues}
          data={data}
          refetchCourseData={refetchCourseData}
        />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAddLinkDialog}
        onClose={handleCloseAddLinkDialog}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <AddLinkPopUp
          handleCloseAddLinkDialog={handleCloseAddLinkDialog}
          linkInputs={linkInputs}
          handleInputChange={handleInputChange}
          handleDeleteLink={handleDeleteLink}
          handleAddLink={handleAddLink}
          data={data}
          sectionPopUp={true}
          section={sectionValues}
          refetchCourseData={refetchCourseData}
          linkData={linkItem}
          courseLinks={""}
        />
      </Dialog>
      {/* edit document */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openEditDocument}
        onClose={handleCloseEditDocument}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
       <EditDocumentPopUp handleCloseEditDocument={handleCloseEditDocument} document={documents} data={data[0]} refetchCourseData={refetchCourseData}/>
      </Dialog>

      <Popover
        id={idDeleteSectionPopover}
        open={openSectionPopOver}
        anchorEl={deleteSectionPopOverAnchor}
        onClose={() => handleCloseSectionPopOver()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete Section?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseSectionPopOver}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteSections}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>

      <Popover
        id={idDeleteLinkPopover}
        open={openLinkPopOver}
        anchorEl={deleteLinkAnchor}
        onClose={() => handleCloseLinkPopOver()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete Sectifron?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseLinkPopOver}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSectionLinkDelete}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )}
            
            </Button>
          </Stack>
        </Stack>
      </Popover>
        {/* delete document */}
      <Popover
        id={idDeleteDocumentPopover}
        open={openDeleteDocumentPopOver}
        anchorEl={deleteDocumentPopOverAnchor}
        onClose={() => handleCloseDeleteDocument()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete document?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseDeleteDocument}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteSectionDocument}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover> 
    </Stack>
  );
};

export default TestSection;
