
import Box from "@mui/material/Box";;
export interface IStatusCell {
  text: string;
  type: "error" | "warning" | "info" | "success";
  icon?: React.ReactNode;
}
export const StatusCell = ({ text, type,icon }: IStatusCell) => {
  const styles = {
    backgroundColor: "white",
    color: "#10A00D",
    border: "1px solid rgba(16, 160, 13, 0.3)",
  };
  const getStyles = (status: string) => {
    switch (status) {
      case "success":
        styles.backgroundColor = "#10a00d1a";
        styles.color = "#10A00D";
        styles.border = "1px solid rgba(16, 160, 13, 0.3)";
        break;
      case "warning":
        styles.backgroundColor = "#ffa87d1a";
        styles.color = "#FF8143";
        styles.border = "1px solid #ffa87d4d";
        break;
      case "error":
        styles.backgroundColor = "rgba(16, 160, 13, 0.10);";
        styles.color = "#10A00D";
        styles.border = "1px solid rgba(16, 160, 13, 0.30);";
        break;
    }
    return styles;
  };
  return (
    // <Alert severity={type} icon={false}>{text}</Alert>
    <Box
      sx={{
        // mx: 1,
        fontSize: "13px",
        fontWeight: "400",
        //  padding: "5px 20px 5px 10px",
        // pr:"3px",
        boxShadow: "none",
        textAlign: "center",
        borderRadius: "4px",
        textTransform: "capitalize",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        // p:0,
        ...getStyles(type),
      }}
    >
      {icon && <span >{icon}</span>}
  {text}
    </Box>
  );
};
