import React, { useState } from "react";
import {
  Avatar,
  Button,
  Stack,
  Typography,
  Box,
  Chip,
  TextField,
  InputAdornment,
  Divider,
  DialogProps,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Grid,
} from "@mui/material";
// import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PublicIcon from "@mui/icons-material/Public";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useRequests } from "../../../hooks/useRequests";
import { useCommunity } from "../../../hooks/useCommunity";
import { USER_APP_BASE_URL } from "../../../configurations/url.config";
import Close from "@mui/icons-material/Close";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

interface SendRequestCommunityPreviewProps {
  isOpen: boolean;
  handleClose: () => void;
  coverImageSrc: string;
  id: string;
  logo: string;
  description: string;
  title: string;
  city: string;
  members: [];
  message: string;
  type: string;
  handleCopyLink: () => void;
  copied: boolean;
  whatsappShareUrl: string;
  facebookShareUrl: string;
  linkedinShareUrl: string;
  twitterShareUrl: string;
  navigateToUserFlow: () => void;
}

const SendRequestCommunityPreview: React.FC<
  SendRequestCommunityPreviewProps
> = ({
  isOpen,
  handleClose,
  coverImageSrc,
  id,
  logo,
  title,
  description,
  city,
  members,
  type,
  handleCopyLink,
  copied,
  whatsappShareUrl,
  facebookShareUrl,
  linkedinShareUrl,
  twitterShareUrl,
  navigateToUserFlow,
}) => {
    // const [showFullContents] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const [maxPopWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const { SendCommunityRequest } = useRequests();
    const { joinToPublicCommunity } = useCommunity();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);


    // console.log(sendRequest, "SendCommunityRequest");

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };

    const handleCloses = () => {
      setOpen(false);
    };

    const handleClickSendRequest = async (community: string, message: string) => {
      try {
        const formData = new FormData();
        // Append the community_id and invites_id to the FormData object
        formData.append("community", community);
        formData.append("message", message);
        const response = await SendCommunityRequest(formData);
        if (response && response.status === 201) {
          // console.log(message);
          handleClose();

        } else {
          handleClose();
        }
      } catch (error) {
        // console.log("Error while sending to Request Community");
      }
    };

    const handleClickJoin = async (id: string) => {
      try {
        setLoading(true);
        const response = await joinToPublicCommunity(id);
        // console.log(response);
        if (response && response.status === "success") {
          // console.log("You have successfully joined!");
          handleClose();
          navigateToUserFlow();
        } else if (response && response.status === 500) {
          // console.log(response?.message);
          // handleClose();
        }
      } catch (error) {
        // console.log("Error while joining to Public Community");
      }
      setLoading(false);
      handleClose();
    };


    const handleToggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };



    const maxLength = 6; // Maximum length for the title

    const truncatedTitle = title?.length > maxLength ? title.substring(0, maxLength) : title;

    const label = `${USER_APP_BASE_URL}/${truncatedTitle}`;




    return (
      <>
        <Stack sx={{ padding: "0px" }}>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxPopWidth}
            open={isOpen}
            onClose={handleClose}
          >
            <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ backgroundColor: "#F0F9FF" }}
              >
                <Stack></Stack>
                <Stack></Stack>
                <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                  <Box
                    onClick={handleClose}
                    sx={{
                      backgroundColor: "#3C5AA0",
                      padding: "4px",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "10px",
                      mt: "0.5px",
                      mr: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        // backgroundColor: "#50A1CA", // Background color on hover
                        cursor: "pointer",
                        border: "none",
                      },
                    }}
                  >
                    <Close sx={{ fontSize: "15px" }} />
                  </Box>
                </Stack>
              </Stack>
            </DialogActions>

            <DialogContent sx={{ padding: "0px", }}>
              <div style={{ position: "relative" }}>
                <img
                  src={coverImageSrc}
                  alt="Cover"
                  style={{
                    width: "100%",
                    height: "auto", // Set height to auto to maintain aspect ratio
                    maxHeight: 200, // Set the maximum height
                    objectFit: "cover", // Use object-fit to handle the cropping
                    position: "relative",
                    marginBottom: "-10px",
                  }}
                />
              </div>

              {/* Bottom Section - Description */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#ffffff",
                  marginTop: "-35px",
                  position: "relative",
                  borderRadius: "20px 20px 0px 0px",
                  borderTop: "1px solid #A9A9A9",
                  boxShadow: "0px -5px 5px -5px #A9A9A9", // Adjust values as needed
                }}
              >
                {/* Add display picture (DP) */}
                <Box sx={{ position: "relative" }}>
                  <Avatar
                    src={logo}
                    alt="DP"
                    sx={{
                      width: "100px",
                      height: "100px",
                      position: "relative",
                      top: "-60px",
                      zIndex: 1, // Ensure the Avatar is on top of the white circle
                    }}
                  />
                  <Avatar
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "120px", // Adjust the size to fit your design
                      height: "120px", // Adjust the size to fit your design
                      borderRadius: "100%",
                      position: "absolute",
                      top: "-70px", // Adjust the top position to align with the Avatar
                      left: "-10px", // Adjust the left position to align with the Avatar
                      zIndex: 0, // Set a lower zIndex to place it beneath the Avatar
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Add a box shadow
                    }}
                  />
                </Box>

                {/* Add text below */}
                <Typography
                  sx={{ fontWeight: "700", fontSize: "18px", mt: "-45px", fontFamily: "Inter" }}
                  variant="body1"
                  gutterBottom
                >
                  {title?.length > 25 ? title.slice(0, 25) + '...' : title}
                </Typography>
                <Typography variant="body2" gutterBottom sx={{ fontFamily: "Inter" }}>
                  {city ? (
                    <>
                      {city?.length > 20 ? `${city?.slice(0, 20)}...` : city}
                    </>
                  ) : (
                    <>
                      No city
                    </>
                  )} | {members} Members
                </Typography>

                <Stack
                  direction={"row"}
                  justifyContent="center"
                  sx={{

                    ml: 0.5
                  }}
                >
                  <Chip
                    label={label}
                    icon={<PublicIcon sx={{ fontSize: '18px' }} />}
                    component="a"
                    href={`${USER_APP_BASE_URL}/communities/${id}/about`}
                    target="_blank"
                    clickable
                    sx={{
                      backgroundColor: "#f1f1f1",
                      fontSize: "12px",
                      fontFamily: "Inter",
                      textTransform: "lowercase",
                      "@media (max-width: 600px)": {
                        marginBottom: "1rem",
                      },
                    }}
                  />
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        backgroundColor: "#f1f1f1",
                        textTransform: "capitalize",
                        color: "#636363",
                        fontWeight: "normal",
                        mt: 4,
                        fontSize: "12px",
                        fontFamily: "Inter",
                        display: { xs: 'none', md: 'flex', },
                        "&:hover": { backgroundColor: "#f1f1f1" },
                        borderRadius: "20px 20px 20px 20px",

                      }}
                      // onClick={handleCopyLink}
                      variant="text"
                      color="primary"
                      startIcon={<FileCopyOutlinedIcon />}
                      onClick={handleCopyLink}
                    >
                      {copied ? "Copied!" : "Copy"}
                    </Button>
                  </InputAdornment>
                </Stack>
                {/* <div>
              <InputAdornment position="end">
                    <Button
                      sx={{
                        backgroundColor: "#f1f1f1",
                        textTransform: "capitalize",
                        color: "#636363",
                        fontWeight: "normal",
                        mt: 4,
                        fontSize: "12px",
                        display:{xs:'flex', md:'none',},
                        "&:hover": { backgroundColor: "#f1f1f1" },
                        borderRadius: "20px 20px 20px 20px",

                      }}
                      // onClick={handleCopyLink}
                      variant="text"
                      color="primary"
                      startIcon={<FileCopyOutlinedIcon />}
                      onClick={handleCopyLink}
                    >
                      {copied ? "Copied!" : "Copy"}
                    </Button>
                  </InputAdornment>
              </div> */}

              </div>



              <Box sx={{}}>
                <Stack
                  gap={1}
                  direction="row"
                  sx={{ fontWeight: 500, pt: 1, mx: 2, color: "#636363" }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "13px", md: "15px", },
                      fontWeight: 500,
                      textDecoration: "none",
                      color: "#50A1CA",
                      textAlign: "center",
                      textTransform: "uppercase",
                      fontFamily: "Inter"
                    }}
                  >
                    About Us :-
                  </Typography>
                </Stack>
                {/* <Typography
                sx={{
                  
                  
                  mx: 2,
                  color: "#636363",
                  fontSize: "14px",
                }}
              >
                {" "}
                {description?.length > 200 ? ( // Check if description has more than 150 characters
                  showFullContents ? (
                    <>{description}</>
                  ) : (
                    <>
                      {description?.slice(0, 120)}...
                      <Button
                        onClick={handleClickOpen}
                        sx={{
                          textTransform: "capitalize",
                          color: "#50A1CA",
                          padding: "0px",
                          fontSize:'14px'
                        }}
                      >
                        &nbsp;&nbsp; Show More
                      </Button>
                    </>
                  )
                ) : (
                  // If the description has 150 characters or less, just display the full description
                  <>{description}</>
                )}
              </Typography> */}

                <SimpleBar style={{ height: '60px', overflowY: 'auto' }}>
                  <Typography
                    variant="caption"
                    paragraph={true}
                    className="description"
                    sx={{ color: '#565656', fontSize: '13px', overflow: 'hidden', textOverflow: 'ellipsis', mx: 2, fontFamily: "Inter" }}
                  >
                    {showFullDescription ? (description || '') : (description ? description.slice(0, 150) + ' ' : '')}
                    &nbsp; &nbsp;
                    {description && description.length > 150 && (
                      <Button
                        size="small"
                        onClick={handleToggleDescription}
                        sx={{
                          color: "#50A1CA",
                          fontWeight: "400",
                          fontSize: '12px',
                          textTransform: 'capitalize',
                          textDecoration: 'underline',
                          cursor: "pointer",
                          fontFamily: "Inter",
                          pt: 0
                        }}
                      >
                        {showFullDescription ? 'Show Less' : 'Show More'}
                      </Button>
                    )}
                  </Typography>
                </SimpleBar>


                {type === "PRIVATE" && (
                  <>
                    <Typography
                      sx={{
                        pt: 1,
                        color: "#787878",
                        fontSize: "11px",
                        fontFamily: "Inter",
                        textTransform: "capitalize",
                        mx: 2
                      }}
                    >
                      (Optional: This is a private community, You can customize
                      your request or just send request directly.)
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      sx={{ px: 2 }}
                      placeholder="Type your message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                  mt: 3,
                }}
              >
                <Button
                  sx={{
                    color: "white",
                    width: { xs: "17vh", md: "20vh", },
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    backgroundColor: "#3B9B7F",
                    boxShadow: 'none',
                    "&:hover": {
                      backgroundColor: "#3B9B7F",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    if (type === "PRIVATE") {
                      // Call the "Send Request" API
                      handleClickSendRequest(id, message);
                    } else {
                      // Call the "Join" API
                      handleClickJoin(id);
                    }
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : type === "PRIVATE" ? (
                    "Send Request"
                  ) : (
                    "Join"
                  )}
                </Button>
                <Button
                  sx={{
                    color: "#3B9B7F",
                    width: { xs: "17vh", md: "20vh", },
                    textTransform: "capitalize",
                    borderColor: "#3B9B7F",
                    fontFamily: "Inter",
                    "&:hover": {
                      borderColor: "#3B9B7F",
                    },
                  }}
                  variant="outlined"
                  onClick={handleClose}
                >
                  Back
                </Button>
              </Box>

              <Box sx={{}}>
                <Stack
                  gap={1}
                  direction="row"
                  sx={{
                    fontWeight: 400,
                    pt: 1,
                    mx: 2,
                    color: "#636363",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    alignContent: "center",
                  }}
                >
                  {/* <LinkOutlinedIcon />
                  <Typography
                    sx={{
                      marginBottom: 1,
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Roboto",
                    }}
                  >
                    Share Community
                  </Typography> */}
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    pb: 2,
                    mx: 2,
                    mt: 1
                  }}
                >
                  <TextField
                    size="small"
                    sx={{
                      borderRadius: "100px",
                      width: { xs: '80%', sm: '80%', md: "calc(50% - 5px)", }
                    }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              color: "#ffffff",
                              fontSize: { xs: "14px", md: "16px", },
                              fontWeight: 600,
                              fontFamily: 'Inter',
                              backgroundColor: "#3B9B7F",
                              cursor: "default",
                              "&:hover": { backgroundColor: "#3B9B7F" },
                              ml: "-15px",
                              borderRadius: "20px 0px 0px 20px",
                              p: { xs: 1, md: 0.7 },
                            }}
                          >
                            Share With
                          </Button>
                          <Divider orientation="vertical" sx={{ height: 38 }} />
                          <Stack
                            direction="row"
                            spacing={{ xs: 2, md: 1.6 }}
                            alignItems="center"
                            sx={{ pt: 1, ml: { xs: "5px", md: "8px", } }}
                          >
                            <a
                              href={whatsappShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <WhatsAppIcon
                                sx={{
                                  fontSize: { xs: "20px", md: "30px", },
                                  color: "#25d366",
                                }}
                              />
                            </a>
                            <a
                              href={facebookShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FacebookIcon
                                sx={{
                                  fontSize: { xs: "20px", md: "30px", },

                                  color: "#3b5998",
                                }}
                              />
                            </a>
                            <a
                              href={linkedinShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkedInIcon
                                sx={{
                                  fontSize: { xs: "20px", md: "30px", },

                                  color: "#1976d2",
                                }}
                              />
                            </a>
                            <a
                              href={twitterShareUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TwitterIcon
                                sx={{
                                  fontSize: { xs: "20px", md: "30px", },
                                  color: "#1da1f2",
                                }}
                              />
                            </a>
                          </Stack>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "100px",
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Stack>

        {/* Pop Up for About Us Display */}
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleCloses}
        >
          <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: "#F0F9FF" }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  textDecoration: "none",
                  color: "#50A1CA",
                  textTransform: "uppercase",
                  marginLeft: 2,
                }}
              >
                About Community
              </Typography>
              <Box
                onClick={handleCloses}
                sx={{
                  backgroundColor: "#3C5AA0",
                  padding: "4px",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "10px",
                  mt: "0.5px",
                  mr: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                <Close sx={{ fontSize: "15px" }} />
              </Box>
            </Grid>
          </DialogActions>

          <DialogContent sx={{ p: 2 }}>
            <DialogContentText>
              <Typography sx={{ fontSize: "15px" }}>{description}</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  };

export default SendRequestCommunityPreview;
