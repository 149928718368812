import axios from "axios";
import { INewUser } from "../models/user.model";
import { BASE_URL, BASE_URL_V2 } from "../configurations/url.config";
import { CommunityMembers } from "../models/community-user.model";


export const getUsers = async (token: string,id:string) => {
  try {
   
      const response = await axios.get(`${BASE_URL}/community/${id}/members/all`, {
    //  const response = await axios.get(`${BASE_URL}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      if (Array.isArray(response.data)) {
        response.data = response.data.map((user: CommunityMembers) => memberParse(user));
      } else {
        // If not an array, just apply memberParse to the single object
        response.data = memberParse(response.data);
      }
    }

    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }

};

export const createUsers = async (token:string,usersToAdd:any) => {
  try{
    const response = await axios.post(`${BASE_URL}/users/create`,usersToAdd ,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
    if(response.status === 200) {
      response.data = addUserParse(response.data)
    }
      return response;
    } catch(err) {
      console.log("Err :================================ ",err);
      return{status:500,data:[]};
  }
};


export const existUserCheck = async (token: string, phoneNumber: string) => {
  try {
    console.log(phoneNumber, "getUserNumber");
    const response = await axios.post(
      `${BASE_URL_V2}/auth/is-user-exists`,
      {
        phoneNumber: phoneNumber 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }
    );

    return response;
  } catch (err) {
    console.log("Err: ", err);
    return { status: 500, data: [] };
  }
};




export const getUser = async (token: string,id:string) => {
  try {
    const response = await axios.get(`${BASE_URL}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // if (response.status === 200) {
    //   response.data = response.data;
    // }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const updateUser = async (token: string,id:string,data:any,avatar:File|null) => {
  try {
    
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        // Append the key and value to the FormData object
        formData.append(key, value);
      }
    }
    if(avatar){
      formData.append("avatar", avatar);
    }
    const response = await axios.put(`${BASE_URL}/users/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
          Accept: "*",
      },
    }
    
    );

    // if (response.status === 200) {
    //   response.data = response.data;
    // }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

//Invitations
export const addUsers = async (token:string,usersToAdd:any) => {

  try{
    const response = await axios.post(`${BASE_URL}/bulk/`,usersToAdd ,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    });
   
    if(response.status === 200) {
      response.data = addUserParse(response.data)
    }
      return response;
    } catch(err) {
      console.log("Err :================================ ",err);
      return{status:500,data:[]};
  }
};

export const getInvitations = async (token: string,id:string) => {
  try {
    const response = await axios.get(`${BASE_URL}/bulk/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    
    // if (response.status === 200) {
    //   response.data = response.data;
    // }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const deleteInvite = async (token: string,communityId:string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/bulk/${communityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const leaveCommunityUser = async (token: string,id:string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${id}/leaveCommunity`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

const addUserParse=(user:INewUser)=>{
 
    return {
      ...user,
      firstName:user.firstName ?? " ",
      lastName:user.lastName ?? " ",
      role:user.role ?? " ",
      emailId:user.emailId ?? " ",
      phoneNumber:user.phoneNumber ?? " ",
      status:user.status ?? " ",
      community:user.community ?? " ",
     
    };
}

const memberParse = (member:CommunityMembers)=>{

  const createdAtDate = new Date(member.createdAt);
  return{
    ...member,
    createdAt:
      createdAtDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }) ?? "",
    createdAtTime:
      createdAtDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }) ?? "",
  }
}