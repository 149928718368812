import React, { useContext, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Stack,
  TextField,
  Typography,
  Divider,
  Breadcrumbs,
} from "@mui/material";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext, IAuthContext } from "../../contexts/Auth.context";
import { ICommunity } from "../../models/communities.modal";
import store from "../../store";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Loader from "../Loader/Loader";
import { IMultiMedia } from "../../models/multi-media.model";
import Close from "@mui/icons-material/Close";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import "dayjs/locale/en"; // Import the English locale
import { InsertPhotoOutlined } from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { usePlans } from "../../hooks/usePlans";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { NewAddPlan } from "../Plans/NewAddPlan";
import {
  saveCourse,
  updateCourseByCourseId,
} from "../../services/courses.service";
import { useCourses } from "../../hooks/useCourses";
import { metaConfig } from "../../utils/constants";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


dayjs.extend(utc);
//Asynchronous load on
interface Community {
  name: string;
  id: string;
}

//
export interface IAddCourse {
  name: string;
  description: string;
  endDate: string;
  pricing: string;
  status: string;
  plan: any;
  image: IMultiMedia | "";
  community: string;

}
interface ISelectedCommunity {
  selectedCommunity: ICommunity[];
  closeDialog: () => void;
  loadPlans: () => void;
}

export interface ICourse {
  _id: string;
  name: string;
  description: string;
  endDate: string;
  pricing: string;
  status: string;

  image: IMultiMedia | "";
  document: string;
  community: string;
  images: IMultiMedia | "";
}

// duration dropdown values

export const AddCourse: React.FC<ISelectedCommunity> = ({
  closeDialog,
  loadPlans,
}) => {
  const navigate = useNavigate();

  const communityState = store.getState();
  const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;
  // console.log(currentCommunity,"currentCommunity")

  const [value, setValue] = React.useState("FREE");
  const [planDetail] = useState<any>();
  const [selectedPlan, setSelectedPlan] = useState<any>(planDetail);
  // console.log(selectedPlan);

  const [courseValues, setCourseValues] = useState<any>({
    name: "",
    description: "",
    pricing: "FREE",
    endDate: "",
    status: "",
    image: "",

    community: currentCommunity?._id ?? "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValue((event.target as HTMLInputElement).value);
    if (event.target.value === "FREE") {
      // Reset selected plan when switching to 'free'
      // setSelectedPlan(null);
    }
    setCourseValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const { id } = useParams();
  const { getCourseByCourseId } = useCourses();
 
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [community] = React.useState(false);
  const [options] = React.useState<Community[]>([]);
  const loading = community && options.length === 0;

  //label change logic start

  const [open, setOpen] = React.useState(false);
  const [planImages, setPlanImages] = useState<FileList | null>(null);

  const [planDocuments] = useState<FileList | null>(null);
  // eslint-disable-next-line
  const [error, setError] = useState(false);

  //attach file logic end
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState<Dayjs | null>(null);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [plans, setPlans] = useState<IAddCourse[]>([]);
  // to load data from the server
  // useEffect(() => {
  //   if (!id) return;
  //   const fetchPlan = async () => {
  //     const { data } = await getPlan(getAccessToken(), id);
  //     const labelValue: any = durationDropdownOptions.find(
  //       (e) => e.key === data.duration
  //     );
  //     setDurationFieldValue(labelValue?.value);
  //     let tempBF = bFrequency.map((b) =>
  //       data.billingFrequency.includes(b.key)
  //         ? { ...b, defaultChecked: true }
  //         : b
  //     );

  //     setBFrequency(tempBF);
  //     setCourseValues({
  //       ...courseValues,
  //       name: data.name,
  //       description: data.description,
  //       pricing: data.pricing,
  //       status: data.status,
  //       // sections: data.sections,
  //       // subscribers:data.subscribers,
  //       // views:data.views,
  //       // documents: data.document,
  //       community: data.community,
  //       image: data.image,
  //       // coverImage: data.images,
  //       endDate: formateStartDate(data?.endDate),
  //     });
  //     //console.log(data.document.value, "Data");
  //   };

  //   fetchPlan();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  useEffect(() => {
    if (!id) return;
    const loadCourseList = async () => {
      const data = await getCourseByCourseId(selectedCommunityId, id || "");

      const firstCourse = data.courses[0];

      setCourseValues({
        ...courseValues,
        name: firstCourse?.name,
        description: firstCourse?.description,
        pricing: firstCourse?.pricing,
        status: firstCourse?.status,

        //  plan: firstCourse?.plan,
        // sections: data.sections,
        // subscribers:data.subscribers,
        // views:data.views,
        // documents: data.document,
        community: firstCourse?.community,
        // image: data.image,
        // coverImage: data.images,
        endDate: formateStartDate(firstCourse?.updatedAt),
      });
      setValue(firstCourse.pricing);
      if (firstCourse?.plan && firstCourse.pricing === "PAID") {
        setSelectedPlan(firstCourse.plan);
      }
    };

    loadCourseList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);



  const formateStartDate = (date: string) => {
    const startDate = date.slice(0, 10);
    setSelectedStartDate(dayjs.utc(startDate));
    return startDate;
  };

  // Functions

  const handleMyDateChange = (e: any) => {
    // Create a new Date object from the user's input
    const selectedNewDate = new Date(e);
    const timezoneOffsetInMinutes = selectedNewDate.getTimezoneOffset();
    selectedNewDate.setMinutes(
      selectedNewDate.getMinutes() - timezoneOffsetInMinutes
    );

    const isoDateString = selectedNewDate.toISOString(); //to ISO

    setCourseValues({ ...courseValues, endDate: isoDateString });
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Ensure that offerValue does not exceed totalPlanValue
    if (name === "offerValue") {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue) {
        console.error("Offer Price cannot exceed Actual Price");
        return;
      }
    }

    setCourseValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    setError(value === "");
  };
    // eslint-disable-next-line
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const handleSubmit = async (e: any, status: string) => {
    setIsSubmitClicked(true);
    e.preventDefault();
    courseValues.community = currentCommunity?._id ?? "";
    courseValues.status = status;
    courseValues.plan = selectedPlan?._id;
    if (courseValues.pricing === "PAID" && selectedPlan) {
      courseValues.plan = selectedPlan?._id;
    } else {
      courseValues.pricing = "FREE";
      delete courseValues.plan;
    }
    try {
      setLoader(true); // Set loading to true when initiating the request
      if (id) {
        // Editing an existing plan
        const updateResponse = await updateCourseByCourseId(
          selectedCommunityId,
          id,
          getAccessToken(),
          courseValues,
          planImages,
          planDocuments
        );
        if (updateResponse.data && updateResponse.status === 200) {
          enqueueSnackbar("Course updated", {
            variant: "success",
            autoHideDuration: 3000,
          });
          navigate("/courses");

          closeDialog();
        } else {
          enqueueSnackbar("Failed to update Course", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } else {
        const createResponse = await saveCourse(
          currentCommunity?._id ?? "",
          getAccessToken(),
          courseValues,
          planImages,
          planDocuments
        );
        setLoader(true); // Set loading to true when initiating the request
        if (createResponse.status === 200) {
          setPlans((prevPlans) => [...prevPlans, createResponse.data]);
          enqueueSnackbar("Course added", {
            variant: "success",
            autoHideDuration: 3000,
          });
          navigate("/courses");
          closeDialog();
          loadPlans();
          setLoader(true);
        } else {
          enqueueSnackbar("Failed to add course ", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoader(false);
    }
  };

  //attach file new logic start
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");

  const handlePlanImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanImages(e.target.files);
    }
  };

  const handleClickOpenImg = () => {
    setOpen(true);
  };

  const handleClosesImg = () => {
    setOpen(false);
  };

  const locations = useLocation();
  const IsTitleDisplay = locations.pathname.includes("/plans/new") && id;

  const { getCommunityPlansList } = usePlans();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [plansList, setPlansList] = useState<ICourse[]>([]);

  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const loadPlansList = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getCommunityPlansList(selectedCommunityId);

        if (plans_data) {
          setIsLoading(false);
          setPlansList(plans_data);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(plansList);
  useEffect(() => {
    loadPlansList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);

  const plansTitles = plansList ? plansList.map((plan) => plan?.name) : [];

  const [openPlans, setOpenPlans] = React.useState(false);
  

  // const communitiesSuggestion = useSelector(() => {
  //   return state?.communities;
  // });
  // const filteredSelectedCommunity = communitiesSuggestion?.communities?.filter(
  //   (community) => community?._id === selectedCommunity?.selectedCommunity?.id
  // );

  const handleClickOpenPlans = () => {
    setOpenPlans(true);
  };
  const handleClose = () => {
    setOpenPlans(false);
  };

  return (
<>
    <Helmet>
        <title>{metaConfig.title.addCourses}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
    <div>
      {loading ? (
        <Loader /> // Replace with your loading indicator component
      ) : (
        <Stack direction={"column"} spacing={1}>
          <Stack sx={{ display: IsTitleDisplay ? "block" : "none" }}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                color: "#3760A9",
                fontFamily: "Inter",
              }}
            >
              {id ? "Publish" : "Create"} a Course
            </Typography>
          </Stack>

          <Box
            sx={{
              backgroundColor: "white",
              pl: 1,
              pr: 1,
              pb: 1,
              borderRadius: "5px",
              height: { xs: "fit-content", md: "90vh" },
            }}
          >
            <>
              <Stack>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: 500,
                    color: "#3C5AA0",
                    fontFamily: "Inter",
                    p: 1.5,
                  }}
                >
                  <Stack>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link  to="/courses" style={{textDecoration:'none'}}>
                        Courses
                      </Link>
                      <Typography
                        sx={{
                          fontSize: { xs: "16px", md: "20px" },
                          fontWeight: 500,
                          color: "#3C5AA0",
                          fontFamily: "Inter",
                          p: 1,
                        }}
                      >
                        {" "}
                        {id ? "Update" : "New"} Course{" "}
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Typography>
              </Stack>
              <Box
                sx={{
                  borderRadius: "10px",
                }}
              >
                <Grid container sx={{ borderRadius: "10px" }}>
                  <Grid item xs={12} md={7}>
                    <Stack spacing={1} sx={{ m: 1 }}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#565656",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          Course Details
                        </Typography>
                      </Stack>
                      <Stack>
                        <Stack>
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "13px",
                              color: "#2653A3",
                              fontWeight: "500",
                              fontFamily: "Inter",
                            }}
                          >
                            Course Name
                          </Typography>
                          <TextField
                            required
                            name="name"
                            label="Name"
                            size="small"
                            onChange={handleChanges}
                            value={courseValues?.name}
                            // InputProps={{ style: { height: "45px" } }}
                            InputLabelProps={{
                              style: { fontSize: "13px", color: "#565656" },
                            }}
                            sx={{
                              mt: 1,

                              mr: 1,
                              mb: 1,
                              fontSize: "13px",
                              backgroundColor: "white",
                            }}
                          ></TextField>
                        </Stack>
                      </Stack>

                      <Stack>
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "#2653A3",
                              fontWeight: "500",
                              fontFamily: "Inter",
                            }}
                          >
                            Course Description
                          </Typography>
                          <TextField
                            name="description"
                            id="description"
                            //placeholder="Describe your plan in short words..."
                            label="Description"
                            size="medium"
                            value={courseValues?.description}
                            onChange={handleChanges}
                            multiline
                            rows={2}
                            InputLabelProps={{
                              style: { fontSize: 13, color: "#565656" },
                            }}
                            sx={{ mt: 1, mr: 1, backgroundColor: "white" }}
                          ></TextField>
                        </Stack>
                        <Typography
                          sx={{
                            ml: 1,
                            mt: 1,
                            fontSize: "13px",
                            color: "#5E6C84",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          {/* * The Course Name and Course Description will appear
                          on the invoice as entered above. */}
                        </Typography>
                      </Stack>
                      <Divider></Divider>
                    </Stack>

                    <Stack sx={{ m: 1 }}>
                      <Grid container spacing={{ xs: 1, md: 5 }}>
                        <Grid item xs={12} md={6}>
                          <Stack>
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  mt: 1,
                                  fontSize: "13px",
                                  color: "#2653A3",
                                  fontWeight: "500",
                                  fontFamily: "Inter",
                                }}
                              >
                                Expiry date of course
                              </Typography>
                            </Stack>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DesktopDatePicker"]}
                                sx={{
                                  overflow: "hidden",
                                  backgroundColor: "white",
                                  mt: 1,
                                  p: 0,
                                }}
                              >
                                <DatePicker
                                  value={selectedStartDate}
                                  label="Ends on"
                                  disablePast
                                  onChange={handleMyDateChange}
                                  sx={{
                                    fontSize: "12px",
                                    backgroundColor: "white",
                                    "& .css-q9e13y-MuiStack-root>.MuiTextField-root":
                                      {
                                        backgroundColor: "white",
                                      },
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      fullWidth: true,
                                      sx: {
                                        "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error":
                                          {
                                            // Styling for the label
                                            color: "#00000061",
                                          },
                                        "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                          {
                                            // Styling for the input box
                                            borderColor: "green",
                                            border: "1px solid",
                                          },
                                        "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                                          {
                                            color: "#92929D",
                                            fontSize: "13px",
                                          },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                              {/* {isSubmitClicked && !courseValues?.endDate && (
                                <Typography
                                  style={{ color: "red", fontSize: "8px" }}
                                >
                                  Start date is required!
                                </Typography>
                              )} */}
                            </LocalizationProvider>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>

                      <Grid container spacing={5} sx={{ pr: 1 }}>
                        <Grid item xs={6.5}>
                          <Stack sx={{ mt: 2 }}>
                            <Stack>
                              <Typography
                                sx={{
                                  color: "#565656",
                                  fontSize: "15px",
                                  fontWeight: 500,
                                }}
                              >
                                Pricing Details
                              </Typography>
                            </Stack>
                            <FormControl>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                sx={{
                                  mt: 1,
                                  fontSize: "13px",
                                  color: "#2653A3",
                                  fontWeight: "500",
                                  fontFamily: "Inter",
                                }}
                              >
                                Select
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="pricing"
                                value={value}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="FREE"
                                  control={<Radio />}
                                  label="Free"
                                />
                                {currentCommunity?.collectSubscription === "YES" ? ( 
                                  <FormControlLabel
                                  value="PAID"
                                  control={<Radio />}
                                  label="Paid"
                                />
                                ):(
                                  <>
                                  </>
                                )}
                              
                                
                              </RadioGroup>
                            </FormControl>
                          </Stack>
                        </Grid>

                        {value === "PAID" && (
                          <Grid item xs={5.5}>
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  ml: 1,
                                  mt: 3,
                                  fontSize: "13px",
                                  color: "#2653A3",
                                  fontWeight: "500",
                                  fontFamily: "Inter",
                                }}
                              >
                                Select Plan
                              </Typography>
                            </Stack>{" "}
                            <Stack sx={{}}>
                              <Autocomplete
                                options={plansTitles}
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                loading={isLoading}
                                sx={{ mt: 1 }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Select Plan" />
                                )}
                                // disabled={value === 'free'}
                                noOptionsText="No Plans"
                                value={selectedPlan ? selectedPlan.name : ""}
                                // onChange={(event, newValue) => {
                                //   if (
                                //     typeof newValue === "string" ||
                                //     newValue === null
                                //   ) {
                                //     setSelectedPlan(newValue);
                                //   } else {
                                //     setSelectedPlan(null); // Or handle this case as per your logic
                                //   }
                                // }}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    // console.log(newValue);
                                    const selectedPlanObject = plansList?.find(
                                      (plan) => plan?.name === newValue
                                    );
                                    // console.log(selectedPlanObject);
                                    setSelectedPlan(selectedPlanObject); // Set the entire plan object as selected
                                  } else {
                                    setSelectedPlan(null);
                                  }
                                }}
                              />
                            </Stack>
                            {plansTitles.length === 0 && (
                              <Button
                                onClick={handleClickOpenPlans}
                                variant="contained"
                                color="primary"
                                sx={{
                                  backgroundColor: "transparent",
                                  boxShadow: "none",
                                  color: "#3C5AA0",
                                  textTransform: "capitalize",
                                  fontSize: "13px",
                                  textDecoration: "underline",

                                  ":hover": {
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                    color: "#3C5AA0",
                                  },
                                }}
                              >
                                Create Plan
                              </Button>
                            )}
                            <Dialog
                              fullWidth={fullWidth}
                              maxWidth={maxWidth}
                              open={openPlans}
                              onClose={handleClose}
                            >
                              <DialogActions
                                sx={{ backgroundColor: "#F0F9FF" }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{ backgroundColor: "#F0F9FF" }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: 500,
                                      textDecoration: "none",
                                      color: "#3C5AA0",

                                      marginLeft: 2,
                                    }}
                                  >
                                    Create a Plan
                                  </Typography>
                                  <Box
                                    onClick={handleClose}
                                    sx={{
                                      backgroundColor: "#3C5AA0",
                                      padding: "4px",
                                      borderRadius: "50%",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "white",
                                      fontSize: "10px",
                                      mt: "0.5px",
                                      mr: "10px",
                                      cursor: "pointer",
                                      "&:hover": {
                                        cursor: "pointer",
                                        border: "none",
                                      },
                                    }}
                                  >
                                    <Close sx={{ fontSize: "15px" }} />
                                  </Box>
                                </Grid>
                              </DialogActions>

                              <DialogContent>
                                <NewAddPlan
                                  selectedCommunity={[]}
                                  closeDialog={handleClose}
                                  loadPlans={loadPlans}
                                />
                              </DialogContent>
                            </Dialog>
                          </Grid>
                        )}
                      </Grid>
                      <Divider sx={{ mt: 2 }}></Divider>
                    </Stack>

                    <Stack>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={5.9}></Grid>
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 2.5,
                        fontSize: "13px",
                        color: "#2653A3",
                        fontWeight: "500",
                        fontFamily: "Inter",
                      }}
                    >
                      Cover Image
                    </Typography>
                    <Stack
                      sx={{
                        backgroundColor: "#F5F5F5",
                        p: 1.5,
                        m: 1,
                        borderRadius: "5px",
                        border: "1px dashed #000000",
                        height: { xs: "20vh", md: "25vh" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundImage: courseValues?.image
                          ? `url(${courseValues?.image})`
                          : "none",
                        backgroundPosition: "center",
                      }}
                    >
                      <AddPhotoAlternateOutlinedIcon
                        sx={{ fontSize: "40px", color: "#5E6C84", pb: 1 }}
                      />
                      <Typography
                        sx={{ fontSize: "13px", color: "#5E6C84", pb: 1 }}
                      >
                        Upload Cover Image
                      </Typography>
                      <input
                        type="file"
                        name="images"
                        onChange={handlePlanImages}
                        style={{
                          border: "2px solid #5E6C84",
                          borderRadius: "5px",
                          padding: "8px",
                          backgroundColor: "#ffffff",
                          color: "#5E6C84",
                          fontSize: "10px",
                          fontWeight: "500",
                          cursor: "pointer",
                          width: "70%",
                          marginTop: "10px",
                        }}
                      />
                    </Stack>
                    <Stack>
                      {courseValues?.image && (
                        <Button
                          variant="outlined"
                          onClick={handleClickOpenImg}
                          sx={{
                            textTransform: "capitalize",
                            mt: { xs: 2, md: 1.5 },
                            fontSize: "13px",
                            fontWeight: "defult",
                            width: "160px",
                            color: "#5E6C84",
                            border: "1px solid #5E6C84",
                            ":hover": {
                              color: "#5E6C84",
                              border: "1px solid #5E6C84",
                            },
                          }}
                          startIcon={<InsertPhotoOutlined />}
                        >
                          Preview Image
                        </Button>
                      )}

                      <Stack>
                        <Typography
                          sx={{
                            ml: 1,
                            mt: 1,
                            fontSize: "13px",
                            color: "#2653A3",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Your Subscription at{" "}
                        </Typography>
                        <Typography
                          sx={{
                            ml: 1,

                            fontSize: "13px",
                            color: "#000000",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          {currentCommunity?.name}
                        </Typography>

                        <Divider sx={{ mt: 1 }}></Divider>
                      </Stack>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "#000000",
                          fontWeight: "500",
                          fontFamily: "Inter",
                          ml: 1,
                        }}
                      >
                        {courseValues?.name}
                      </Typography>
                      <Stack>
                        {selectedPlan && (
                          <>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#2653A3",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              Plan Details
                            </Typography>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#000000",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                textTransform: "capitalize",
                              }}
                            >
                              Summary: {selectedPlan?.summary}
                            </Typography>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#000000",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              Offer Value:₹ {selectedPlan?.offerValue}
                            </Typography>
                          </>
                        )}
                      </Stack>
                      {/* Pop Up for About us Content */}
                      <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={open}
                        onClose={handleClosesImg}
                      >
                        <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ backgroundColor: "#F0F9FF" }}
                          >
                            <Stack></Stack>
                            <Stack></Stack>
                            <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                              <Box
                                onClick={handleClosesImg}
                                sx={{
                                  backgroundColor: "#3C5AA0",
                                  padding: "4px",
                                  borderRadius: "50%",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "white",
                                  fontSize: "10px",
                                  mt: "0.5px",
                                  mr: "10px",
                                  cursor: "pointer",
                                  "&:hover": {
                                    // backgroundColor: "#50A1CA", // Background color on hover
                                    cursor: "pointer",
                                    border: "none",
                                  },
                                }}
                              >
                                <Close sx={{ fontSize: "13px" }} />
                              </Box>
                            </Stack>
                          </Stack>
                        </DialogActions>

                        <DialogContent>
                          <img
                            alt=""
                            // onClick={() => navigate(`/plans/${plan?._id}`)}
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "300px",
                              objectFit: "cover",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                            // src={
                            //   courseValues?.coverImage
                            //     ? courseValues?.coverImage?.value
                            //     : " "
                            // }
                          ></img>
                        </DialogContent>
                      </Dialog>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Grid container sx={{ mt: 2, pb: { xs: 5, md: 2 } }}>
                <Grid item xs={12} md={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        background:
                          "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                        textTransform: "capitalize",
                        border: "none",
                        borderRadius: "10px",
                        boxShadow: "none",
                        color: "white",
                        "&:hover": {
                          background:
                            "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                          cursor: "pointer",
                          color: "white",
                          border: "none",
                          boxShadow: "none",
                        },
                      }}
                      onChange={(e: any) => setCourseValues(e.target.value)}
                      // onClick={handleSubmit}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      onClick={(e) => {
                        if (courseValues?.name && courseValues?.description) {
                          handleSubmit(e, courseValues?.status || "CREATED");
                        } else {
                          if (
                            courseValues?.name &&
                            !courseValues?.description
                          ) {
                            setErrorMessage(
                              "*Please enter a Course description before submitting"
                            );
                          } else {
                            setErrorMessage(
                              "*Please enter a Course name before submitting"
                            );
                          }
                        }
                      }}
                      // disabled={!isDataAvailable}
                    >
                      {"Save"}
                    </Button>
                    <Typography sx={{ color: "red" }}>
                      {" "}
                      {errorMessage}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </>
          </Box>
        </Stack>
      )}
    </div>
    </>
  );
};
