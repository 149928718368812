import { Close } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from './CustomButton'
import { interfaceCourse } from '../../../models/courses.model'
import { useCourses } from '../../../hooks/useCourses'
interface IEditDocuments {
    handleCloseEditDocument: () => void
    document: any;
    data: interfaceCourse;
    refetchCourseData: () => void; 
}
const EditDocumentPopUp: React.FC<IEditDocuments> = ({ handleCloseEditDocument,document,data ,refetchCourseData}) => {
    const [loading,setLoading]=useState(false)
    const {  updateDocumentByDocumentId,  } = useCourses();
    const [documentsData,setDocumentsData]=useState(document)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentsData({ ...documentsData, label: e.target.value });
      };
     const handleSave =async()=>{
        setLoading(true)
        await updateDocumentByDocumentId(data.community?._id, document?._id, { label: documentsData.label })
        setLoading(false)
        refetchCourseData();
        handleCloseEditDocument();
        
     } 
    
    return (
        <Stack>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: "#F0F9FF", height: "35px" }}
            >
                <Typography
                    sx={{
                        color: "#3C5AA0",
                        fontSize: "20px",
                        fontWeight: "500",
                        backgroundColor: "#F0F9FF",
                        // pt: 1,
                        px: 2,
                    }}
                >
                    Edit Document
                </Typography>

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        backgroundColor: "#3C5AA0",
                        padding: "4px",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: "10px",
                        mt: "0.5px",
                        mr: "10px",
                        cursor: "pointer",
                    }}
                >
                    <IconButton onClick={handleCloseEditDocument}>
                        <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
                    </IconButton>
                </Stack>
            </Grid>
            <Stack sx={{ p: 2 }}>

                <Typography
                    sx={{ fontSize: "10px", fontWeight: "400", color: "#5E6C84" }}
                >
                    Name
                </Typography>
                <Grid container>
                    <Grid item xs={12}>
                    <TextField
                                placeholder='Document Name'
                                size='small'
                                name="name"
                                value={documentsData.label}
                                fullWidth
                                sx={{ pb: 2, '& input::placeholder': { fontSize: '0.8rem' } }}
                                onChange={handleChange}
                            />
                    </Grid>

                </Grid>
                <Stack
                    display={"flex"}
                    // alignItems={"center"}
                    justifyContent={"flex-end"}
                    flexDirection={"row"}
                    sx={{ pt: 1.5 }}
                >
                    <Box sx={{ mr: "10px" }}>
                        <CustomButton
                            loading={loading}
                            children={" Save "}
                            background={"#3C5AA0"}
                            textColor={"#FFFFFF"}
                            onClick={handleSave}
                            disable={false}
                            />
                    </Box>
                    <Box>
                        <CustomButton
                            children={"Cancel"}
                            background={"#BEBEBE"}
                            textColor={"#FFFFFF"}
                            disable={false}
                            loading={false}
                            onClick={handleCloseEditDocument}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default EditDocumentPopUp;