import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { addPaymentRequests, getPaymentRequestList,deleteAllPaymentRequest, deletePaymentRequestByUserId, updatePaymentRequest } from "../services/paymentRequests.services";

export const UsePaymentRequest =()=>{
    const {getAccessToken} = useContext<IAuthContext>(AuthContext)
    const { enqueueSnackbar } = useSnackbar();
   //const [paymentRequest,setPaymentRequest]=useState<IpaymentRequest[]>([]);
   const [isLoading,setIsLoading]=useState(false);

   const getPaymentRequestListByCommunity = async(id:string)=>{
    try {
        setIsLoading(true);
        const response = await getPaymentRequestList(getAccessToken(),id);
        if(response.status===200){
            //setPaymentRequest(response.data)
        } else{
            enqueueSnackbar("Failed to fetch payment requests",{variant: "error", autoHideDuration: 3000 })
        }
        return response
    } catch (error) {
        enqueueSnackbar("Failed to fetch payment requests", { variant: "error", autoHideDuration: 3000 });
    }finally{
        setIsLoading(false);
    }
   }

   const addPaymentRequestsList = async (id: string, paymentDetails: any) => {
    try {
      setIsLoading(true);
      const response = await addPaymentRequests(getAccessToken(), id, paymentDetails);
      if (response?.status === 201) {
       // setPaymentRequest(response?.data);
        enqueueSnackbar("Payment request sent", { variant: "success", autoHideDuration: 3000 });
        return response.data;
      } else {
        enqueueSnackbar("Payment request failed", { variant: "error", autoHideDuration: 3000 });
      } return response;
    } catch {
      enqueueSnackbar("Payment request failed", { variant: "error", autoHideDuration: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAllPaymentRequestList = async (
    // communityId: string,
    formData: any
  ) => {
    try {
      const response = await deleteAllPaymentRequest(
        getAccessToken(),
        // communityId,
        formData
      );
      if (response.status === 200) {
       // setPaymentRequest(response.data.data);
        enqueueSnackbar("List deleted", { variant: "success" });
      } else {
        enqueueSnackbar("Couldn’t delete request", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t delete request", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

 const deleteRequestPaymentId = async (communityId:string,id:string) => {
    try {
      const response = await deletePaymentRequestByUserId(getAccessToken(), communityId,id);
      if (response.status === 200) {
       /// setPaymentRequest(response.data.data);
        enqueueSnackbar("Deleted payment request", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Failed to delete payment request", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t delete", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

  const updatePaymentRequestbyId = async (communityId:string,id:string,formData:any) => {
    try {
      const response = await updatePaymentRequest(getAccessToken(), communityId,id,formData);
      
      if (response && response.status === 200) {
        //setPaymentRequest(response.data);
        enqueueSnackbar("Payment updated", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Failed to update payment", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Failed to update payment", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };
   return {
    isLoading,
    setIsLoading,
    //setPaymentRequest,
    getPaymentRequestListByCommunity,
    addPaymentRequestsList,
    deleteAllPaymentRequestList,
    deleteRequestPaymentId,
    updatePaymentRequestbyId
   }
}