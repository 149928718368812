import axios from "axios";
import { BASE_URL } from "../configurations/url.config";

import { Invitations } from "../models/Invitation.model";


export const addInvitations = async (token:string,usersToAdd:any,communityId:string) => {

  try{
    const response = await axios.post(`${BASE_URL}/invites/${communityId}/bulk`,usersToAdd ,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    });
   
    if(response.status === 200) {
      response.data = parseInvitation(response.data)
    }
      return response;
    } catch(err) {
      console.log("Err :================================ ",err);
      return{status:500,data:[]};
  }
};
export const getAllCommunityInvitations = async (token: string,id:string) => {
  try {
    const response = await axios.get(`${BASE_URL}/invites/community/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
   
    if (response.status === 200) {
        response.data =parseInvitation(response.data)
      }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};
export const deleteInvitationById = async (token: string,id:string) => {
    try {
      const response = await axios.delete(`${BASE_URL}/invites/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        response.data = parseInvitation(response.data)
      }
      return response;
    } catch (err) {
      console.log("ERR :", err);
      return { status: 500, data: [] };
    }
  };
//Checkbox selected
  export const deleteSelectedInvitation = async (token: string,communityId:string,formData:any) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/invites/${communityId}/bulk`,
      {
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

   

    if (response.status === 200) {
      response.data = parseInvitation(response.data); // Assuming parseInvitation is defined
    }

    return response;
  } catch (err) {
    console.error('Error:', err);
    return { status: 500, data: [] };
  }
};

  export const updateInvitationById = async (token: string,id:string,formData:any) => {
    try {
      const response = await axios.put(`${BASE_URL}/invites/${id}`,formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        response.data = parseInvitation(response.data)
      }
      return response;
    } catch (err) {
      console.log("ERR :", err);
      return { status: 500, data: [] };
    }
  };

  //checkbox
  export const updateSelectedInvitation = async (token: string,communityId:string,formData:any) => {
    try {
      const response = await axios.put(`${BASE_URL}/invites/${communityId}/bulk`,formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        response.data = parseInvitation(response.data)
      }
      return response;
    } catch (err) {
      console.log("ERR :", err);
      return { status: 500, data: [] };
    }
  };

  export const createInvitationByCommunityId = async (token: string,id:string,formData:any) => {
    try {
      const response = await axios.post(`${BASE_URL}/invites/${id}`,formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
       
        response.data = parseInvitation(response.data)
      }
      return response;
    } catch (err) {
      console.log("ERR :", err);
      return { status: 500, data: [] };
    }
  };



  export const getCommunityInvitationsForUser = async (token:string) => {
    try {
      const response = await axios.get(`${BASE_URL}/invites`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
       return  response.data;
      }else {
        return []
      }
      
    } catch (err) {
      console.log("ERR :", err);
      return { status: 500, data: [] };
    }

  }
  export const joinToInvitedCommunity = async (token: string,formData:any) => {
    try {
      const response = await axios.post(`${BASE_URL}/invites/community/join`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    console.log(response.data)
      return response;
    } catch (err) {
      console.log("ERR :", err);
      return { status: 500, data: [] };
    }
  };

const parseInvitation = (d: Invitations) => {
  return {
    ...d,
    _id: d._id ?? "",
    firstName: d.firstName ?? "",
    lastName: d.lastName ?? "",
    phoneNumber: d.phoneNumber ?? 0,
    emailId: d.emailId ?? "",
    community: d.community ?? {},
    createdBy: d.createdBy ?? {},
    status: d.status ?? "",
    createdAt: d.createdAt ?? "",
    updatedAt: d.updatedAt ?? "",
    role: d.role ?? "",
    updatedBy: d.updatedBy ?? {},
    message:d.message ?? ""
  };
};
