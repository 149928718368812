import axios from "axios";
import { BASE_URL } from "../configurations/url.config";

export const fetchCommunityRequests = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/requests/community/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
export const acceptInvitationRequest = async (token: string, formData: any) => {
  try {
    const response = await axios.post(`${BASE_URL}/requests/accept`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const sendRequestToJoin = async (token: string, formData: any) => {
  try {
    const response = await axios.post(`${BASE_URL}/requests/create`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // console.log(response.data);
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const deleteInvitationRequest = async (token: string, id: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/requests/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
