import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import Divider from "@mui/material/Divider";

interface ITableProps {
  data: any;
}
export const VisitorsBySource = ({ data }: ITableProps) => {
  return (
    <Stack direction={"column"} spacing={2}>
      <Stack direction={"row"} spacing={2} alignItems={"baseline"}>
        <Typography variant="body2" sx={{ fontWeight: "500",pt:"0.9vh",fontSize:"14px" }}>
          Visitors By Source
        </Typography>

        {/* <Stack direction={"row-reverse"} sx={{ flexGrow: "1" }}>
          <MoreVertIcon sx={{ color: "info.main", cursor: "pointer" }} />
        </Stack> */}
      </Stack>
      <Divider />
      <Stack direction={"column"} spacing={1}></Stack>
    </Stack>
  );
};
