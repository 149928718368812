// import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
// import MuiDrawer from "@mui/material/Drawer";

// export const drawerWidth = 400;

export const styles = {
  main: {
    // flexGrow: 1,
    // p: 3,
    borderRadius: "30px",
    // height: "100vh",
    height: {xs:"87vh", md:'99vh'},
    overflow: "hidden",
  },
  contentSection: {
    height: "calc(100vh - 60px)",
    overflow: "auto",
    p: 2,
    borderRadius: "30px",
    backgroundColor: "#F4F4F4",
  },
};


