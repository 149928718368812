
import { IconButton } from "@mui/material";
import { IPost } from "../Posts/Posts";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";


interface FavoriteProps {
  post:IPost;
  userId: string |undefined;
  handleHeartClick: (e: React.MouseEvent<HTMLButtonElement>, data: any) => void;
}

export const Favorite :React.FC<FavoriteProps> = ({post,userId,handleHeartClick}) => {
  const isLikedByUser = post?.likedBy.some(user => user._id === userId);

  return (
    <>
    <IconButton
     onClick={(e) => handleHeartClick(e, post)}
      sx={{
        "&:hover": {
          backgroundColor: "#fff",
          cursor: "pointer",
        },
      }}
    >
      {isLikedByUser ? (
        <FavoriteIcon
          sx={{
            color: "red",
            fontSize: "17px",
          }}
        />
      ) : (
        <FavoriteBorderIcon
          sx={{
            color: "gray",
            fontSize: "17px",
          }}
        />
      )}
    </IconButton>
    </>
  );
};
