

const StaticValues = {
"ACTIVE": "Active",
"INACTIVE": "Inactive",
"INVALID": "Invalid",
"ADMIN": "Admin",
"USER": "Member",
"MODERATOR": "Moderator",
"YES": "Yes",
"NO": "No",
"YEAR": "Year",
"MONTH": "Month",
"DAY": "Day",
"WEEK": "Week",
"YEARLY": "Yearly",
"MONTHLY": "Monthly",
"WEEKLY": "Weekly",
"DAILY": "Daily",
"QUARTERLY": "Quarterly",
"HALF_YEARLY": "Half Yearly",
"ONE_TIME": "One Time",
"FREE":"Free",
"PAID":"Paid",
"PUBLIC": "Public",
"PRIVATE": "Private",
"BUSYNESS": "Business",
"Business": "Business",
"TECHNOLOGY": "Technology",
"Role Type":"Role",
"INVITED":"Invited",
"IN_ACTIVE":"In_Active",
"BLOCK": "Blocked",
"CREATED":'Created',
"PUBLISHED":'Published',
"USERCANCELLED":'Cancelled',
"FAILURE":'Failed',
"PENDING":"Pending",
"User":"Member",
"Admin":"Admin",
"ONLY_MEMBERS":"Only Members",
"EVERY_ONE":"Every One",
"NO_ONE":"No One",
"SUCCESS":'Success',
"SUPERADMIN":"SuperAdmin",
"BOTH":"SMS & Email",
"EMAIL":"email",
"DROPPED":"Dropped",
"SMS":"SMS",
"VALIDATING":"Validating",
"REJECT":"Reject"

};
export const getStaticValue = (key:string)=>{
    //console.log(key);
    return StaticValues[key as keyof typeof StaticValues]; // Use type assertion
}