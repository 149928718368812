import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  IconButton,
  Box,
  Dialog,
  Grid,
  DialogContent,
  Tooltip,
  Stack,
  Divider,
  Popover,
  TablePagination,
  Checkbox,
  Avatar,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import { Helmet } from "react-helmet";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { IColumn } from "../../models/table.model";
import { CustomTableHeader } from "../tables/components/CustomTableHeader";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Close,
  DeleteOutline,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { PaymentRequests } from "./PaymentRequest";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { IpaymentRequest } from "../../models/paymentRequest.model";
import { useSelector } from "react-redux";
import { UsePaymentRequest } from "../../hooks/usePaymentRequest";
import { getStaticValue } from "../../utils/StaticValues";
import ClearOutlined from "@mui/icons-material/ClearOutlined";
import Loader from "../Loader/Loader";
import { GetColor } from "../tables/GetColor";
import { metaConfig } from "../../utils/constants";
import { Link } from 'react-router-dom';
import { AlternativePage } from "../AlternatePages/AlternativePage";
import AlterpageImage from "../../assets/icons/svg-icons/noPayments.png"

const PaymentRequestList = () => {
  const [tableData, setTableData] = useState<IpaymentRequest[]>([]);
  const [tableRowsData, setTableRowsData] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<IpaymentRequest | null>(null);
  //table sorting
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [deleteCheckboxPopOverAnchor, setDeleteCheckboxPopOverAnchor] =
    useState<null | HTMLElement>(null);

  const [open, setOpen] = useState(false);
  const {
    getPaymentRequestListByCommunity,
    deleteAllPaymentRequestList,
    deleteRequestPaymentId,
  } = UsePaymentRequest();
  const [isLoading, setIsLoading] = useState(false);

  //community id, function api
  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const selectedCommunityId =  selectedCommunity?._id;

  //getapi
  useEffect(() => {
    if (selectedCommunityId && selectedCommunityId.length > 0) {
      const fetchList = async () => {
        setIsLoading(true);
        try {
          const response = await getPaymentRequestListByCommunity(
            selectedCommunityId
          );
          setTableData(response?.data.requests || []);
        } catch (error) {
          console.error("Error fetching payment requests:", error);
          // Handle error as needed
        } finally {
          setIsLoading(false);
        }
      };
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);

  const refetchData = async () => {
    setIsLoading(true);
    const response = await getPaymentRequestListByCommunity(
      selectedCommunityId
    );
    setIsLoading(false);
    // console.log(response,"resulttt");

    if (response) {
      setTableData(response?.data.requests);
    }
  };

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = tableData?.slice(startIndex, endIndex);

  const columns: IColumn[] = [
    { title: "Name", dataKey: "name" },
    // { title: "Cycle", dataKey: "cycle" },
    { title: "Frequency", dataKey: "frequency" },
    { title: "Notify on", dataKey: "notify" },
    { title: "Amount", dataKey: "amount" },
    { title: "Status", dataKey: "status" },  
    { title: "Comments", dataKey: "comments" },
    { title: "Actions", dataKey: "actions" },
  ];
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);

    // setTableRowsData(null);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // setTableRowsData(null);
  };

  const handleAddRequests = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    // setTableData(null)
    setSelectedRow(null);
  };

  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: "asc" | "desc" | undefined } = {};
      let newSortedColumns: string[] = [];

      if (prevSortDirections[columnTitle] === "asc") {
        newSortDirections[columnTitle] = "desc";
      } else {
        newSortDirections[columnTitle] = "asc";
      }

      newSortedColumns.push(columnTitle);
      setSortedColumns(newSortedColumns);
      return newSortDirections;
    });
  };

  const sortedData = [...paginatedData];
  const sortedColumnTitles = sortedColumns.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );
  if (sortedColumnTitles.length > 0) {
    sortedData?.sort((a: any, b: any) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns.find((col) => col.title === columnTitle);
        if (column) {
          if (column.dataKey === "name") {
            const aValue = a?.user.firstName;
            const bValue = b?.user.firstName;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === "frequency") {
            const aValue = a?.frequency;
            const bValue = b?.frequency;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === "notify") {
            const aValue = a?.notify;
            const bValue = b?.notify;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "amount") {
            const aValue = a?.amount;
            const bValue = b?.amount;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "status") {
            const aValue = a?.status;
            const bValue = b?.status;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "comments") {
            const aValue = a?.description;
            const bValue = b?.description;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          }
        }
      }
      return 0;
    });
  }

  const handleClearClick = () => {
    setTableRowsData([]); // Clear all selected rows
  };

  const handleDeleteSelected = (e: any) => {
    if (tableRowsData.length > 0) {
      setDeleteCheckboxPopOverAnchor(e.currentTarget);
    }
  };

  const openDeletePopOver = Boolean(deleteCheckboxPopOverAnchor);
  const idCheckBoxDeletePopover = openDeletePopOver
    ? "delete-multiple-popover"
    : undefined;

  const handleCloseCheckboxDeletePopover = () => {
    setDeleteCheckboxPopOverAnchor(null);
  };

  const handleDeleteAll = async () => {
    const selectedRowsData: IpaymentRequest[] =
      tableData?.filter((inv) => tableRowsData?.includes(inv._id)) || [];

    const selectedRowIds = selectedRowsData.map(
      (selectedRow) => selectedRow._id
    );

    setIsLoading(true);
    try {
      const payload = {
        ids: selectedRowIds,
      };
      console.log(payload);

      await deleteAllPaymentRequestList(payload);
      await refetchData();
      handleCloseCheckboxDeletePopover();
      setTableRowsData([]);
    } catch (error) {
      console.error("Error deleting requests:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteRequest = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e?.currentTarget);
  };

  const handleDeleteRequestByUserId = async () => {
    if (selectedRow) {
      await deleteRequestPaymentId(selectedCommunityId, selectedRow._id);
      handleClose();
      await refetchData();
    }
  };

  const handleClose = () => {
    // setSelectedPost(null);
    setAnchorEl(null);
  };
  const openDeletepopUp = Boolean(anchorEl);
  const id = openDeletepopUp ? "simple-popover" : undefined;

  const handleRowsSelect = (_id: string, row: IpaymentRequest) => {
    const isSelected = tableRowsData.includes(_id);
    let newSelectedRows = [];
    if (isSelected) {
      // If the row is already selected, remove it from the selected rows
      newSelectedRows = tableRowsData.filter((id) => id !== _id);
    } else {
      // If the row is not selected, add it to the selected rows
      newSelectedRows = [...tableRowsData, _id];
    }
    setTableRowsData(newSelectedRows);
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.paymentsRequests}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
       <Stack sx={{flexDirection:"row",display:"flex",alignItems:"center"}}>
          <Link to="/payments"  style={{ textDecoration: "none" }}>
              <IconButton
                sx={{
                  // backgroundColor: "#3C5AA0",
                  cursor: "pointer",
                  "&:hover": {
                 // Background color on hover
                 backgroundColor:"transparent",
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
             <ArrowBackIcon sx={{ color: "#3C5AA0",fontSize:"20px" }} />
              </IconButton>
          </Link>
          <Typography
            sx={{
              fontSize: {xs:"15px", md:"20px"},
              fontWeight: 500,
              color: "#3760A9",
              fontFamily: "Inter",
            }}
            >
            Payment Requests
          </Typography>
        
            </Stack>
        <Button
          variant="contained"
          startIcon={<AddCircleRoundedIcon />}
          sx={{
            textTransform: "capitalize",
            borderRadius: "30px",
            backgroundColor: "#3C5AA0",
            mb: "5px",
            "&:hover": {
              backgroundColor: "#3C5AA0", // Background color on hover
              cursor: "pointer",
              border: "none",
            },
          }}
          onClick={handleAddRequests}
        >
          send request
        </Button>
      </Stack>
      <Stack>
        {tableRowsData.length > 0 && tableData.length > 0 && (
          <Stack
            sx={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center",
              mt: 0,
              backgroundColor: "#ffffff",
            }}
            spacing={3}
            direction={"row"}
          >
            <ClearOutlined
              sx={{ cursor: "pointer", color: "#1C1B1F" }}
              onClick={handleClearClick}
            />

            <Typography sx={{ color: "#1C1B1F", fontWeight: "500", fontFamily:"inter" }}>
              {`${tableRowsData.length} Selected `}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={(e) => handleDeleteSelected(e)}
            >
              <DeleteOutline sx={{ color: "#000000" }} />
            </IconButton>
          </Stack>
        )}
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ height: "calc(100vh - 25vh)", mt: "0px", pt: 0 }}
        >
          <Table
            sx={{
              "& th": {
                fontSize: "12px",
                fontWeight: 700,
                color: "#565656",
                px: 1,
              },
              "& td": {
                borderColor: "#E0E0E0",
                fontSize: "13px",
                fontWeight: 400,
                color: "#565656",
                py: " 0px",
                px: "10px",

                "& .MuiTypography-root": { fontSize: "11px" },
              },
            }}
          >
            <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: '#ffffff' }}>
              <TableCell padding="checkbox">
                <Checkbox
                  size="small"
                  sx={{ pl: "11px", mt: "03px" }}
                  style={{
                    transform: "scale(1)",
                  }}
                  checked={tableRowsData.length === tableData.length}
                  onChange={() => {
                    if (tableRowsData.length === tableData.length) {
                      setTableRowsData([]);
                    } else {
                      setTableRowsData(tableData.map((row) => row._id));
                    }
                  }}
                />
              </TableCell>
              {columns.map((column: IColumn, i: number) => (
                <TableCell
                  key={i}
                  onClick={() => handleSort(column.title)}
                  sx={{ p: 0 }}
                >
                  <CustomTableHeader
                    title={column.title}
                    sortDirection={sortDirections[column.title]}
                  />
                </TableCell>
              ))}
            </TableHead>

            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Loader /> {/* Replace Loader with your loader component */}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {tableData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      align="center"
                      style={{ border: "none" }}
                    >
                     <AlternativePage image={AlterpageImage}
                     title="No Payment Requests"
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {sortedData
                      ?.sort(
                        (a, b) =>
                          new Date(b.createdAt).getTime() -
                          new Date(a.createdAt).getTime()
                      )
                      .map((row: IpaymentRequest, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{}} padding="checkbox">
                            <Checkbox
                              size="small"
                              sx={{
                                transform: "scale(1)",
                                ml: "3px",
                              }}
                              checked={tableRowsData.includes(row._id)}
                              onChange={() => {
                                handleRowsSelect(row._id, row);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                src={row?.user.avatar}
                                sx={{ height: "30px", width: "30px" }}
                                alt={`${row?.user.firstName} ${row?.user.lastName}`}
                              />
                              <Tooltip
                                title={`${row?.user.firstName} ${row?.user.lastName}`}
                                placement="bottom"
                              >
                                <Stack
                                  style={{
                                    marginLeft: "8px",
                                    fontWeight: 600,
                                    fontSize: "13px !important",
                                    color: "#1A1A1A",
                                  }}
                                >
                                  {row?.user.firstName?.length +
                                    row?.user.lastName.length >
                                  20
                                    ? `${row?.user.firstName.substring(
                                        0,
                                        20
                                      )}...`
                                    : `${row?.user.firstName} ${row?.user.lastName}`}
                                </Stack>
                              </Tooltip>
                            </div>
                          </TableCell>
                          {/* <TableCell>{row.cycle}</TableCell> */}
                          <TableCell>{getStaticValue(row.frequency)}</TableCell>
                          <TableCell padding="normal" sx={{ minWidth: 100 }}>
                            {getStaticValue(row.notify)}
                          </TableCell>
                          <TableCell sx={{ minWidth: 100 }}>
                            {row.amount}
                          </TableCell>
                          <TableCell sx={{ minWidth: 100 }}>
                            <Stack
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "11px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                              direction={"row"}
                            >
                              <FiberManualRecordIcon
                                key={i}
                                sx={{
                                  hieght: "10px",
                                  width: "10px",
                                  pb: 1,
                                  color: GetColor(row.status),
                                }}
                              />
                              {getStaticValue(row.status)}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={row.description} placement="bottom">
                              <Stack>
                                {row?.description.length > 20
                                  ? `${row?.description.substring(0, 20)}...`
                                  : `${row?.description}`}
                              </Stack>
                            </Tooltip>
                          </TableCell>
                          <TableCell sx={{ minWidth: 100 }}>
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                handleAddRequests();
                                setSelectedRow(row);
                              }}
                            >
                              <EditIcon sx={{fontSize:'18px'}} disable={row.status==="PAID"} />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={(e: any) => {
                                handleDeleteRequest(e);
                                setSelectedRow(row);
                              }}
                            >
                              <DeleteIcon sx={{fontSize:'18px'}} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Stack>

      <Stack
        direction="row-reverse"
        alignItems="center"
        sx={{ backgroundColor: "white", color: "grey", fontSize: "11px" }}
      >
        <Typography sx={{ mr: "20px", fontSize: {xs:"10px", md:'1.5vh',}, pr: {xs:"0px", md:"25px"} }}>
          Showing{" "}
          {Math.min(rowsPerPage, tableData?.length - page * rowsPerPage)} of{" "}
          {tableData?.length} entries
        </Typography>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={tableData?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="View"
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: { border: "1px solid #A5A5A5", borderRadius: "5px" },
            },
            MenuProps: {
              PaperProps: { style: { border: "1px solid #A5A5A5" } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(tableData?.length / rowsPerPage);

            const generatePageButtons = () => {
              const buttons = [];
              for (let offset = 0; offset < totalPages; offset++) {
                const pageNumber = page + offset;
                if (pageNumber < totalPages) {
                  buttons.push(
                    <IconButton
                      key={pageNumber}
                      onClick={(event) => onPageChange(event, pageNumber)}
                      style={{
                        fontSize: "1.9vh",
                        border:
                          page === pageNumber ? "1px solid #3C5AA0" : "none",
                        color: page === pageNumber ? "#3C5AA0" : "#000000",
                        width: page === pageNumber ? "30px" : "30px",
                        height: page === pageNumber ? "30px" : "30px",
                      }}
                    >
                      {pageNumber + 1}
                    </IconButton>
                  );
                }
              }
              return buttons;
            };

            return (
              <Stack
                direction={"row"}
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <IconButton
                  onClick={(event) => onPageChange(event, 0)}
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton>
                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>
                {generatePageButtons()}
                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)}
                  style={{ fontSize: "1.9vh" }}
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>
                <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              </Stack>
            );
          }}
        />
      </Stack>

      <Popover
        id={id}
        open={openDeletepopUp}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Do you really want to delete this record? This process cannot be undo.
          {/* Delete Post */}
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteRequestByUserId}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",

                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Popover
        id={idCheckBoxDeletePopover}
        open={openDeletePopOver}
        anchorEl={deleteCheckboxPopOverAnchor}
        onClose={() => setDeleteCheckboxPopOverAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete selected Payment Requests?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseCheckboxDeletePopover}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteAll}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => handleCloseModal()}
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ backgroundColor: "#F0F9FF", height: "50px", px: 2 }}
        >
          <Typography
            sx={{
              color: "#3C5AA0",
              fontSize: {xs:"15px", md:"20px"},
              fontWeight: "500",
              backgroundColor: "#F0F9FF",
              fontFamily:"Inter",
            }}
          >
            Payment Requests
          </Typography>
          <Box
            sx={{
              backgroundColor: "#3C5AA0",
              padding: "4px",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => handleCloseModal()}
          >
            <Close
              sx={{
                fontSize: "15px",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#3C5AA0", // Background color on hover
                  cursor: "pointer",
                  border: "none",
                },
              }}
            />
          </Box>
        </Grid>
        <DialogContent sx={{ px: "20px", pt: "10px" }}>
          <>
            <PaymentRequests
              communityId={selectedCommunityId}
              tableData={tableData}
              handleCloseModal={handleCloseModal}
              refetchData={refetchData}
              selectedRow={selectedRow!}
              setSelectedRow={setSelectedRow}
            />
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentRequestList;
