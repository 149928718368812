import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InvitationList from "./InvitationList";
import { Invitations } from "../../models/Invitation.model";
import { useInvitations } from "../../hooks/useInvitations";
import { AddUser } from "../users/AddNewUser";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { CommunityMembers } from "../../models/community-user.model";
import { useUsers } from "../../hooks/useUsers";
import { Close } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NoInvites } from "../AlternatePages/noInvitations";
import { metaConfig } from "../../utils/constants";
import { Link } from 'react-router-dom';

interface InvitationProp {
  openMode:boolean;
}
const Invitation:React.FC<InvitationProp> = ({openMode}) => {
  const [openInvites, setOpenInvites] = React.useState(false);
  const [isCheckboxSelected] = useState(false);
  const [selectedInvitations, setSelectedInvitations] = useState<Invitations[]>(
    []
  );
  const { isLoading, getInviteList } = useInvitations();
  const [invitations, setInvitations] = useState<Invitations[]>([]);
  const [memberList, setMemberList] = useState<CommunityMembers[]>([]);
  const { getUsersList } = useUsers();


  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  
  const selectedCommunityId = selectedCommunity?._id;
  useEffect(() => {
    const fetchInviteList = async () => {
      try {
        const response = await getInviteList(selectedCommunityId ?? "");
        setInvitations(response.data);

        const users = await getUsersList(selectedCommunityId);
        // memberListRef.current = users || [];
        setMemberList(users || []);
      } catch (error) {
        console.error("Error fetching invite list:", error);
      }
    };
    if (selectedCommunityId) {
      fetchInviteList();
    }
    // eslint-disable-next-line
  }, [selectedCommunityId]);

  const handleClickOpenInvites = () => {
    setOpenInvites(true);
    setSelectedInvitations([]);
  };

  const handleClose = async () => {
    setOpenInvites(false);
  };

  const updateInvitationList = (newInvitation: Invitations[]) => {
    setInvitations(newInvitation.concat(invitations));
  };

  const refetchInvitesData = async () => {
    const response = await getInviteList(selectedCommunityId);
    if (response) {
      setInvitations(response.data);
    }
  };

  return (
    <>
     <Helmet>
        <title>{metaConfig.title.invitations}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack sx={{flexDirection:"row",display:"flex",alignItems:"center"}}>
          <Link to="/users"  style={{ textDecoration: "none" }}>
              <IconButton
                sx={{
                  // backgroundColor: "#3C5AA0",
                  cursor: "pointer",
                  "&:hover": {
                 // Background color on hover
                 backgroundColor:"transparent",
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
               {!openMode ? <ArrowBackIcon sx={{ color: "#3C5AA0",fontSize:"20px" }} /> : null}
              </IconButton>
          </Link>
          {!openMode &&(
          <Typography
            sx={{
              fontSize: {xs:"15px", md:'20px'},
              fontWeight: 500,
              color: "#3760A9",
              fontFamily: "Inter",
            }}
            >
            Invitation Status
          </Typography>
          )}
            </Stack>
          <Tooltip title="Send New Invitation" placement="left">
            
            <Box
            component={"button"}
              onClick={handleClickOpenInvites}
              sx={{
                backgroundColor: "#3C5AA0",
                padding: "8px",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                // "&:hover": {
                //   backgroundColor: "#50A1CA", // Background color on hover
                //   cursor: "pointer",
                //   border: "none",
                // },
              }}
              disabled={selectedCommunity.status !== "PUBLISHED"}
            >
              <AddIcon
                style={{
                  fontSize: "32px",
                  color: "#ffffff",
                }}
              />
            </Box>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : invitations.length === 0 ? (
            <Paper
              elevation={0}
              sx={{
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pb:10,
              }}
            >
              <NoInvites />
              < Button
                onClick={handleClickOpenInvites}
                sx={{
                  backgroundColor: "#3C5AA0",
                  padding: "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#ffffff",
                  borderRadius: "30px",
                  marginTop: "10px",
                  textTransform:"capitalize",
                  px: 2,
                  "&:hover": {
                    backgroundColor: "#3C5AA0", // Background color on hover
                    cursor: "pointer",
                    border: "none", 
                  },
                  // Add some top margin to separate from CardMedia
                }}
                disabled={selectedCommunity.status !== "PUBLISHED"}
              >
                <Typography sx={{ color: "#ffffff" }}>
                  Send Invitation
                </Typography>
              </Button>
                {selectedCommunity.status !== "PUBLISHED" &&
                <Typography sx={{color:"red",fontSize:"12px",mt:"5px"}}>Publish Your Community to Send Invitation</Typography>
                }
            </Paper>
          ) : (
            <InvitationList
              invitations={invitations}
              refetchInvitesData={refetchInvitesData}
              communityMembers={memberList}
              communityId={selectedCommunityId}
              updateInvitationList={updateInvitationList}
            />
          )}
        </Grid>
        <Dialog
          fullWidth
          maxWidth="md"
          open={openInvites}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "100%",
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: "3px", backgroundColor: "#F0F9FF", height: "50px" }}
          >
            <Typography
              sx={{
                fontSize: {xs:"15px", md:"20px"},
                fontWeight: 500,
                textDecoration: "none",
                color: "#3C5AA0",
                marginLeft: 3,
                // textTransform: "uppercase",
                // mr:0.5
              }}
            >
              {isCheckboxSelected
                ? selectedInvitations.length > 0
                  ? "Edit Invites"
                  : "Add Invitations"
                : selectedInvitations.length === 1
                ? "Edit Invites"
                : "Add Invitations"}
            </Typography>
            {/* <Button onClick={() => handleClose()}>X</Button> */}
            <Box
              onClick={handleClose}
              sx={{
                backgroundColor: "#3C5AA0",
                padding: "4px",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "10px",
                mt: "0.5px",
                mr: "10px",
                cursor: "pointer",
                // "&:hover": {
                //   backgroundColor: "#50A1CA", // Background color on hover
                //   cursor: "pointer",
                //   border: "none",
                // },
              }}
            >
              <Close sx={{ fontSize: "15px" }} />
            </Box>
          </Grid>
          <DialogContent sx={{ padding: "10px 20px" }}>
            <AddUser
              onClose={handleClose}
              isCheckboxSelected={isCheckboxSelected}
              initialInviteData={invitations}
              selectedInvitations={[]}
              communityMembers={memberList}
              selectedCommunityId={selectedCommunityId}
              refetchInvitesData={refetchInvitesData}
              updateInvitationList={updateInvitationList}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
export default Invitation;
