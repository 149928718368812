import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";

import {
  acceptInvitationRequest,
  deleteInvitationRequest,
  fetchCommunityRequests,
  sendRequestToJoin,
} from "../services/requests.service";
import store from "../store";
import { useSelector } from "react-redux";
import { Invitations } from "../models/Invitation.model";

export const useRequests = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requests, setRequests] = useState<Invitations[]>([]);

  const state = store.getState();
  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });

  //const [selectedCommunityId]= useState(selectedCommunity.selectedCommunity?.id)

  const getCommunityRequests = async () => {
    setIsLoading(true);
    const communityId = selectedCommunity.selectedCommunity?._id ?? "";
    if (communityId) {
      const response = await fetchCommunityRequests(
        getAccessToken(),
        communityId
      );
      if (response.status === 200) {
        setIsLoading(false);
        return response.data;
      } else if (response.status === 302) {
        return response;
      } else {
        enqueueSnackbar("Fetching community request failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
    }
  };
  const acceptInvitation = async (formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await acceptInvitationRequest(
        getAccessToken(),

        formData
      );

      if (response.status === 200) {
        setRequests((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar("User added", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn’t accept request", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar(" Couldn’t add user", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const SendCommunityRequest = async (formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await sendRequestToJoin(getAccessToken(), formData);

      if (response.status === 201) {
        setRequests((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar(response?.data?.message, {
          variant: "success",
          autoHideDuration: 3000,
        });
      }
      if (response.status === 200) {
        enqueueSnackbar(response?.data?.message, {
          variant: "warning",
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("Failed to send request", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const deleteInvitation = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await deleteInvitationRequest(getAccessToken(), id);
      if (response.status === 200) {
        setRequests((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar("Invite deleted", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn’t delete the request", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t delete request", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return {
    isLoading,
    requests,
    getCommunityRequests,
    acceptInvitation,
    SendCommunityRequest,
    deleteInvitation,
  };
};
