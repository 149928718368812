import { Stack, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "./ArrowStyles.css";
import { JoinedAllCommunities } from "../AllCommunities/JoinedAllCommunities";
import { ICommunity } from "../../../models/communities.modal";
import { useCommunity } from "../../../hooks/useCommunity";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Loader from "../../Loader/Loader";

const JoinedCommunities = () => {
  const { memberCommunities } = useCommunity();
  const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const responseData = await memberCommunities();
        setMyMemberCommunities(responseData?.data || []);
      } catch (err) {
        console.log('Failed to load communities.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 6,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : myMemberCommunities.length > 0 ? (
        <Stack>
          <Stack
            direction={"row"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: '18px' },
                fontWeight: 700,
                textDecoration: "none",
                color: "#000000",
                marginLeft: 2,
              }}
            >
              Joined Communities
            </Typography>

            {myMemberCommunities.length > 5 && (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#50A1CA",
                  textAlign: "right",
                  alignItems: "end",
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(`/joined-communities`)}
              >
                {myMemberCommunities.length} Communities
              </Typography>
            )}
          </Stack>
          <Carousel showDots={false} arrows={true} responsive={responsive}>
            {myMemberCommunities.map((item) => (
              <div key={item._id}>
                <JoinedAllCommunities {...item?.community} />
              </div>
            ))}
          </Carousel>
        </Stack>
      ) : (
        <Stack pt={2}>
          <Stack
            direction={"row"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: '18px' },
                fontWeight: 700,
                textDecoration: "none",
                color: "#000000",
              }}
            >
              Joined Communities
            </Typography>

            {myMemberCommunities.length > 5 && (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#50A1CA",
                  textAlign: "right",
                  alignItems: "end",
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(`/joined-communities`)}
              >
                {myMemberCommunities.length} Communities
              </Typography>
            )}
          </Stack>

          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              alignContent: 'center',
              justifyContent: 'center',
              border: '1px solid #BFBFBF',
              height: "200px",
              borderRadius: "6px",
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: '18px' },
                fontWeight: 700,
                textDecoration: "none",
                color: "#838383",
                textAlign: "center",
              }}
            >
              No Communities
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default JoinedCommunities;
