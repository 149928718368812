import { Paper, List, ListItem, Divider } from "@mui/material";
import { useInvitations } from "../../../hooks/useInvitations";
import { useEffect, useState } from "react";
import { Invitations } from "../../../models/Invitation.model";
import CommunityInvites from "./CommunityInvites";
import { NoInvites } from "../../AlternatePages/noInvitations";
import InvitesLoader from "../../Loader/InvitesLoader";
import React from "react";

const InvitesList: React.FC = () => {
    const [invitesCommunities, setInvitesCommunities] = useState<Invitations[]>([]);
    const { getInvitesForUser } = useInvitations();

    const [loading, setLoading] = useState(true); 
    const [reload, setReload] = useState(true);
    
    useEffect(() => {
        async function fetchData() {
          try {
            setLoading(true);
        
        if(reload){
            const data = await getInvitesForUser();
            setInvitesCommunities(data);
        }
        setLoading(false);
        setReload(false);
          } catch (error) {
            console.error("Error fetching suggestion communities:", error);
          } finally {
            setLoading(false); 
          }
        }
        fetchData();
        return () => {
            setLoading(false);
            setReload(false);
          };
      }, [reload]);

    // const refetchData = async () => {
    //     const response = await getInvitesForUser();
    //     if (response) {
    //         setInvitesCommunities(response.data);
    //     }
    // };

    return (
        <Paper elevation={0} sx={{height:'70vh'}}>
            {loading ? (
                <InvitesLoader /> // Show loader while data is being fetched
            ) : (
                <List>
                    {invitesCommunities.length > 0 ? (
                        invitesCommunities.map((item, index) => (
                            <React.Fragment key={item._id}>
                                <ListItem>
                                    <CommunityInvites
                                        community={item?.community}
                                        _id={item?._id}
                                        message={item?.message}
                                        createdAt={item?.createdAt}
                                        // refetchData={refetchData}
                                        invites={item}
                                    />
                                </ListItem>
                                {index !== invitesCommunities.length - 1 && <Divider />}
                            </React.Fragment>
                        ))
                    ) : (
                        <NoInvites />
                    )}
                </List>
            )}
        </Paper>
    );
};

export default InvitesList;
