import { Add, Close } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Bin } from '../../../assets/icons'
import CustomButton from './CustomButton'
import { useCourses } from '../../../hooks/useCourses'
import { ILinks,  interfaceCourse } from '../../../models/courses.model'
interface AddLinkPopUpProps {
    handleCloseAddLinkDialog: () => void;
    linkInputs: string[];
    handleInputChange: (index: any, value: string) => void;
    handleDeleteLink: (index: any) => void;
    handleAddLink: () => void;
    data: interfaceCourse[];
    sectionPopUp:boolean;
    section:any;
    refetchCourseData:()=>void;
    linkData:any;
    courseLinks:any
}
const AddLinkPopUp: React.FC<AddLinkPopUpProps> = ({
    handleCloseAddLinkDialog,
    data,
    sectionPopUp,
    section, 
    refetchCourseData,
    linkData,
    courseLinks
}) => {
    
const [fields, setFields] = useState<{  name: string;value: string; }[]>([{ name: '', value: '' }]);
const [loading,setLoading]=useState(false);
const {updateCourse,updateCourseSectionsById} = useCourses();
    const communityId=data[0]?.community._id
    const courseId=data[0]?._id
    // const sectionValues = data[0]?.sections

    useEffect(() => {
      if(!sectionPopUp){
        if(courseLinks){
            setFields([courseLinks])
        }
      }else{
        if (!section) {
            const initialFields = section?.links?.map((link:ILinks) => ({
                name: link.name,
                value: link.value,
            }));
            setFields(initialFields);
        }
        if(linkData){
            setFields([linkData])
        }
        
    }
    // eslint-disable-next-line 
    }, [data,linkData]);

    const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const values = [...fields];
        values[index][event.target.name as keyof typeof values[0]] = event.target.value;
        setFields(values);
    };

    const handleAddField = () => {
        setFields([...fields, { name: "" , value: ""}]);
    };

    const handleDeleteField = (index: number) => {
        const values = [...fields];
        values.splice(index, 1);
        setFields(values);
    };

    const handleSave = async() => {
        // Handle the data when the "Save" button is clicked
        
     // create course link
        const existingCourseLink = data[0].links || [];
        const updatedCourseLink = [...existingCourseLink, ...fields];

        const editingCourseLink = data[0].links.filter(link => link._id !== courseLinks._id) //edit course link
        const singleUpdatedCourseLink =[...editingCourseLink, ...fields]

     //section link
        const existingLinks = section.links || [];
        const updatedLinksInSection = [...existingLinks, ...fields];


        if( linkData){     
            const editSectionLink =section?.links?.filter((link:ILinks) => link._id !== linkData._id)
            const updatingSectionLink =[...editSectionLink, ...fields] 
            setLoading(true)     
            await updateCourseSectionsById(communityId,courseId,section._id, {links:updatingSectionLink})
            refetchCourseData()
            setLoading(false)
            handleCloseAddLinkDialog();
        }else if(sectionPopUp ){     
            setLoading(true)  
            await updateCourseSectionsById(communityId,courseId,section._id, {links:updatedLinksInSection})
            refetchCourseData()
            setLoading(false) 
            handleCloseAddLinkDialog();
        }else{
            try{
                if(courseLinks){
                   setLoading(true)
                    await updateCourse(communityId,courseId,{links:singleUpdatedCourseLink})
                    refetchCourseData()
                    setLoading(false)
                }else if(courseLinks.length === 0){
                    setLoading(true)
            await updateCourse(communityId,courseId,{links:updatedCourseLink})
            }
            }catch(error){
                console.log(error);
                
            }finally{
                refetchCourseData();
                handleCloseAddLinkDialog();
                setLoading(false)
            }
        }

      };
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: "#F0F9FF", height: "35px" }}
            >
                <Typography
                    sx={{
                        color: "#3C5AA0",
                        fontSize: "20px",
                        fontWeight: "500",
                        backgroundColor: "#F0F9FF",
                        // pt: 1,
                        px: 2,
                    }}
                >
                    Add Link
                </Typography>

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        backgroundColor: "#3C5AA0",
                        padding: "4px",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: "10px",
                        mt: "0.5px",
                        mr: "10px",
                        cursor: "pointer",
                    }}
                >
                    <IconButton onClick={handleCloseAddLinkDialog}>
                        <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
                    </IconButton>
                </Stack>
            </Grid>
            <Stack sx={{ p: 2 }}>
                <Typography
                    sx={{ fontSize: "10px", fontWeight: "400", color: "#5E6C84" }}
                >
                    {" "}
                    Link
                </Typography>
                {fields?.map((field, index) => (
                    <Grid container spacing={1} key={index}>
                        <Grid item xs={11} md={11}>

                        <TextField
                                    placeholder='Link Name'
                                    size='small'
                                    name="name"
                                    value={field.name}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(index, e)}
                                    fullWidth
                                    sx={{ pb: 2, '& input::placeholder': { fontSize: '0.8rem' } }}
                                />
                        
                        </Grid>
                        <Grid container  spacing={1} key={index} sx={{ px: 1 }}>
                            <Grid item xs={11} md={11.2}>
                            <TextField
                                placeholder="https://youtube.com"
                                size='small'
                                name="value"
                                value={field.value}
                                onChange={(e: any) => handleChange(index, e)}
                                fullWidth
                                sx={{ pb: 2, mr: 1, '& input::placeholder': { fontSize: '0.8rem' } }}
                            />
                            </Grid>
                            <Grid item xs={1} md={0.7}>
                                <IconButton onClick={() => handleDeleteField(index)}>
                                    <Bin />
                                </IconButton>
                            </Grid>
                        </Grid>


                    </Grid>
                ))}
                <Stack sx={{ pt: 1 }}>
                    <CustomButton
                        children={"Add another link"}
                        background={"#F0F9FF"}
                        textColor={"#5E6C84"}
                        icon={<Add />}
                        onClick={handleAddField}
                        disable={false}
                        loading={false}
                    />
                </Stack>

                <Stack
                    display={"flex"}
                    // alignItems={"center"}
                    justifyContent={"flex-end"}
                    flexDirection={"row"}
                    sx={{ pt: 1.5 }}
                >
                    <Box sx={{ mr: "10px" }}>
                        <CustomButton
                        loading={loading}
                            children={" Save "}
                            background={"#3C5AA0"}
                            textColor={"#FFFFFF"}
                            onClick={handleSave} 
                            disable={false}
                        />
                    </Box>
                    <Box>
                        <CustomButton
                            children={"Cancel"}
                            background={"#BEBEBE"}
                            textColor={"#FFFFFF"}
                            disable={false}
                            loading={false}
                            onClick={handleCloseAddLinkDialog}
                        />
                    </Box>
                </Stack>
            </Stack>
        </>
    )
}

export default AddLinkPopUp