
import { Button, Card, Stack, Typography, Tooltip, Avatar } from "@mui/material";
import { ICommunity, } from "../../../models/communities.modal";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { KingIcon } from "../../../assets/icons";
import { TooltipProps, tooltipClasses, } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from "react";
import CommunityPreview from "../CommunityPreview/CommunityPreview";
import { CommunityMembers } from "../../../models/community-user.model";
import { useDispatch, } from "react-redux";
// import store from "../../../store";
import { USER_APP_BASE_URL } from "../../../configurations/url.config";
import { loadSelectedCommunity } from "../../../store/slices/CommunitySlice";
import { useSnackbar } from "notistack";






export const AdminCommunities = (community: ICommunity, CommunityMembers: CommunityMembers) => {

  // const state = store.getState();
  // const selectedCommunity = useSelector(() => {
  //   return state?.selectedCommunity;
  // });

  // console.log(selectedCommunity, "trending")

  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [link, setLink] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));


  const navigate = useNavigate();
  const handlePreviewCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };



  const dispatch = useDispatch();
  const handleButtonClick = () => {
    const { id } = community;
    navigate('/home', { state: { id } });
    dispatch(loadSelectedCommunity(community));
    // console.log(community);
  };




  const handleCopyLink = () => {
    if (community?.status === "PUBLISHED") {
      const labelContent = `${USER_APP_BASE_URL}/communities/${community?._id}/about`;

      navigator.clipboard
        .writeText(labelContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
          enqueueSnackbar('Link copied successfully', { variant: 'success' , autoHideDuration: 3000});
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
        });
    } else {
      // Handle the case when the status is not "PUBLISHED"
      setLink(`/communities/build/${community?._id}`);
      enqueueSnackbar("Cannot copy link & Share the for Non-Published Communities", { variant: "error", autoHideDuration: 3000 })
    }
  };



  const whatsappShareUrl = community?.status === 'PUBLISHED'
    ? `https://api.whatsapp.com/send?text=${encodeURIComponent(`${USER_APP_BASE_URL}/communities/${community?.id}/about`)}`
    : '';

  const facebookShareUrl = community?.status === 'PUBLISHED'
    ? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${USER_APP_BASE_URL}/communities/${community?._id}/about`)}`
    : '';

  const twitterShareUrl = community?.status === 'PUBLISHED'
    ? `https://twitter.com/intent/tweet?url=${encodeURIComponent(`${USER_APP_BASE_URL}/communities/${community?._id}/about`)}`
    : '';

  const linkedinShareUrl = community?.status === 'PUBLISHED'
    ? `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(`${USER_APP_BASE_URL}/communities/${community?._id}/about`)}`
    : '';



  // console.log(community ,"Admin")

  return (
    <>
      <Stack spacing={2} p={1}>

        <Card sx={{ textAlign: 'center', borderRadius: '10px', p: 2, m: 1, minHeight: 'fit-content' }} >

          <Stack direction={"row"} alignContent={"space-evenly"} justifyContent={"space-between"} >
            <Stack direction={"row"} alignContent={"center"}> {community?.type === "PRIVATE" ?
              <LockOutlinedIcon sx={{ color: "#838383", fontSize: '18px' }} /> : <Stack sx={{ height: '25px' }}></Stack>}
              {community?.collectSubscription === "YES" ?
                <KingIcon sx={{ color: "#838383", }} /> : <Stack sx={{ height: '25px' }}></Stack>}
            </Stack>

            <Stack>
              <BootstrapTooltip title="Info" placement="left">
                <InfoOutlinedIcon onClick={handlePreviewCommunity} sx={{ color: "#838383", fontSize: '18px', cursor: "pointer" }}   >
                </ InfoOutlinedIcon>
              </BootstrapTooltip>
            </Stack>
          </Stack>
          {/* Community Logo */}
          <Stack display="flex" alignItems="center" justifyContent="center">
            <Avatar
              className="product--image"
              src={community?.logo}
              alt="community-profile"
              sx={{
                objectFit: 'cover',
                width: '60px',
                height: '60px',
                borderRadius: '100px',

              }}
            />
          </Stack>
          {/* Render name with conditional check */}
          {community?.name ? (
            <Typography
              variant="h2"
              sx={{
                fontSize: "12px",
                color: "#4D4C4C",
                fontWeight: "600",
                mt: 0.5,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "100%", // Ensure the text doesn't overflow the container
              }}
            >
              {community.name.length > 20 ? `${community.name.slice(0, 20)}...` : community.name}
            </Typography>
          ) : (
            <Stack sx={{ height: "15px" }}></Stack>
          )}


          {/* Render city with conditional check */}
          {community?.city ? (
            <Typography sx={{ fontSize: "10px", color: '#878787', fontWeight: '500' }}>
              {community?.city?.length > 20 ? `${community?.city?.slice(0, 20)}...` : community?.city}
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: "10px", color: "#878787", fontWeight: "500" }}
            >
              No city
            </Typography>
          )}

          {/* Render members count with conditional check */}
          {community?.members?.length !== undefined ? (
            <Typography sx={{ fontSize: "11px", color: '#353535', fontWeight: '500' }}>{community?.members?.length} </Typography>
          ) : (
            <Stack sx={{ height: '15px' }}></Stack>
          )}
          <Stack direction={"row"} alignContent={'center'} justifyContent={'center'} spacing={2} mt={1}>
            <Button variant="contained" size="small"
              sx={{
                background: 'linear-gradient(to right bottom, #50a1ca, #3b9b7f)',
                textTransform: 'capitalize',
                height: '25px',
                color: '#ffffff',
                boxShadow: "none"
              }} onClick={handleButtonClick}>
              <Typography sx={{ color: '#ffffff', fontSize: '13px', fontWeight: '500' }}>Go
              </Typography></Button>
          </Stack>
        </Card>
      </Stack>

      <CommunityPreview
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        logo={community.logo}
        id={community?._id}
        title={community?.name}
        link={link}
        city={community?.city}
        status={community?.status}
        members={community?.members.length || []}
        totalmembers={community?.totalMembers}
        type={community?.type}
        description={community?.description}
        handleCopyLink={handleCopyLink}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={community?.banner}
      />
    </>
  );
}
