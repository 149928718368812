import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react'
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { ICategory } from '../models/category.model'
import { get } from '../services/category.service'


export const useCategories = () => {
    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const [list, setList] = useState<ICategory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    },[])
    const getList = async () => {
        setLoading(true);
        const response = await get(getAccessToken());
        if (response.status === 200) {
            setList(response.data.category ?? []);
        } else {
            enqueueSnackbar("Fetching users failed", { variant: "error", autoHideDuration: 3000 });
        }
        setLoading(false);
    }
    return (
        { loading, list }
    )
}
