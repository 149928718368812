import React, { useState } from "react";
import { Button, Card, Stack, Typography, Avatar } from "@mui/material";
import { ICommunity } from "../../../models/communities.modal";
import { KingIcon } from "../../../assets/icons";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SendRequestCommunityPreview from "../CommunityPreview/SendRequestCommunityPreview";
import { USER_APP_BASE_URL } from "../../../configurations/url.config";
import { useSnackbar } from "notistack";
import store from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { loadSelectedCommunity } from "../../../store/slices/CommunitySlice";
import { useNavigate } from "react-router-dom";

export default function TrendingAllCommunities(props: ICommunity,) {


  // console.log(props, "props");
  const state = store.getState();
  // const selectedCommunity = useSelector(() => {
  //   return state?.selectedCommunity;
  // });
  // console.log(selectedCommunity, "trending")


  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });
  //console.log(loggedInUserData)
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // const sortedCommunities = [...props.communities].sort(
  //   (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  // );

  const handleJoinCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCopyLink = () => {
    if (props?.status === "PUBLISHED") {
      const labelContent = `${USER_APP_BASE_URL}/communities/${props?._id}/about`;

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(labelContent)
          .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
            enqueueSnackbar('Link copied successfully', { variant: 'success', autoHideDuration: 3000 });
            // console.log('Link copied successfully', labelContent);     
          })
          .catch((error) => {
            console.error("Error copying text: ", error);
            enqueueSnackbar("Failed to copy link", { variant: "error", autoHideDuration: 3000 });
          });
      } else {
        // Fallback for browsers that do not support the Clipboard API
        // You can implement your own copy mechanism here
        console.error("Clipboard API not supported");
        enqueueSnackbar("Clipboard API not supported", { variant: "error" });
      }
    } else {
      // Handle the case when the status is not "PUBLISHED"
      enqueueSnackbar("Cannot copy link for non-published items", { variant: "error", autoHideDuration: 3000 })
    }
  };


  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${props?._id}/about`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${props?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${props?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${props?._id}/about`
  )}`;



  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    const id = props._id;
    navigate('/home', { state: { id } });
    dispatch(loadSelectedCommunity(props));
  };


  const navigateToUserFlow = () => {
    const token = localStorage.getItem("access-token");
    const id = props?._id;
    const url = `${USER_APP_BASE_URL}/login?communityId=${id}&token=${token}`;
    window.open(url, '_blank');
  };

  //console.log(props, "Trending Communities");


  return (
    <>
      <Stack spacing={5} sx={{ p: 1 }}>

        <Card
          sx={{
            textAlign: "center",
            borderRadius: "10px",
            p: 2,
            m: 1,
            minHeight: 'fit-content'
          }}
        >

          <Stack direction={"row"} alignContent={"center"} > 
            {props?.type === "PRIVATE" ? <LockOutlinedIcon sx={{ color: "#838383", fontSize: '18px' }} /> : <Stack sx={{ height: '25px' }}></Stack>}
            {props?.collectSubscription === "YES" ? <KingIcon sx={{ color: "#838383", }} /> : <Stack sx={{ height: '25px' }}></Stack>}
          </Stack>

          {/* Community Logo */}
          <Stack display="flex" alignItems="center" justifyContent="center">
            <Avatar
              className="product--image"
              src={props?.logo}
              alt="community-profile"
              sx={{
                objectFit: "cover",
                width: "60px",
                height: "60px",
                borderRadius: "100px",
              }}
            />
          </Stack>
          {/* Render name with conditional check */}
          {props?.name ? (
            <Typography variant="h2" sx={{ fontSize: "12px", color: '#4D4C4C', fontWeight: '600', mt: 0.5, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {props?.name.length > 20 ? props?.name.slice(0, 20) + '...' : props?.name}
            </Typography>
          ) : (
            <Stack sx={{ height: '15px' }}></Stack>
          )}
          {/* Render city  with conditional check */}
          {props?.city ? (
            <Typography sx={{ fontSize: "10px", color: '#878787', fontWeight: '500' }} >
              {props?.city?.length > 20 ? props?.city?.slice(0, 20) + '...' : props?.city}
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: "10px", color: "#878787", fontWeight: "500" }}
            >
              No city
            </Typography>
          )}

          {/* Render members count with conditional check */}
          {props?.members?.length !== undefined ? (
            <Typography sx={{ fontSize: "11px", color: '#353535', fontWeight: '500' }}>{props?.members?.length}</Typography>
          ) : (
            <Stack sx={{ height: '15px' }}></Stack>
          )}
          <Stack
            direction={"row"}
            alignContent={"center"}
            justifyContent={"center"}

            mt={1}
          >

            {/* <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#50A1CA",
                textTransform: "capitalize",
                height: "25px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
                ":hover": {
                  backgroundColor: "#3B9B7F", // Change the color on hover

                },
              }}
              onClick={handleJoinCommunity}
            >
              {(loggedInUserData && loggedInUserData?.user?.id === props?.createdBy?._id) ? "GO" : "Join"}
            </Button>

            <Button variant="contained" size="small"
              sx={{
                background: 'linear-gradient(to right bottom, #50a1ca, #3b9b7f)',
                textTransform: 'capitalize',
                height: '25px',
                color: '#ffffff'
              }} onClick={handleButtonClick}>
              <Typography sx={{ color: '#ffffff', fontSize: '13px', fontWeight: '500' }}>Go
              </Typography></Button> */}



            {loggedInUserData && loggedInUserData?.user?.id === props?.createdBy?._id ? (
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#50A1CA",
                  textTransform: "capitalize",
                  height: "25px",
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                  ":hover": {
                    backgroundColor: "#3B9B7F", // Change the color on hover
                  },
                }}
                onClick={handleButtonClick}
              >
                Go
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                sx={{
                  background: 'linear-gradient(to right bottom, #50a1ca, #3b9b7f)',
                  textTransform: 'capitalize',
                  height: '25px',
                  color: '#ffffff',
                  boxShadow: "none"
                }}
                onClick={handleJoinCommunity}
              >
                <Typography sx={{ color: '#ffffff', fontSize: '13px', fontWeight: '500' }}>
                  Join
                </Typography>
              </Button>
            )}


            <SendRequestCommunityPreview
              isOpen={isModalOpen}
              handleClose={handleCloseModal}
              logo={props?.logo}
              id={props?._id}
              title={props?.name}
              city={props?.city}
              members={props?.members?.length || []}
              message={props?.message}
              type={props?.type}
              description={props?.description}
              handleCopyLink={handleCopyLink}
              copied={copied}
              whatsappShareUrl={whatsappShareUrl}
              facebookShareUrl={facebookShareUrl}
              linkedinShareUrl={linkedinShareUrl}
              twitterShareUrl={twitterShareUrl}
              coverImageSrc={props?.banner}
              navigateToUserFlow={navigateToUserFlow}
            />
          </Stack>

        </Card>
      </Stack>
    </>
  );
}
