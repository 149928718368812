import { useSnackbar } from "notistack";
import { useContext,  useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { ICommunity } from "../models/communities.modal";
import {
  getCommunities,
  checkCommunityAvailability,
  communityById,
  createCommunity,
  // favCommunity,
  updateCommunityService,
  membershipCommunities,
  updateUserRole,
  fetchTrendingCommunities,
  SuggestionCommunities,
  buildCommunityService,
  deleteGalleryByUrl,
  joinCommunity,
  updateUserMappingCommunity,
  updateUserMappingCommunityBanner,
} from "../services/communities.service";
import store from "../store";
import {
  loadCommunities,
  loadSelectedCommunity,
  setJoinedCommunities,
} from "../store/slices/CommunitySlice";
import { useDispatch } from "react-redux";

export const useCommunity = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [communities, setCommunities] = useState<ICommunity[]>([]);

  const getCommunitiesList = async () => {
    setIsLoading(true);
    const response = await getCommunities(getAccessToken());

    if (response.status === 200) {
      setCommunities(response.data);
      dispatch(loadCommunities(response.data));
      setIsLoading(false);
      return response.data;
    } else {
      enqueueSnackbar("Fetching communities failed", { variant: "error" });
      setIsLoading(false);
      return [];
    } 
  };

  const isCommunityExist = async (name: string) => {
    setIsLoading(true);
    const response = await checkCommunityAvailability(getAccessToken(), name);
    if (response.status === 200) {
      return true;
    } else if (response.status === 302) {
      return false;
    } else {
      enqueueSnackbar("Fetching communities failed", { variant: "error" });
    }
    setIsLoading(false);
  };

  const getCommunityById = async (id: string) => {
    setIsLoading(true);
    const response = await communityById(getAccessToken(), id);
    if (response.status === 200) {
      store.dispatch(loadSelectedCommunity(response.data));
      return response;
    } else if (response.status === 302) {
      return response;
    } else {
      enqueueSnackbar("Fetching selected community failed", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  const createNewCommunity = async (CommunityData: any) => {
    try {
      const response = await createCommunity(getAccessToken(), CommunityData);
      if (response.status === 201) {
        setCommunities(response.data);
        store.dispatch(loadSelectedCommunity(response.data));
        dispatch(loadCommunities(response.data));
        console.log("loadSelectedCommunity action dispatched:", response.data);
       
        enqueueSnackbar(" Community Created Successfully", {
          variant: "success", autoHideDuration: 3000
        });
      } else {
        enqueueSnackbar("Failed to Create Community", { variant: "error" , autoHideDuration: 3000});
      }
      return response;
    } catch (error) {
      enqueueSnackbar("An error occurred while Creating the Community", {
        variant: "error", autoHideDuration: 3000
      });
    }
  };

  // ... (previous code)

  // const toggleCommunityFavorite = async (id: string,favorite: boolean) => {
  //   setIsLoading(true);

  //   try {
  //     console.log('Toggling favorite status...');

  //   // Log the data before making the API request
  //   console.log('API Endpoint Data BEFORE:', communities);
  //     const response = await favCommunity(getAccessToken(), id,favorite);
  //     // console.log('API Response:', response);
  //     if (response.status === 200) {
  //       response.data.favorite = favorite;

  //       console.log('API Endpoint Data AFTER:', response.data);
  //       setCommunities((prevCommunities) =>
  //       prevCommunities.map((community) =>
  //        community._id === id ? { ...community, favorite } : community
  //       )
  //     );
  //       store.dispatch(loadCommunities(response?.data));

  //       enqueueSnackbar("Community favorited/unfavorited successfully", {
  //         variant: "success",
  //       });
  //     } else {
  //       enqueueSnackbar("Failed to favorite/unfavorite Community", {
  //         variant: "error",
  //       });
  //     }
  //   } catch (error) {
  //     enqueueSnackbar("An error occurred while favoriting/unfavoriting the Community", {
  //       variant: "error",
  //     });
  //   }

  //   setIsLoading(false);
  // };

  const updateCommunityDetails = async (id: string, formData: any) => {
    setIsLoading(true);

    try {
      const response = await updateCommunityService(
        getAccessToken(),
        id,
        formData
      );
      if (response.status === 200) {
        setCommunities(response.data);
        store.dispatch(loadSelectedCommunity(response.data));
        store.dispatch(loadCommunities(response?.data));
      } else {
        enqueueSnackbar("Failed to update Community", {
          variant: "error", autoHideDuration: 3000
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("An error occurred while update the Community", {
        variant: "error", autoHideDuration: 3000
      });
    }

    setIsLoading(false);
  };
  const buildCommunityDetails = async (
    id: string,
    formData: any,
    logo: File | null,
    banner: File | null,
    gallery: FileList | null
  ) => {
    try {
      setIsLoading(true);
      const response = await buildCommunityService(
        getAccessToken(),
        id,
        formData,
        logo,
        banner,
        gallery
      );
      if (response.status === 200) {
        // setCommunities(response.data)
        setCommunities((prevBuild) =>
          prevBuild.map((i) => (i._id === id ? response.data : i))
        );
        store.dispatch(loadCommunities(response?.data));
      } else {
        enqueueSnackbar("Failed to update Community", {
          variant: "error", autoHideDuration: 3000
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("An error occurred while update the Community", {
        variant: "error", autoHideDuration: 3000
      });
    }

    setIsLoading(false);
  };
  const communityUserRole = async (
    communityId: string,
    id: string,
    formData: any
  ) => {
    setIsLoading(true);
    try {
      const response = await updateUserRole(
        getAccessToken(),
        communityId,
        id,
        formData
      );
      if (response) {
        setCommunities(response);
      } else {
        enqueueSnackbar("Failed to set a Role", {variant: "error", autoHideDuration: 3000});
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while setting a role", {
        variant: "error", autoHideDuration: 3000
      });
    }
    setIsLoading(false);
  };

  const memberCommunities = async () => {
    try {
      setIsLoading(true);
      const response = await membershipCommunities(getAccessToken());
      if (response?.status === 200) {
        dispatch(setJoinedCommunities(response.data));
        return response.data;
      } else {
        enqueueSnackbar("Failed to fetch joined communities", {
          variant: "error", autoHideDuration: 3000
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while update the Community", {
        variant: "error", autoHideDuration: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getSuggestionCommunities = async () => {
    setIsLoading(true);
    try {
      const response = await SuggestionCommunities(getAccessToken());
      if (response?.status === 200) {
        return response.data;
      } else {
        enqueueSnackbar("Failed to fetch trending communities", {
          variant: "error", autoHideDuration: 3000
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while fetching the Community", {
        variant: "error", autoHideDuration: 3000
      });
    }

    setIsLoading(false);
  };

  const getTrendingCommunities = async () => {
    setIsLoading(true);
    try {
      const response = await fetchTrendingCommunities(getAccessToken());
      if (response?.status === 200) {
        return response.data;
      } else {
        enqueueSnackbar("Failed to fetch trending communities", {
          variant: "error", autoHideDuration: 3000
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while fetching the Community", {
        variant: "error", autoHideDuration: 3000
      });
    }

    setIsLoading(false);
  };

  const deleteGalleryInBuild = async (url: string) => {
    try {
      const response = await deleteGalleryByUrl(getAccessToken(), url);
      if (response.status === 200) {
        setCommunities(response.data.data);
        enqueueSnackbar(" Image deleted successfully", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Failed to delete Image", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("An error occurred while deleting the Invite", {
        variant: "error", autoHideDuration: 3000
      });
    }
  };

  const joinToPublicCommunity = async (community_id: string) => {
    setIsLoading(true);
    try {
      const response = await joinCommunity(getAccessToken(), community_id);
      if (response.status === "success") {
        enqueueSnackbar("You have successfully joined!", {
          variant: "success", autoHideDuration: 3000
        });
      } else {
        enqueueSnackbar("Error while joining to public community!", {
          variant: "error", autoHideDuration: 3000
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar(
        "An error occurred while joining to Community, Please try again.",
        { variant: "error", autoHideDuration: 3000 }
      );
    }
    setIsLoading(false);
  };
  const updateUserMappingDetails = async (id: string, formData: any) => {
    setIsLoading(true);

    try {
    
      const response = await updateUserMappingCommunity(getAccessToken(), id, formData);
      if (response.status === 200) {
        enqueueSnackbar("updated user in community Succesfully", {
          variant: "success", autoHideDuration: 3000
        });
       return response
      
      } else {
        enqueueSnackbar("Failed to update user in community", {
          variant: "error", autoHideDuration: 3000
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("An error occurred while update the Community", {
        variant: "error", autoHideDuration: 3000
      });
    }

    setIsLoading(false);
  };
  const updateUserMappingBanner = async (
    id: string,
    file: FileList | null,
    formData: FormData
  ) => {
    try {
      setIsLoading(true);
      const response = await updateUserMappingCommunityBanner(
        getAccessToken(),
        id,
        file,
        formData
      );

      if (response.status === 200) {
        enqueueSnackbar("Banner updated successfully", { variant: "success", autoHideDuration: 3000 });
        // setUpdatePostsFlag(true);
      } else {
        enqueueSnackbar("Failed to Update Banner", { variant: "error", autoHideDuration: 3000 });
      }

      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("An error occurred while Updating  the Banner", {
        variant: "error", autoHideDuration: 3000
      });
    }
  };

  return {
    isLoading,
    communities,
    isCommunityExist,
    getCommunityById,
    createNewCommunity,
    // toggleCommunityFavorite,
    updateCommunityDetails,
    setCommunities,
    memberCommunities,
    getTrendingCommunities,
    buildCommunityDetails,
    communityUserRole,
    deleteGalleryInBuild,
    getCommunitiesList,
    joinToPublicCommunity,
    getSuggestionCommunities,
    updateUserMappingDetails,
    updateUserMappingBanner
  };
};
