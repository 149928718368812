import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { Login } from "./components/Auth/Login/Login";
import { useContext, useState } from "react";
import { AuthContext } from "./contexts/Auth.context";
import Loader from "./components/Loader/Loader";
import { Plans } from "./components/Plans/Plans";
import { Messages } from "./components/messages/Messages";
import { Users } from "./components/users/Users";
import Invitation from "./components/Invitations/Invitation";
import { CommunitiesList } from "./components/community/CommunitiesList";
import { Help } from "./components/help/Help";
import { Settings } from "./components/settings/Settings";
import { Payments } from "./components/Payments/Payments";
import { CreateCommunity } from "./components/community/CreateCommunity";
import { BuildCommunity } from "./components/community/BuildCommunity";
import { AddOns } from "./components/add-ons/AddOns";
import { CreateAddOn } from "./components/add-ons/CreateAddOn";
// import { PaymentRequests } from "./components/Payments/PaymentRequest";
import DataTable from "./components/Payments/PaymentsTable";
import { Register } from "./components/Auth/Login/Register";
import Profile from "./components/Layout/components/NavigationBar/Profile";
import { ProfileEdit } from "./components/Auth/Login/ProfileEdit";
import ExploreCommunities from "./components/ExploreCommunities/ExploreCommunities";
import { AddPlan } from "./components/Plans/AddPlan";
import ExploreCreateCommunities from "./components/ExploreCommunities/ExploreCreateCommunities";
import CreateCommunity3 from "./components/community/CreateCommunity3";
import { Home } from "./components/Home/Home";
import Activities from "./components/Activities/Activities";
import NoAuthLayout from "./components/Layout/NoAuthLayout";
import { Posts } from "./components/Posts/Posts";
//import { RichText } from "./components/sub-components/RichText";
import FAQ from "./components/FAQ/FAQ";
// import { CreateCommunityPage2 } from "./components/community/CreateCommunityPage2";
import CommunityInvitations from "./components/Invitations/CommunityInvitationsandRequests";
import TrendingCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/TrendingCommunitiesGird";
import InvitiesCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/InvitiesCommunitiesGird";
import JoinedCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/JoinedCommunitiesGird";
import Faqa from "./components/FAQ/Faqa";
// import PaymentForm from "./components/Payments/PaymentForm";
import PaymentList from "./components/Payments/PaymentList";
import SuperAdmin from "./components/SuperAdmin/SuperAdmin";
import AdministeredCommunitiesGrid from "./components/ExploreCommunities/GirdViewCommunities/AdminCommunitiesGird";
import NewLayout from "./components/Layout/components/NewLayout";
import About from "./components/AboutCommunity/About";
// import CommunityProfile from "./components/Profiles/CommunityProfile";
// import MyProfile from "./components/Profiles/MyProfile";
import PaymentRequestList from "./components/Payments/PaymentRequestList";
import { NewAddPlan } from "./components/Plans/NewAddPlan";
// import CommunityInvites from "./components/Invitations/CommunityInvitations/CommunityInvites";
import InvitesList from "./components/Invitations/CommunityInvitations/InvitesList";
import { AddCourse } from "./components/Courses/AddNewCourse";
import { Courses } from "./components/Courses/Courses";
import AddFilesLinksCourse from "./components/Courses/addCourseDetail/AddFilesLinksCourse";
//import SuperAdmin from "./components/SuperAdmin/SuperAdmin";

// import SuperAdmin from "./components/SuperAdmin/SuperAdmin";

const RequireAuth = (props: any) => {
  const authContext = useContext(AuthContext);

  if (authContext.loading) {
    return <Loader />;
  }
  return authContext.isAuthenticated ? (
    props.children
  ) : (
    <Navigate to={"/login"} />
  );
};
const LoginWrapper = (props: any) => {
  const authContext = useContext(AuthContext);
  // console.log(authContext);
  if (authContext.loading) {
    return <Loader />;
  }
  return authContext.isAuthenticated ? <Navigate to={"/"} /> : props.children;
};
function App() {
  const [openPopUp] = useState(false);

  return (
    <>
      <Routes>
        <Route
          element={
            <RequireAuth>
              <NewLayout />
            </RequireAuth>
          }
        >
          <Route path="/home/:id/:token" element={<Home />} />
          <Route path="/" element={<Navigate to="/home" replace={true} />} />
          <Route path="/home" element={<Home />} />
          {/* <Route path="/profile" element={<Profile />} /> */}
          <Route path="/posts/profile/:id" element={<Profile />} />
          <Route path="/invitations" element={<CommunityInvitations />} />
          {/* <Route path="/invitations" element={<CommunityRequestandInvites />} /> */}
          <Route path="/community/:id/about" element={<About />} />
          <Route path="/community-invites" element={<InvitesList />} />
        </Route>
      </Routes>

      <Routes>
        <Route
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >

          <Route path="/" element={<Home />} />
          <Route path="/home/:id/:token" element={<Home />} />
          <Route path="/" element={<Navigate to="/home" replace={true} />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/activities" element={<Activities />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/profile/:id" element={<ProfileEdit />} />
          <Route path="/payments/requests" element={<PaymentRequestList />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/plans/new" element={<NewAddPlan selectedCommunity={[]} closeDialog={function (): void {
            throw new Error("Function not implemented.");
          }} loadPlans={function (): void {
            throw new Error("Function not implemented.");
          }} />} />
          <Route path="/plans/new/:id" element={<NewAddPlan selectedCommunity={[]} closeDialog={function (): void {
            throw new Error("Function not implemented.");
          }} loadPlans={function (): void {
            throw new Error("Function not implemented.");
          }} />} />

          <Route
            path="/planss/new"
            element={
              <AddPlan
                selectedCommunity={[]}
                closeDialog={function (): void {
                  throw new Error("Function not implemented.");
                }}
                loadPlans={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
            }
          />
          <Route
            path="/plans/:id"
            element={
              <AddPlan
                selectedCommunity={[]}
                closeDialog={function (): void {
                  throw new Error("Function not implemented.");
                }}
                loadPlans={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
            }
          />

          {/* Email Routes */}

          <Route path="/communities/:communityId/plans" element={<Plans />} />
          <Route path="/communities/:communityId/build" element={<BuildCommunity />} />
          <Route path="/communities/:communityId/bank-details" element={<PaymentList openMode={openPopUp} />} />
          <Route path="/communities/:communityId" element={<CreateCommunity3 />} />





          <Route path="/users" element={<Users />} />
          {/* <Route path="/posts/user/:name/:id" element={<Home/>} /> */}
          {/* <Route path="/users/add" element={<AddUser />} /> */}
          <Route path="/users/invitations" element={<Invitation openMode={openPopUp} />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/communities" element={<CommunitiesList />} />
          <Route path="/communities/create-community" element={<CreateCommunity3 />} />
          <Route path="/create-community/:id" element={<CreateCommunity3 />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/add-course/:id" element={<AddFilesLinksCourse />} />
          <Route path="/courses/add" element={<AddCourse selectedCommunity={[]} closeDialog={function (): void {
            throw new Error("Function not implemented.");
          }} loadPlans={function (): void {
            throw new Error("Function not implemented.");
          }} />} />
          <Route path="/courses/add/:id" element={<AddCourse selectedCommunity={[]} closeDialog={function (): void {
            throw new Error("Function not implemented.");
          }} loadPlans={function (): void {
            throw new Error("Function not implemented.");
          }} />} />
          <Route
            path="/communities/add"
            element={<CreateCommunity communitydata={null} />}
          />
          <Route
            path="/communities/edit/:id"
            element={<CreateCommunity communitydata={null} />}
          />
          <Route
            path="/communities/build/:communityId"
            element={<BuildCommunity />}
          />
          <Route path="/add-ons" element={<AddOns />} />
          <Route path="/add-ons/create" element={<CreateAddOn />} />
          <Route path="/profiles" element={<Profile />} />
          <Route path="/help" element={<Help />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/" element={<DataTable />} />
          <Route path="/faq-answers" element={<Faqa />} />
          <Route
            path="/payments/bank-details"
            element={<PaymentList openMode={openPopUp} />}
          />
          {/* <Route path="/paymentlist" element={<PaymentList/>}/> */}
          {<Route path="/superadmin" element={<SuperAdmin />} />}
        </Route>
        <Route
          path="/login"
          element={
            <LoginWrapper>
              <Login />
            </LoginWrapper>
          }
        />
      </Routes>

      <Routes>
        <Route
          element={
            <RequireAuth>
              <NoAuthLayout />
            </RequireAuth>
          }
        >
          <Route path="/explore-communities" element={<ExploreCommunities />} />
          <Route
            path="/admin-communities"
            element={<AdministeredCommunitiesGrid data={[]} />}
          />
          <Route
            path="/joined-communities"
            element={<JoinedCommunitiesGird />}
          />
          <Route
            path="/invites-communities"
            element={<InvitiesCommunitiesGird />}
          />
          <Route
            path="/trending-communities"
            element={<TrendingCommunitiesGird />}
          />
          <Route
            path="/explore-create-community"
            element={<ExploreCreateCommunities />}
          />
          <Route
            path="/explore-create-community/:token"
            element={<ExploreCreateCommunities />}
          />
          <Route path="/profile-edit/:id" element={<ProfileEdit />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/register" element={<Register />} />
      </Routes>
    </>
  );
}

export default App;
