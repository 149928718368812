import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

interface ITableProps {
  data: any;
}
export const RecentNotifications = ({ data }: ITableProps) => {
  return (
    <Stack direction={"column"} spacing={2}>
      <Stack direction={"row"} spacing={2} alignItems={"baseline"}>
        <Typography variant="body2" sx={{ fontWeight: "500",pt:"1.4vh",fontSize:"14px" }}>
          Recent Notifications
        </Typography>

        <Stack direction={"row-reverse"} sx={{ flexGrow: "1" }}>
          {/* <MoreVertIcon sx={{ color: "info.main", cursor: "pointer" }} /> */}
          <Typography
          variant="caption"
          sx={{
            fontWeight: "bold",
            color: "#2952A2",
            display: "flex",
            cursor: "pointer",
          }}
        >
          View All
          <ArrowForwardIcon sx={{ fontSize: "16px" }} />
        </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction={"column"} spacing={1}>
        {data.map((item: any, i: number) => (
          <>
            <Box key={`${i}notification`}>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "#2952A2",
                  fontWeight: 500,
                }}
              >
                {item.type}
              </Typography>
              <Stack direction={"row"} spacing={1}>
                <Typography
                  sx={{ fontSize: "11px", fontWeight: 400, color: "#565656" }}
                >
                  12 Aug. 2022
                </Typography>
                <Typography sx={{ m: 0, fontSize: "10px", color: "#56565680" }}>
                  {" "}
                  08:34pm
                </Typography>
              </Stack>
              <Typography sx={{ fontSize: "11px", fontWeight: 400 }}>
                {item.title}
              </Typography>
            </Box>
            {data.length > i + 1 ? <Divider /> : ""}
          </>
        ))}
      </Stack>
    </Stack>
  );
};
