import { Button } from "@mui/material"

interface IButton {
    url?: string;
    name: string;
    icon?: React.ReactNode;
    onClick?: () => void; // Add onClick as an optional prop

}

const OutlineButton: React.FC<IButton> = ({ url, name, onClick, icon }) => {




    return (
        <>
            <Button variant="outlined" sx={{
                border: '1px solid #2A53A2',
                fontSize: { xs: "10px", md: "15px" },
                color: '#2A53A2',
                textTransform: "capitalize",
                fontWeight: 400,
            }} onClick={onClick} startIcon={icon}
                href={onClick ? undefined : url}
            >
                {name}
            </Button>
        </>


    )
}

export default OutlineButton