import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import { styles } from "./Sidebar.styles";

import { NavLink } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

import {
  HomeIcon,
  CardTickIcon,
  IcDashboardIcon,
  PeopleIcon,
  BoxTickIcon,
  SmsTrackingIcon,
  Setting2Icon,
  CommunityIcon,
  ActivityIcon,
  ExploreIcon,
} from "./../../../../assets/icons";
import { AuthContext } from "../../../../contexts/Auth.context";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';



const getIcon = (name: string) => {
  switch (name) {
    case "HomeIcon":
      return <HomeIcon />;

    case "CardTickIcon":
      return <CardTickIcon />;
    case "IcDashboardIcon":
      return <IcDashboardIcon />;
    case "PeopleIcon":
      return <PeopleIcon />;
    case "BoxTickIcon":
      return <BoxTickIcon />;
    case "SmsTrackingIcon":
      return <SmsTrackingIcon />;
    case "CommunityIcon":
      return <CommunityIcon />;
    case "HelpOutlineIcon":
      return <HelpOutlineIcon />;
    case "Setting2Icon":
      return <Setting2Icon />;
    case "LogoutIcon":
      return <LogoutIcon />;
    case "ActivityIcon":
      return <ActivityIcon />;
    case "ExploreIcon":
      return <ExploreIcon />;
      case "QuizOutlinedIcon":
        return <QuizOutlinedIcon />;
        case "CourseIcon":
        return <LibraryBooksOutlinedIcon/>;
  
    default:
      return null;
  }
};

interface IMenuItem {
  name: string;
  icon: string;
  url: string;
  type?: string;
  children: any[];
}

interface ISidebar {
  open: boolean;
}
export default function Sidebar({ open }: ISidebar) {
  const { logout } = useContext(AuthContext);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [, setParentExpanded] = useState<boolean>(open);
  

  const selectedCommunity = useSelector((state:any) => state.selectedCommunity.selectedCommunity);
 


  const community_id = selectedCommunity?._id ;

  const handleMenuClick = (menu: IMenuItem) => {
    if (menu.url === "logout") {
      logout();
    } else {
      const isExpanded = expandedItems.includes(menu.name);
      let newExpandedItems: string[];

      if (isExpanded) {
        newExpandedItems = expandedItems.filter((item) => item !== menu.name); // Remove the menu from expandedItems
      } else {
        newExpandedItems = [menu.name]; // Only expand the clicked menu and close others
      }

      setExpandedItems(newExpandedItems);
      setParentExpanded(true); // Always set parentExpanded to true on menu click
    }
  };

  const menusList: IMenuItem[][] = [
    [
      {
        name: "Home",
        icon: "HomeIcon",
        url: "/home",
        children: [],
      },
      
      {
        name: "Manage Posts",
        icon: "SmsTrackingIcon",
        url: "/posts",
        children: [],
      },
      {
        name: "Manage Members",
        icon: "PeopleIcon",
        url: "/users",
        children: [],
      },
      {
        name: "Manage Payments",
        icon: "CardTickIcon",
        url: "/payments",
        children: [],
      },
  
      ...(selectedCommunity?.collectSubscription !== "NO" ? [{
        name: "Manage Plans",
        icon: "IcDashboardIcon",
        url: "/plans",
        // url: `/communities/${community_id}/plans`,
        children: [],
      }] : []),

      {
        name: "Manage Courses",
        icon: "CourseIcon",
        url: "/courses",
        children: [],
      },
    
      {
        name: "Communities",
        icon: "CommunityIcon",
        url: "/communities",
        children: [],
      },
      {
        name: "About Community ",
        icon: "CommunityIcon",
        url: `/community/${community_id}/about`,

        children: [],
      },
    

      {
        name: "Explore ",
        icon: "ExploreIcon",
        url: "/explore-communities",

        children: [],
      },
    ],
  ];

  const menuItem = (menu: IMenuItem) => {
    return (
      <>
        <Stack direction={"row"} alignItems="center" className="menu-item">
          {getIcon(menu.icon)} {open ? menu.name : ""}
        </Stack>
      </>
    );
  };
 
  return (
    <Box sx={styles}>
      <Stack spacing={open ? 1 : 0} className={open ? "expanded" : "collapsed"}>
        {menusList.map((list: IMenuItem[], i: number) => {
          return (
            <React.Fragment key={i}>
              {list.map((menu: IMenuItem, ii: number) => {
                return menu.type !== "button" ? (
                  <React.Fragment key={i + "navlink" + ii}>
                    <NavLink
                      to={menu.url}
                      className={({ isActive }) =>
                        `nav-link ${isActive ? "active" : ""}`
                      }
                      onClick={() => handleMenuClick(menu)}
                    >
                      {menuItem(menu)}
                    </NavLink>
                    {expandedItems.includes(menu.name) &&
                      menu.children &&
                      menu.children.length > 0 && (
                        <Box>
                          <ul>
                            <Stack
                              sx={{
                                fontSize: "14px",
                                marginTop: "-20px",
                                ml: 2.6,
                                color: "#565656",
                              }}
                            >
                              {menu.children.map((subMenu: IMenuItem) => (
                                <li key={subMenu.name}>
                                  <NavLink
                                    to={subMenu.url}
                                    style={{
                                      textDecoration: "none",
                                      color: "#565656",
                                    }}
                                  >
                                    {subMenu.name}
                                  </NavLink>
                                </li>
                              ))}
                            </Stack>
                          </ul>
                        </Box>
                      )}
                  </React.Fragment>
                ) : (
                  <Box
                    key={"sdsd" + ii}
                    className={`nav-link`}
                    onClick={() => handleMenuClick(menu)}
                  >
                    {menuItem(menu)}
                  </Box>
                );
              })}
              {menusList.length > i + 1 && <Divider key={i} />}
             
                {/* <Stack display={'flex'} direction={'row'} justifyContent={"space-between"}  >
                  
                <Link to="/explore-communities  " style={{ textDecoration: 'none' ,marginTop:'100px'}}>
                    <Button variant="outlined" sx={{ color: '#50A1CA', border: '1px solid #50A1CA', borderRadius: '30px',
                    fontSize: "14px", textTransform:'capitalize ', fontWeight:'400' }}
                    startIcon={<ExploreIcon sx={{fontSize:'25px'}}/>}>
                        Explore
                    </Button>
                </Link>
               
                <Typography>

                </Typography>
            </Stack> */}
              
            </React.Fragment>
          );
        })}
      </Stack>
    </Box>
  );
}
