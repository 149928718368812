import * as React from "react";
import { styles } from "./Dashboard.styles";
import Stack from "@mui/material/Stack";
import { DashboardCard, ICard } from "./components/card";
import { Paper } from "@mui/material";
import { RecentTransactions } from "../tables/RecentTransactions";
import { ActivePlans } from "./components/ActivePlans";
import { RecentNotifications } from "./components/RecentNotifications";
import { VisitorsBySource } from "./components/VisitorsBySource";
import { Helmet } from "react-helmet";

export const Dashboard = () => {
  const stackDetails = {
    commonHeight: 280,
  };
  const recentTransactions = [
    {
      id: 896990,
      name: "Rakesh",
      communityname:"ashirvad",
      PaymentType: "Economy",
      amount: 2000,
      status: "success",
      paymentMode: "Net Banking",
      bank: "ICICI Bank",
      date: new Date(),
      type:'Member'
    },
    {
      id: 896990,
      name: "Ramesh",
      communityname:"ashirvad",
      PaymentType: "Payment request",
      amount: 2000,
      status: "success",
      paymentMode: "Net Banking",
      bank: "ICICI Bank",
      date: new Date(),
      type:'admin'
    },
    {
      id: 896990,
      name: "Suresh",
      communityname:"ashirvad",
      PaymentType: "Economy",
      amount: 2000,
      status: "success",
      paymentMode: "Net Banking",
      bank: "ICICI Bank",
      date: new Date(),
      type:'admin'
    },
    {
      id: 896990,
      name: "Mahesh",
      communityname:"ashirvad",
      PaymentType: "Premium",
      amount: 2000,
      status: "fail",
      paymentMode: "Net Banking",
      bank: "ICICI Bank",
      date: new Date(),
      type:'admin'
    },
  ];
  // const recentSubscriptions = [
  //   {
  //     id: 896990,
  //     name: "Isha Paul",
  //     avatar: "",
  //     status: "active",
  //     planName: "standard",
  //     date: new Date(),
  //   },
  //   {
  //     id: 896990,
  //     name: "Arvind Sinha",
  //     avatar: "",
  //     status: "inactive",
  //     planName: "business",
  //     date: new Date(),
  //   },
  //   {
  //     id: 896990,
  //     name: "Sanjeet Singh",
  //     avatar: "",
  //     status: "active",
  //     planName: "economy",
  //     date: new Date(),
  //   },
  //   {
  //     id: 896990,
  //     name: "Isha Paul",
  //     avatar: "",
  //     status: "active",
  //     planName: "standard",
  //     date: new Date(),
  //   },
  // ];
  const activePlans = [
    { name: "standard", value: 75 },
    { name: "economy", value: 80 },
    { name: "business", value: 50 },
    { name: "others", value: 25 },
  ];
  const recentNotifications = [
    {
      type: "Membership",
      date: new Date(),
      title: "Inquiry received from Mr. Gautam Rai",
      description: "",
    },
    {
      type: "Community",
      date: new Date(),
      title: "ParamSukha Org created by Naman Arora",
      description: "",
    },
    {
      type: "Plan",
      date: new Date(),
      title: "New plan is activated by Arjun Sharma",
      description: "",
    },
    {
      type: "Subscriber",
      date: new Date(),
      title: "Re-activate by Sheela lalwani",
      description: "",
    },
    {
      type: "Support",
      date: new Date(),
      title: "Inquire generate by Anil Kumar",
      description: "",
    },
  ];
  return (
    <>
    <Helmet>
      <title>Dashboard - Communn - Build & Manage your community</title>
      <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
    <Stack spacing={1} direction="row">
      <Stack spacing={1} direction="column" sx={{ flex: "1 1 75%" }}>
        <Stack direction="row" spacing={1} sx={styles.cards}>
          {cards.map((card, i: number) => (
            <Paper key={i} elevation={0} sx={{ flex: "1 1 25%" }}>
              <DashboardCard data={card} />
            </Paper>
          ))}
        </Stack>

        <Stack direction={"row"} sx={{ width: "100%" }} spacing={1}>
          <Paper
            elevation={0}
            sx={{
              height: `${stackDetails.commonHeight}px`,
              padding: "5px 20px",
              flex: "1 1 67%",
            }}
          >
            {/* <RecentTransactions data={recentTransactions} /> */}
          </Paper>
          <Paper
            elevation={0}
            sx={{
              minHeight: `${stackDetails.commonHeight}px`,
              flex: "1 1 33%",
              padding: "5px 20px",
            }}
          >
            <ActivePlans data={activePlans} />
          </Paper>
        </Stack>
        <Stack direction={"row"} sx={{ width: "100%" }} spacing={1}>
          <Paper
            elevation={0}
            sx={{
              minHeight: `${stackDetails.commonHeight}px`,
              flex: "1 1 100%",
              padding: "5px 20px",
            }}
          >
            {/* <RecentSubscribers data={recentSubscriptions} /> */}
            <RecentTransactions data={recentTransactions} />
          </Paper>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        direction="column"
        sx={{ flex: "1 1 25%", minHeight: `${stackDetails.commonHeight}px` }}
      >
        <Paper
          elevation={0}
          sx={{
            minHeight: `${stackDetails.commonHeight + 145}px`,
            flex: "1 1 100%",
            padding: "5px 20px",
          }}
        >
          <RecentNotifications data={recentNotifications} />
        </Paper>
        <Paper
          elevation={0}
          sx={{
            minHeight: `${stackDetails.commonHeight}px`,
            flex: "1 1 100%",
            padding: "5px 20px",
          }}
        >
          <VisitorsBySource data={recentNotifications} />
        </Paper>
      </Stack>
    </Stack>
    </>
  );
};

const cards: ICard[] = [
  {
    name: "users",
    arrow: "up",
    percentage: 24,
    icon: "users",
    total: "12.58K",
    text: "Total Users",
  },
  {
    name: "subscribers",
    arrow: "down",
    percentage: 13,
    icon: "subscribers",
    total: "8.32K",
    text: "Total Subscribers",
  },
  {
    name: "posts",
    arrow: "up",
    percentage: 17,
    icon: "posts",
    total: "83.02K",
    text: "Total Posts",
  },
];
