export const INACTIVE_USER_MSG = "We regret to inform you that your account has been temporarily deactivated. Please contact the Administrator."
export const USER_NOT_FOUNT_MSG = "We regret to inform you that your account is not available with communn,Please contact the Administrator or Create account"

// TODO:
export const EMPTY_STRING = ""


// OTP messages
export const OTP_SEND_FAILED = "Failed to send OTP - #PHONE_NUMBER , Please check phone number"
export const INVALID_OTP = "The One-Time Password you provided is not valid. Please verify!"
export const OTP_SENT = "The One-Time Password has been sent to #PHONE_NUMBER successfully."
export const OTP_RESENT = "The One-Time Password has been re-sent to #PHONE_NUMBER successfully."
export const OTP_LIMIT_REACHED = "Maximum resend attempts reached. Please try again later."
export const PASSWORD_INVALID="Incorrect Username/Password."
