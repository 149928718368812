import { Box, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from './CustomButton'
import { Close } from '@mui/icons-material'
import { useCourses } from '../../../hooks/useCourses';
import { interfaceCourse } from '../../../models/courses.model';
interface AddSectionPopUpProps {
  handleCloseAddSectionDialog: () => void;
  data: interfaceCourse[];
  section: any
  refetchCourseData: () => void 
}
const AddSectionPopUp: React.FC<AddSectionPopUpProps> = ({ handleCloseAddSectionDialog, data, section, refetchCourseData }) => {
  const defaultSectionName = section ? section.name : '';
  const defaultSectionDescription = section ? section.description : '';
  const communityId = data[0]?.community._id
  const courseId = data[0]?._id

  const [sectionName, setSectionName] = useState(defaultSectionName);
  const [sectionDescription, setSectionDescription] = useState(defaultSectionDescription);
  const { addCourseSectionByCourseId, updateCourseSectionsById } = useCourses();
  const [loading, setLoading] = useState(false)
  const disable = !sectionName && !sectionDescription
  const handleSaveSection = async () => {
    const formData = {
      name: sectionName,
      description: sectionDescription
    }

    try {
      if (section) {
        setLoading(true)
        await updateCourseSectionsById(communityId, courseId, section._id, formData)
        refetchCourseData()
      } else {
        setLoading(true)
        await addCourseSectionByCourseId(communityId, courseId, formData); //course section newly
        refetchCourseData()
      }
    } catch (error) {
      console.log(error);

    } finally {
      handleCloseAddSectionDialog()
      setLoading(false)
    }
  };
  return (
    <Stack>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#F0F9FF", height: "35px" }}
      >

        <Typography
          sx={{
            color: "#3C5AA0",
            fontSize: "20px",
            fontWeight: "500",
            backgroundColor: "#F0F9FF",
            // pt: 1,
            px: 2,
          }}
        >
          {section.length === 0 ? 
          "Add New Section" : "Edit Section"}
        </Typography>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            backgroundColor: "#3C5AA0",
            padding: "4px",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "10px",
            mt: "0.5px",
            mr: "10px",
            cursor: "pointer",
          }}
        >
          <IconButton onClick={handleCloseAddSectionDialog}>
            <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
          </IconButton>
        </Stack>
      </Grid>
      <Stack sx={{ p: 2 }}>

        <Stack
          sx={{
            display: "flex",

          }}

        >
          <Stack sx={{ py: 2 }}>
            <Typography
              sx={{ fontSize: "10px", fontWeight: "400", color: "#5E6C84" }}
            >
              {" "}
              Name
            </Typography>
            <TextField
              placeholder="Section name"
              variant="outlined"
              fullWidth
              size="small"
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
              sx={{ mr: 1, "& input::placeholder": { fontSize: "0.8rem" } }}
            />
          </Stack>
          <Stack>
            <Typography
              sx={{ fontSize: "10px", fontWeight: "400", color: "#5E6C84" }}
            >
              Description (Optional)
            </Typography>
            <TextField
              placeholder=""
              variant="outlined"
              fullWidth
              size="medium"
              value={sectionDescription}
              onChange={(e) => setSectionDescription(e.target.value)}
              sx={{ mr: 1, "& input::placeholder": { fontSize: "0.8rem" } }}
            />
          </Stack>
        </Stack>

        <Stack
          display={"flex"}
          // alignItems={"center"}
          justifyContent={"flex-end"}
          flexDirection={"row"}
          sx={{ pt: 1.5 }}
        >
          <Box sx={{ mr: "10px" }}>
            <CustomButton
              loading={loading}
              children={" Save "}
              background={"#3C5AA0"}
              textColor={"#FFFFFF"}
              onClick={handleSaveSection}
              disable={disable}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default AddSectionPopUp