import React, { useState } from "react";

import {
  Grid,
  Typography,
  Divider,
  Stack,
  IconButton,
  CardContent,
  Card,
  Menu,
  MenuItem,
  Button,
  Popover,
} from "@mui/material";
import course from "../../../assets/images/courses/courseIcon@2x.png";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { MoreVert } from "@mui/icons-material";
import { PublishIconSvg } from "../../../assets/icons";
import CustomButton from "./CustomButton";
import { interfaceCourse } from "../../../models/courses.model";
import { useCourses } from "../../../hooks/useCourses";
import { useNavigate } from "react-router-dom";

interface CourseInfoCardProps {
  data: interfaceCourse[];
  refetchCourseData: () => void
}

const CourseInfoCard: React.FC<CourseInfoCardProps> = ({ data, refetchCourseData }) => {
  const courseData = data[0]
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const { updateCourse,deleteCourseByIds } = useCourses();
  const [deleteCourseAnchor, setDeleteCourseAnchor] = useState<null | HTMLElement>(null);
  const openDeleteCoursePopOver = Boolean(deleteCourseAnchor);
  const idDeleteCourse = openDeleteCoursePopOver
    ? "delete-popover"
    : undefined;
    const navigate = useNavigate();

  const handleStatus = async () => {
    setLoading(true)
    await updateCourse(courseData?.community?._id, courseData?._id, { status: "PUBLISHED" });
    setLoading(false);
    refetchCourseData()
  }

  const handleStatusUnPublish = async () => {
    setLoading(true)
    await updateCourse(courseData?.community?._id, courseData?._id, { status: "UN_PUBLISHED" });
    setLoading(false);
    handleClose();
    refetchCourseData()
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteCourse = (e:any) => {
    setDeleteCourseAnchor(e.currentTarget);
  }
  const handleCloseDelete=()=>{
    setDeleteCourseAnchor(null)
  }

  const handleCourseDelete =async()=>{
    setLoading(true)
    await deleteCourseByIds(courseData?.community?._id,courseData?._id);
    navigate("/courses");
    setLoading(false);
    refetchCourseData()
  }

  return (
    <Stack spacing={1}>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardContent>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                px: 1,
                alignItems: "center",
              }}
            >
              <img
                src={courseData?.coverImage ? courseData?.coverImage.value : course}
                style={{  height: "150px",
                  width: "250px",
                  borderRadius: "10px",
                  objectFit: "cover",}}
                alt={courseData?.coverImage?.label}
              />
              <Stack sx={{ pl: "20px", flexGrow: 1 }}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: {xs:"13px",md:"15px"},
                      // lineHeight: "1.5",
                      marginBottom: "2px",
                    }}
                  >
                    {courseData?.name}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {" "}
                    {/* Stack for icons after name */}
                    <IconButton aria-label="share">
                      <ShareOutlinedIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                    <IconButton aria-label="menu" onClick={handleClick}>
                      <MoreVert sx={{ fontSize: "18px" }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {courseData?.status === "UN_PUBLISHED" &&
                        <MenuItem onClick={() => { handleStatus(); handleClose(); }}
                          sx={{ fontSize: "13px" }}>Publish</MenuItem>}
                        {courseData?.status === "PUBLISHED" && <MenuItem onClick={() => { handleStatusUnPublish() }}
                          sx={{ fontSize: "13px" }}>UnPublish</MenuItem>}
                      <MenuItem
                        onClick={(e:any) => { handleDeleteCourse(e) }}
                        sx={{ fontSize: "13px" }}>Delete</MenuItem>
                    </Menu>
                  </Stack>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily:'Inter',
                    fontSize: {xs:"11px", md:"13px"},
                    lineHeight: "1.5",
                    marginBottom: "2px",
                    width: "80%",
                    color: "#000"
                  }}
                >
                  {courseData?.description}
                </Typography>
                <Stack direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily:'Inter',
                      fontWeight:'bold',
                      fontSize: {xs:"13px",md:"15px"},
                      lineHeight: "1.5",
                      marginBottom: "5px",
                    }}
                  >
                   {courseData?.pricing === "FREE" ? "Free" :  `₹ ${courseData?.plan.offerValue}`}
                    {/* ₹ {courseData?.plan.offerValue ? courseData?.plan.offerValue : courseData?.pricing === "FREE"  } */}
                  </Typography>
                   {courseData?.status === "UN_PUBLISHED"|| courseData?.status==='CREATED' && 

                    <CustomButton
                      loading={loading}
                      children="Publish"
                      icon={<PublishIconSvg />}
                      background={'#2952A2'}
                      textColor="#fff"
                      disable={false}
                      onClick={handleStatus}
                    />
                   }
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Divider sx={{ mx: 2 }} />
      </Grid>

      <Popover
        id={idDeleteCourse}
        open={openDeleteCoursePopOver}
        anchorEl={deleteCourseAnchor}
        onClose={() => handleCloseDelete()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete Sectifron?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseDelete}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleCourseDelete}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {/* {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )} */}
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>

    </Stack>
  );
};

export default CourseInfoCard;
