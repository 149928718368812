import axios from "axios";
import { BASE_URL } from "../configurations/url.config";
import { IPost } from "../components/Posts/Posts";

export const getPosts = async (token: String) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      response.data = response.data.map((d: IPost) => {
        return parsePost(d);
      });
    }
    return response;
  } catch (err) {
    
    return { status: 500, data: [] };
  }
};

export const getPost = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // if (response.status === 200) {
    //   response.data = parsePost(response.data);
    // }
    return response;
  } catch (err) {
    
    return { status: 500, data: [] };
  }
};

export const updatePost = async (token: string, id: string, data: FormData,files: FileList | null) => {
  try {
    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append("images", file);
      }
    }
    for (const [field, value] of data.entries()) {
      formData.append(field, value);
    }
    
    const response = await axios.put(`${BASE_URL}/posts/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`, 
        "Content-Type": "multipart/form-data",
        Accept: "*",
      },
    });
    if (response.status === 200) {
      response.data = parsePost(response.data);
    }
    return response;
  } catch (err) {
    
    return { status: 500, data: {} };
  }
};

export const createNewCommunityPost = async (
  token: string,
  files: FileList | null,
  data: FormData
) => {
  try {
    
    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append("images", file);
      }
    }
    for (const [field, value] of data.entries()) {
      formData.append(field, value);
    }
    const response = await axios.post(`${BASE_URL}/posts`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*",
      },
    });
    if (response.status === 201) {
      // Parse the post data if needed
      // const parsedPost = parsePost(response.data);
      // return parsedPost;
      response.data = parsePost(response.data);
    }

    return response;
  } catch (err) {
    return { status: 500, data: {} };
  }
};
export const deletePost = async (token: string, id: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/posts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (err) {
    
    return { status: 500, data: [] };
  }
};
export const deleteMultiplePosts = async (token: string, ids: string[]) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/posts/deleteMany`,
      {
        ids,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      response.data = parsePost(response.data);
    }
    return response;
  } catch (err) {
  
    return { status: 500, data: [] };
  }
};

export const HomePosts = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts/community/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    return { status: 200, data: [] };
  }
};
export const ProfilePosts = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts/community/${id}/mine`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // if (response.status === 200) {
    //   response.data = response.data.map((d: IPost) => {
    //     return parsePost(d);
    //   });
    // }

    return response;
  } catch (err) {
    
    return { status: 500, data: [] };
  }
};
export const userPosts = async (token: string, communityId: string,userId:string) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts/community/${communityId}/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // if (response.status === 200) {
    //   response.data = response.data.map((d: IPost) => {
    //     return parsePost(d);
    //   });
    // }

    return response;
  } catch (err) {
    
    return { status: 500, data: [] };
  }
};
export const likePost = async (token: string, postId: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/posts/${postId}/like`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (err) {
    
    return { status: 500, data: {} };
  }
};
const parsePost = (d: IPost) => {
  const createdAtDate = new Date(d.createdAt);
  const updatedAtDate = new Date(d.updatedAt);
  return {
    ...d,
    _id: d._id ?? "",
    whoCan: d.whoCan ?? "",
    communities: d.communities ?? [],
    createdAt:
      createdAtDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }) ?? "",
    createdAtTime:
      createdAtDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }) ?? "",
      updatedAt:
      updatedAtDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }) ?? "",
      updatedAtTime:
      updatedAtDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }) ?? "",
    content: d.content,
    images: d.images ?? [],
    hashTag: d.hashTag ?? "",
    createdBy: d.createdBy,
    status: d.status ?? "",
    likedBy:d.likedBy??[]

    // actions: [

    //   { icon: EditIcon },
    //   { name: "delete", icon: DeleteIcon },
    // ],
  };
};


export const likeUnLikePost = async(token:string,id:string,isLiked:boolean) =>{
  const formData = new FormData();
    formData.append("like", isLiked.toString());
  try {
    const response = await axios.post(`${BASE_URL}/posts/${id}/like`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }

}
