import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ITeam } from "../../models/communities.modal";
import { Close } from "@mui/icons-material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


interface membersProps {
  productData: ITeam[];
}
const AboutCarouselTeem: React.FC<membersProps> = ({ productData }) => {
  // console.log(productData);


  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");
  const [selectedItem, setSelectedItem] = useState<ITeam | null>(null);

  const handleCloses = () => {
    setOpen(false);
  };

  const handleClickOpen = (item: ITeam) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const product = productData?.map((item: any, index) => (
    <>

      <Card
        key={item._id}
        style={{
          height: "90%",
          marginRight: "15px",
          paddingBottom: "15px",
          textAlign: "left",
        }}
      >
        {/* <Stack p={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Avatar
            alt="Profile"
            style={{
              width: "200px", // Adjust the width as needed
              height: "200px", // Adjust the height as needed
              objectFit: "cover",
              borderRadius: "50%", // Set borderRadius to 50% for a circular effect
              background: "-webkit-linear-gradient(180deg, #3C5AA0 0%, #50A1CA 100%)",
            }}
            src={item?.avatar?.value}
          ></Avatar>
        </Stack> */}
        <CardContent sx={{ marginTop: "0px" }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar sx={{
              color: '#ffffff',
              fontSize: { xs: "14px", md: '25px' },
              fontFamily: 'Montserrat',
              fontWeight: "700",
              textAlign: 'center',
              p: 5,
              background: "-webkit-linear-gradient(180deg, #3C5AA0 0%, #50A1CA 100%)",
              borderRadius: '100px'
            }}>0{index + 1}</Avatar>
          </Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "Inter",
              color: "#000000",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {item?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "11px",
              fontFamily: "Inter",
              color: "#000000",
              // fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {item?.designation},{" "}{item?.experience}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              paddingBottom: '15px',
              color: '#565656',
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                flexGrow: 1,
              }}
            >
              {item?.description && item.description.length > 100
                ? item.description.substring(0, 100) + '...'
                : item.description}
            </Typography>
            {item?.description && item?.description.length > 100 && (
              <Button
                onClick={() => handleClickOpen(item)}
                sx={{ minWidth: 'auto', padding: 0, marginLeft: '8px' }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "small", color: '#3C5AA0 ' }} />
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

    </>
  ));
  return (
    <div>
      <Carousel responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
      >
        {product}
      </Carousel>
      {selectedItem && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleCloses}
        >
          <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: "#F0F9FF" }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "15px" },
                  fontWeight: 500,
                  textDecoration: "none",
                  color: "#3C5AA0",
                  textTransform: "uppercase",
                  marginLeft: 2,
                }}
              >
                {selectedItem.name}
              </Typography>
              <Box
                onClick={handleCloses}
                sx={{
                  backgroundColor: "#3C5AA0",
                  padding: "4px",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "10px",
                  mt: "0.5px",
                  mr: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#3C5AA0", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                <Close sx={{ fontSize: "15px" }} />
              </Box>
            </Grid>
          </DialogActions>
          <DialogContent sx={{ p: 2 }}>
            <DialogContentText>
              <Typography sx={{ fontSize: "15px" }}> {selectedItem.description}</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default AboutCarouselTeem;
