import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PerfectScrollbar from "react-perfect-scrollbar";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Divider from "@mui/material/Divider";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { GetColor } from "./GetColor";
import { UserInfoCell } from "./components/UserInfoCell";

interface ITableProps {
  data: any;
}

export const RecentTransactions = ({ data }: ITableProps) => {
  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"baseline"}
        sx={{ paddingBottom: "10px" }}
      >
        <Typography variant="body2" sx={{ fontWeight: "500", pt: "1.4vh",fontSize:"14px" }}>
          Recent Transactions
        </Typography>
        <Stack direction={"row-reverse"} sx={{ flexGrow: "1" }}>
          {/* <MoreVertIcon sx={{ color: "info.main", cursor: "pointer" }} /> */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: "#2952A2",
              display: "flex",
              cursor: "pointer",
            }}
          >
            View All
            <ArrowForwardIcon sx={{ fontSize: "16px" }} />
          </Typography>
        </Stack>
      </Stack>
      <Divider />

      <PerfectScrollbar>
        <Box>
          <Table
            sx={{
              "& th": {
                fontSize: "12px",
                fontWeight: 500,
                color: "#565656",
                p: 0,
              },
              "& td": {
                fontSize: "11px",
                fontWeight: 400,
                color: "#565656",
                p: "10px 0px",
                "& .MuiTypography-root": { m: 0, fontSize: "11px" },
                "& .MuiTypography-root:nth-child(2)": {
                  m: 0,
                  fontSize: "10px",
                  color: "#56565680",
                },
              },
            }}
          >
            <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: '#ffffff' }}>
              <TableRow>
                <TableCell>Payee Details</TableCell>
                <TableCell>Payment Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((order: any, i: number) => (
                <TableRow hover key={order.name}>
                  {/* <TableCell>{order.id}</TableCell> */}
                  <UserInfoCell
                    name={order.name}
                    avatar={""}
                    avatarStyles={{
                      mt:2,
                      mb:2,
                      height: "33px",
                      width: "33px",
                      fontSize: "11px",
                    }}
                    nameProps={{
                      sx: {
                        fontWeight: 600,
                        fontSize: "14px !important",
                        color: "#1A1A1A",
                      },
                    }}
                    id={order.type + ":" + order.communityname}
                    idProps={{
                      sx: {
                        fontWeight: 400,
                        fontSize: "12px !important",
                      },
                    }}
                  ></UserInfoCell>
                  <Divider></Divider>
                  <TableCell>{order.PaymentType}</TableCell>
                  <TableCell>&#8377; {order.amount}</TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textTransform: "capitalize",
                      }}
                    >
                      <FiberManualRecordIcon key={i}
                        sx={{
                          hieght: "10px",
                          width: "10px",
                          color: GetColor(order.status),
                        }}
                      />
                      {order.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{order.paymentMode}</Typography>
                    <Typography> {order.bank}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>12 Aug. 2022</Typography>
                    <Typography>08:34pm</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  );
};
