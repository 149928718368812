
import { Group432Icon } from "../../../assets/icons";
import { Box, Stack } from "@mui/material";
import { useState } from "react";

interface ICustomTableHeaderProps {
  title: string;
  sortDirection: "asc" | "desc" | undefined;
}

export const CustomTableHeader = ({ title, sortDirection }: ICustomTableHeaderProps) => {
  const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  return (
    <>
      <Box
        component="span"
        sx={{ cursor: "pointer",ml:"0px"}}
        display="flex"
        alignItems="center"
        onMouseEnter={() => setIsHovered(true)} 
        onMouseLeave={() => setIsHovered(false)} // Set isHovered to false when the user leaves the hover area
      >
        <Stack direction={'row'} spacing={2} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{color:'#000000',fontWeight:500,fontSize:'12px'}}>
        {title}
        {isHovered && ( // Show the icon when isHovered is true (user is hovering)
          <Group432Icon sx={{ fontSize: "10px" }} />
        )}
        </Stack>
      </Box>
    </>
  );
};