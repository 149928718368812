import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { getPaymentList, initiatePayment, transactionDetails } from "../services/payment.service";
import { IPaymentList } from "../models/payment.model";
export const usePayment = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
 //// Payment Details/////
  const getCommunityPaymentsById = async (id: string) => {
    setIsLoading(true);
    const response = await getPaymentList(getAccessToken(), id);

    if (response.status === 200) {
      setIsLoading(false);
      return response.data.data as IPaymentList[];
    } else {
      enqueueSnackbar("Failed to fetch community payments", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
    setIsLoading(false);
  };


  const getTransactionDetails = async (transactionId: string) => {
    setIsLoading(true);
    const response = await transactionDetails(getAccessToken(), transactionId);
    if (response?.status === 200) {
      setIsLoading(false);
      return response?.data ;
    } else {
      
    }
    setIsLoading(false);

  }
  ////Payment  initiate//////
  const initiatePaymentByIds = async (communityId: string,planId:string) => {
    setIsLoading(true);
    const response = await initiatePayment(getAccessToken(), communityId,planId);

    if (response?.status === 200) {
      setIsLoading(false);
      return response?.data ;
    } else {
      enqueueSnackbar("Failed to initiate payment", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
    setIsLoading(false);
  };

  //////////////////////////////////////////////////////////////// return statement
  return {
    isLoading,
    getCommunityPaymentsById,
    initiatePaymentByIds,
    getTransactionDetails
    
  };
};
