import * as React from 'react';
import { DataGrid, GridColDef} from '@mui/x-data-grid';

const columns: GridColDef[] = [
//   { field: 'id', headerName: 'ID', width: 70 },
  { field: 'payeedetails', headerName: 'Payee Details', width: 130 },
  { field: 'plan', headerName:'Plan', width: 130 },
  {field: 'paymentcycle',headerName:'Payment Cycle',type:'text',width: 130,},
  {field: 'date',headerName:'Date',type: 'date',width: 130,},
  {field: 'aomunt',headerName:'Amount(₹)',type: 'date',width: 130,},
  {field: 'mode',headerName: 'Mode',type: 'text',width: 130,},
  {field: 'status',headerName: 'Status',type: 'text',width: 130,},

];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function DataTable() {
  return (
    <div style={{ height: 400, width: '100%' , backgroundColor:'#ffffff'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 0, pageSize: 5 },
        //   },
        // }}
        // pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}