import axios from "axios";
import { BASE_URL } from "../configurations/url.config";

export const getMyBankAccounts = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/bankaccounts/mine`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};
export const addBankAccountsByCommunity = async (
  token: string,
  id: string,
  formatData: any
) => {
  try {
    const response = await axios.post(`${BASE_URL}/bankaccounts/communities/${id}`,
      formatData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 201) {
      return response;
    } else if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const deleteBankDetailsById = async (token: string, id: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/bankaccounts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const deleteManyBankDetailsByIds = async (token: string,formData:any) => {
  try {
    const response = await axios.delete(`${BASE_URL}/bankaccounts/many`,
      {
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response; 
    }

    return response;
  } catch (err) {
    console.error('Error:', err);
    return { status: 500, data: [] };
  }
};

export const updateBankDetails = async (
  token: string,
  id: string | undefined,
  formData: any
) => {
  try {
    const response = await axios.put(`${BASE_URL}/bankaccounts/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const getBankDetailsById = async (
  token: string,
  id: string,
) => {
  try {
    const response = await axios.get(`${BASE_URL}/bankaccounts/communities/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};