import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { useUsers } from "../../hooks/useUsers";
import { CommunityMembers } from "../../models/community-user.model";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getStaticValue } from "../../utils/StaticValues";
import { IUser, IpaymentRequest } from "../../models/paymentRequest.model";
import { UsePaymentRequest } from "../../hooks/usePaymentRequest";
interface PaymentRequestsProps {
  communityId: string;
  tableData: IpaymentRequest[];
  handleCloseModal: () => void;
  refetchData: () => void;
  selectedRow: IpaymentRequest;
  setSelectedRow: React.Dispatch<React.SetStateAction<IpaymentRequest | null>>;
}
export const PaymentRequests: React.FC<PaymentRequestsProps> = ({
  communityId,

  handleCloseModal,
  refetchData,
  selectedRow,
}) => {
  const { addPaymentRequestsList, updatePaymentRequestbyId } =
    UsePaymentRequest();

  const [paymentValues, setPaymentValues] = useState<IpaymentRequest>({
    _id: "",
    user: { _id: "", firstName: "", lastName: "", avatar: "", role: "" },
    frequency: "",
    notify: "BOTH",
    amount: "100",
    status: "",
    description: "",
    createdAt: ""
  });
  const [errorText, setErrorText] = useState("")

  const [loading, setLoading] = useState(true);
  const recurringValues =
    paymentValues?.frequency.charAt(0)?.toUpperCase() +
    paymentValues?.frequency?.slice(1).toLowerCase();

  const [recurring, setRecurring] = React.useState(
    recurringValues || "One_time"
  );
  // eslint-disable-next-line
  const [notifyOption, setNotifyOption] = useState("BOTH"); // Set the default value here
  const [selectedOptions, setSelectedOptions] = useState<any[]>(
    selectedRow ? [selectedRow] : []
  );

  const handleChange = (event: SelectChangeEvent) => {
    setRecurring(event.target.value);
  };

  const recurringDropdownOptions: {
    key: string;
    value: string;
    label: string;
  }[] = [
      { key: "DAILY", value: "Daily", label: "Daily" },
      { key: "WEEKLY", value: "Weekly", label: "Weekly" },
      { key: "MONTHLY", value: "Monthly", label: "Monthly" },
      { key: "QUARTERLY", value: "Quarterly", label: "Quarterly" },
      { key: "HALFYEARLY", value: "Half_yearly", label: "Halfyearly" },
      { key: "YEARLY", value: "Yearly", label: "Yearly" },
    ];

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setPaymentValues({ ...paymentValues, [name]: value });
  };

  useEffect(() => {
    if (selectedRow) {
      setPaymentValues({
        _id: selectedRow._id || "",
        user: selectedRow.user || {
          _id: "",
          firstName: "",
          lastName: "",
          avatar: "",
          role: "",
        },
        frequency: selectedRow.frequency || "",
        notify: selectedRow.notify || "BOTH",
        amount: selectedRow.amount || "100",
        status: selectedRow.status || "",
        description: selectedRow.description || "",
        createdAt: selectedRow.createdAt
      });
    } else {
      // Reset to default values when no row is selected
      setPaymentValues({
        _id: "",
        user: { _id: "", firstName: "", lastName: "", avatar: "", role: "" },
        frequency: "",
        notify: "BOTH",
        amount: "100",
        status: "",
        description: "",
        createdAt: ""
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (parseInt(paymentValues.amount, 10) < 100) {
      // Set the error message
      setErrorText('Amount must be at least 100');
      return;
    }
    const userIds = selectedOptions
      ? selectedOptions.map((option) =>
        option === "All" ? option : option.user._id
      )
      : [];

    const data = {
      users: userIds,
      amount: paymentValues.amount,
      frequency: recurring.toUpperCase(),
      notify: paymentValues.notify.toUpperCase(),
      description: paymentValues.description,
    };
    if (selectedRow) {
      setLoading(true);
      if (communityId && selectedRow?._id) {
        await updatePaymentRequestbyId(communityId, selectedRow?._id, data);
      }
      handleCloseModal();
      setLoading(false);
      await refetchData();
    } else {
      setLoading(true);
      console.log("Adding payment request...");
      if (communityId) {
        const response = await addPaymentRequestsList(communityId, data);
        console.log("API Response:", response);
        if (response.message === "Payment Requested successfully!") {
          await refetchData();
          handleCloseModal();
          console.log("Payment request added successfully.");
        }
      }
      setLoading(false);
    }
    handleCloseModal();
  };

  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;
  if (!community_id) {
    community_id = singleCommunity?._id;
  }

  const { getUsersList } = useUsers();
  const [userList, setUserList] = useState<any[]>([]);
  useEffect(() => {
    const loadUserList = async () => {
      try {
        setLoading(true);
        const users = await getUsersList(community_id);
        if (!users) {
          setUserList([]);
        } else {
          // Apply filters

          setUserList(users);
        }
      } catch (error) {
        console.error("Error loading user list:", error);
        setUserList([]);
      } finally {
        setLoading(false);
      }
    };

    loadUserList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community_id]);

  const handleChangeNotifyOption = (event: any) => {
    const selectedValue = event.target.value;

    // Assuming `paymentValues` is an object in your state
    setPaymentValues((prevPaymentValues) => ({
      ...prevPaymentValues,
      notify: selectedValue,
    }));
  };

  const isDataAvailable =
    notifyOption &&
    recurring &&
    selectedOptions &&
    paymentValues?.amount &&
    paymentValues?.description;
  console.log(userList)

  return (
    <>
      <Stack>
        <Paper
          elevation={0}
          sx={{
            flex: "1 1 100%",
            // padding: "10px 20px 10px 20px",
          }}
        >
          <Typography sx={{ fontsize: "15px", font: "Roboto" }}>
            Select user to continue
          </Typography>
          <Typography
            sx={{ marginTop: "1vh", fontSize: "13px", font: "Roboto" }}
          >
            Search by User name
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} sm={6} sx={{ marginTop: "1vh" }}>
              <Autocomplete
                // sx={{ width: "20vw" }}
                size="small"
                limitTags={3}
                ListboxProps={{ sx: { fontSize: "13px", font: "Roboto" } }}
                multiple
                id="checkboxes-tags-demo"
                options={["All", ...userList.filter(user => (user?.type !== 'ADMIN'))]}
                disableCloseOnSelect
                getOptionLabel={(option) =>
                  option === "All" ? option : option?.user?.firstName
                }
                onChange={(event, newValue) => {
                  if (newValue.includes("All")) {
                    // If "All" is included in the new selected options
                    if (selectedOptions.length === userList.length) {
                      // If all options are already selected, deselect all options
                      setSelectedOptions([]);
                    } else {
                      // Otherwise, select all options
                      setSelectedOptions(userList);
                    }
                  } else {
                    // If "All" is not included, update selected options accordingly
                    setSelectedOptions(newValue);
                  }
                }}
                value={selectedOptions}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={
                        {
                          // marginRight: 4,
                          // padding: "3px",
                          // lineHeight: "1",
                        }
                      }
                      checked={
                        selected ||
                        (option === "All" &&
                          selectedOptions?.length === userList?.length)
                      }
                    />
                    {option === "All" ? "All" : option?.user?.firstName}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    placeholder="User List"
                    value={selectedOptions}
                  // sx={{ width: { xl: "30vw", xs: "80vw",md:'30vw',lg:'30vw',sm:'40vw' } }}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  const allSelected =
                    JSON.stringify(tagValue) === JSON.stringify(userList); // Check if tagValue equals userList
                  return allSelected ? (
                    <Chip
                      size="small"
                      label="All"
                      {...getTagProps({ index: tagValue?.indexOf("All") })}
                      onDelete={() => {
                        const newSelectedOptions = tagValue.filter(
                          (option) => option === "All"
                        );
                        setSelectedOptions(newSelectedOptions);
                      }}
                    />
                  ) : (
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        label={
                          option === "All" ? "All" : option?.user?.firstName
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  );
                }}
              />

              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  sx={{ pt: 1, pr: 1 }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {selectedOptions
                    .slice(0, 3)?.map((option: CommunityMembers, index) => (
                      <Stack
                        key={index}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"center"}
                        sx={{ pt: 1, pr: 1 }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: deepOrange[500],
                            height: "30px",
                            width: "30px",
                            fontSize: "11px",
                          }}
                          alt="Remy Sharp"
                          src={option?.user?.avatar}
                        />
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: "16px" }}
                          >
                            {option?.user?.firstName}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "#565656" }}
                          >
                            {getStaticValue(option?.user?.role)}
                            <span
                              style={{
                                textDecoration: "underLine",
                                color: "#4578F9",
                                textDecorationColor: "#565656",
                              }}
                            ></span>
                          </Typography>
                        </Box>
                      </Stack>
                    ))}
                  {selectedOptions.length > 3 && (
                    <Typography
                      sx={{
                        fontSize: "11px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      +{selectedOptions.length - 3} more
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5} md={5} sm={3} sx={{ marginTop: "1vh" }}>
              <FormControl fullWidth size="small">
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount (in rupees)
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">₹</InputAdornment>
                  }
                  label="Amount (in rupees)"
                  type="number"
                  inputProps={{ min: 100 }}
                  name="amount"
                  size="small"
                  onChange={handleChanges}
                  value={paymentValues?.amount}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ pt: 1 }}>
            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Stack>
                <Stack>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      sx={{ fontSize: "15px", font: "Roboto" }}
                    >
                      Payment Cycle
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="Payment Cycle"
                      name="payment-cycle-radio"
                      value={recurring}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Recurring"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                            Recurring
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="One_time"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                            One Time
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Stack>
                  <FormControl
                    sx={{ maxWidth: 150, marginTop: 1 }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">
                      Recurring
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      disabled={recurring === "One_time"}
                      value={recurring}
                      label="Recurring"
                      onChange={handleChange}
                    >
                      {recurringDropdownOptions?.map((option) => (
                        <MenuItem
                          key={option.key}
                          value={option.value}
                          sx={{ fontSize: "13px" }}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Stack>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Notify on
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={handleChangeNotifyOption}
                    name="row-radio-buttons-group"
                    value={paymentValues.notify} // Set the default value here
                  >
                    <FormControlLabel
                      value="BOTH"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                          Both
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="SMS"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                          SMS
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="EMAIL"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                          Email
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "2vh" }}>
            <Grid item xs={12} lg={12} md={12} sm={10}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Payment Note"
                variant="outlined"
                name="description"
                onChange={handleChanges}
                value={paymentValues.description}
                placeholder="Type here"
                multiline
                rows={3}
                maxRows={10}
              />
            </Grid>
          </Grid>
          {errorText && <Typography style={{ color: 'red', fontSize: "11px" }}>{errorText}</Typography>}

          <Stack
            direction={"row"}
            spacing={2}
            sx={{ mt: "20px", justifyContent: "flex-end" }}
          >
            <Button
              size="large"
              variant="contained"
              disabled={!isDataAvailable}
              sx={{
                background:
                  "linear-gradient(to right bottom, #3C5AA0, #50A1CA)",

                textTransform: "capitalize",
                borderRadius: "10px",
                width: "170px",
                fontFamily: "Inter",
                ml: 1,
                color: "white",
                "&:hover": {
                  background:
                    "linear-gradient(to right bottom, #3C5AA0, #50A1CA)",
                  cursor: "pointer",
                  border: "none",
                },
              }}
              onChange={(e: any) => setPaymentValues(e.target.value)}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Send Request"
              )}
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};
