import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Close, TextsmsOutlined } from "@mui/icons-material";
import { useInvitations } from "../../../hooks/useInvitations";
import InvitesandJoinPreview from "../../ExploreCommunities/CommunityPreview/InvitesandJoinPreview";
import { USER_APP_BASE_URL } from "../../../configurations/url.config";
import { useSnackbar } from "notistack";
import { ICommunity } from "../../../models/communities.modal";
import { Invitations } from "../../../models/Invitation.model";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCommunity } from "../../../hooks/useCommunity";
import { loadSelectedCommunity } from "../../../store/slices/CommunitySlice";

interface InvitesAllCommunitiesProps {
  community: ICommunity; // Replace 'any' with the actual type of 'community'
  _id: string; // Assuming _id is a string
  message: string; //
  createdAt: string;
  // refetchData: () => void;
  invites:Invitations
}

const CommunityInvites: React.FC<InvitesAllCommunitiesProps> = ({
  community,
  _id,
  message,
  createdAt,
  invites,
  
}) => {
  const [link] = useState("");
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { joinInvitation } = useInvitations();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxPopWidth] = React.useState<DialogProps["maxWidth"]>("xs");
  const [, setLoading] = useState(false);

  // const { refetchData } = community;


  const navigate = useNavigate();
  const { getCommunityById } = useCommunity();

  // console.log(invites?.role, "Roles");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const formatDateRelative = (dateString: string): string => {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    if (daysDifference < 1) {
      if (hoursDifference < 1) {
        if (minutesDifference < 1) {
          return "Just Now";
        }
        return `${minutesDifference} minute${
          minutesDifference !== 1 ? "s" : ""
        } ago`;
      } else if (hoursDifference < 24) {
        if (hoursDifference === 1) {
          return "1 hour ago";
        }
        return `${hoursDifference} Hours ago`;
      } else {
        return "Today";
      }
    } else if (daysDifference === 1) {
      return "Yesterday";
    } else if (daysDifference === 2) {
      return "2 days ago";
    } else if (daysDifference === 3) {
      return "3 days ago";
    }
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return inputDate.toLocaleDateString(undefined, options);
  };

  const navigateToUserFlow = (id: string) => {
    const token = localStorage.getItem("access-token");
    const url = `${USER_APP_BASE_URL}/login?communityId=${id}&token=${token}`;
    window.open(url, "_blank");
  };

  const dispatch = useDispatch();

  const handleJoinClick = async (communityId: string, inviteId: string) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("community_id", communityId);
      formData.append("invites_id", inviteId);
      const response = await joinInvitation(formData);
      if (response && response.status === 200) {
        await getCommunityById(communityId);
        if(community?.collectSubscription ==='YES' ){
          if (invites?.role === 'USER') {
            navigateToUserFlow(communityId);
          } else if (invites?.role === 'ADMIN') {
            navigate(`/community/${communityId}/about`, { state: { id: communityId } })
            dispatch(loadSelectedCommunity(community));
          }
        }
        else if(community?.collectSubscription==='NO'){
          if (invites?.role === 'USER') {
            navigateToUserFlow(communityId);
            
          } else if (invites?.role === 'ADMIN') {
            navigate(`/community/${communityId}/about`, { state: { id: communityId } })
            dispatch(loadSelectedCommunity(community));
          }
        }
        
      } else {
        // console.log("Joined Community Failed");
      }
    }  catch (error) {}
  };

  const handlePreviewCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/communities/${community?._id}/about`;

    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
        enqueueSnackbar("Link copied successfully", { variant: "success" });
      })
      .catch((error) => {
        //console.error("Error copying text: ", error);
      });
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?._id}/about`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?._id}/about`
  )}`;

  // console.log(community, "Invities")

  return (
    <>
      <Grid container direction={"row"} sx={{display:'flex'}} spacing={3}>
        <Grid
          item
          xs={1.2}
          md={1.2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ width: 45, height: 45 }}
            alt="communnity-logo"
            src={community?.logo}
          />
        </Grid>
        <Grid item xs={6} md={5.5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#000000",
              fontFamily:'Inter'
            }}
          >
            {community?.name}{" "}
          </Typography>
          <Typography
            sx={{
              color: "#565656",
              fontSize: "12px",
              fontWeight: "500",
              fontFamily:'Inter'
            }}
          >
            {community?.city ? (
               <>
              {community?.city?.length > 20 ? `${community?.city?.slice(0, 20)}...` : community?.city}
              </>
          ) : (
            <>
              No city
            </>
          )} | {community?.members?.length} Members
          </Typography>
          <Typography sx={{ color: "#787878", fontSize: "12px", fontFamily:'Inter' }}>
            {formatDateRelative(createdAt)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          md={2.2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleJoinClick(community?._id, _id)}
            sx={{
              textTransform: "none",
              fontSize: "13px",
              backgroundColor: "#3C5AA0",
              color: "#ffffff",
              fontWeight: "500",
              fontFamily:'Inter',
              // ml: 5,
              // mr: 2,
              alignItems: "right",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "#3C5AA0",
              },
            }}
          >
            Join
          </Button>
        </Grid>
        <Grid
          item
          xs={2.8}
          md={1.5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Stack>
              <BootstrapTooltip title="Info" placement="top">
                <InfoOutlinedIcon
                  onClick={handlePreviewCommunity}
                  sx={{
                    color: "#838383",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                ></InfoOutlinedIcon>
              </BootstrapTooltip>
            </Stack>
            <Stack>
              <BootstrapTooltip title="Message" placement="top">
                <TextsmsOutlined
                  onClick={handleClickOpen}
                  sx={{
                    color: "#838383",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                ></TextsmsOutlined>
              </BootstrapTooltip>
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxPopWidth}
                open={open}
                onClose={handleClose}
              >
                <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ backgroundColor: "#F0F9FF" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        textDecoration: "none",
                        color: "#50A1CA",
                        marginLeft: 2,
                        fontFamily:'Inter'
                      }}
                    >
                      Invite Message
                    </Typography>
                    <Box
                      onClick={handleClose}
                      sx={{
                        backgroundColor: "#3C5AA0",
                        padding: "4px",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: "10px",
                        mt: "0.5px",
                        mr: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <Close sx={{ fontSize: "15px" }} />
                    </Box>
                  </Grid>
                </DialogActions>
                <DialogContent sx={{}}>
                  <Typography sx={{ fontSize: "13px", fontFamily:'Inter' }}>{message}</Typography>
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <InvitesandJoinPreview
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        logo={community?.logo}
        id={community?._id}
        title={community?.name}
        city={community?.city}
        members={community?.members.length || []}
        totalmembers={community?.totalMembers}
        status={community?.status}
        link={link}
        type={community?.type}
        description={community?.description}
        handleCopyLink={handleCopyLink}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={community?.banner}
      />
    </>
  );
};

export default CommunityInvites;
