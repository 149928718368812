import React, { useContext, useEffect } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  // SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPlan, savePlan, updatePlan } from "../../services/plans.service";
import { AuthContext, IAuthContext } from "../../contexts/Auth.context";
import { ICommunity } from "../../models/communities.modal";
import store from "../../store";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Loader from "../Loader/Loader";
import { IMultiMedia } from "../../models/multi-media.model";
import Close from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import "dayjs/locale/en"; // Import the English locale

dayjs.extend(utc);
//Asynchronous load on
interface Community {
  name: string;
  _id: string;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
//
export interface IAddPlan {
  name: string;
  duration: string;
  interval: number;
  startDate: string;
  endDate: boolean;
  status: string;
  billingFrequency: string[];
  totalPlanValue: number;
  offerValue: number;
  promoCode: string;
  description: string;
  summary: string;
  image: IMultiMedia | "";
  document: string;
  community: string;
  images: IMultiMedia | "";
  isOfferEnds: boolean;
  offerEndsAfter: number;
  offerEndsDuration: string;
  offerEndDate: string;
}
interface ISelectedCommunity {
  selectedCommunity: ICommunity[];
  closeDialog: () => void;
  loadPlans: () => void;
}

// duration dropdown values
const durationDropdownOptions: { key: string; value: string; label: string }[] =
  [
    { key: "YEAR", value: "Year", label: "Year" },
    { key: "MONTH", value: "Month", label: "Month" },
    { key: "WEEK", value: "Week", label: "Week" },
    { key: "DAY", value: "Day", label: "Day" },
  ];
const billingFrequencyOptions = [
  { key: "YEARLY", value: "Yearly", defaultChecked: false },
  { key: "HALF_YEARLY", value: "Half Yearly", defaultChecked: false },
  { key: "QUARTERLY", value: "Quarterly", defaultChecked: false },
  { key: "MONTHLY", value: "Monthly", defaultChecked: false },
  { key: "WEEKLY", value: "Weekly", defaultChecked: false },
  { key: "DAILY", value: "Daily", defaultChecked: false },
  { key: "ONE_TIME", value: "One Time", defaultChecked: false },
];

export const AddPlan: React.FC<ISelectedCommunity> = ({

  closeDialog,
  loadPlans,
}) => {
  const navigate = useNavigate();

  const state = store.getState();
  const communitiesFromStore = useSelector(() => {
    return state?.communities;
  });
  const { communities } = communitiesFromStore;
  const communityState = store.getState();
  const currentCommunity = communityState.selectedCommunity.selectedCommunity;

  const { id } = useParams();

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [community, setCommunity] = React.useState(false);
  const [options, setOptions] = React.useState<Community[]>([]);
  const loading = community && options.length === 0;
  //label change logic start
  const [durationFieldValue, setDurationFieldValue] = useState<string>("");

  const [labelText, setLabelText] = useState("Year");
  const [open, setOpen] = React.useState(false);
  const [opensPublish, setOpensPublish] = React.useState(false);
  const [planImages, setPlanImages] = useState<FileList | null>(null);
  const [planDocuments, setPlanDocuments] = useState<FileList | null>(null);
  const [error, setError] = useState(false);

  //attach file logic end
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState<Dayjs | null>(null);
    // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [plans, setPlans] = useState<IAddPlan[]>([]);
  // to load data from the server
  useEffect(() => {
    if (!id) return;
    const fetchPlan = async () => {
      const { data } = await getPlan(getAccessToken(), id);
      const labelValue: any = durationDropdownOptions.find(
        (e) => e.key === data.duration
      );
      setDurationFieldValue(labelValue?.value);
      let tempBF = bFrequency.map((b) =>
        data.billingFrequency.includes(b.key)
          ? { ...b, defaultChecked: true }
          : b
      );

      setBFrequency(tempBF);
      setPlanValues({
        ...planValues,
        name: data.name,
        duration: data.duration,
        interval: data.interval,
        status: data.status,
        offerValue: data.offerValue,
        totalPlanValue: data.totalPlanValue,
        description: data.description,
        summary: data.summary,
        community: data.community,
        image: data.image,
        images: data.images,
        document: data.document,
        startDate: formateStartDate(data?.startDate),
        billingFrequency: data.billingFrequency,
        isOfferEnds: data?.isOfferEnds ?? false,
        offerEndsAfter: data?.offerEndsAfter ?? 0,
        offerEndsDuration: data?.offerEndsDuration ?? " ",
        offerEndDate: data?.offerEndDate ?? " ",
      });
      //console.log(data.document.value, "Data");
    };

    fetchPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    let active = true;
    (async () => {
      let tempCommunity = communities.map((community) => {
        return {
          _id: community.id,
          name: community.name,
        };
      });
      await sleep(1e3); // For demo purposes.
      if (active) {
        setOptions([...tempCommunity]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (durationFieldValue) {
      const labelValue: any = durationDropdownOptions.find(
        (e) => e.value === durationFieldValue
      );
      setLabelText(`Set ${labelValue?.value}s`);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
   [durationFieldValue]);

  const [bFrequency, setBFrequency] = useState<
    { key: string; value: string; defaultChecked: boolean }[]
  >(billingFrequencyOptions);
  const [planValues, setPlanValues] = useState<IAddPlan>({
    name: "",
    duration: "",
    interval: 0,
    startDate: "",
    endDate: false,
    billingFrequency: [],
    status: "",
    totalPlanValue: 0,
    offerValue: 0,
    promoCode: "",
    description: "",
    summary: "",
    image: "",
    images: "",
    document: "",
    community: currentCommunity?._id ?? "",
    isOfferEnds: true,
    offerEndsAfter: 0,
    offerEndsDuration: "Year",
    offerEndDate: "",
  });

  const formateStartDate = (date: string) => {
    const startDate = date.slice(0, 10);
    setSelectedStartDate(dayjs.utc(startDate));
    return startDate;
  };

  
console.log(planValues)
  // Functions

  const handleMyDateChange = (e: any) => {
    // Create a new Date object from the user's input
    const selectedNewDate = new Date(e);
    const timezoneOffsetInMinutes = selectedNewDate.getTimezoneOffset();
    selectedNewDate.setMinutes(selectedNewDate.getMinutes() - timezoneOffsetInMinutes);

    const isoDateString = selectedNewDate.toISOString(); //to ISO 
  
    setPlanValues({ ...planValues, startDate: isoDateString });
  };
  
  
  const handleBillingFrequencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, name } = event.target;
  
    let tempBF = bFrequency.map((b) =>
      b.key === name ? { ...b, defaultChecked: checked } : { ...b, defaultChecked: false }
    );
  
    setBFrequency(tempBF);
  
    const selectedBF = tempBF.filter((b) => b.defaultChecked === true);
    const selectedBFKeys = selectedBF.map((b) => b.key);
    setPlanValues({ ...planValues, billingFrequency: selectedBFKeys });
  };  

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Ensure that offerValue does not exceed totalPlanValue
    if (name === "offerValue") {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue > planValues.totalPlanValue) {
        // Display an error, set an error state, or handle it in a way that makes sense for your application
        // console.error('Offer Price cannot exceed Actual Price');
        return;
      }
    }

    setPlanValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setError(value === "");
  };

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const handleSubmit = async (e: any, status: string) => {
    // console.log(planValues,"values");
    setIsSubmitClicked(true)
    e.preventDefault();
    planValues.community = currentCommunity?._id ?? "";
    planValues.status = status;
    
    try {
      setLoader(true); // Set loading to true when initiating the request
      if (id) {
        // Editing an existing plan
        const updateResponse = await updatePlan(
          id,
          getAccessToken(),
          planValues,
          planImages,
          planDocuments
        );
        if (updateResponse.data && updateResponse.status === 200) {
          enqueueSnackbar("Plan updated successfully", { variant: "success" });
          navigate("/plans");
          closeDialog();
        } else {
          enqueueSnackbar("Failed to update plan", { variant: "error" });
        }
      } if(planValues?.startDate) {
        const createResponse = await savePlan(
          currentCommunity?._id ?? "",
          getAccessToken(),
          planValues,
          planImages,
          planDocuments
        );

        // Set loading to true when initiating the request

        setLoader(true); // Set loading to true when initiating the request
        console.log(createResponse.data)
        if (createResponse.data ) {
          setPlans((prevPlans) => [...prevPlans, createResponse.data]);
          enqueueSnackbar("Plan created successfully", { variant: "success" });
          // navigate("/plans");
          closeDialog();
          loadPlans();
          setLoader(true);
        } else {
          enqueueSnackbar("Failed to create plan", { variant: "error" });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoader(false);
    }
  };

  const [checked] = useState<boolean>(false);
  // const handleCheck = (e: any) => {
  //   setChecked(e.target?.checked);
  // };

  //attach file new logic start
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const handleCommunitySelection = (event: any) => {
    setPlanValues({ ...planValues, community: event });
  };

  const handleDurationChange = (e: any) => {
    setPlanValues({ ...planValues, duration: e });
  };

  const handlePlanImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanImages(e.target.files);
    }
  };

  const handlePlanDocuments = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanDocuments(e.target.files);
    }
  };

  const handleClickOpenImg = () => {
    setOpen(true);
  };

  const handleClosesImg = () => {
    setOpen(false);
  };

  const handleClickOpenPublish = () => {
    setOpensPublish(true);
  };

  const handleClickClosesPublish = () => {
    setOpensPublish(false);
  };

  // const handleYesOrNo = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPlanValues({
  //     ...planValues,
  //     isOfferEnds: event.target.value === "true",
  //   });
  // };

  // const handleOfferEndsDuration = (
  //   event: SelectChangeEvent<string>,
  //   child: React.ReactNode
  // ) => {
  //   setPlanValues({ ...planValues, offerEndsDuration: event.target.value });
  // };

  const locations = useLocation();
  const IsTitleDisplay = locations.pathname.includes("/plans/") && id;

  const isDataAvailable =
    planValues.duration &&
    planValues.billingFrequency &&
    planValues.description &&
    planValues.totalPlanValue &&
    planValues.interval &&
    selectedStartDate !== null &&
    planValues.startDate;

   
console.log(planValues);
  return (
    <div>
      {loading ? (
        <Loader /> // Replace with your loading indicator component
      ) : (
        <Stack direction={"column"} spacing={1}>
          <Stack sx={{ display: IsTitleDisplay ? "block" : "none" }}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                color: "#3760A9",
                fontFamily: "Inter",
              }}
            >
              {id ? "Publish" : "Create"} a Plans
            </Typography>
          </Stack>

          <>
            <Box>
              <Grid container sx={{ borderRadius: "none" }}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      pb: 0.5,
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                  >
                    <Stack sx={{ pt: 0.7 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          ml: 1,
                          fontSize: "13px",
                          color: "#2653A3",
                          fontWeight: "500",
                          fontFamily: "Inter",
                          pl: 0.5,
                          pr: 0.5,
                        }}
                      >
                        Community
                      </Typography>

                      <Stack sx={{ m: 1, pt: 0.5, pl: 0.5, pr: 0.5 }}>
                        <Autocomplete
                          size="small"
                          id="asynchronous-demo"
                          fullWidth
                          open={community}
                          onOpen={() => {
                            setCommunity(true);
                          }}
                          onClose={() => {
                            setCommunity(false);
                          }}
                          onChange={(e, v) =>
                            handleCommunitySelection(v?._id ?? "")
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.name === value.name
                          }
                          getOptionLabel={(option) => option.name}
                          options={options}
                          loading={loading}
                          value={currentCommunity}
                          disabled
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Communities"
                              placeholder="Type and search communities"
                              sx={{ width: "100%", fontSize: "11px" }}
                              InputLabelProps={{
                                style: { fontSize: "13px", color: "#565656" },
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 2, mt: 2, borderRadius: "none" }}>
                <Grid item xs={12}>
                  <Paper
                    sx={{ pb: 0.5, boxShadow: "0px 0px 4px 0px #00000040" }}
                  >
                    <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          ml: 1,
                          fontSize: "13px",
                          color: "#2653A3",
                          fontWeight: "500",
                          fontFamily: "Inter",
                        }}
                      >
                        Name
                      </Typography>

                      <TextField
                        required
                        name="name"
                        label="Name"
                        size="small"
                        onChange={handleChanges}
                        value={planValues.name}
                        // InputProps={{ style: { height: "45px" } }}
                        InputLabelProps={{
                          style: { fontSize: "13px", color: "#565656" },
                        }}
                        sx={{ mt: 1, ml: 1, mr: 1, mb: 1, fontSize: "13px" }}
                      ></TextField>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                direction="row"
                sx={{ marginBottom: 1 }}
              >
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Stack direction={"row"}>
                    <Paper
                      sx={{
                        pt: "10px",
                        pl: "13px",
                        pr: "10px",
                        pb: "12px",
                        border: "none",
                        boxShadow: "0px 0px 4px 0px #00000040",
                      }}
                    >
                      <Stack sx={{ pl: 0.5, pr: 0.5 }}>
                        <Typography
                          variant="h5"
                          sx={{
                            fontSize: "13px",
                            color: "#2653A3",
                            fontWeight: "500",
                          }}
                        >
                          Duration
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "12px" }}>
                          Set your plan duration.
                        </Typography>
                      </Stack>{" "}
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item sm={6} xs={6} md={6} lg={6} xl={6}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Duration
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="duration"
                              label="Plan Duration"
                              name="duration"
                              disabled={checked}
                              // style={{ height: "45px",marginTop:1}}

                              onChange={
                                (e) => {
                                  setDurationFieldValue(e.target.value);
                                }
                                // setLabelText(e.target.value) }
                              }
                              value={durationFieldValue}
                            >
                              {durationDropdownOptions.map((option) => (
                                <MenuItem
                                  key={option.key}
                                  value={option.value}
                                  onClick={(e) =>
                                    handleDurationChange(option.key)
                                  }
                                >
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          <TextField
                            name="interval"
                            id="interval"
                            label={labelText}
                            variant="outlined"
                            size="small"
                            disabled={checked}
                            fullWidth
                            onChange={handleChanges}
                            type="number"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            value={
                              planValues.interval !== 0
                                ? planValues.interval
                                : " "
                            }
                          />
                        </Grid>
                        <label htmlFor="otherField"></label>

                        <Grid item sm={6} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DesktopDatePicker"]}
                              sx={{ overflow: "hidden" }}
                            >
                              <DatePicker
                                value={selectedStartDate}
                                label="Starts from"
                                disablePast
                                onChange={handleMyDateChange}
                                sx={{
                                  fontSize: "12px",
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    fullWidth: true,
                                    sx: {
                                      "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error":
                                        {
                                          // Styling for the label
                                          color: "#00000061",
                                        },
                                      "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                        {
                                          // Styling for the input box
                                          borderColor: "green",
                                          border: "1px solid",
                                        },
                                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                                        {
                                          color: "#92929D",
                                          fontSize: "13px",
                                        },
                                    },
                                  },
                                }}
                              />
                            </DemoContainer>
                            {isSubmitClicked && !planValues?.startDate && <Typography style={{ color: 'red',fontSize:"8px" }}>Start date is required!</Typography> }
                          </LocalizationProvider>
                        </Grid>
                        {/* <Grid item sm={5} xs={12}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox sx={{ transform: "scale(1.1)", color: '#92929D' }} />
                              }
                              onChange={(e) => handleCheck(e)}
                              label="Mark as never ends"

                              sx={{
                                fontSize: "10px",
                                '& .MuiFormControlLabel-label': {
                                  // Add styles for the label using inputProps

                                  fontSize: { xs: "13px", md: '13px' },
                                }
                              }}
                            />
                          </FormGroup>
                        </Grid> */}
                      </Grid>
                    </Paper>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Stack direction={"row"}>
                    <Paper
                      sx={{ p: "10px", boxShadow: "0px 0px 4px 0px #00000040" }}
                    >
                      <Stack sx={{ pl: 0.5, pr: 0.5 }}>
                        <Typography
                          variant="h5"
                          sx={{
                            fontSize: "13px",
                            color: "#2653A3",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Billing Frequency
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ marginTop: "1vh", fontSize: "12px" }}
                        >
                          Choose the option for how often you want to bill your
                          members.
                        </Typography>
                      </Stack>

                      <Grid container>
                        {bFrequency.map((Option) => (
                          <Grid
                            item
                            sm={4}
                            xs={6}
                            md={4}
                            lg={4}
                            xl={4}
                            sx={{ pl: 0.5, pr: 0.5 }}
                          >
                            <FormGroup>
                              <FormControlLabel
                                key={Option.key}
                                value={Option.value}
                                label={Option.value}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: {
                                      xs: "13px",
                                      md: "2.6vh",
                                      lg: "2vh",
                                      xl: "2vh",
                                    },
                                  },
                                }}
                                control={
                                  <Checkbox
                                    required
                                    checked={Option.defaultChecked}
                                    // checked={planValues.billingFrequency.includes(Option.key)}
                                    onChange={handleBillingFrequencyChange}
                                    value={Option.key}
                                    name={Option.key}
                                    id={Option.key}
                                    style={{
                                      transform: "scale(1.2)",
                                    }}
                                    sx={{ fontSize: { xs: "8px", md: '5px' }, pt: 1, color: '#92929D' }}
                                  />
                                 
                                }
                                // label={Option.value}
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 1, mt: 1, pt: 1 }}>
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{
                      height: "220px",
                      pl: 0.5,
                      pr: 0.5,
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                  >
                    <Stack sx={{ pt: 1 }}>
                      <Typography
                        sx={{
                          ml: 1,
                          fontSize: "13px",
                          color: "#2653A3",
                          fontWeight: "500",
                          fontFamily: "Inter",
                        }}
                      >
                        Description
                      </Typography>
                      <TextField
                        name="description"
                        id="description"
                        placeholder="Describe your plan in short words..."
                        label="Description"
                        size="medium"
                        value={planValues.description}
                        onChange={handleChanges}
                        required
                        multiline
                        rows={2}
                        InputLabelProps={{
                          style: { fontSize: 13, color: "#565656" },
                        }}
                        sx={{ mt: 1, ml: 1, mr: 1 }}
                      ></TextField>
                      <TextField
                        placeholder="Highlight key features about plan."
                        label="Summary"
                        name="summary"
                        id="summary"
                        onChange={handleChanges}
                        size="medium"
                        required
                        multiline
                        value={planValues.summary}
                        rows={2}
                        InputLabelProps={{
                          style: { fontSize: "13px", color: "#565656" },
                        }}
                        sx={{ mt: 1, ml: 1, mr: 1, fontSize: "13px" }}
                      ></TextField>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 1, mt: 1, pt: 1 }}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <Card
                    sx={{
                      pl: 0.5,
                      pr: 0.5,
                      pb: { xs: 2, md: 0.5 },
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                  >
                    <Stack sx={{ pt: 1, pl: 1 }}>
                      <Typography
                        sx={{
                          ml: 1,
                          fontSize: "13px",
                          color: "#2653A3",
                          fontWeight: "500",
                          fontFamily: "Inter",
                        }}
                      >
                        Pricing
                      </Typography>
                      <Typography
                        sx={{
                          ml: 1,
                          fontSize: "12px",
                          // color: "#292D32",
                        }}
                      >
                        Choose the discount price and original price for the
                        plan (Note: The discount is a fixed value deducted from
                        the actual value you entered, not a percentage).
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ ml: 2, mr: 2, mt: 2, mb: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            fullWidth
                            onChange={handleChanges}
                            name="totalPlanValue"
                            id="totalPlanValue"
                            value={
                              planValues.totalPlanValue !== 0
                                ? planValues.totalPlanValue
                                : " "
                            }
                            label="Actual Price"
                            size="small"
                            type="number"
                            InputLabelProps={{
                              style: { fontSize: 17, color: "#565656" },
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyRupeeIcon
                                    sx={{
                                      backgroundColor: "#D9D9D9",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                              // style: { height: "45px" },
                            }}
                          ></TextField>
                          <TextField
                            fullWidth
                            name="offerValue"
                            id="offerValue"
                            value={
                              planValues.offerValue !== 0
                                ? planValues.offerValue
                                : " "
                            }
                            onChange={handleChanges}
                            label="Offer Price"
                            size="small"
                            type="number"
                            sx={{ display: { xs: "block", md: "none" }, mt: 2 }}
                            InputLabelProps={{
                              sx: { fontSize: 17, color: "#565656" },
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyRupeeIcon
                                    sx={{
                                      backgroundColor: "#D9D9D9",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                              // style: { height: "45px" },
                            }}
                          ></TextField>
                          {/* <Stack sx={{ mt: 1, pb: 1 }}>
                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label">
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    color: "#2653A3",
                                    fontWeight: "500",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Do you want to continue with the offer price
                                  until the end?
                                </Typography>
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="yes"
                                name="radio-buttons-group"
                                value={planValues?.isOfferEnds}
                                onChange={handleYesOrNo}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label={
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "black",
                                      }}
                                    >
                                      Yes, continue with the offer price for all
                                      cycles.
                                    </span>
                                  }
                                />
                                <Box display="flex" alignItems="center">
                                  <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label={
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "black",
                                        }}
                                      >
                                        No, switch to regular (actual) price after
                                      </span>
                                    }
                                  />
                                  <TextField
                                    name="offerEndsAfter"
                                    size="small"
                                    type="number"
                                    label="Offer Ends After"
                                    disabled={planValues?.isOfferEnds} // Disable when Yes is selected

                                    value={planValues?.offerEndsAfter !== 0 ? planValues?.offerEndsAfter : " "}
                                    onChange={handleChanges}
                                    sx={{
                                      marginRight: "5px",
                                      width: "17%",
                                      p: 0,
                                      ml: -1,
                                      fontSize: "10px",
                                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                                      {
                                        padding: "5px",
                                        fontSize: "13px",
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        padding: "0px",
                                        fontSize: "10px",
                                      },
                                    }}
                                    InputProps={{
                                      inputProps: { min: 0 },
                                    }}
                                  />

                                  <FormControl sx={{ m: 1, minWidth: 95 }}>
                                    <Select
                                      value={planValues?.offerEndsDuration}
                                      onChange={handleOfferEndsDuration}
                                      displayEmpty
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      disabled={planValues?.isOfferEnds} 
                                      sx={{ marginLeft: "5px", fontSize: "13px", "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": { padding: "5px", textAlign: 'center' } }}
                                    >
                                      {["Year", "Months", "Weeks", "days"].map((item) => (
                                        <MenuItem sx={{ fontSize: "13px" }} key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      ))}

                                    </Select>

                                  </FormControl>
                                </Box>
                              </RadioGroup>
                            </FormControl> */}

                          {/* <RadioGroup name="use-radio-group" value={planValues.isOfferEnds} >
                              <MyFormControlLabel value="yes" label={<span style={{ fontSize: '13px', color: 'black' }}>Yes, continue with the offer price for all cycles.</span>} control={<Radio />} sx={{ color: 'black', fontSize: '14px', fontWeight: '500', fontFamily: 'Inter' }} />
                              <Box display="flex" alignItems="center">
                                <MyFormControlLabel value="no" label={<span style={{ fontSize: '13px', color: 'black' }} >No, switch to regular (actual) price after</span>} control={<Radio />} />
                                <TextField
                                  name="offerEndsAfter"
                                  size="small"
                                  type="number"
                                  label="No"
                                  
                                  value={planValues?.offerEndsAfter}
                                  onChange={handleChanges}
                                  sx={{
                                    marginRight: "5px", width: "10%", p: 0, ml: -1, fontSize: "13px",
                                    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                                      padding: "5px",
                                      fontSize: "13px",
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      padding: "0px",
                                      fontSize: '10px'
                                    },
                                  }}
                                  InputProps={{
                                    inputProps: { min: 0, },
                                  }}
                                />
                                <FormControl fullWidth size="small" sx={{width:'20%',p: 0}}>
                                  <InputLabel id="demo-simple-select-label" sx={{fontSize:'12px'}}>
                                    Duration
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="duration"
                                    label="Plan Duration"
                                    name="offerEndsDuration"
                                   
                                    // style={{ height: "45px",marginTop:1}}
                                    sx={{
                                      fontSize:'13px',
                                      '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                                        padding:"5px",
                                      }
                                    }}
                                    onChange={
                                      (e) => {
                                      //  setOfferEndsDurationFieldValue(e.target.value);
                                      }
                                      // setLabelText(e.target.value) }
                                    }
                                    //value={offerEndsdurationFieldValue}
                                  >
                                  
                                  </Select>
                                </FormControl>
                              </Box>
                            </RadioGroup> */}
                          {/* </Stack> */}
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                          <TextField
                            fullWidth
                            name="offerValue"
                            id="offerValue"
                            value={
                              planValues.offerValue !== 0
                                ? planValues.offerValue
                                : " "
                            }
                            onChange={handleChanges}
                            label="Offer Price"
                            size="small"
                            type="number"
                            sx={{ display: { xs: "none", md: "block" } }}
                            InputLabelProps={{
                              style: { fontSize: 17, color: "#565656" },
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyRupeeIcon
                                    sx={{
                                      backgroundColor: "#D9D9D9",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                              // style: { height: "45px" },
                            }}
                            error={error}
                            helperText={error ? "Offer Price is required" : ""}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 1 }}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <Card
                    sx={{
                      mt: 1,
                      pb: 2,
                      pl: 1,
                      boxShadow: "0px 0px 4px 0px #00000040",
                    }}
                  >
                    <Stack sx={{ pt: 1 }}>
                      <Typography
                        sx={{
                          ml: 1,
                          fontSize: "13px",
                          color: "#2653A3",
                          fontWeight: "500",
                          fontFamily: "Inter",
                        }}
                      >
                        Attachments
                      </Typography>
                      <Typography
                        sx={{
                          ml: 1,
                          fontSize: "12px",
                          // color: "#292D32",
                        }}
                      >
                        Select an image or document that provides additional
                        information about the plan details and benefits.
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ ml: 2, mr: 2, mt: 2 }}>
                      <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                          <input
                            type="file"
                            name="images"
                            onChange={handlePlanImages}
                            // value={planValues?.images ? planValues?.images?.label : ''}
                          ></input>

                          {planValues?.image && (
                            <Button
                              variant="outlined"
                              onClick={handleClickOpenImg}
                              sx={{
                                textTransform: "capitalize",
                                mt: { xs: 2, md: 0 },
                                fontSize: "10px",
                              }}
                            >
                              Preview Image
                            </Button>
                          )}
                          {/* Pop Up for About us Content */}
                          <Dialog
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            open={open}
                            onClose={handleClosesImg}
                          >
                            <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                sx={{ backgroundColor: "#F0F9FF" }}
                              >
                                <Stack></Stack>
                                <Stack></Stack>
                                <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                                  <Box
                                    onClick={handleClosesImg}
                                    sx={{
                                      backgroundColor: "#3C5AA0",
                                      padding: "4px",
                                      borderRadius: "50%",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "white",
                                      fontSize: "10px",
                                      mt: "0.5px",
                                      mr: "10px",
                                      cursor: "pointer",
                                      "&:hover": {
                                        // backgroundColor: "#50A1CA", // Background color on hover
                                        cursor: "pointer",
                                        border: "none",
                                      },
                                    }}
                                  >
                                    <Close sx={{ fontSize: "13px" }} />
                                  </Box>
                                </Stack>
                              </Stack>
                            </DialogActions>

                            <DialogContent>
                              <img
                                alt=""
                                // onClick={() => navigate(`/plans/${plan?._id}`)}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: "300px",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                                src={
                                  planValues?.images
                                    ? planValues?.images?.value
                                    : " "
                                }
                              ></img>
                            </DialogContent>
                          </Dialog>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6} xl={6}>
                          <input
                            type="file"
                            name="documents"
                            onChange={handlePlanDocuments}
                          ></input>

                          {planValues?.document ? (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              href={
                                typeof planValues?.document === "object" &&
                                "value" in planValues?.document
                                  ? (planValues?.document as { value: string })
                                      .value
                                  : ""
                              }
                            >
                              <Tooltip
                                title="View Document"
                                placement="right-start"
                              >
                                <IconButton>
                                  <CloudDownloadOutlinedIcon
                                    sx={{ cursor: "pointer", color: "#2952A2" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </a>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 4 }}>
                <Grid item xs={12} md={12}>
                  <Stack direction="row" spacing={5}>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        background:
                          "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                        textTransform: "capitalize",
                        border: "none",
                        borderRadius: "10px",
                        boxShadow: "none",
                        color: "white",
                        "&:hover": {
                          background:
                            "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                          cursor: "pointer",
                          color: "white",
                          border: "none",
                          boxShadow: "none",
                        },
                      }}
                      onChange={(e: any) => setPlanValues(e.target.value)}
                      // onClick={handleSubmit}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      onClick={(e) => handleSubmit(e, "CREATED")}
                      disabled={!isDataAvailable &&!planValues.startDate}
                    >
                      {/* <Typography
                        sx={{ color: "white", textTransform: "capitalize" }}
                        
                      >
                        {" "}
                       
                      </Typography> */}
                      {id ? "Update" : "Create"}
                    </Button>

                    <Button
                      variant="outlined"
                      size="large"
                      sx={{
                        textTransform: "capitalize",
                        // fontWeight:600,
                        color: "#50A1CA",
                        border: "1px solid #50A1CA",
                        borderRadius: "10px",
                        boxShadow: "none",
                        "&:hover": {
                          cursor: "pointer",
                          border: "1px solid #50A1CA",
                          boxShadow: "none",
                        },
                      }}
                      onClick={handleClickOpenPublish}
                      // disabled={!isDataAvailable &&!planValues.startDate}
                    >
                      {/* <Typography
                        sx={{
                          color: "#50A1CA",
                          textTransform: "capitalize",
                          "&:hover": {
                            color: "#50A1CA",
                          },
                        }}
                      >
                        Publish
                      </Typography> */}
                      Publish
                    </Button>

                    {/* Pop Up for About us Content */}
                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={opensPublish}
                      onClose={handleClickClosesPublish}
                    >
                      <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ backgroundColor: "#F0F9FF" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: 500,
                              textDecoration: "none",
                              color: "#3C5AA0",

                              marginLeft: 2,
                            }}
                          >
                            {"Publish the Plan?"}
                          </Typography>
                          <Box
                            onClick={handleClickClosesPublish}
                            sx={{
                              backgroundColor: "#3C5AA0",
                              padding: "4px",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              fontSize: "10px",
                              mt: "0.5px",
                              mr: "10px",
                              cursor: "pointer",
                              "&:hover": {
                                cursor: "pointer",
                                border: "none",
                              },
                            }}
                          >
                            <Close sx={{ fontSize: "15px" }} />
                          </Box>
                        </Grid>
                      </DialogActions>

                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          sx={{ fontSize: "13px", textAlign: "center" }}
                        >
                          Once you publish your subscription plan, you cannot
                          make any more changes to it. This is to be transparent
                          and build trust with subscribers. Are you ready to
                          publish the plan?.
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button
                          onChange={(e: any) => setPlanValues(e.target.value)}
                          // onClick={handleSubmit}
                          onClick={(e) => handleSubmit(e, "ACTIVE")}
                          size="large"
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                            textTransform: "capitalize",
                            border: "none",
                            borderRadius: "10px",
                            boxShadow: "none",
                            color: "white",
                            "&:hover": {
                              background:
                                "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                              cursor: "pointer",
                              color: "white",
                              border: "none",
                              boxShadow: "none",
                            },
                          }}
                        >
                          Publish
                        </Button>
                        <Button
                          size="large"
                          sx={{
                            textTransform: "capitalize",
                            // fontWeight:600,
                            color: "#50A1CA",
                            border: "1px solid #50A1CA",
                            borderRadius: "10px",
                            boxShadow: "none",
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid #50A1CA",
                              boxShadow: "none",
                            },
                          }}
                          onClick={handleClickClosesPublish}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </>
        </Stack>
      )}
    </div>
    // </DialogContent>
    // </Dialog> */}
  );
};
