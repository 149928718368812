import { useContext } from "react";
import { addCourseSection, fetchCourseByCourseId, fetchCourseByCommunityId,
   saveCourse, updateCourseByCourseId, deleteCourseSections, updateCourseSections, deleteCourse, updateCourseByCourse, updateDocumentName, deleteCourseDocumentById, 
   deleteSectionDocumentById} from "../services/courses.service";
import { useSnackbar } from "notistack";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";

export const useCourses = () => {

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

const createNewCourse = async (id:string,formData: any, files:FileList | null,
    documents:FileList | null) => {
    try {
      const response = await saveCourse(id,getAccessToken(), formData,files,documents);
      if (response.status === 200) {
        enqueueSnackbar("Course created", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Couldn't create course", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't create course", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };
  const courseListByCommunityId = async (id:string) => {
    try {
      const response = await fetchCourseByCommunityId(getAccessToken(),id);
      if (response.status === 200) {
        return response.data ;
        //enqueueSnackbar("Course created", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Couldn't fetch course", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't fetch course", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

  const getCourseByCourseId = async (communityId:string,id:string) => {
     try {
       const response = await fetchCourseByCourseId(getAccessToken(),communityId,id);
     
       if (response && response.status === 200) {
         return response.data ;
       } else {
         enqueueSnackbar("Couldn't get course", { variant: "error", autoHideDuration: 3000 });
       }
       return response;
     } catch (error) {
       enqueueSnackbar("Couldn't get course", {
         variant: "error",
         autoHideDuration: 3000
       });
     }
   };

   const addCourseSectionByCourseId = async (communityId:string,id: string, formData: any) => {
    try {
      // setIsLoading(true);
      const response = await addCourseSection(getAccessToken(), communityId,id, formData);
      
      if (response?.status === 200) {
        // setCourses(response?.data);
        enqueueSnackbar("Course Section Created", { variant: "success", autoHideDuration: 3000 });
        return response.data;
      } else {
        enqueueSnackbar("Couldn't create course section", { variant: "error", autoHideDuration: 3000 });
      } return response;
    } catch {
      enqueueSnackbar("Couldn't create course section", { variant: "error", autoHideDuration: 3000 });
    } finally {
      // setIsLoading(false);
    }
  };

  const UpdateCourseByCourseId = async (community:string,id:string,formData: any, files:FileList | null,
    documents:FileList | null) => {
    try {
      const response = await updateCourseByCourseId(community,id,getAccessToken(), formData,files,documents);
    
      // console.log(response.data)
      if (response.status === 200) {
        // setCourses((prevPlans) => [...prevPlans, response.data]);
        enqueueSnackbar("Course updated", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Couldn't updated", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't updated", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

  const updateCourse = async (communityId:string,id: string, formData: any) => {
    try {
      // setIsLoading(true);
      const response = await updateCourseByCourse(getAccessToken(), communityId,id, formData);
      
      if (response?.status === 200) {
        // setCourses(response?.data);
        enqueueSnackbar("Course Section updated", { variant: "success", autoHideDuration: 3000 });
        return response.data;
      } else {
        enqueueSnackbar("Couldn't updated course section", { variant: "error", autoHideDuration: 3000 });
      } return response;
    } catch {
      enqueueSnackbar("Couldn't updated course section", { variant: "error", autoHideDuration: 3000 });
    } finally {
      // setIsLoading(false);
    }
  };

  const deleteSections = async(communityId:string,courseId:string,id: string,)=>{
    try{
      const response =await deleteCourseSections(getAccessToken(), communityId,courseId,id);
      if (response?.status === 200) {
        enqueueSnackbar("Course Section Deleted", { variant: "success", autoHideDuration: 3000 });
        return response.data;
      } else {
        enqueueSnackbar("Couldn't delete course section", { variant: "error", autoHideDuration: 3000 });
      } 
      return response;
    }
    catch {
      enqueueSnackbar("Couldn't delete course section", { variant: "error", autoHideDuration: 3000 });
    }
  }
  const updateCourseSectionsById = async (communityId:string,courseId:string,id: string, formData: any) => {
    try {
      // setIsLoading(true);
      const response = await updateCourseSections(getAccessToken(), communityId, courseId,id,formData);
      
      if (response?.status === 200) {
        // setCourses(response?.data);
        enqueueSnackbar("Course Section updated", { variant: "success", autoHideDuration: 3000 });
        return response.data;
      } else {
        enqueueSnackbar("Couldn't update course section", { variant: "error", autoHideDuration: 3000 });
      } return response;
    } catch {
      enqueueSnackbar("Couldn't update course section", { variant: "error", autoHideDuration: 3000 });
    } finally {
      // setIsLoading(false);
    }
  };

  // const addCourseDetails = async (id: string, formData: any) => {
  //   try {
  //     const response = await addCoursesDetail(getAccessToken(), id, formData);
  //     if (response?.status === 200) {
  //       enqueueSnackbar("Course Created", { variant: "success", autoHideDuration: 3000 });
  //       return response.data;
  //     } else {
  //       enqueueSnackbar("Couldn't create course", { variant: "error", autoHideDuration: 3000 });
  //     } return response;
  //   } catch {
  //     enqueueSnackbar("Couldn't create course", { variant: "error", autoHideDuration: 3000 });
  //   } 
  // };

  const deleteCourseByIds = async (communityId: string,courseId:string) => {
    try {
      const response = await deleteCourse(getAccessToken(), communityId,courseId);
      if (response.status) {
       
         enqueueSnackbar("Course deleted", { variant: "success", autoHideDuration: 3000 });
        return response;
      } else {
        // enqueueSnackbar("Couldn’t delete Course", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      // enqueueSnackbar("Couldn’t delete Course", {
      //   variant: "error",
      //   autoHideDuration: 3000
      // });
    }
  };

  const updateDocumentByDocumentId = async (communityId:string,id: string, formData: any) => {
    try {
      // setIsLoading(true);
      const response = await updateDocumentName(getAccessToken(), communityId,id,formData);
      
      if (response?.status === 200) {
        // setCourses(response?.data);
        enqueueSnackbar("Course Section updated", { variant: "success", autoHideDuration: 3000 });
        return response.data;
      } else {
        enqueueSnackbar("Couldn't update course section", { variant: "error", autoHideDuration: 3000 });
      } return response;
    } catch {
      enqueueSnackbar("Couldn't update course section", { variant: "error", autoHideDuration: 3000 });
    } finally {
      // setIsLoading(false);
    }
  };

  const deleteDocumentById = async (communityId: string,courseId:string,docId:string) => {
    try {
      const response = await deleteCourseDocumentById(getAccessToken(), communityId,courseId,docId);
      if (response.status) {
       
         enqueueSnackbar("document deleted", { variant: "success", autoHideDuration: 3000 });
        return response;
      } else {
        // enqueueSnackbar("Couldn’t delete Course", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      // enqueueSnackbar("Couldn’t delete Course", {
      //   variant: "error",
      //   autoHideDuration: 3000
      // });
    }
  };

  const deleteSectionDocument = async (communityId: string,secId:string,id:string) => {
    try {
      const response = await deleteSectionDocumentById(getAccessToken(), communityId,secId,id);
      if (response.status) {
       
         enqueueSnackbar("document deleted", { variant: "success", autoHideDuration: 3000 });
        return response;
      } else {
        enqueueSnackbar("Couldn’t delete Course", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      // enqueueSnackbar("Couldn’t delete Course", {
      //   variant: "error",
      //   autoHideDuration: 3000
      // });
    }
  };

  return {
    createNewCourse,
    courseListByCommunityId,
    getCourseByCourseId,
    addCourseSectionByCourseId,
    UpdateCourseByCourseId,
    updateCourse,
    deleteSections,
    updateCourseSectionsById,
    // addCourseDetails
    deleteCourseByIds,
    updateDocumentByDocumentId,
    deleteDocumentById,
    deleteSectionDocument
  };
};