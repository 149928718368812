import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  IconButton,
  Box,
  Dialog,
  Grid,
  DialogContent,
  Tooltip,
  Stack,
  Divider,
  Popover,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PaymentForm from "../../components/Payments/PaymentForm";
import { BankAccInterface } from "../../models/BankAcc.models";
import { useBankAccounts } from "../../hooks/useBankAccounts";
import Loader from "../Loader/Loader";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { getStaticValue } from "../../utils/StaticValues";
import { EditIcon } from "../../assets/icons";
import { IColumn } from "../../models/table.model";
import { CustomTableHeader } from "../tables/components/CustomTableHeader";
import { GetColor } from "../tables/GetColor";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CommentsPopUp from "../sub-components/popup/CommentsPopup";
import { SubNavbar } from "../Layout/components/SubNavbar";
interface PaymentProps {
  openMode: boolean;
}
const PaymentList: React.FC<PaymentProps> = ({ openMode }) => {
  const [openModal, setOpenModal] = useState(false);
  const [bankDetails, setBankDetails] = useState<BankAccInterface[]>([]);
  const [tableData, setTableData] = useState<BankAccInterface | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, row: BankAccInterface) => {
    setAnchorEl(event.currentTarget);
    setTableData(row);
  };

  const handlePopoverClose = () => {
    setTableData(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    isLoading,
    getAllMyBankAccounts,
    deleteBankDetailsId,
    getBankDetailById,
  } = useBankAccounts();
  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [bankDetailByCommunityId, setBankDetailsByCommunityId] = useState<BankAccInterface[]>([])

  const communitySelected = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const fetchMyBankDetails = async () => {
    try {
      const response = await getAllMyBankAccounts();
      setBankDetails(response.data);

    } catch (error) {
      console.error("Error fetching Bank details:", error);
    }
  };


  const fetchBankDetailsById = async () => {
    try {
      if (communitySelected?._id) {
        const response = await getBankDetailById(communitySelected?._id);
        if (response && response.data?.length > 0) {
          setBankDetails(response.data);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  useEffect(() => {
    if (openMode) {
      fetchBankDetailsById();
    } else {
      fetchMyBankDetails();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(() => {
    const fetchBankDetailsById = async () => {
      try {
        if (communitySelected?._id) {
          const response = await getBankDetailById(communitySelected?._id);
          if (response && response.data?.length > 0) {
            setBankDetailsByCommunityId(response.data)
          }
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchBankDetailsById()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [communitySelected?._id]);

  const handleAddDetailsClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTableData(null);
  };

  const handleEdit = (adminData: BankAccInterface) => {
    // Open the modal
    setOpenModal(true);
    setTableData(adminData);
  };

  //delete Bank details
  const [deleteAnchorEl, setDeleteAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const openDeletePopover = Boolean(deleteAnchorEl);
  const idDeletePopover = openDeletePopover ? "delete-popover" : undefined;

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement>,
    row: BankAccInterface
  ) => {
    setDeleteAnchorEl(e.currentTarget);
    setTableData(row);
  };
  const handleDeleteClosePopover = () => {
    setDeleteAnchorEl(null);
    setTableData(null);
  };

  const handleDeleteUserById = async () => {
    if (tableData) {
      setDeleteAnchorEl(null);
      await deleteBankDetailsId(tableData._id);
      fetchMyBankDetails();
    }
  };
  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = bankDetails.slice(startIndex, endIndex);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setTableData(null);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTableData(null);
  };
  //table sorting
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});
  const columns: IColumn[] = [
    { title: "Community", dataKey: "community" },
    { title: "Name", dataKey: "name" },
    { title: "Mobile Number", dataKey: "mobilenumber" },
    { title: "Bank Name", dataKey: "bankname" },
    { title: "Account Number", dataKey: "accountnumber" },
    { title: "IFSC", dataKey: "ifsc" },
    { title: "Status", dataKey: "status" },
    { title: "Label", dataKey: "label" },
    { title: "Actions", dataKey: "actions" },
  ];
  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: "asc" | "desc" | undefined } = {};
      let newSortedColumns: string[] = [];

      if (prevSortDirections[columnTitle] === "asc") {
        newSortDirections[columnTitle] = "desc";
      } else {
        newSortDirections[columnTitle] = "asc";
      }

      newSortedColumns.push(columnTitle);
      setSortedColumns(newSortedColumns);
      return newSortDirections;
    });
  };
  const sortedData = [...paginatedData];

  const sortedColumnTitles = sortedColumns.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );
  if (sortedColumnTitles.length > 0) {
    sortedData?.sort((a: any, b: any) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns.find((col) => col.title === columnTitle);
        if (column) {
          if (column.dataKey === "community") {
            const aValue = a?.community?.name;
            const bValue = b?.community?.name;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === "name") {
            const aValue = a?.fullName;
            const bValue = b?.fullName;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === "mobilenumber") {
            const aValue = a?.phoneNumber;
            const bValue = b?.phoneNumber;

            if (sortDirection === "asc") {
              return typeof aValue === "number" && typeof bValue === "number"
                ? aValue - bValue
                : 0;
            } else {
              return typeof aValue === "number" && typeof bValue === "number"
                ? bValue - aValue
                : 0;
            }
          } else if (column.dataKey === "bankname") {
            const aValue = a?.bankName;
            const bValue = b?.bankName;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "accountnumber") {
            const aValue = a?.accountNumber;
            const bValue = b?.accountNumber;
            if (sortDirection === "asc") {
              return typeof aValue === "number" && typeof bValue === "number"
                ? aValue - bValue
                : 0;
            } else {
              return typeof aValue === "number" && typeof bValue === "number"
                ? bValue - aValue
                : 0;
            }
          } else if (column.dataKey === "ifsc") {
            const aValue = a?.ifsc;
            const bValue = b?.ifsc;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "status") {
            const aValue = a?.status;
            const bValue = b?.status;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "label") {
            const aValue = a?.label;
            const bValue = b?.label;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "status") {
            const aValueDate = new Date(a?.updatedAt);
            const bValueDate = new Date(b.updatedAt);
            if (aValueDate.getTime() !== bValueDate.getTime()) {
              // If the dates are not the same, sort based on date
              if (sortDirection === "asc") {
                return aValueDate.getTime() - bValueDate.getTime();
              } else {
                return bValueDate.getTime() - aValueDate.getTime();
              }
            } else {
              // If dates are the same, include time for sorting
              // const aValueTime = new Date(
              //   `${a.createdAt}T${a.createdAt}`
              // );
              // const bValueTime = new Date(
              //   `${b.updated.date}T${b.updated.time}`
              // );
              // if (sortDirection === "asc") {
              //   return aValueTime.getTime() - bValueTime.getTime();
              // } else {
              //   return bValueTime.getTime() - aValueTime.getTime();
              // }
            }
          } else {
            // const aValue = a[column?.dataKey];
            // const bValue = b[column?.dataKey];

            if (sortDirection === "asc") {
              //  return aValue.localeCompare(bValue);
            } else {
              //  return bValue.localeCompare(aValue);
            }
          }
        }
      }
      return 0;
    });
  }
  return (
    <>
      {/* <h2
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span></span>
        <Typography
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "#3760A9",
              fontFamily: "Inter",
              marginBottom: "10px",
            }}
          >
            Manage Invitations
          </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={
            <AddIcon
              sx={{
                fontSize: "24px",
                bgcolor: "#fff",
                borderRadius: "50%",
                color: "#2952A2",
              }}
            />
          }
          onClick={handleAddDetailsClick}
          sx={{
            alignSelf: "flex-end",
            borderRadius: "30px",
            marginTop: "8px",
            backgroundColor: "#2952A2",
            "&:hover": {
              backgroundColor: "#2952A2", // Background color on hover
              cursor: "pointer",
              border: "none",
            },
          }}
        >
          <Typography variant="button" sx={{ textTransform: "capitalize" }}>
            Add Details
          </Typography>
        </Button>
      </h2> */}

      <Stack direction={"column"} spacing={1} >
        <SubNavbar>
          <Stack>
            <Typography
              sx={{
                fontSize: { xs: "15px", md: '20px' },
                fontWeight: 500,
                color: "#3C5AA0",
                fontFamily: "Inter",
              }}
            >
              Bank Details
            </Typography>
          </Stack>
          <Stack direction={"row-reverse"} flexGrow={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <AddIcon
                  sx={{
                    fontSize: "24px",
                    bgcolor: "#fff",
                    borderRadius: "50%",
                    color: "#2952A2",
                  }}
                />
              }
              onClick={handleAddDetailsClick}
              sx={{
                alignSelf: "flex-end",
                borderRadius: "30px",
                marginTop: "8px",
                backgroundColor: "#2952A2",
                "&:hover": {
                  backgroundColor: "#2952A2", // Background color on hover
                  cursor: "pointer",
                  border: "none",
                },
              }}
            >
              <Typography variant="button" sx={{ textTransform: "capitalize" }}>
                Add Details
              </Typography>
            </Button>
          </Stack>
        </SubNavbar>
      </Stack>


      <div
        style={{
          minHeight: openMode ? "30vh" : "80vh",
          marginTop: openMode ? "0px" : "10px",
        }}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ height: "calc(100vh - 30vh)" }}
        >
          <Table
            sx={{
              "& th": {
                fontSize: "12px",
                fontWeight: 700,
                color: "#565656",
                p: 1,
              },
              "& td": {
                fontSize: "15px",
                fontWeight: 500,
                color: "#1A1A1A",
                p: "5px 10px",
                "& .MuiTypography-root": { m: 0, fontSize: "11px" },
                "& .MuiTypography-root:nth-child(2)": {
                  m: 0,
                  fontSize: "6px",
                  color: "#56565680",
                  fontWeight: 600,
                },
              },
            }}
          >
            <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, background: '#ffffff' }}>
              <TableRow style={{ height: "-10px" }}>
                {columns?.map((column: IColumn, i: number) => (
                  <TableCell
                    key={i}
                    onClick={() => handleSort(column.title)}
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    <CustomTableHeader
                      title={column.title}
                      sortDirection={sortDirections[column.title]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {sortedData?.map((rowData, index) => (
                    <TableRow key={index}>
                      {columns[0].dataKey === "community" && (
                        <TableCell>
                          <Tooltip
                            title={rowData?.community?.name}
                            placement="right-start"
                          >
                            <img
                              src={rowData?.community?.logo}
                              alt="logo"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      )}
                      {columns[1]?.dataKey === "name" && (
                        <TableCell
                          style={{ fontSize: "11px", fontWeight: "normal" }}
                        >
                          {rowData?.fullName}
                        </TableCell>
                      )}

                      {columns[2].dataKey === "mobilenumber" && (
                        <TableCell
                          style={{ fontSize: "11px", fontWeight: "normal" }}
                        >
                          {rowData.phoneNumber}
                        </TableCell>
                      )}
                      {columns[3].dataKey === "bankname" && (
                        <TableCell
                          style={{ fontSize: "11px", fontWeight: "normal" }}
                        >
                          {rowData?.bankName} {rowData?.branchName}
                        </TableCell>
                      )}
                      {/* <TableCell
                        style={{ fontSize: "11px", fontWeight: "normal" }}
                      >
                        {rowData.branchName}
                      </TableCell> */}
                      {columns[4].dataKey === "accountnumber" && (
                        <TableCell
                          style={{ fontSize: "11px", fontWeight: "normal" }}
                        >
                          {rowData?.accountNumber}
                        </TableCell>
                      )}
                      {columns[5].dataKey === "ifsc" && (
                        <TableCell
                          style={{ fontSize: "11px", fontWeight: "normal" }}
                        >
                          {rowData.ifsc}
                        </TableCell>
                      )}
                      {columns[6].dataKey === "status" && (
                        <TableCell
                          style={{ fontSize: "11px", fontWeight: "normal" }}
                        >
                          <Stack
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "11px",
                              color: "#000000",
                              fontWeight: "400",
                            }}
                            direction={"row"}
                          >
                            <FiberManualRecordIcon
                              key={index}
                              sx={{
                                hieght: "10px",
                                width: "10px",
                                pb: 1,
                                color: GetColor(rowData.status),
                              }}
                            />
                            {getStaticValue(rowData.status)}
                          </Stack>
                        </TableCell>
                      )}
                      {columns[7].dataKey === "label" && (


                        <TableCell
                          onMouseEnter={(e: any) => handlePopoverOpen(e, rowData)}
                          sx={{ cursor: 'pointer' }}
                        // onMouseOut={handlePopoverClose}
                        >
                          <Typography style={{ fontSize: "11px", fontWeight: "normal", cursor: "pointer" }}>
                            {rowData?.label}
                          </Typography>
                        </TableCell>


                      )}

                      <TableCell>
                        <Button
                          sx={{ minWidth: "20px", minHeight: "10px" }}
                          onClick={() => handleEdit(rowData)}
                          disabled={rowData.status === "PENDING"}
                        >
                          <EditIcon
                            fontSize="small"
                            sx={{ color: "#232b2b", }}
                          >
                            {" "}
                          </EditIcon>
                        </Button>

                        <Button
                          sx={{ minWidth: "20px", minHeight: "10px" }}
                          onClick={(e: any) => handleDelete(e, rowData)}
                          disabled={rowData.status === "PENDING"}
                        >
                          <DeleteOutlinedIcon
                            fontSize="small"
                            style={{ color: "#232b2b" }}
                          />
                        </Button>
                      </TableCell>

                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}

          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}


        >
          <Stack sx={{ p: 2, maxHeight: "300px", width: "350px", overflowY: "auto", }}>
            <CommentsPopUp data={tableData?.comments} />
          </Stack>
        </Popover>

        <Stack
          direction={"row-reverse"}
          alignItems={"center"}
          sx={{ color: "#A5A5A5", fontSize: { xs: "10px", md: '1.5vh', }, pr: { xs: "0px", md: "25px" } }}
        >
          Showing {Math.min(rowsPerPage, bankDetails.length - page * rowsPerPage)} of{" "}
          {bankDetails.length} entries
          <TablePagination
            // rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={bankDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="View"
            labelDisplayedRows={() => null}
            SelectProps={{
              SelectDisplayProps: {
                style: { border: "1px solid #A5A5A5", borderRadius: "5px" },
              },
              // You can also customize the style of the menu options if desired
              MenuProps: {
                PaperProps: { style: { border: "1px solid #A5A5A5" } },
              },
            }}
            ActionsComponent={({ onPageChange, page }) => {
              const totalPages = Math.ceil(bankDetails.length / rowsPerPage);

              return (
                <Stack
                  direction={"row"}
                  display="flex"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <IconButton
                    onClick={(event) => onPageChange(event, 0)} // Go to the first page
                    disabled={page === 0}
                    aria-label="go to first page"
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </IconButton>

                  <IconButton
                    onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                    disabled={page === 0}
                    aria-label="go to previous page"
                  >
                    <KeyboardArrowLeft />
                  </IconButton>

                  {[...Array(3)].map((_, index) => {
                    const pageNumber = page + index;
                    if (pageNumber < totalPages) {
                      return (
                        <IconButton
                          key={pageNumber}
                          onClick={(event) => onPageChange(event, pageNumber)}
                          style={{
                            fontSize: "1.9vh",
                            border:
                              page === pageNumber
                                ? "1px solid #3C5AA0"
                                : "none", // Highlight the active page with a border
                            color: page === pageNumber ? "#3C5AA0" : "#000000", // Highlight the active page with a color
                            width: page === pageNumber ? "30px" : "30px",
                            height: page === pageNumber ? "30px" : "30px",
                          }}
                        >
                          {pageNumber + 1}
                        </IconButton>
                      );
                    } else {
                      return null; // Don't render the button if it's out of bounds
                    }
                  })}

                  <IconButton
                    onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                    disabled={page >= totalPages - 1}
                    aria-label="next page"
                  >
                    <KeyboardArrowRight />
                  </IconButton>

                  <IconButton
                    onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                    aria-label="go to last page"
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </IconButton>
                </Stack>
              );
            }}
          />
        </Stack>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={openModal}
          onClose={() => handleCloseModal()}
          PaperProps={{
            sx: {
              width: "100%",
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: "#F0F9FF", height: "50px", px: 2 }}
          >
            <Typography
              sx={{
                color: "#3C5AA0",
                fontSize: { xs: "15px", md: '20px' },
                fontWeight: "500",
                fontFamily: 'Inter',
                backgroundColor: "#F0F9FF",
              }}
            >
              Account Details
            </Typography>
            <Box
              sx={{
                backgroundColor: "#3C5AA0",
                padding: "4px",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: 'pointer'
              }}
              onClick={() => handleCloseModal()}
            >
              <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
            </Box>
          </Grid>
          <DialogContent>
            <PaymentForm
              onCloseModal={handleCloseModal}
              tableRowData={tableData}
              fetchMyBankDetails={fetchMyBankDetails}
              fetchBankDetailsById={fetchBankDetailsById}
              openMode={openMode}
              bankDetailsByCommunity={bankDetailByCommunityId}
            />
          </DialogContent>
        </Dialog>
      </div>
      <Popover
        id={idDeletePopover}
        open={openDeletePopover}
        anchorEl={deleteAnchorEl}
        onClose={handleDeleteClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete the BankDetails for{" "}
          {tableData?.fullName}
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
            onClick={() => {
              handleDeleteClosePopover();
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  // backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                  cursor: "pointer",
                },
              }}
              onClick={handleDeleteUserById}
              disabled={tableData?.status === "PENDING"}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default PaymentList;
