import axios from "axios";
import { BASE_URL } from "../configurations/url.config";
import {
  ICategory,
  ICommunity,
  ICommunityResponse,
} from "../models/communities.modal";
import { IDate } from "../models/general.model";
import { formatDate } from "../utils/dateUtil";
const INTERNAL_SERVER_ERROR = "Internal Server Error";

export const getCommunities = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/community`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200 && response.data.length > 0) {
      response.data = response.data.map((d: ICommunityResponse): ICommunity => {
        const updatedDate = new Date(d.updatedAt);
        // Create an object of type IDate
        const updated: IDate = {
          date: `${updatedDate.getDate()} ${updatedDate.toLocaleString(
            "default",
            { month: "short" }
          )}. ${updatedDate.getFullYear()}`,
          time: `${updatedDate.toLocaleString("default", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`,
        };

        return {
          ...d,
          _id: d._id ?? "",
          title: d.name,
          createdBy: d.createdBy ?? "",
          totalMembers: d.members?.length ? d.members.length : 0,
          category: d.category ?? "",
          markedAs: d?.type,
          status: d?.status,
          updated: updated,
          membership: d?.collectSubscription,
          earnings: d.earnings ?? 0,
          fullAddress: d?.fullAddress,
          createdAt: formatDate(new Date(d.createdAt)),
          updatedAt: formatDate(new Date(d.updatedAt)),
          favorite: d?.favorite ?? false,
          logo: d?.logo,
          updatedBy: d.updatedBy ?? "",
          phoneNumber: d?.phoneNumber,
          mobileNumber: d?.mobileNumber,
          images: d.images ?? [""],
          services: d?.services,
          maxMembers: d?.maxMembers ?? 0,
          banner: d?.banner,
          otherCategory: d?.otherCategory,
          teams: d?.team,
          faq: d?.faq,
        };
      });
    }
    console.log(response, "data");
    return response;
  } catch (err) {
    //console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const getCategories = (): ICategory[] => {
  const data: ICategory[] = [
    { title: "Health Care" },
    { title: "Technology" },
    { title: "Education" },
    { title: "Politics" },
    { title: "Business" },
    { title: "Influencer" },
    { title: "Consultancy" },
    { title: "NGO" },
    { title: "Travel" },
    { title: "Social" },
  ];
  return data;
};

export const checkCommunityAvailability = async (
  token: string,
  name: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/community/${name}/available`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 302, data: [] };
  }
};

export const updateCommunityService = async (
  token: string,
  id: string | undefined,
  formData: any
) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      //"Content-Type": "multipart/json",
    };

    const response = await axios.put(`${BASE_URL}/community/${id}`, formData, {
      headers,
    });
    if (response.status === 201) {
      let community = response.data;
      response.data = {
        ...community,
        // _id: community._id,
        name: community.name ?? "",
        category: community.category ?? "",
        zipCode: community.zipCode ?? "",
        fullAddress: community.fullAddress ?? "",
        gstNumber: community.gstNumber ?? "",
        type: community.type ?? "",
        collectSubscription: community.collectSubscription ?? "",
        city: community.city ?? "",
        maxMembers: community?.maxMembers,
        logo: community?.logo,
        banner: community?.banner,
        services: community?.services,
        teams: community?.teams,
        faq: community?.faq,
      };
    }
    console.log(response, "data");
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const buildCommunityService = async (
  token: string,
  id: string | undefined,
  data: any,
  logo: File | null,
  banner: File | null,
  gallery: FileList | null
) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      "Content-Type": "multipart/json",
      Accept: "*",
    };

    const formData = new FormData();
    if (logo) formData.append("logo", logo);
    if (banner) formData.append("banner", banner);
    if (gallery != null) {
      for (let i = 0; i < gallery.length; i++) {
        const file = gallery[i];
        formData.append("gallery", file);
      }
    }

    formData.append("zipCode", data?.community?.zipCode);
    formData.append("fullAddress", data?.community?.fullAddress);
    formData.append("description", data?.community?.description);
    formData.append("vision", data?.community?.vision);
    formData.append("mission", data?.community?.mission);
    formData.append("adminMessage", data?.community?.adminMessage);
    formData.append("directorMessage", data?.community?.directorMessage);
    formData.append("phoneNumber", data?.community?.phoneNumber);
    formData.append("email", data?.community?.email);
    formData.append("mobileNumber", data?.community?.mobileNumber);
    formData.append("status", data?.community?.status);
    formData.append("location", data?.community?.location);
    formData.append("services", JSON.stringify(data?.services));
    formData.append("socialLinks", JSON.stringify(data?.socialLinks));
    formData.append("teams", JSON.stringify(data?.teams));
    formData.append("faq", JSON.stringify(data?.faq));

    const response = await axios.put(`${BASE_URL}/community/${id}`, formData, {
      headers,
    });
    if (response.status === 200) {
      let community = response.data;
      response.data = {
        ...community,
        _id: community._id,
        name: community.name ?? "",
        category: community.category ?? "",
        zipCode: community.zipCode ?? "",
        fullAddress: community.fullAddress ?? "",
        gstNumber: community.gstNumber ?? "",
        type: community.type ?? "",
        collectSubscription: community.collectSubscription ?? "",
        city: community.city ?? "",
        maxMembers: community.maxMembers,
        logo: community?.logo,
        banner: community.banner,
        otherCategory: community?.otherCategory,
        services: community?.services,
        teams: community?.teams,
        faq: community?.faq,
      };
    }
    console.log(response, "data");
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};
export const communityById = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/community/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    let { community, mapping } = response.data;
    //  console.log(mapping)
    response.data = {
      _id: community._id ?? "",
      name: community.name ?? "",
      category: community.category ?? "",
      zipCode: community.zipCode ?? "",
      fullAddress: community.fullAddress ?? "",
      gstNumber: community.gstNumber ?? "",
      description: community.description ?? "",
      type: community.type ?? "",
      collectSubscription: community.collectSubscription ?? "",
      //plan: community.plan ?? "",
      vision: community.vision ?? "",
      gallery: community.gallery ?? "",
      mission: community.mission ?? "",
      adminMessage: community.adminMessage ?? "",
      directorMessage: community.directorMessage ?? "",
      contactName: community.contactName ?? "",
      phoneNumber: community.phoneNumber ?? "",
      mobileNumber: community.mobileNumber ?? "",
      email: community.email ?? "",
      createdAt: formatDate(new Date(community.createdAt)),
      updatedAt: formatDate(new Date(community.updatedAt)),
      socialLinks: community.socialLinks,
      members: community.members,
      posts: community.posts,
      maxMembers: community.maxMembers,
      city: community.city,
      logo: community.logo,
      banner: community.banner,
      status: community.status,
      otherCategory: community?.otherCategory,
      services: community?.services,
      teams: community?.teams,
      faq: community?.faq,
      joinedOn: formatDate(new Date(mapping?.createdAt)),
      slug: mapping?.slug,
      rollType: mapping?.type,
      isDefault: mapping?.isDefault,
    };
    console.log(response, "data");
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const createCommunity = async (token: string, CommunityData: any) => {
  try {
    const formData = JSON.stringify(CommunityData);
    const response = await axios.post(
      `${BASE_URL}/community/create`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    //console.log(CommunityData);

    if (response.status === 201) {
      let community = response.data;
      response.data = {
        _id: community._id,
        name: community.name ?? "",
        category: community.category ?? "",
        zipCode: community.zipCode ?? "",
        fullAddress: community.fullAddress ?? "",
        gstNumber: community.gstNumber ?? "",
        description: community.description ?? "",
        type: community.type ?? "",
        collectSubscription: community.collectSubscription ?? "",
        plan: community.plan ?? "",
        vision: community.vision ?? "",
        mission: community.mission ?? "",
        adminMessage: community.adminMessage ?? "",
        directorMessage: community.directorMessage ?? "",
        contactName: community.contactName ?? "",
        phoneNumber: community.phoneNumber ?? "",
        email: community.email ?? "",
        createdAt: formatDate(new Date(community.createdAt)),
        updatedAt: formatDate(new Date(community.updatedAt)),
        socialLinks: community.socialLinks ?? [],
        members: community.members ?? [],
        posts: community.posts ?? 0,
        city: community.city ?? "",
        maxMembers: community?.maxMembers,
        otherCategory: community?.otherCategory,
        logo: community.logo,
        banner: community.banner,
        gallery: community.gallery,
        services: community?.services,
      };
    }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};
//update user role
export const updateUserRole = async (
  token: string,
  communityId: string,
  id: string | undefined,
  formData: any
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    // const response = await axios.put(`${BASE_URL}/community/${communityId}/role-update/${id}`, formData, {
    const response = await axios.put(
      `${BASE_URL}/communities/${communityId}/update-user-mapping/${id}`,
      formData,
      {
        headers,
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const favCommunity = async (
  token: string,
  id: string,
  favorite: boolean
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.put(
      `${BASE_URL}/community/${id}/favorite`,
      { favorite },
      {
        headers,
      }
    );
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};
export const favJoinedCommunity = async (
  token: string,
  id: string,
  favorite: boolean
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.put(
      `${BASE_URL}/communities/${id}/mark-as-favorite`,
      { favorite },

      {
        headers,
      }
    );

    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};
// Add other community-related services here as needed
export const membershipCommunities = async (token: string) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      //"Content-Type": "multipart/json",
    };

    const response = await axios.get(`${BASE_URL}/community/membership/all`, {
      headers,
    });
    if (response.status === 200) {
      return response;
    }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const SuggestionCommunities = async (token: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.get(`${BASE_URL}/community/suggestions/all`, {
      headers,
    });
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const fetchTrendingCommunities = async (token: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const fullAddress = token
      ? `${BASE_URL}/community/trending/all`
      : `${BASE_URL}/communities/trending`;
    const response = await axios.get(fullAddress, {
      headers,
    });

    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const deleteGalleryByUrl = async (token: string, url: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/gallery/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};

export const joinCommunity = async (token: string, community_id: string) => {
  try {
    const formData = JSON.stringify({ community_id: community_id });
    console.log(formData);
    const response = await axios.post(
      `${BASE_URL}/community/${community_id}/join`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    return { status: 500, data: [], message: INTERNAL_SERVER_ERROR };
  }
};
export const updateUserMappingCommunity = async (
  token: string,
  id: string | undefined,
  formData: any
) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "*",
    };
    const response = await axios.put(
      `${BASE_URL}/communities/${id}/update-my-community-user-mapping`,
      formData,
      {
        headers,
      }
    );
    if (response.status === 200) {
      // let community = response.data;
      // response.data = {
      //   ...community,
      //   // _id: community._id,
      //   name: community.name ?? "",
      //   category: community.category ?? "",
      //   zipCode: community.zipCode ?? "",
      //   fullAddress: community.fullAddress ?? "",
      //   gstNumber: community.gstNumber ?? "",

      //   type: community.type ?? "",
      //   collectSubscription: community.collectSubscription ?? "",

      //   city: community.city ?? "",

      //   maxMembers: community.maxMembers,
      //   logo: community.logo,
      //   banner: community.banner,
      // };
      return response;
    }
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: [] };
  }
};
export const updateUserMappingCommunityBanner = async (
  token: string,
  id: string,
  files: FileList | null,
  data: FormData
) => {
  try {
    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append("banner", file);
      }
    }
    for (const [field, value] of data.entries()) {
      formData.append(field, value);
    }
    const response = await axios.put(
      `${BASE_URL}/communities/${id}/update-my-community-user-mapping`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*",
        },
      }
    );

    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: {} };
  }
};
