import Stack from "@mui/material/Stack";
import { IAction } from "../../../models/table.model";

interface IProps {
  actions: IAction[];
  onActionClick: (a: any, e: React.MouseEvent<HTMLButtonElement>) => void;
}
export const CustomActions = (props: IProps) => {
  const { actions, onActionClick } = props;
  return (
    <Stack direction={"row"} spacing={2}>
      {actions.map((action: any, i: number) => {
        const Component = action.icon;
        const styles = action.styles;
        // transition: "all 0.3s", "&:hover": { transform: "scale3d(1.5, 1.5, 1.5)" }
        return (
          //   <Tooltip key={i} title={action.tooltip}>
          <Component
            key={i}
             sx={styles}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              onActionClick(action, e)
            }
          />
          //   </Tooltip>
        );
      })}
    </Stack>
  );
};
