import {Stack, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import { useInvitations } from "../../../hooks/useInvitations";
import { InvitesAllCommunities } from "../AllCommunities/InvitesAllCommunities";
import "./ArrowStyles.css";
import { useEffect, useState } from "react";
import { Invitations } from "../../../models/Invitation.model";
import { useNavigate } from "react-router-dom";
// import Loader from "../../Loader/Loader";
import SmallLoader from "../../Loader/SmallLoader";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 6,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 2.5,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const InvitesCommunities = () => {
  const [invitesCommunities, setInvitesCommunities] = useState<Invitations[]>(
    []
  );

  const { isLoading, getInvitesForUser } = useInvitations();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const data = await getInvitesForUser();
      setInvitesCommunities(data);
    }
    fetchData();
  }, []);

  return (
    <>
      <Stack>
        {invitesCommunities.length > 0 && ( // Conditionally render the Stack section
          <Stack>
            <Stack
              direction={"row"}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                sx={{
                  fontSize: {xs:"14px",md:'18px'},
                  fontWeight: 700,
                  textDecoration: "none",
                  color: "#000000",
                   marginLeft: 2,
                }}>
                Community Invitations
              </Typography>
              
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#50A1CA",
                      textAlign: "right",
                      alignItems: "end",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => navigate(`/invites-communities`)}
                  >
                    {invitesCommunities?.length} Communities
                  </Typography>
               
            </Stack>

            {isLoading ? (
              <Stack
              sx={{
                color: "grey.500",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
              spacing={2}
              direction="row"
            >
              <SmallLoader />
              </Stack>
               
            ) : (
              <Carousel showDots={false} arrows={true} responsive={responsive}>
                {invitesCommunities.map((item) => (
                  <div key={item._id}>
                    <InvitesAllCommunities
                      community={item?.community}
                      _id={item?._id}
                      message = {item?.message}
                      invites={item}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default InvitesCommunities;
