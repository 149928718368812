import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { CardTickIcon, HomeIcon, IcDashboardIcon, } from '../../../../assets/icons';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { useSelector } from 'react-redux';
import store from '../../../../store';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Fab from '@mui/material/Fab';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';


export default function MobileNav() {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);



  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );

  // console.log(selectedCommunity?.selectedCommunity?.collectSubscription, "selectedCommunity")



  const state = store.getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const usersData = store.getState();

  const [userId] = React.useState(usersData.loggedInUser.user?.id);

  const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  });



  return (
    <Box sx={{ pb: 7, }} ref={ref}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 0.3 }} elevation={3} >
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white', borderRadius: '10px 10px 0px 0px' }}>
          <Toolbar>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ minWidth: 'auto' }}
            >
              <BottomNavigationAction
                label={<span style={{ fontSize: '12px' }}>Home</span>}
                icon={
                  <HomeIcon
                    sx={{
                      fontSize: '20px',
                      color: location.pathname !== '/home' ? '#92929D' : "#3C5AA0",
                      "& .Mui-selected": {
                        color: location.pathname === '/home' ? '#3C5AA0' : "#92929D",
                      }
                    }
                    }
                  />}

                component={RouterLink}
                to="/home"

                sx={{
                  fontSize: '13px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: location.pathname !== '/home' ? '#92929D' : "#3C5AA0",
                  textDecoration: 'none',
                  "& .Mui-selected": {
                    color: location.pathname === '/home' ? '#3C5AA0' : "#92929D",
                  }
                }
                }
              />

              <BottomNavigationAction
                label={<span style={{ fontSize: '12px' }}>Payments</span>}
                icon={
                  <CardTickIcon

                    sx={{
                      fontSize: '20px',
                      color: location.pathname !== '/payments' ? '#92929D' : "#3C5AA0",
                      "& .Mui-selected": {
                        color: location.pathname === '/payments' ? '#3C5AA0' : "#92929D",
                      }
                    }
                    }
                  />}
                component={RouterLink}
                to="/payments"

                sx={{
                  fontSize: '13px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: location.pathname !== '/payments' ? '#92929D' : "#3C5AA0",
                  textDecoration: 'none',
                  "& .Mui-selected": {
                    color: location.pathname === '/payments' ? '#3C5AA0' : "#92929D",
                  }
                }
                }
              />
            </BottomNavigation>
            <StyledFab color="secondary" aria-label="add">
              <BottomNavigationAction icon={<AddIcon />}
                sx={{
                  background: "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                  padding: 0,
                  borderRadius: "50%",
                  minWidth: '15%',
                  height: '100%',
                  justifyContent: "center",
                  alignItems: "center",
                  color: 'white',
                  '&.Mui-selected': {
                    color: "#ffffff",
                  },
                }}
                component={RouterLink}
                to="/communities/add"
              />
            </StyledFab>
            <Box sx={{ flexGrow: 1 }} />
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ minWidth: 'auto' }}
            >
              <BottomNavigationAction
                label={<span style={{ fontSize: '12px' }}>courses</span>}
                icon={
                  <LibraryBooksOutlinedIcon
                    sx={{
                      fontSize: '20px',
                      color: location.pathname !== '/courses' ? '#92929D' : "#3C5AA0",
                      "& .Mui-selected": {
                        color: location.pathname === '/courses' ? '#3C5AA0' : "#92929D",
                      }
                    }
                    }
                  />}

                component={RouterLink}
                to="/courses"

                sx={{
                  fontSize: '13px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: location.pathname !== '/courses' ? '#92929D' : "#3C5AA0",
                  textDecoration: 'none',
                  "& .Mui-selected": {
                    color: location.pathname === '/courses' ? '#3C5AA0' : "#92929D",
                  }
                }
                }
              />

              {selectedCommunity?.selectedCommunity?.collectSubscription === "NO" ? (
                <BottomNavigationAction
                  label={<span style={{ fontSize: '12px', fontFamily: 'Inter' }}>Profile</span>}
                  icon={
                    <AccountCircleOutlinedIcon
                      sx={{
                        fontSize: '20px',
                        color: location.pathname !== `/posts/profile/${userId}` ? '#92929D' : "#3C5AA0",
                        "& .Mui-selected": {
                          color: location.pathname === `/posts/profile/${userId}` ? '#3C5AA0' : "#92929D",
                        }
                      }}
                    />
                  }
                  sx={{
                    color: location.pathname !== `/posts/profile/${userId}` ? '#92929D' : "#3C5AA0",
                    "& .Mui-selected": {
                      color: location.pathname === `/posts/profile/${userId}` ? '#3C5AA0' : "#92929D",
                    }
                  }}
                  component={RouterLink}
                  to={`/posts/profile/${userId}`}
                />
              ) : (
                <BottomNavigationAction
                  label={<span style={{ fontSize: '12px', fontFamily: 'Inter' }}>Plans</span>}
                  icon={
                    <IcDashboardIcon
                      sx={{
                        fontSize: '20px',
                        color: location.pathname !== '/plans' ? '#92929D' : "#3C5AA0",
                        "& .Mui-selected": {
                          color: location.pathname === '/plans' ? '#3C5AA0' : "#92929D",
                        }
                      }}
                    />
                  }
                  sx={{
                    color: location.pathname !== '/plans' ? '#92929D' : "#3C5AA0",
                    "& .Mui-selected": {
                      color: location.pathname === '/plans' ? '#3C5AA0' : "#92929D",
                    }
                  }}
                  component={RouterLink}
                  to="/plans"
                />
              )}
            </BottomNavigation>
          </Toolbar>
        </AppBar>
      </Paper>
    </Box>
  );
}

