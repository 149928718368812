
import {
    Card,
    CardMedia,
    Paper,
    Stack,
  } from "@mui/material";
  // Use lowercase for the function name
  export const NoCourses = () => {
    return (
      <>
        <Paper elevation={0}>
          <Stack
            spacing={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ paddingBottom: "10px", p: 2 }}
          >
             <Card elevation={0}>
              <CardMedia
                component="img"
                alt="No posts"
                
                image={require("./../../assets/icons/svg-icons/No Post.png")}
                sx={{
                  objectFit: "cover",
                  width: {xs:"60%", md:'100%'}, // Set the width to 100% to fill the container
                  height: {xs:"30vh", md:'40vh'}, // Set the height to 100% to fill the container
                }}
              />
            </Card>
            
            {/* <Typography textAlign={"center"} sx={{color:'#9F9F9F', fontSize:'12px'}}>
            Be the first one to share something with the community!
            </Typography> */}
            {/* <Divider sx={{width:'100%'}} /> */}
          </Stack>
        </Paper>
      </>
    );
  };
  