import React from "react";
import { Card, CardMedia, Paper, Stack, Typography, } from "@mui/material";


export const NoInvites = () => { 
  return (
    <>
      <Paper elevation={0} sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%'}}>
        <Stack
          spacing={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingBottom: "10px", p: 2 }}
        >
           <Card elevation={0}>
            <CardMedia
              component="img"
              alt="No posts"
              
              image={require("../../assets/icons/svg-icons/NoInvitation.png")}
              sx={{
                objectFit: {xs:"contain", md:"cover"},
                width: {xs:"100%", md:"100%"}, // Set the width to 100% to fill the container
                height: "40vh", // Set the height to 100% to fill the container
              }}
            />
          </Card>
          <Typography sx={{color:'#777777', fontSize:{xs:'13px', md:'15px'}, fontWeight:'500', fontFamily:'Inter'}}>No Invites Available</Typography>
        </Stack>
      </Paper>
    </>
  );
};
