import { Add, FileCopyOutlined } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CourseInfoCard from "./CourseInfoCard";
import CustomButton from "./CustomButton";
import DragAndAddFiles from "./DragAndAddFiles";
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import {
  AddSection,
  Bin,
  EditIcon, 
  FolderMenu,
  ShareLink,
} from "../../../assets/icons";
import AddLinkPopUp from "./AddLinkPopUp";
import TestSection from "./TestSection";
import AddSectionPopUp from "./AddSectionPopUp";
import { useCourses } from "../../../hooks/useCourses";
import { useSelector } from "react-redux";
import { ILinks, interfaceCourse } from "../../../models/courses.model";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate, useParams } from "react-router-dom";
import { updateDocument } from "../../../services/courses.service";
import { AuthContext, IAuthContext } from "../../../contexts/Auth.context";
import Loader from "../../Loader/Loader";
import EditDocumentPopUp from "./EditDocumentPopUp";

const AddFilesLinksCourse = () => {
  // const location = useLocation();
  // const courseId = location.state?.courseId;
  const {id }=useParams();
  const navigate = useNavigate();
  
const courseId = id || ''
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAddLinkDialog, setOpenAddLinkDialog] = useState(false);
  const [openEditDocument,setEditDocument]=useState(false)
  const [openAddSection, setOpenAddSection] = useState(false);
  const [linkInputs, setLinkInputs] = useState([""]);
  const [course, setCourse] = useState<interfaceCourse[]>([])
  const [links, setLinks] = useState<ILinks[]>([]);
  const [deleteLinkAnchor, setDeleteLinkAnchor] = useState<null | HTMLElement>(null);
  const [deleteDocumentAnchor, setDeleteDocumentAnchor] = useState<null | HTMLElement>(null);
  const [linkItem, setLinkItem] = useState<ILinks | null>()
  const { getCourseByCourseId, updateCourse,deleteDocumentById } = useCourses();
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [selectedDocument,setSelectedDocument]=useState()
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity.selectedCommunity
  );
  const selectedCommunityId = selectedCommunity?._id;
  const [loading, setLoding] = useState(false);
  const [isCourseDataLoading,setCourseDataLoading]=useState(false)

  const openDeleteDocumentPopOver = Boolean(deleteDocumentAnchor);
  const idDeleteDocument = openDeleteDocumentPopOver
    ? "delete-popover"
    : undefined;

    const handleDocumentDelete = (e: any,item:any) => {
      setDeleteDocumentAnchor(e.currentTarget);
      setSelectedDocument(item)
      
    };
    const handleCloseDocumentPopOver =()=>{
      setDeleteDocumentAnchor(null)
    }

    const handleDeleteDocument = async () => {
      if (selectedDocument && '_id' in selectedDocument) {
        const {_id} = selectedDocument
        try{
        setLoding(true)
        await deleteDocumentById(selectedCommunityId, course[0]._id, _id)
        }catch(err){
          console.log("error");
          
        }finally{
          refetchCourseData();
        setLoding(false);
        handleCloseDocumentPopOver()

        }
      }
    };


  const handleFilesSelected = async (files: FileList | null) => {
    // setSelectedFiles(files);
//call api
    if (files ) {
      try {       
       await updateDocument(getAccessToken(),selectedCommunityId,courseId,files)   
      
      refetchCourseData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        setCourseDataLoading(true);
        const data = await getCourseByCourseId(selectedCommunityId, courseId);

        if (data && data.courses) {
          setCourse(data.courses);
          const allLinks = data.courses.flatMap((course: interfaceCourse) => course.links);
          setLinks(allLinks)
          // const allFiles = data.courses.flatMap((course: interfaceCourse) => course.documents);
          // setSelectedFiles(allFiles)
        }
      } catch (error) {
        console.error('Error fetching course list:', error);
      } finally {
        setCourseDataLoading(false);
      }
    };

    fetchCourse();
    // eslint-disable-next-line
  }, [selectedCommunityId]);
  

  const refetchCourseData = async () => {
    const response = await getCourseByCourseId(selectedCommunityId, courseId);
    if (response.courses) {
      setCourse(response.courses);
      const allLinks = response.courses.flatMap((course: interfaceCourse) => course.links);
      setLinks(allLinks);
    }
  };

  //DRAG
  const [draggedItem, setDraggedItem] = useState<number | null>(null);
  const handleDragStart = (index: number) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index: number) => {
    if (draggedItem === null || index === draggedItem) {
      return;
    }

    const newLinks = [...links];

    const draggedLink = newLinks[draggedItem];

    newLinks.splice(draggedItem, 1);
    newLinks.splice(index, 0, draggedLink);
    setLinks(newLinks);
    setDraggedItem(index);
  };


  const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = (e.target.files); // Convert FileList to File[]
      
      handleFilesSelected(files);
    }
  };

  const handleAddLink = () => {
    setLinkInputs([...linkInputs, ""]); // Add a new input to the array
  };

  const handleDeleteLink = (index: number) => {
    const updatedInputs = linkInputs.filter((_, i) => i !== index);
    setLinkInputs(updatedInputs);
  };


  const handleInputChange = (value: any, index: any) => {
    const updatedInputs = [...linkInputs];
    updatedInputs[index] = value;
    setLinkInputs(updatedInputs);
  };

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenAddLinkDialog = () => {
    setOpenAddLinkDialog(true);
  };
  const handleCloseAddLinkDialog = () => {
    setOpenAddLinkDialog(false);
    setLinkItem(null)
    handleClose()
  };
  const handleOpenAddSectionDialog = () => {
    setOpenAddSection(true);
  }
  const handleCloseAddSectionDialog = () => {
    setOpenAddSection(false)
    handleClose()
  }
  const handleEditDocument = (e:any,item:any )=>{
    setEditDocument(true)
    setSelectedDocument(item)
  }
  const handleCloseEditDocument=()=>{
    setEditDocument(false)
  }

  const handleEditLink = (e: any, item: ILinks) => {
    setOpenAddLinkDialog(true)
    setLinkItem(item)
  }
  const openLinkPopOver = Boolean(deleteLinkAnchor);
  const idDeleteLinkPopover = openLinkPopOver
    ? "delete-popover"
    : undefined;
  const handleOpenDeleteLink = (e: any, item: ILinks) => {
    setDeleteLinkAnchor(e.currentTarget)
    setLinkItem(item)
  }
  
  const handleCloseLinkPopOver = () => {
    setDeleteLinkAnchor(null);

  };

  const handleSectionLinkDelete = async () => {
    if (links) {
      const deletingLink = links.filter(link => link._id !== linkItem?._id)
      setLoding(true)
      try {
        await updateCourse(selectedCommunityId, courseId, { links: deletingLink })
      } catch (error) {
        console.log(error);

      } finally {
        handleCloseLinkPopOver()
        refetchCourseData();
        setLoding(false)
      }
    }

  }

  return (
    <>
 {isCourseDataLoading ? <Loader/> :
    <Stack
      sx={{
        backgroundColor: "#fff",
        my: { xs: 0, sm: 0, md: 1 },
      }}
      spacing={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ color: "#3760A9", pt: 2,}}
      >
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "20px" },
            fontWeight: 500,
            color: "#3C5AA0",
            fontFamily: "Inter",
            px:2 
          }}
        >
          Courses
        </Typography>
        <Stack sx={{ px: 3 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" onClick={() => navigate(`/courses`)} sx={{
                  fontSize: { xs: "14px", md: "16px" },
                 cursor:'pointer', fontFamily:'Inter'}}>
              Courses
            </Link>
            <Typography color="#3C5AA0" sx={{
                  fontSize: { xs: "14px", md: "16px" },
                 cursor:'pointer', fontFamily:'Inter'}}>Details</Typography>
          </Breadcrumbs>
        </Stack>
      </Stack>
   
        <CourseInfoCard data={course} refetchCourseData={refetchCourseData}/>
       
      <Stack
        sx={{ justifyContent: "flex-end", alignItems: "flex-end", px: 2.3 }}
      >
        <CustomButton
          onClick={handleAddClick}
          children="Add"
          icon={<Add sx={{ fontSize: "13px" }} />}
          background={"#fff"}
          textColor={"#565656"}
          disable={false}
          loading={false}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ mr: "10px" }}
        >
          <MenuItem
            onClick={handleClose}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="file"
              id="course-file"
              accept=".jpg,.png,.mp3,.mp4,.mov,.docx,.pdf,.zip,.csv,.txt"
              style={{ display: "none" }} // Hide the input visually
              onChange={handleFiles}
            />
            <label htmlFor="course-file" style={{ cursor: "pointer" }}>
              <Stack
                flexDirection={"row"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Stack sx={{ mt: "5px" }}>
                  <FolderMenu />
                </Stack>
                <Typography
                  sx={{ fontSize: "10px", color: "#565656", fontWeight: "400" }}
                >
                  Upload File
                </Typography>
              </Stack>
            </label>
          </MenuItem>
          <MenuItem
            onClick={handleOpenAddLinkDialog}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Stack sx={{ mt: "5px" }}>
              <ShareLink />
            </Stack>
            <Typography
              sx={{ fontSize: "10px", color: "#565656", fontWeight: "400" }}
            >
              Add Link
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={handleOpenAddSectionDialog}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Stack sx={{ mt: "5px" }}>
              <AddSection />
            </Stack>
            <Typography
              sx={{ fontSize: "10px", color: "#565656", fontWeight: "400" }}
            >
              Add Section
            </Typography>
          </MenuItem>
        </Menu>
      </Stack>
      {/* test section */}
      {/* add files */}
      <DragAndAddFiles
        data={course}
        sectionPopUp={false}
        section={[]}
        refetchCourseData={refetchCourseData}
        onFilesSelected={handleFilesSelected}
        inputId={"course-file"}
      />
    <Stack sx={{ px: 2 }}>
        <TestSection data={course} selectedCommunityId={selectedCommunityId} refetchCourseData={refetchCourseData} />
      </Stack>

      <Stack sx={{ pl: 2 ,pt:1}}>
     
            <>
              {course[0]?.documents && 
                <Grid container spacing={2} sx={{ px: 2, pt: 0 }}>

                  {course[0]?.documents?.slice().reverse().map((item:any, index:number) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      key={index}
                      alignItems="center"
                      sx={{
                        p: 1,
                        backgroundColor: "#F0F9FF",
                        borderRadius: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack direction="row" alignItems="center">
                          <DragIndicatorOutlinedIcon
                            sx={{ color: "#565656", fontSize: "20px", pr: "3px" }}
                          />
                          <IconButton sx={{ p: 0 }}>
                          <FileCopyOutlined sx={{color:"#3C5AA0",fontSize:"20px"}}/>
                          </IconButton>
                          <Stack sx={{ px: "10px" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#565656",
                                fontFamily: "Roboto",
                                fontWeight: "600",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Link href={item.value} onClick={() => window.open(item.value, '_blank')} rel="noopener noreferrer">
                      <Typography
                        sx={{
                          fontSize: "8px",
                          color: "#565656",
                          fontFamily: "Roboto",
                        }}
                      >
                        Link
                      </Typography>
                    </Link>
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <EditIcon
                            sx={{ color: "#3C5AA0", fontSize: "20px", pr: "3px" }}
                            onClick={(e: any) => { handleEditDocument(e, item) }}
                          />
                          <Stack sx={{ ml: "10px" }} onClick={(e: any) => { handleDocumentDelete(e,item)}}>
                            <Bin />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                   ))} 

                </Grid>
               } 
            </>
     

      </Stack>
      
      <Stack sx={{ pt: 0, pl: 2 }}>
        <Grid container spacing={2} sx={{ px: 2, }}
        >
          {links?.slice().reverse().map((item, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: "#F0F9FF",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={() => handleDragOver(index)}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center">
                  <DragIndicatorOutlinedIcon
                    sx={{ color: "#565656", fontSize: "20px", pr: "3px" }}
                  />
                  <IconButton onClick={() => window.open(item.value, '_blank')} sx={{ p: 0 }}>
                    <LinkIcon sx={{ transform: 'rotate(135deg)', color: "#3C5AA0" }} />
                  </IconButton>
                  <Stack sx={{ px: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#565656",
                        fontFamily: "Roboto",
                        fontWeight: "600",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Link href={`https://${item.value}`}  target="_blank" rel="noopener noreferrer">
                      <Typography
                        sx={{
                          fontSize: "8px",
                          color: "#565656",
                          fontFamily: "Roboto",
                        }}
                      >
                        Link
                      </Typography>
                    </Link>
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <EditIcon
                    sx={{ color: "#3C5AA0", fontSize: "20px", pr: "3px" }}
                    onClick={(e: any) => { handleEditLink(e, item) }}
                  />
                  <Stack sx={{ ml: "10px" }} onClick={(e) => { handleOpenDeleteLink(e, item) }}>
                    <Bin />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>


      </Stack>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAddLinkDialog}
        onClose={handleCloseAddLinkDialog}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        {linkItem ? 
         <AddLinkPopUp
         handleCloseAddLinkDialog={handleCloseAddLinkDialog}
         linkInputs={linkInputs}
         handleInputChange={handleInputChange}
         handleDeleteLink={handleDeleteLink}
         handleAddLink={handleAddLink}
         sectionPopUp={false}
         data={course}
         section={""}
         refetchCourseData={refetchCourseData}
         linkData={""}
         courseLinks={linkItem}
       />
: 

        <AddLinkPopUp
        handleCloseAddLinkDialog={handleCloseAddLinkDialog}
        linkInputs={linkInputs}
        handleInputChange={handleInputChange}
        handleDeleteLink={handleDeleteLink}
        handleAddLink={handleAddLink}
        sectionPopUp={false}
        data={course}
        section={""}
        refetchCourseData={refetchCourseData}
        linkData={""}
        courseLinks={""}
      />}
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAddSection}
        onClose={handleCloseAddSectionDialog}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <AddSectionPopUp
          handleCloseAddSectionDialog={handleCloseAddSectionDialog}
          data={course}
          section={""}
          refetchCourseData={refetchCourseData}
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openEditDocument}
        onClose={handleCloseEditDocument}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
    <EditDocumentPopUp 
    handleCloseEditDocument={handleCloseEditDocument} 
    document={selectedDocument} 
    data={course[0]}
    refetchCourseData={refetchCourseData}
    />

      </Dialog>


      <Popover
        id={idDeleteLinkPopover}
        open={openLinkPopOver}
        anchorEl={deleteLinkAnchor}
        onClose={() => handleCloseLinkPopOver()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete Sectifron?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseLinkPopOver}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSectionLinkDelete}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>

      {/* delete document popover */}

      <Popover
        id={idDeleteDocument}
        open={openDeleteDocumentPopOver}
        anchorEl={deleteDocumentAnchor}
        onClose={() => handleCloseDocumentPopOver()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Are you sure you want to delete Section?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseDocumentPopOver}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteDocument}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Stack>
}
    </>
  );
};

export default AddFilesLinksCourse;
