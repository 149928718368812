import React, { useContext, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  // SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPlan, savePlan, updatePlan } from "../../services/plans.service";
import { AuthContext, IAuthContext } from "../../contexts/Auth.context";
import { ICommunity } from "../../models/communities.modal";
import store from "../../store";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Loader from "../Loader/Loader";
import { IMultiMedia } from "../../models/multi-media.model";
import Close from "@mui/icons-material/Close";
// import FormControlLabel from "@mui/material/FormControlLabel";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import "dayjs/locale/en"; // Import the English locale
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { getStaticValue } from "../../utils/StaticValues";
// import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import { InsertPhotoOutlined } from "@mui/icons-material";
import { getStaticValue } from "../../utils/StaticValues";

dayjs.extend(utc);
//Asynchronous load on
interface Community {
  name: string;
  _id: string;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
//
export interface IAddPlan {
  name: string;
  duration: string;
  interval: number;
  startDate: string;
  endDate: boolean;
  status: string;
  billingFrequency: string[];
  totalPlanValue: number;
  offerValue: number;
  promoCode: string;
  description: string;
  summary: string;
  image: IMultiMedia | "";
  document: string;
  community: string;
  images: IMultiMedia | "";
  isOfferEnds: boolean;
  offerEndsAfter: number;
  offerEndsDuration: string;
  offerEndDate: string;
  totalCount: string;
}
interface ISelectedCommunity {
  selectedCommunity: ICommunity[];
  closeDialog: () => void;
  loadPlans: () => void;
}

// duration dropdown values
const durationDropdownOptions: { key: string; value: string; label: string }[] =
  [
    { key: "YEAR", value: "Year", label: "Year" },
    { key: "MONTH", value: "Month", label: "Month" },
    { key: "WEEK", value: "Week", label: "Week" },
    { key: "DAY", value: "Day", label: "Day" },
  ];
const billingFrequencyOptions = [
  { key: "YEARLY", value: "Yearly", defaultChecked: false },
  { key: "HALF_YEARLY", value: "Half Yearly", defaultChecked: false },
  { key: "QUARTERLY", value: "Quarterly", defaultChecked: false },
  { key: "MONTHLY", value: "Monthly", defaultChecked: false },
  { key: "WEEKLY", value: "Weekly", defaultChecked: false },
  { key: "DAILY", value: "Daily", defaultChecked: false },
  { key: "ONE_TIME", value: "One Time", defaultChecked: false },
];

export const NewAddPlan: React.FC<ISelectedCommunity> = ({

  closeDialog,
  loadPlans,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = store.getState();
  const communitiesFromStore = useSelector(() => {
    return state?.communities;
  });
  const { communities } = communitiesFromStore;
  const communityState = store.getState();
  const currentCommunity = communityState.selectedCommunity.selectedCommunity;

  //   console.log(currentCommunity?.name);

  const { id } = useParams();

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [community, setCommunity] = React.useState(false);
  const [options, setOptions] = React.useState<Community[]>([]);
  const loading = community && options.length === 0;

  const [loadingButton, setLoadingButton] = useState(false);


  //label change logic start
  const [durationFieldValue, setDurationFieldValue] = useState<string>("");
  const [labelText, setLabelText] = useState("Year");
  const [open, setOpen] = React.useState(false);
  const [opensPublish, setOpensPublish] = React.useState(false);
  const [planImages, setPlanImages] = useState<FileList | null>(null);
  const [planDocuments, setPlanDocuments] = useState<FileList | null>(null);
  // eslint-disable-next-line
  const [error, setError] = useState(false);

  //attach file logic end
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState<Dayjs | null>(null);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [plans, setPlans] = useState<IAddPlan[]>([]);
  // to load data from the server
  useEffect(() => {
    if (!id) return;
    const fetchPlan = async () => {
      const { data } = await getPlan(getAccessToken(), id);
      const labelValue: any = durationDropdownOptions.find(
        (e) => e.key === data.duration
      );
      setDurationFieldValue(labelValue?.value);
      let tempBF = bFrequency.map((b) =>
        data.billingFrequency.includes(b.key)
          ? { ...b, defaultChecked: true }
          : b
      );

      setBFrequency(tempBF);
      setPlanValues({
        ...planValues,
        name: data.name,
        duration: data.duration,
        interval: data.interval,
        status: data.status,
        offerValue: data.offerValue,
        totalPlanValue: data.totalPlanValue,
        description: data.description,
        summary: data.summary,
        community: data.community,
        image: data.image,
        images: data.images,
        document: data.document,
        startDate: formateStartDate(data?.startDate),
        billingFrequency: data.billingFrequency,
        isOfferEnds: data?.isOfferEnds ?? false,
        offerEndsAfter: data?.offerEndsAfter ?? 0,
        offerEndsDuration: data?.offerEndsDuration ?? " ",
        offerEndDate: data?.offerEndDate ?? " ",
        totalCount: data?.totalCount,
      });
      //console.log(data.document.value, "Data");
    };

    fetchPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    let active = true;
    (async () => {
      let tempCommunity = communities.map((community) => {
        return {
          _id: community._id,
          name: community.name,
        };
      });
      await sleep(1e3); // For demo purposes.
      if (active) {
        setOptions([...tempCommunity]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (durationFieldValue) {
      const labelValue: any = durationDropdownOptions.find(
        (e) => e.value === durationFieldValue
      );
      setLabelText(`Set ${labelValue?.value}s`);
    }
  }, [durationFieldValue]);

  const [bFrequency, setBFrequency] = useState<
    { key: string; value: string; defaultChecked: boolean }[]
  >(billingFrequencyOptions);
  const [planValues, setPlanValues] = useState<IAddPlan>({
    name: "",
    duration: "",
    interval: 0,
    startDate: "",
    endDate: false,
    billingFrequency: [],
    status: "",
    totalPlanValue: 0,
    offerValue: 0,
    promoCode: "",
    description: "",
    summary: "",
    image: "",
    images: "",
    document: "",
    community: currentCommunity?._id ?? "",
    isOfferEnds: true,
    offerEndsAfter: 0,
    offerEndsDuration: "Year",
    offerEndDate: "",
    totalCount: "",
  });

  const formateStartDate = (date: string) => {
    const startDate = date.slice(0, 10);
    setSelectedStartDate(dayjs.utc(startDate));
    return startDate;
  };

  // Functions

  const handleMyDateChange = (e: any) => {
    // Create a new Date object from the user's input
    const selectedNewDate = new Date(e);
    const timezoneOffsetInMinutes = selectedNewDate.getTimezoneOffset();
    selectedNewDate.setMinutes(
      selectedNewDate.getMinutes() - timezoneOffsetInMinutes
    );

    const isoDateString = selectedNewDate.toISOString(); //to ISO

    setPlanValues({ ...planValues, startDate: isoDateString });
  };

  // const handleBillingFrequencyChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { checked, name } = event.target;

  //   let tempBF = bFrequency.map((b) =>
  //     b.key === name
  //       ? { ...b, defaultChecked: checked }
  //       : { ...b, defaultChecked: false }
  //   );

  //   setBFrequency(tempBF);

  //   const selectedBF = tempBF.filter((b) => b.defaultChecked === true);
  //   const selectedBFKeys = selectedBF.map((b) => b.key);
  //   setPlanValues({ ...planValues, billingFrequency: selectedBFKeys });
  // };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Ensure that offerValue does not exceed totalPlanValue

    if (name === "offerValue") {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue > planValues.totalPlanValue) {
        // console.error('Offer Price cannot exceed Actual Price');
        return;
      }
    }

    setPlanValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setError(value === "");
  };

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const handleSubmit = async (e: any, status: string) => {
    // console.log(planValues, "values");
    setIsSubmitClicked(true);
    e.preventDefault();
    planValues.community = currentCommunity?._id ?? "";
    planValues.status = status;

    try {
      setLoadingButton(true);
      setLoader(true); // Set loading to true when initiating the request
      if (id) {
        // Editing an existing plan
        const updateResponse = await updatePlan(
          id,
          getAccessToken(),
          planValues,
          planImages,
          planDocuments
        );
        if (updateResponse.data && updateResponse.status === 200) {
          enqueueSnackbar("Plan updated successfully", { variant: "success" });
          navigate("/plans");
          closeDialog();
        } else {
          enqueueSnackbar("Failed to update plan", { variant: "error" });
        }
      } else {
        const createResponse = await savePlan(
          currentCommunity?._id ?? "",
          getAccessToken(),
          planValues,
          planImages,
          planDocuments
        );

        // Set loading to true when initiating the request

        setLoader(true); // Set loading to true when initiating the request

        if (createResponse.data) {
          setPlans((prevPlans) => [...prevPlans, createResponse.data]);
          enqueueSnackbar("Plan created successfully", { variant: "success" });
          if (location.pathname === "/plans") {
            navigate("/plans");
          } else if (location.pathname === "/communities/create-community") {
            navigate("/communities/create-community");
          }

          closeDialog();
          loadPlans();
          setLoader(true);
        } else {
          enqueueSnackbar("Failed to create plan", { variant: "error" });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoader(false);
      setLoadingButton(false);
    }
  };

  const [checked] = useState<boolean>(false);




  //attach file new logic start
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const handleCommunitySelection = (event: any) => {
    setPlanValues({ ...planValues, community: event });
  };

  const handleDurationChange = (e: any) => {
    setPlanValues({ ...planValues, duration: e });
  };

  const handlePlanImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanImages(e.target.files);
    }
  };

  const handlePlanDocuments = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPlanDocuments(e.target.files);
    }
  };

  const handleClickOpenImg = () => {
    setOpen(true);
  };

  const handleClosesImg = () => {
    setOpen(false);
  };

  const handleClickOpenPublish = () => {
    setOpensPublish(true);
  };

  const handleClickClosesPublish = () => {
    setOpensPublish(false);
  };

  // const handleYesOrNo = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPlanValues({
  //     ...planValues,
  //     isOfferEnds: event.target.value === "true",
  //   });
  // };

  // const handleOfferEndsDuration = (
  //   event: SelectChangeEvent<string>,
  //   child: React.ReactNode
  // ) => {
  //   setPlanValues({ ...planValues, offerEndsDuration: event.target.value });
  // };

  const locations = useLocation();
  const IsTitleDisplay = locations.pathname.includes("/plans/new") && id;

  const hideCreateButton = location.pathname === `/communities/${id}`;

  const isDataAvailable =
    planValues.name &&
    planValues.duration &&
    planValues.totalPlanValue &&
    planValues.interval &&
    selectedStartDate === null &&
    planValues.startDate;


  const offerValue = planValues.offerValue;
  const totalPlanValue = planValues.totalPlanValue;
  const showOfferValue = offerValue > 0;
  const showTotalPlanValue = showOfferValue && offerValue !== totalPlanValue && totalPlanValue > 0;


  return (
    <div>
      {loading ? (
        <Loader /> // Replace with your loading indicator component
      ) : (
        <Stack direction={"column"} spacing={1}>
          <Stack sx={{ display: IsTitleDisplay ? "block" : "none" }}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                color: "#3760A9",
                fontFamily: "Inter",
              }}
            >
              {id ? "Publish" : "Create"} a Plan
            </Typography>
          </Stack>
          <Box sx={{ backgroundColor: "white", pl: 1, pr: 1, pb: 2, borderRadius: "5px" }}>
            <>
              <Grid container sx={{ borderRadius: "none" }}>
                <Grid item xs={12}>
                  <Stack sx={{ pt: 0.7 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        ml: 1,
                        fontSize: "13px",
                        color: "#2653A3",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        pl: 0.5,
                        pr: 0.5,
                      }}
                    >
                      Community
                    </Typography>

                    <Stack sx={{ m: 1, pt: 0.5, pl: 0.5, pr: 0.5 }}>
                      <Autocomplete
                        size="small"
                        id="asynchronous-demo"
                        fullWidth
                        open={community}
                        onOpen={() => {
                          setCommunity(true);
                        }}
                        onClose={() => {
                          setCommunity(false);
                        }}
                        onChange={(e, v) =>
                          handleCommunitySelection(v?._id ?? "")
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                        options={options}
                        loading={loading}
                        value={currentCommunity}
                        disabled
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Communities"
                            placeholder="Type and search communities"
                            sx={{ width: "100%", fontSize: "11px" }}
                            InputLabelProps={{
                              style: { fontSize: "13px", color: "#565656" },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Box
                sx={{
                  backgroundColor: "#E0E0E04D",
                  p: 2,
                  borderRadius: "10px",
                  mt: 1,
                }}
              >
                <Grid container sx={{ borderRadius: "10px" }}>
                  <Grid item xs={12} md={9}>
                    <Stack spacing={1} sx={{ m: 1 }}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          Plan Summary
                        </Typography>
                      </Stack>
                      <Stack>
                        <Stack>
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "13px",
                              color: "#2653A3",
                              fontWeight: "500",
                              fontFamily: "Inter",
                            }}
                          >
                            Plan Name
                          </Typography>
                          <TextField
                            required
                            name="name"
                            label="Name"
                            size="small"
                            onChange={handleChanges}
                            value={planValues?.name}
                            // InputProps={{ style: { height: "45px" } }}
                            InputLabelProps={{
                              style: { fontSize: "13px", color: "#565656" },
                            }}
                            sx={{
                              mt: 1,

                              mr: 1,
                              mb: 1,
                              fontSize: "13px",
                              backgroundColor: "white",
                            }}
                          ></TextField>
                        </Stack>
                      </Stack>

                      <Stack>
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "#2653A3",
                              fontWeight: "500",
                              fontFamily: "Inter",
                            }}
                          >
                            Plan Description
                          </Typography>
                          <TextField
                            name="description"
                            id="description"
                            //placeholder="Describe your plan in short words..."
                            label="Description"
                            size="medium"
                            value={planValues?.description}
                            onChange={handleChanges}
                            required
                            multiline
                            rows={2}
                            InputLabelProps={{
                              style: { fontSize: 13, color: "#565656" },
                            }}
                            sx={{ mt: 1, mr: 1, backgroundColor: "white" }}
                          ></TextField>
                          {/* <TextField
                            placeholder="Highlight key features about plan."
                            label="Summary"
                            name="summary"
                            id="summary"
                            onChange={handleChanges}
                            size="medium"
                            required
                            multiline
                            value={planValues.summary}
                            rows={2}
                            InputLabelProps={{
                              style: { fontSize: "13px", color: "#565656" },
                            }}
                            sx={{ mt: 1, ml: 1, mr: 1, fontSize: "13px" }}
                          ></TextField> */}
                        </Stack>
                        <Typography
                          sx={{
                            ml: 1,
                            mt: 1,
                            fontSize: "13px",
                            color: "#5E6C84",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          * The Plan Name and Plan Description will appear on
                          the invoice as entered above.
                        </Typography>
                      </Stack>
                      <Divider></Divider>
                    </Stack>

                    <Stack sx={{ m: 1 }}>
                      <Stack sx={{ mt: 1 }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "15px",
                            fontWeight: 500,
                            ml: 1,
                          }}
                        >
                          Billing Details
                        </Typography>
                      </Stack>

                      <Grid container spacing={{ xs: 1, md: 5 }}>
                        <Grid item xs={12} md={6}>
                          <Stack sx={{}}>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#2653A3",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              Billing Frequency
                            </Typography>
                            {/* <Typography variant="body1" sx={{ fontSize: "12px" }}>
                          Set your plan duration.
                        </Typography> */}
                          </Stack>{" "}
                          <Grid container spacing={1} sx={{ mt: 1 }}>
                            <Grid item sm={6} xs={6} md={6} lg={6} xl={6}>
                              <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">
                                  Frequency
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="duration"
                                  label="Plan Duration"
                                  name="duration"
                                  disabled={checked}
                                  sx={{ backgroundColor: "white" }}
                                  // style={{ height: "45px",marginTop:1}}

                                  onChange={
                                    (e) => {
                                      setDurationFieldValue(e.target.value);
                                    }
                                    // setLabelText(e.target.value) }
                                  }
                                  value={durationFieldValue}
                                >
                                  {durationDropdownOptions.map((option) => (
                                    <MenuItem
                                      key={option.key}
                                      value={option.value}
                                      onClick={(e) =>
                                        handleDurationChange(option.key)
                                      }
                                    >
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item sm={6} xs={6} md={6}>
                              <TextField
                                sx={{ backgroundColor: "white" }}
                                name="interval"
                                id="interval"
                                label={labelText}
                                variant="outlined"
                                size="small"
                                disabled={checked}
                                fullWidth
                                onChange={handleChanges}
                                type="number"
                                InputProps={{
                                  inputProps: { min: 0 },
                                }}
                                value={
                                  planValues.interval !== 0
                                    ? planValues.interval
                                    : " "
                                }
                              />
                            </Grid>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#5E6C84",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              * You can set billing cycle (start date and end
                              date) and trial period later while, creating a
                              subscription.
                            </Typography>
                            <label htmlFor="otherField"></label>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack sx={{ m: 1 }}>
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  ml: 1,
                                  mt: 1,
                                  fontSize: "13px",
                                  color: "#2653A3",
                                  fontWeight: "500",
                                  fontFamily: "Inter",
                                }}
                              >
                                Start Date
                              </Typography>
                            </Stack>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DesktopDatePicker"]}
                                sx={{
                                  overflow: "hidden",
                                  backgroundColor: "white",
                                  mt: 1,
                                  p: 0,
                                }}
                              >
                                <DatePicker
                                  value={selectedStartDate}
                                  label="Starts from"
                                  disablePast
                                  onChange={handleMyDateChange}
                                  sx={{
                                    fontSize: "12px",
                                    backgroundColor: "white",
                                    "& .css-q9e13y-MuiStack-root>.MuiTextField-root":
                                    {
                                      backgroundColor: "white",
                                    },
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      fullWidth: true,
                                      sx: {
                                        "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error":
                                        {
                                          // Styling for the label
                                          color: "#00000061",
                                        },
                                        "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                        {
                                          // Styling for the input box
                                          borderColor: "green",
                                          border: "1px solid",
                                        },
                                        "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                                        {
                                          color: "#92929D",
                                          fontSize: "13px",
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                              {/* {isSubmitClicked && !planValues?.startDate && (
                                <Typography
                                  style={{ color: "red", fontSize: "8px" }}
                                >
                                  Start date is required!
                                </Typography>
                              )} */}
                            </LocalizationProvider>
                            {/* <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox sx={{ transform: "scale(1.1)", color: '#92929D' }} />
                              }
                              onChange={(e) => handleCheck(e)}
                              label="* Immediate, subscriptions starts with the first payment."

                              sx={{
                                fontSize: "13px",
                                color: "#5E6C84",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                '& .MuiFormControlLabel-label': {
                                  // Add styles for the label using inputProps

                                  fontSize: { xs: "13px", md: '13px' },
                                color: "#5E6C84",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                }
                              }}
                            />
                          </FormGroup> */}


                            {/* <Grid item xs={6} md={6}>
                          <Stack direction={"row"} sx={{ display: "flex" }}>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#2653A3",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              Total Count
                            </Typography>
                            <Typography
                              sx={{
                                ml: 2,
                                mt: 1,
                                fontSize: "13px",
                                color: "#5E6C84",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              (*No of billing cycles to be charged)
                            </Typography>
                            
                          </Stack>

                          <TextField
                            sx={{
                              mt: 2,
                              backgroundColor: "white",
                              fontSize: "12px",
                            }}
                            required
                            fullWidth
                            onChange={handleChanges}
                            name="totalCount"
                            id="totalCount"
                            value={planValues?.totalCount}
                            label="Total Count"
                            size="small"
                            type="number"
                            InputLabelProps={{
                              style: { fontSize: 17, color: "#565656" },
                            }}
                          ></TextField>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox sx={{ transform: "scale(1.1)", color: '#92929D' }} />
                              }
                              onChange={(e) => handleCheck(e)}
                              label="Mark as never ends"

                              sx={{
                                fontSize: "13px",
                                color: "#5E6C84",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                '& .MuiFormControlLabel-label': {
                                  // Add styles for the label using inputProps

                                  fontSize: { xs: "13px", md: '13px' },
                                color: "#5E6C84",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                }
                              }}
                            />
                          </FormGroup>
                        </Grid> */}

                            {/* <Divider sx={{ mt: 2 }}></Divider> */}
                          </Stack>

                        </Grid>
                      </Grid>

                      <Grid container spacing={5} sx={{ pr: 1 }}>
                        <Grid item xs={6}>
                          <Stack sx={{}}>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#2653A3",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              Actual Price
                            </Typography>
                          </Stack>
                          <TextField
                            sx={{ mt: 2, backgroundColor: "white" }}
                            required
                            fullWidth
                            onChange={handleChanges}
                            name="totalPlanValue"
                            id="totalPlanValue"
                            value={
                              planValues.totalPlanValue !== 0
                                ? planValues.totalPlanValue
                                : " "
                            }
                            label="Actual Price"
                            size="small"
                            type="number"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                            InputLabelProps={{

                              style: { fontSize: 17, color: "#565656", },
                            }}
                            InputProps={{

                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyRupeeIcon
                                    sx={{
                                      // backgroundColor: "#D9D9D9",
                                      color: "black",
                                      fontSize: "16px",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                              // style: { height: "45px" },
                            }}
                          ></TextField>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 1,
                              fontSize: "13px",
                              color: "#5E6C84",
                              fontWeight: "500",
                              fontFamily: "Inter",
                            }}
                          >
                            * Billing amount and billing frequency can not be
                            changed later.
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Stack sx={{}}>
                            <Typography
                              sx={{
                                ml: 1,
                                mt: 1,
                                fontSize: "13px",
                                color: "#2653A3",
                                fontWeight: "500",
                                fontFamily: "Inter",
                              }}
                            >
                              Offer Price
                            </Typography>
                          </Stack>{" "}
                          <TextField
                            sx={{ mt: 2, backgroundColor: "white" }}
                            fullWidth
                            name="offerValue"
                            id="offerValue"
                            value={
                              planValues.offerValue !== 0
                                ? planValues.offerValue
                                : " "
                            }
                            onChange={handleChanges}
                            label="Offer Price"
                            size="small"
                            type="number"
                            InputLabelProps={{
                              sx: { fontSize: 17, color: "#565656" },
                            }}
                            InputProps={{
                              inputProps: { min: 0 },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyRupeeIcon
                                    sx={{
                                      // backgroundColor: "#D9D9D9",
                                      color: "black",
                                      fontSize: "16px",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                              // style: { height: "45px" },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 2 }}></Divider>
                    </Stack>



                    <Stack>
                      <Stack sx={{ pt: 1 }}>
                        <Typography
                          sx={{
                            ml: 1,
                            fontSize: "13px",
                            color: "#2653A3",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Attachments
                        </Typography>
                        <Typography
                          sx={{
                            ml: 1,
                            fontSize: "12px",
                            // color: "#292D32",
                          }}
                        >
                          * Upload additional documents that provide more
                          information.
                        </Typography>
                      </Stack>

                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} >
                          <Stack sx={{ backgroundColor: 'white', p: 1.5, m: 1, borderRadius: '5px' }}>
                            <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}>Attach Image</Typography>
                            <input
                              type="file"
                              name="images"
                              onChange={handlePlanImages}
                              style={{
                                border: '2px solid #5E6C84',
                                borderRadius: '5px',
                                padding: '8px',
                                backgroundColor: '#ffffff',
                                color: '#5E6C84',
                                fontSize: '12px',
                                fontWeight: '500',
                                cursor: 'pointer',
                                width: '70%',

                              }}
                            // value={planValues?.images ? planValues?.images?.label : ''}
                            ></input>

                            {planValues?.image && (
                              <Button

                                variant="outlined"
                                onClick={handleClickOpenImg}
                                sx={{
                                  textTransform: "capitalize",
                                  mt: { xs: 2, md: 1.5 },
                                  fontSize: "13px",
                                  fontWeight: 'defult',
                                  width: '160px',
                                  color: "#5E6C84",
                                  border: '1px solid #5E6C84',
                                  ":hover": {
                                    color: "#5E6C84",
                                    border: '1px solid #5E6C84'
                                  }
                                }}
                                startIcon={<InsertPhotoOutlined />}
                              >
                                Preview Image
                              </Button>
                            )}
                            {/* Pop Up for About us Content */}
                            <Dialog
                              fullWidth={fullWidth}
                              maxWidth={maxWidth}
                              open={open}
                              onClose={handleClosesImg}
                            >
                              <DialogActions
                                sx={{ backgroundColor: "#F0F9FF" }}
                              >
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  sx={{ backgroundColor: "#F0F9FF" }}
                                >
                                  <Stack></Stack>
                                  <Stack></Stack>
                                  <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                                    <Box
                                      onClick={handleClosesImg}
                                      sx={{
                                        backgroundColor: "#3C5AA0",
                                        padding: "4px",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                        fontSize: "10px",
                                        mt: "0.5px",
                                        mr: "10px",
                                        cursor: "pointer",
                                        "&:hover": {
                                          // backgroundColor: "#50A1CA", // Background color on hover
                                          cursor: "pointer",
                                          border: "none",
                                        },
                                      }}
                                    >
                                      <Close sx={{ fontSize: "13px" }} />
                                    </Box>
                                  </Stack>
                                </Stack>
                              </DialogActions>

                              <DialogContent>
                                <img
                                  alt=""
                                  // onClick={() => navigate(`/plans/${plan?._id}`)}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "300px",
                                    objectFit: "cover",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                  src={
                                    planValues?.images
                                      ? planValues?.images?.value
                                      : " "
                                  }
                                ></img>
                              </DialogContent>
                            </Dialog>

                          </Stack>

                        </Grid>
                        <Grid item xs={12} md={5.9}  >
                          <Stack sx={{ backgroundColor: 'white', p: 1.5, m: 1, borderRadius: '5px' }}>
                            <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}>Attach Document</Typography>
                            <input
                              type="file"
                              name="documents"
                              onChange={handlePlanDocuments}
                              style={{
                                border: '2px solid #5E6C84',
                                borderRadius: '5px',
                                padding: '8px',
                                backgroundColor: '#ffffff',
                                color: '#5E6C84',
                                fontSize: '12px',
                                fontWeight: '500',
                                cursor: 'pointer',
                                width: '70%',

                              }}
                            ></input>

                            {planValues?.document ? (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                href={
                                  typeof planValues?.document === "object" &&
                                    "value" in planValues?.document
                                    ? (
                                      planValues?.document as {
                                        value: string;
                                      }
                                    ).value
                                    : ""
                                }
                              >
                                <Button
                                  variant="outlined"
                                  onClick={handleClickOpenImg}
                                  sx={{
                                    textTransform: "capitalize",
                                    mt: { xs: 2, md: 1.5 },
                                    fontSize: "13px",
                                    fontWeight: 'defult',
                                    width: '200px',
                                    color: "#5E6C84",
                                    border: '1px solid #5E6C84',
                                    ":hover": {
                                      color: "#5E6C84",
                                      border: '1px solid #5E6C84'
                                    }
                                  }}
                                  startIcon={<CloudDownloadOutlinedIcon />}
                                >
                                  Download Document
                                </Button>
                              </a>
                            ) : (
                              ""
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                      {/* <Stack
                        direction="row"
                        sx={{
                          
                          m:2,
                          backgroundColor:'white',
                          p: 2,
                          borderRadius: "5PX",
                        }}
                      >
                        <Grid container spacing={10}>
                          <Grid item xs={12} md={6}  >
                            <input
                              type="file"
                              name="images"
                              onChange={handlePlanImages}
                              // value={planValues?.images ? planValues?.images?.label : ''}
                            ></input>

                            {planValues?.image && (
                              <Button
                                variant="outlined"
                                onClick={handleClickOpenImg}
                                sx={{
                                  textTransform: "capitalize",
                                  mt: { xs: 2, md: 0 },
                                  fontSize: "10px",
                                }}
                              >
                                Preview Image
                              </Button>
                            )}
                           
                            <Dialog
                              fullWidth={fullWidth}
                              maxWidth={maxWidth}
                              open={open}
                              onClose={handleClosesImg}
                            >
                              <DialogActions
                                sx={{ backgroundColor: "#F0F9FF" }}
                              >
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  sx={{ backgroundColor: "#F0F9FF" }}
                                >
                                  <Stack></Stack>
                                  <Stack></Stack>
                                  <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                                    <Box
                                      onClick={handleClosesImg}
                                      sx={{
                                        backgroundColor: "#3C5AA0",
                                        padding: "4px",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                        fontSize: "10px",
                                        mt: "0.5px",
                                        mr: "10px",
                                        cursor: "pointer",
                                        "&:hover": {
                                      
                                          cursor: "pointer",
                                          border: "none",
                                        },
                                      }}
                                    >
                                      <Close sx={{ fontSize: "13px" }} />
                                    </Box>
                                  </Stack>
                                </Stack>
                              </DialogActions>

                              <DialogContent>
                                <img
                                  alt=""
                                  
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "300px",
                                    objectFit: "cover",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                  src={
                                    planValues?.images
                                      ? planValues?.images?.value
                                      : " "
                                  }
                                ></img>
                              </DialogContent>
                            </Dialog>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6} xl={6}>
                            <input
                              type="file"
                              name="documents"
                              onChange={handlePlanDocuments}
                            ></input>

                            {planValues?.document ? (
                             
                              <a
                                href={
                                  typeof planValues?.document === "object" &&
                                  "value" in planValues?.document
                                    ? (
                                        planValues?.document as {
                                          value: string;
                                        }
                                      ).value
                                    : ""
                                }
                              >
                                <Tooltip
                                  title="View Document"
                                  placement="right-start"
                                >
                                  <IconButton>
                                    <CloudDownloadOutlinedIcon
                                      sx={{
                                        cursor: "pointer",
                                        color: "#2952A2",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </a>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </Stack> */}
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "5px",

                    }}

                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        height: "100vh",
                        p: 1,
                      }}
                    >
                      <Stack>
                        <Typography
                          sx={{
                            ml: 1,
                            mt: 1,
                            fontSize: "13px",
                            color: "#2653A3",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Your Subscription at{" "}
                        </Typography>
                        <Typography
                          sx={{
                            ml: 1,

                            fontSize: "13px",
                            color: "#000000",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          {currentCommunity?.name}
                        </Typography>

                        <Divider sx={{ mt: 1 }}></Divider>
                      </Stack>
                      <Stack sx={{ m: 1 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: "#2653A3",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Subscription Plan{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: "#000000",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          {planValues?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#000000",
                            fontFamily: "Inter",
                          }}
                        >
                          {planValues?.description}
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: "13px",
                            color: "#000000",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            backgroundColor: "#E0E0E0",
                            textAlign: "left",
                            justifyContent: "left",
                            height: "35px",
                            mt: 1,
                            boxShadow: "none",
                            ":hover": {
                              backgroundColor: "#E0E0E0",
                              boxShadow: "none",
                            },
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#000000",
                              fontSize: "2vh",

                            }}
                          >
                            {showOfferValue && `₹ ${offerValue}/-`}
                            {!showOfferValue && totalPlanValue > 0 && `₹ ${totalPlanValue}/-`}
                          </Typography>
                          {showTotalPlanValue && (
                            <Stack
                              direction={"row"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"left"}
                            >
                              <Typography sx={{ color: "#787878", fontSize: "13px", }}>₹ </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#787878",
                                  fontSize: "2vh",
                                  textDecorationLine: "line-through",
                                }}
                              >
                                {" "} {totalPlanValue}/-
                              </Typography>
                            </Stack>
                          )}
                        </Button>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            mt: 1,
                            color: "#000000",
                            fontFamily: "Inter",
                            textTransform: 'capitalize'
                          }}
                        >
                          Billed every {" "}
                          {planValues?.interval}{" "}{planValues?.interval > 1 ? `${getStaticValue(planValues?.duration)}s` : getStaticValue(planValues?.duration)}
                        </Typography>
                      </Stack>

                      <Divider sx={{ mt: 1 }}></Divider>

                      {/* <Stack sx={{ m: 1 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: "#2653A3",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Payment Details
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: "#000000",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Next Due on
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#000000",
                            fontFamily: "Inter",
                          }}
                        >
                          {planValues?.startDate}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: "#000000",
                            fontWeight: "500",
                            fontFamily: "Inter",
                          }}
                        >
                          Recurring Amount
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#000000",
                            fontFamily: "Inter",
                          }}
                        >
                          ₹ - {planValues?.offerValue}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#000000",
                            fontFamily: "Inter",
                          }}
                        >
                          * Billed every {planValues?.interval}{" "}
                          {planValues?.duration}
                        </Typography>
                      </Stack>
                      <Divider sx={{ mt: 1 }}></Divider> */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} md={12}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        background:
                          "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                        textTransform: "capitalize",
                        border: "none",
                        borderRadius: "10px",
                        boxShadow: "none",
                        color: "white",
                        // display: hideCreateButton ? "none" : "block",
                        "&:hover": {
                          background:
                            "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                          cursor: "pointer",
                          color: "white",
                          border: "none",
                          boxShadow: "none",
                        },
                      }}
                      onClick={handleClickOpenPublish}
                      // disabled={!isDataAvailable && !id}
                    >
                      Publish

                    </Button>
                    {location.pathname === "/plans" && (
                      <Button
                        variant="outlined"
                        size="large"
                        sx={{
                          textTransform: "capitalize",
                          // fontWeight:600,
                          color: "#50A1CA",
                          border: "1px solid #50A1CA",
                          borderRadius: "10px",
                          boxShadow: "none",
                          "&:hover": {
                            cursor: "pointer",
                            border: "1px solid #50A1CA",
                            boxShadow: "none",
                          },
                        }}
                        onChange={(e: any) => setPlanValues(e.target.value)}
                        // onClick={handleSubmit}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={(e) => handleSubmit(e, "CREATED")}
                        disabled={!isDataAvailable && !id}
                      >
                        {loadingButton ? (
                          <CircularProgress size={24} sx={{ color: "white" }} />
                        ) : (
                          id ? "Update" : "Create"
                        )}

                      </Button>
                    )}

                    {/* Pop Up for About us Content */}
                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={opensPublish}
                      onClose={handleClickClosesPublish}
                    >
                      <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ backgroundColor: "#F0F9FF" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: 500,
                              textDecoration: "none",
                              color: "#3C5AA0",

                              marginLeft: 2,
                            }}
                          >
                            {"Publish the Plan?"}
                          </Typography>
                          <Box
                            onClick={handleClickClosesPublish}
                            sx={{
                              backgroundColor: "#3C5AA0",
                              padding: "4px",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              fontSize: "10px",
                              mt: "0.5px",
                              mr: "10px",
                              cursor: "pointer",
                              "&:hover": {
                                cursor: "pointer",
                                border: "none",
                              },
                            }}
                          >
                            <Close sx={{ fontSize: "15px" }} />
                          </Box>
                        </Grid>
                      </DialogActions>

                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          sx={{ fontSize: "13px", textAlign: "center" }}
                        >
                          Once you publish your subscription plan, you cannot
                          make any more changes to it. This is to be transparent
                          and build trust with subscribers. Are you ready to
                          publish the plan?.
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button
                          onChange={(e: any) => setPlanValues(e.target.value)}
                          onClick={(e) => handleSubmit(e, "ACTIVE")}
                          size="large"
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                            textTransform: "capitalize",
                            border: "none",
                            borderRadius: "10px",
                            boxShadow: "none",
                            color: "white",
                            "&:hover": {
                              background:
                                "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                              cursor: "pointer",
                              color: "white",
                              border: "none",
                              boxShadow: "none",
                            },
                          }}
                        >

                          {loadingButton ? (
                            <CircularProgress size={24} sx={{ color: "white" }} />
                          ) : (
                            "Publish"
                          )}

                        </Button>
                        <Button
                          size="large"
                          sx={{
                            textTransform: "capitalize",
                            // fontWeight:600,
                            color: "#50A1CA",
                            border: "1px solid #50A1CA",
                            borderRadius: "10px",
                            boxShadow: "none",
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid #50A1CA",
                              boxShadow: "none",
                            },
                          }}
                          onClick={handleClickClosesPublish}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Stack>
                </Grid>
              </Grid>
            </>
          </Box>
        </Stack>
      )}
    </div>
  );
};
