import { InputBase, styled } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

export const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
    backgroundColor: "#fff",
  },

  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  backgroundColor: theme.palette.common.white,
  
 
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  width: "100%",
  position: "relative",
  boxShadow: "none",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
 padding:theme.spacing(0,0.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
 
   left:295,
   color:'red',
   borderTopRightRadius:'30px',
   borderBottomRightRadius:'30px',
   background:'linear-gradient(90deg, #3C5AA0 0%, #50A1CA 100%);'
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
    border:'1px solid #E0E0E0',
    borderTopLeftRadius:'30px',
    borderBottomLeftRadius:'30px',
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

export const styles = {
  flexGrow: 1,
  "& .MuiToolbar-root": {
    // pl: 0,
  },
};
