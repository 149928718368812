import React, { useState, useEffect } from "react";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popover,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { usePlans } from "../../hooks/usePlans";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { SubNavbar } from "../Layout/components/SubNavbar";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import store from "../../store";
import { getStaticValue } from "../../utils/StaticValues";
import { NoPlans } from "../AlternatePages/noPlans";
import { Helmet } from "react-helmet";
import { IMultiMedia } from "../../models/multi-media.model";
import Close from '@mui/icons-material/Close'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ICommunity } from "../../models/communities.modal";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { NewAddPlan } from "./NewAddPlan";
import { metaConfig } from "../../utils/constants";

interface IParam {
  name: string;
  value: string;
  icon: string;
}
export interface IPlan {
  _id: string;
  name: string;
  description: string;
  image: string;
  parameters: IParam[];
  createdAt: string;
  updatedAt: string;
  status: string;
  duration: string;
  interval: string;
  billingFrequency: string;
  summary: string;
  promocode: string;
  totalPlanValue: number;
  startDate: string;
  offerValue: number;
  community: string;
  images: IMultiMedia | "";
  documents: IMultiMedia | "";
  document: IMultiMedia | "";
  communityData: ICommunity;
  offerEndsDuration: string;
  subscribers: string;

}
interface IPlanProps {
  plan: IPlan;
  onClick?: () => void;
  loadPlans: () => void;
}


export const Plan = ({ plan, loadPlans }: IPlanProps) => {

  const { deletePlanById } = usePlans();
  // eslint-disable-next-line
  const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openImg, setOpensimg] = React.useState(false);
  // eslint-disable-next-line
  const [openDocs, setOpensDocs] = React.useState(false);
  const [openPreview, setOpensPreview] = React.useState(false);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>, row: IPlan) => {
    setOpen(true);
    setAnchorEl(e.currentTarget)
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClose = () => setOpen(false);
  const [fullWidth,] = React.useState(true);
  const [maxWidth,] = React.useState<DialogProps["maxWidth"]>("lg");
  const [maxImgWidth,] = React.useState<DialogProps["maxWidth"]>("sm");
  const [showFullDescription, setShowFullDescription] = useState(false);


  const formatDate = (dateString: string | number | Date) => {
    const originalDate = new Date(dateString);
    return format(originalDate, "do MMM. yyyy");
  };

  // chip date format
  const dateString = plan?.startDate; // Replace this with your actual date string
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleDateString("en-US");

  const handleDeletePlan = async () => {
    if (plan) {
      setSelectedPlan(plan); // Set the selected plan before deletion
      deletePlanById(plan?._id);
      setOpen(false);
      loadPlans();
    }
  };

  const handleClickOpenImg = () => {
    setOpensimg(true);
  };

  const handleClosesImg = () => {
    setOpensimg(false);
  };


  const handleClickOpenDocs = () => {
    setOpensDocs(true);
  };



  const handleClickOpenPreview = () => {
    setOpensPreview(true);
  };

  const handleClickClosesPreview = () => {
    setOpensPreview(false);
  };


  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const created = plan?.createdAt;
  const updated = plan?.updatedAt;




  // console.log("Plans :", plan);




  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };








  return (
    <>
      {" "}

      <Paper sx={{ height: {} }}  >
        <Grid container spacing={{ xs: 1, sm: 1, md: 5 }} p={2}>
          <Grid item xs={0} md={3} lg={3} xl={3}>
            <Stack sx={{ display: { xs: "none", md: "block" } }}>
              <Box
                // onClick={handleClickOpenImg}
                component={"img"}
                src={(plan?.images) ? plan?.images?.value : require("./../../assets/images/NoPlanImage.png")}
                alt=""
                sx={{
                  width: "100%",
                  height: { xs: "15vh", md: "20vh", },
                  // maxHeight: '25vh',
                  objectFit: 'cover',
                  borderRadius: "10px",
                  // cursor: "pointer",
                }}
              />
              {/* Pop Up for Image Display */}
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxImgWidth}
                open={openImg}
                onClose={handleClosesImg}

              >
                <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ backgroundColor: '#F0F9FF' }}
                  >
                    <Stack></Stack>
                    <Stack>

                    </Stack>
                    <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                      <Box
                        onClick={handleClosesImg}
                        sx={{
                          backgroundColor: "#3C5AA0",
                          padding: "4px",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontSize: "10px",
                          mt: "0.5px",
                          mr: "10px",
                          cursor: "pointer",
                          "&:hover": {

                            cursor: "pointer",
                            border: "none",
                          },
                        }}
                      >
                        <Close sx={{ fontSize: "15px" }} />
                      </Box>
                    </Stack>
                  </Stack>
                </DialogActions>


                <DialogContent>
                  <img
                    alt=""
                    // onClick={() => navigate(`/plans/${plan?._id}`)}
                    style={{
                      width: "100%",
                      height:'250px',
                      maxHeight: '250px',
                      objectFit: 'cover',
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    src={(plan?.images) ? plan?.images?.value : require("./../../assets/images/NoPlanImage.png")}

                  ></img>


                </DialogContent>
              </Dialog>
            </Stack>
          </Grid>
          <Grid item xs={6} md={6} lg={6} xl={6} sx={{ paddingLeft: { xs: '20px', md: '40px' } }} >
            <Stack direction={"column"} spacing={1}>
              <Stack sx={{ display: { xs: 'none', md: 'block' } }}>
                <Chip
                  label={
                    plan?.interval + " " + getStaticValue(plan?.duration) + " - Starts From - " + formattedDate
                  }
                  style={{ width: "50%", fontWeight: 600, border: '1px solid #3C5AA0', backgroundColor: 'transparent', fontFamily: 'Inter' }}
                ></Chip>
              </Stack>

              <Typography variant="h5" sx={{ fontWeight: 500, fontSize: { xs: '16px', md: '20px' }, color: '#000000', fontFamily: 'Inter' }}>
                {(plan?.name.length < 30) ? plan?.name : plan?.name.slice(0, 30) + ".."}
              </Typography>
              <Typography
                variant="caption"
                paragraph={true}
                className="description"
                sx={{ color: '#565656', fontSize: '13px', display: { xs: 'none', md: 'block' }, fontFamily: 'Inter' }}
              >
                {(plan?.description.length < 310) ? plan?.description : plan?.description.slice(0, 310) + "..."}

              </Typography>
              <Typography
                variant="caption"
                paragraph={true}
                className="description"
                sx={{ color: '#565656', fontSize: '13px', display: { xs: 'block', md: 'none' }, fontFamily: 'Inter' }}
              >
                {(plan?.description.length < 70) ? plan?.description : plan?.description.slice(0, 70) + "..."}

              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={5.5} md={3} lg={3} xl={3} sx={{}}>
            <Stack
              display={"flex"}
              justifyContent={"right"}
              alignItems={"center"}
              direction={"row"}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  marginRight: "4px",

                  animation: "wave 1s infinite alternate",
                  backgroundColor: plan?.status === "CREATED" ? "#ff4f00" : "#27AE1B",
                }}
              ></span>
              <Typography sx={{ fontSize: { xs: "13px", md: "15px", fontFamily: 'Inter', fontWeight: '500' } }}>
                {getStaticValue(plan?.status)}
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: 2, }} direction={"row"} display={"flex"} alignItems={'end'} >
              <Stack
                direction={"row"}
                alignItems="end"
                justifyContent={"end"}
                spacing={2}
                sx={{ flexGrow: 1, mr: 0 }}
              >
                <GroupsIcon sx={{ color: '#787878' }} />
                <Stack alignItems={"center"} spacing={1} justifyContent={"right"}>

                  <Typography sx={{ color: "#565656", fontWeight: "400", fontSize: "12px", textAlign: 'end', fontFamily: 'Inter' }}>
                    {plan?.subscribers?.length > 1 ? `${plan.subscribers.length} Subscribers` : 'No Subscribers'}
                  </Typography>

                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"right"}
            >
              <Typography sx={{ color: "#787878", fontFamily: 'Inter' }}>₹</Typography>
              <Typography
                variant="subtitle1"
                color="#3C5AA0"
                fontWeight={"500"}
                sx={{ fontSize: { xs: "16px", md: "18px" }, fontFamily: 'Inter' }}
              >
                {(plan?.offerValue === 0) ? plan?.totalPlanValue : plan?.offerValue} / {plan?.interval}{" "}{plan?.interval > "1" ? `${getStaticValue(plan?.duration)}s` : getStaticValue(plan?.duration)}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"right"}
            >
              {plan.offerValue !== 0 ? (
                <>
                  <Typography sx={{ color: '#787878' }}>₹</Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#787878',
                      fontSize: { xs: "14px", md: "16px" },
                      textAlign: 'right',
                      textDecorationLine: 'line-through',
                      fontFamily: 'Inter'
                    }}
                  >
                    {plan?.totalPlanValue}
                  </Typography>
                </>
              ) : (
                <></>
              )
              }
            </Stack>
          </Grid>
        </Grid>

        <Stack>
          <Divider />
        </Stack>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', p: "3px" }}>
          <Grid item xs={2} md={3} lg={3} xl={3}>
            <Stack alignItems={"center"} sx={{}}>

              {(plan?.document) ? (
                <a href={(plan?.document) ? plan?.document?.value : ""} download>
                  <Tooltip title="Download Document" placement="left-start">
                    <IconButton>
                      <CloudDownloadOutlinedIcon sx={{
                        cursor: 'pointer', color: '#2952A2', "&:hover": {
                          color: "#3C5AA0",
                        }
                      }} onClick={handleClickOpenDocs} />
                    </IconButton>
                  </Tooltip>
                </a>
              ) :
                ""}

            </Stack>
          </Grid>


          <Grid item xs={3} md={3} lg={3} xl={3} sx={{ ml: -3 }}>
            <Stack direction={"row"} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>
              <Typography variant="caption" sx={{ ml: -4, color: '#565656', fontFamily: 'Inter' }}>Created on :  </Typography> &nbsp;
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, paddingLeft: "1px", fontFamily: 'Inter' }}
              >
                {formatDate(created)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3} md={3} lg={3} xl={3}>
            <Stack direction={"row"} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>
              <Typography variant="caption" sx={{ fontFamily: 'Inter' }}>Last updated : </Typography>&nbsp;
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, paddingLeft: "5px", fontFamily: 'Inter' }}
              >
                {formatDate(updated)}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={4} md={3} lg={3} xl={3} mt={1}>
            <Stack
              direction={"row-reverse"}
              spacing={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              sx={{ ml: 4, mt: -1 }}
            >
              {location.pathname === "/plans" && (
                <> <Tooltip title={plan?.status === "CREATED" ? "Delete" : "You Can't Delete Active plans"} placement="top">
                  <IconButton>
                    {plan?.status === "PUBLISHED" ? (
                      <DeleteIcon
                        sx={{
                          cursor: "pointer", color: '#7A7A7A', fontSize: '20px',
                          "&:hover": {
                            color: "#3C5AA0",
                          }
                        }}
                        onClick={(e: any) => { handleOpen(e, plan) }}
                      />
                    ) : (
                      <DeleteIcon
                        sx={{ cursor: "not-allowed", color: '#B3B3B3', fontSize: '20px', }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
                  <Tooltip title={plan?.status === "CREATED" ? "Go to Publish" : "You Can't Edit Active plans"} placement="top">
                    <IconButton>
                      {plan?.status === "CREATED" ? (
                        <EditIcon
                          sx={{
                            cursor: "pointer", fontSize: '20px', color: '#7A7A7A', "&:hover": {
                              color: "#3C5AA0",
                            }
                          }}
                          onClick={() => navigate(`/plans/new/${plan?._id}`)}
                        />
                      ) : (
                        <EditIcon
                          sx={{ cursor: "not-allowed", color: '#B3B3B3', fontSize: '20px', }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>


                  <Tooltip title="Preview" placement="top">
                    <IconButton>
                      <RemoveRedEyeOutlinedIcon sx={{
                        cursor: 'pointer', fontSize: '20px', color: '#7A7A7A', "&:hover": {
                          color: "#3C5AA0",
                        }
                      }} onClick={handleClickOpenPreview} />
                    </IconButton>
                  </Tooltip>
                  {plan?.status === "CREATED" && (
                    <Button

                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        background: "#3C5AA0",
                        // fontWeight:600,
                        color: "white",
                        fontWeight: 500,
                        borderRadius: '30px',
                        boxShadow: 'none',
                        fontSize: { xs: '13px', md: '15px' },
                        height: { xs: '3vh', md: '5vh' },
                        fontFamily: 'Inter',
                        "&:hover": {
                          backgroundColor: "#3C5AA0", // Background color on hover
                          cursor: "pointer",
                          border: 'none',
                        }

                      }}
                      // onChange={(e: any) => setPlanValues(e.target.value)}
                      // onClick={handleSubmit}
                      // onClick={(e) => handleSubmit(e, "ACTIVE")}
                      onClick={() => navigate(`/plans/new/${plan?._id}`)}
                    >
                      Publish
                    </Button>
                  )}
                </>
              )}

            </Stack>
          </Grid>
        </Grid>
      </Paper>

      {/* Pop Up for Preview Plan Display */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openPreview}
        onClose={handleClickOpenPreview}

      >
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Stack>

            </Stack>
            <Stack>

            </Stack>
            <Stack sx={{ backgroundColor: '#F0F9FF' }}>
              <Box
                onClick={handleClickClosesPreview}
                sx={{
                  backgroundColor: "#3C5AA0",
                  padding: "4px",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "10px",
                  mt: "0.5px",
                  mr: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    // backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                <Close sx={{ fontSize: "15px" }} />
              </Box>
            </Stack>
          </Stack>
        </DialogActions>
        <DialogContent>
          <Box>
            <Paper sx={{ mt: 3 }} elevation={0}  >
              <Grid container spacing={{ xs: 1, sm: 1, md: 2 }}>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Stack sx={{ display: { xs: "none", md: "inline-block" } }}>
                    <img
                      alt=""
                      onClick={handleClickClosesPreview}
                      style={{
                        width: "100%",
                        height: "25vh",
                        // maxHeight: '25vh',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        cursor: "pointer",
                      }}
                      src={(plan?.images) ? plan?.images?.value : require("./../../assets/images/Plans.png")}
                    ></img>
                  </Stack>
                  <Stack sx={{ display: { xs: "inline-block", md: "none" } }}>
                    <Chip
                      label={
                        plan?.interval + "  " + getStaticValue(plan?.duration) + " - Starts From - " + formattedDate
                      }
                      sx={{ width: { xs: "100%", md: "50%" }, fontWeight: 600, border: '1px solid #3C5AA0', backgroundColor: 'transparent', fontSize: '14px' }}
                    ></Chip>
                  </Stack>


                </Grid>

                <Grid item xs={5} md={7} lg={7} xl={7}>

                  <Stack direction={"column"} spacing={1}>
                    <Stack sx={{ display: { xs: "none", md: "inline-block" } }}>
                      <Chip
                        label={
                          plan?.interval + " " + getStaticValue(plan?.duration) + " - Starts From - " + formattedDate
                        }
                        style={{ width: "40%", fontWeight: 600, border: '1px solid #3C5AA0', backgroundColor: 'transparent', }}
                      ></Chip>
                    </Stack>

                    <Typography variant="h5" sx={{ fontWeight: 500, fontSize: { xs: '16px', md: '20px' }, color: '#000000', fontFamily: 'Inter' }}>
                      {plan?.name}
                    </Typography>
                    <SimpleBar style={{ height: '150px', overflowY: 'auto' }}>
                      <Typography
                        variant="caption"
                        paragraph={true}
                        className="description"
                        sx={{ color: '#565656', fontSize: '13px', overflow: 'hidden', textOverflow: 'ellipsis', pr: 2, fontFamily: 'Inter' }}
                      >
                        {showFullDescription ? plan?.description : plan?.description.slice(0, 500) + ' '}
                        &nbsp; &nbsp;
                        {plan?.description.length > 500 && (
                          <Button
                            size="small"
                            onClick={handleToggleDescription} sx={{
                              color: "#50A1CA",
                              fontWeight: "400",
                              fontSize: '12px',
                              textTransform: 'capitalize',
                              textDecoration: 'underline',
                              cursor: "pointer",
                              fontFamily: 'Inter'
                            }}>
                            {showFullDescription ? 'Show Less' : 'Show More'}
                          </Button>
                        )}
                      </Typography>
                    </SimpleBar>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{}}
                >
                  <Stack
                    display={"flex"}
                    justifyContent={"right"}
                    alignItems={"center"}
                    direction={"row"}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "4px",
                        animation: "wave 1s infinite alternate",
                        backgroundColor: plan?.status === "CREATED" ? "#ff4f00" : "#27AE1B",
                      }}
                    ></span>
                    <Typography sx={{ fontSize: { xs: "13px", md: "15px", fontFamily: 'Inter', fontWeight: '500' } }}>
                      {getStaticValue(plan?.status)}
                    </Typography>
                  </Stack>
                  <Stack sx={{ flexGrow: 2, }} direction={"row"} display={"flex"} alignItems={'end'} >
                    <Stack
                      direction={"row"}
                      alignItems="end"
                      justifyContent={"end"}
                      spacing={2}
                      sx={{ flexGrow: 1, mr: 0 }}
                    >
                      <GroupsIcon sx={{ color: '#787878' }} />
                      <Stack alignItems={"center"} spacing={1} justifyContent={"right"}>

                        <Typography sx={{ color: "#565656", fontWeight: "400", fontSize: "12px", textAlign: 'end', fontFamily: 'Inter' }}>
                          {plan?.subscribers?.length > 1 ? `${plan.subscribers.length} Subscribers` : 'No Subscribers'}
                        </Typography>

                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                  >
                    <Typography sx={{ color: "#787878", fontFamily: 'Inter' }}>₹</Typography>
                    <Typography
                      variant="subtitle1"
                      color="#3C5AA0"
                      fontWeight={"500"}
                      sx={{ fontSize: "20px", fontFamily: 'Inter' }}
                    >
                      {(plan?.offerValue === 0) ? plan?.totalPlanValue : plan?.offerValue} / {plan?.interval}{" "}{plan?.interval > "1" ? `${getStaticValue(plan?.duration)}s` : getStaticValue(plan?.duration)}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                  >
                    {plan.offerValue !== 0 ? (
                      <>
                        <Typography sx={{ color: '#787878' }}>₹</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: '#787878',
                            fontSize: '2vh',
                            textAlign: 'right',
                            fontFamily: 'Inter',
                            textDecorationLine: 'line-through',
                          }}
                        >
                          {plan?.totalPlanValue}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )
                    }
                  </Stack>
                </Grid>
              </Grid>

            </Paper></Box>
        </DialogContent>
      </Dialog>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
            fontFamily: 'Inter'
          }}
        >
          Are you sure?
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Do you really want to delete this plan?<Typography sx={{ fontWeight: 600, fontSize: '15px', textAlign: "center", fontFamily: 'Inter' }}>
            {plan?.name}
          </Typography>
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeletePlan}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",

                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};





export const Plans = () => {
  const { getCommunityPlansList } = usePlans();
  const [openPlans, setOpenPlans] = React.useState(false);
  const [fullWidth,] = React.useState(true);
  const [maxWidth,] = React.useState<DialogProps["maxWidth"]>("lg");
  const state = store.getState();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl,] = React.useState<HTMLButtonElement | null>(null);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });

  const communitiesSuggestion = useSelector(() => {
    return state?.communities;
  });
  // const filteredSelectedCommunity = communitiesSuggestion?.communities?.filter(
  //   (community) => community?._id === selectedCommunity?.selectedCommunity?.id
  // );

  let filteredSelectedCommunity: any = [];//= communitiesSuggestion?.communities?.filter(
  //   (community: any) =>
  //     community._id === selectedCommunity.selectedCommunity?.id
  // );
  if (Array.isArray(communitiesSuggestion?.communities)) {
    // Filter the array if it is not null or undefined
    filteredSelectedCommunity = communitiesSuggestion.communities.filter(
      (community: any) =>
        community._id === selectedCommunity.selectedCommunity?._id
    );
    // Use the filtered array here
  }

  // const [selectedCommunityId, setSelectedCommunityId] = useState(selectedCommunity.selectedCommunity?.id)
  // const selectedCommunityId = selectedCommunity?.selectedCommunity?.id;


  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );


  const loadPlans = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getCommunityPlansList(
          selectedCommunityId
        );
        if (plans_data) {
          setIsLoading(false);
          setPlans(plans_data);
        }
      }
    }
    catch (error) {
      console.log('Error: ', error);
    }
    finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    loadPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;



  const handleClickOpenPlans = () => {
    setOpenPlans(true);
  };
  const handleClose = () => {
    setOpenPlans(false);
  };



  return (
    <>
      <Helmet>
        <title>{metaConfig.title.plans}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={"column"} spacing={1} >
        <SubNavbar>
          <Stack>
            <Typography
              sx={{
                fontSize: { xs: "15px", md: '20px' },
                fontWeight: 500,
                color: "#3C5AA0",
                fontFamily: "Inter",
              }}
            >
              Plans
            </Typography>
          </Stack>
          <Stack direction={"row-reverse"} flexGrow={1}>
            <Button
              variant="contained"
              onClick={handleClickOpenPlans}
              startIcon={<AddCircleRoundedIcon />}
              sx={{
                textTransform: "capitalize",
                borderRadius: "30px",
                backgroundColor: "#3C5AA0",
                fontFamily: 'Inter',
                boxShadow: 'none',
                "&:hover": {
                  backgroundColor: "#3C5AA0", // Background color on hover
                  cursor: "pointer",
                  border: 'none',
                }
              }}
            >
              Add Plan
            </Button>


            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openPlans}
              onClose={handleClose}
            >
              <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ backgroundColor: "#F0F9FF" }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      textDecoration: "none",
                      color: "#3C5AA0",

                      marginLeft: 2,
                    }}
                  >
                    {id ? "Publish" : "Create"} a Plan
                  </Typography>
                  <Box
                    onClick={handleClose}
                    sx={{
                      backgroundColor: "#3C5AA0",
                      padding: "4px",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "10px",
                      mt: "0.5px",
                      mr: "10px",
                      cursor: "pointer",
                      "&:hover": {

                        cursor: "pointer",
                        border: "none",
                      },
                    }}
                  >
                    <Close sx={{ fontSize: "15px" }} />
                  </Box>
                </Grid>
              </DialogActions>

              <DialogContent>
                <NewAddPlan selectedCommunity={filteredSelectedCommunity} closeDialog={handleClose} loadPlans={loadPlans} />
              </DialogContent>
            </Dialog>
          </Stack>

        </SubNavbar>

        {isLoading ? ( // Show loader while loading
          <Loader /> //
        ) : plans.length === 0 ? (
          <NoPlans />
        ) : (
          <Stack direction="column" spacing={2}>
            {plans?.map((plan: IPlan) => (
              <Plan plan={plan} key={plan?._id} loadPlans={loadPlans} />
            ))}
          </Stack>
        )}
        <Box pb={1.5}>

        </Box>
      </Stack>
    </>
  );
};
