

export const metaConfig = {
  description:
    "Automate Community Management with AI-Powered Subscription effortlessly Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics",
  keywords : "Automate Community Management with AI-Powered Subscription effortlessly Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics",
  author:"Onecommunn",  
  title: {
    loginPage: "Login / Register - Onecommunn - Build & Manage your community",
    exploreCommunities:
      "Explore Communities - Onecommunn - Build & Manage your community",
      myProfile: "Profile - Onecommunn - Build & Manage your community",
      editProfile: "Edit Profile - Onecommunn - Build & Manage your community",
      home : "Home - Onecommunn - Build & Manage your community",
      post :" Manage Posts - Onecommunn - Build & Manage your community",
      members:"Manage Members  - Onecommunn - Build & Manage your community",
      invitations:"Manage Invitations  - Onecommunn - Build & Manage your community",
      requestsandInvites : "Requests & Invites  - Onecommunn - Build & Manage your community",
      payments : "Manage Payments  - Onecommunn - Build & Manage your community",
      paymentsRequests : "Manage Payment Requests  - Onecommunn - Build & Manage your community",
      plans : "Manage Plans  - Onecommunn - Build & Manage your community",
      courses: "Manage Courses  - Onecommunn - Build & Manage your community",
      addCourses: "Add Course  - Onecommunn - Build & Manage your community",
      communities: "Manage Communities  - Onecommunn - Build & Manage your community",
      createCommunity:"Create Community  - Onecommunn - Build & Manage your community",
      aboutCommunity:"About Community  - Onecommunn - Build & Manage your community",
      trendingCommunities:"Trending Communities  - Onecommunn - Build & Manage your community",
      joinedCommunities:"Joined Communities  - Onecommunn - Build & Manage your community",
      invitesCommunities:"Invites Communities  - Onecommunn - Build & Manage your community",
      adminCommunities:"Admin Communities  - Onecommunn - Build & Manage your community",
  },


  }
  

