import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [    
  {
    question: "What is build community?",
    answer: "Build Community is a feature in Communn that empowers community admins to create a vibrant online space. In this section, you provide essential details about your community, such as a compelling description, the services you offer, communication details, and other key information. It's essentially the canvas where you showcase what makes your community unique and engaging.",
    _id: ""
  },
  {
    question: "How to make my community live?",
    answer: "To make your community live on Communn, you need to complete three crucial steps-Build Your Community: Fill in all the details about your community in the 'Build Community' section, highlighting its essence and offerings.Create Subscriptions: Define subscription plans for your community under the 'Create Subscriptions' tab. These plans not only monetize your community but also make it active and accessible to users who wish to join.Provide your Account Details: Provide your bank account details in the designated section. This ensures that all the money paid by members as subscriptions reflects directly in your account. It's a crucial step for seamless financial management, allowing you to track and utilize the funds contributed by your community members.If your community is free then you don't have to create subscriptions.",
    _id: ""
  },
  {
    question: "Why can’t I invite members without making my community live?",
    answer: "Making your community live is a necessary step to ensure that members have a complete and engaging experience. When your community is live, members can explore its details, understand its offerings, and decide to join. This process ensures transparency and helps in creating an active and thriving community environment. Inviting members after making your community live ensures that they join a well-defined and accessible community.",
    _id: ""
  },
  {
    question: "How does the approval process work for joining a private community?",
    answer: "In a private community, new member requests are reviewed by the admin, who has the authority to approve or deny them based on the community's criteria.",
    _id: ""
  },
  {
    question: "How can I collect subscriptions from community members, and what are the payment options available?",
    answer: "Our platform offers built-in subscription management tools. You can set up subscription plans, define pricing, and choose from various payment options, such as credit/debit cards, UPI,netbanking, BNPL.",
    _id: ""
  },
  {
    question: "Is there a limit to the number of communities I can create?",
    answer: "No, there is no limit to the number of communities you can create. You can create multiple communities based on different interests, topics, or target audiences.",
    _id: ""
  },
  {
    question:"How can I promote my community and attract more members?",
    answer:"We provide various promotional tools and features, such as community invitations, social media sharing, and integration with popular marketing platforms. Additionally, you can utilize content marketing, collaborations, and online advertisements to attract more members to your community.",
    _id:""
  }
];

const FAQ = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Stack sx={{ backgroundColor: 'white', borderRadius: "6px", display: { xs: "0", sm: "600", md: "900", lg: "1200", xl: "1536" } }}>
      <Stack alignContent={"center"} justifyContent={'center'} sx={{ p: 1.5 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#5F5F5F', textAlign: 'center' }}>Frequently Asked Questions</Typography>
        </Stack>
        

      <Stack>
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            elevation={0}
            disableGutters={true}
            sx={{ borderTop: "1px solid #00000030", minHeight: "auto", flexShrink: 0 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ borderTop: "red" }}
            >
              <Typography sx={{ top: 0, fontSize: "12px", color: "5F5F5F",textAlign:"justify" }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ top: 0, fontSize: "12px", color: "5F5F5F",textAlign:"justify" }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
       <Divider sx={{ height: '0.8px', backgroundColor: '#cccccc' }} />

      </Stack>
    </Stack>
  );
};

export default FAQ;
